import { LoadingButton } from "@mui/lab";
import { Box, Divider, Grid, Typography } from "@mui/material";
import ShowMoreText from "Components/Collapses/ShowMoreText";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { useGetUserListingDetailsQuery } from "features/listing/api/userApi";
import { Link, useParams } from "react-router-dom";
import { ContractIcon, HomeTabIcon } from "styles/icons";
import { reportBtnStyle } from "..";
import { handleOpenPDF } from "../../../features/helpers";
import WaiveRequiredList from "Components/Contingencies/WaiveRequiredList";
import { formatToMMM } from "Components/DatesAndTime/helpers";
import TextIcon from "../../Fields/TextIcon";
import TextInfo from "Components/Fields/TextInfo";
import { SaleType } from "features/listing/types";
import { Portal } from "features/users/types";
import { selectAuth } from "store/features/authSlice";
import { listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import {
  CalendarPrimaryIcon,
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  ListTabIcon,
  SaleTypeIcon,
} from "styles/icons";
import { formatUSD } from "utils/helpers";
import { rowsKeysStyle } from "..";

const UserListingDetailsTab: React.FC = (): JSX.Element => {
  const itemId = useParams().itemId || "";
  const { listing: agentListing } = useAppSelector(listingState);
  const { portal } = useAppSelector(selectAuth);
  const { data: listingData, isLoading: listingLoading } =
    useGetUserListingDetailsQuery(itemId, {
      skip: !itemId,
    });
  const listing = agentListing || listingData;
  const formatStartDate = formatToMMM(listing?.auction_start_date || "");
  const formatEndDate = formatToMMM(listing?.auction_end_date || "");
  const isReserved = listing?.reserved;
  const isAutoWinner = listing?.automatic_winner;
  const isOpenListing = listing?.sale_type === SaleType.OPEN;

  return (
    <ConditionalWrapper isLoading={listingLoading}>
      <Grid>
        <Box sx={{ my: 2 }}>
          <TextIcon
            text="Listing Details"
            variant="bigBoldText"
            fontColor="primary"
            icon={<HomeTabIcon stroke={"#6366f1"} />}
          />
        </Box>
        <Grid container>
          <ShowMoreText text={listingData?.description} />
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Grid container rowGap={2}>
          <Grid container>
            <Typography sx={rowsKeysStyle}>Sale Type:</Typography>
            <Grid item display="flex" gap={1}>
              <SaleTypeIcon sx={{ fontSize: "1.25rem" }} />
              <TextInfo
                text={isOpenListing ? "Open" : "Closed"}
                variant="extraSmallBoldText"
                fontColor={"primary.main"}
                infoText={
                  isOpenListing
                    ? "Offers are visible to other users"
                    : "Other user's offers will not be visible"
                }
              />
            </Grid>
          </Grid>
          <Grid container>
            <Typography sx={rowsKeysStyle}>Property Type:</Typography>
            <Typography
              variant="extraSmallRegularText"
              color="primary.dark"
            >
              {listing?.property_type}
            </Typography>
          </Grid>
          <Grid container>
            <Typography sx={rowsKeysStyle}>Starting Price:</Typography>
            <Typography variant="h6Bold" color="primary.dark">
              {formatUSD(listing?.price)}
            </Typography>
          </Grid>

          <Grid container>
            <Typography sx={rowsKeysStyle}>Offer Increment:</Typography>
            <TextInfo
              text={formatUSD(listing?.offer_increment)}
              variant="extraSmallBoldText"
              infoText="Minimum increment amount for counteroffers."
            />
          </Grid>

          <Grid container>
            <Typography sx={rowsKeysStyle}>Auction Start Date:</Typography>
            <TextIcon
              text={formatStartDate}
              fontColor="grey.800"
              variant="extraSmallRegularText"
              icon={<CalendarPrimaryIcon />}
            />
          </Grid>
          <Grid container>
            <Typography sx={rowsKeysStyle}>Auction End Date:</Typography>
            <TextIcon
              text={formatEndDate}
              fontColor="grey.800"
              variant="extraSmallRegularText"
              icon={<CalendarPrimaryIcon />}
            />
          </Grid>
          {isReserved && (
            <Grid container>
              <Typography sx={rowsKeysStyle}>Reserved Sale:</Typography>
              <Typography variant="extraSmallRegularText" color={"grey.800"}>
                {formatUSD(listing?.reserved_amount)}
              </Typography>
            </Grid>
          )}
          {!isOpenListing && (
            <Grid container>
              <Typography sx={rowsKeysStyle}>Automatic Winner:</Typography>
              <Grid item lineHeight={0}>
                {isAutoWinner ? (
                  <CheckboxCheckedIcon />
                ) : (
                  <CheckboxUncheckedIcon />
                )}
              </Grid>
            </Grid>
          )}
          {listing?.contingencies && listing?.contingencies?.length > 0 && (
            <Grid container>
              <Typography sx={rowsKeysStyle}>
                Required Waived Contingencies:
              </Typography>
              <WaiveRequiredList contingencies={listing?.contingencies} />
            </Grid>
          )}
          <Grid container>
            <Typography sx={rowsKeysStyle}>Security Hold:</Typography>
            <TextInfo
              text={formatUSD(listing?.security_hold)}
              variant="extraSmallBoldText"
              infoText="Penalty if the winning offeror fails to sign contract"
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Grid item>
          <Link to="#" onClick={() => handleOpenPDF(listingData?.title_report)}>
            <LoadingButton
              size="medium"
              variant="secondary"
              sx={{ ...reportBtnStyle, mr: 2 }}
              startIcon={
                <ContractIcon stroke={"#6366f1"} sx={{ width: "1rem" }} />
              }
            >
              Title Report
            </LoadingButton>
          </Link>
          <Link
            to="#"
            onClick={() => handleOpenPDF(listingData?.inspection_report)}
          >
            <LoadingButton
              sx={reportBtnStyle}
              variant="secondary"
              startIcon={
                <ContractIcon stroke={"#6366f1"} sx={{ width: "1rem" }} />
              }
            >
              Inspection Report
            </LoadingButton>
          </Link>
        </Grid>
      </Grid>
    </ConditionalWrapper>
  );
};

export default UserListingDetailsTab;
