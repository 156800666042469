import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const OfferDetailsIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.9331 1.1397L2.79004 4.244L10.8576 4.95292L9.31595 1.56777C9.07573 1.04029 8.42932 0.840196 7.9331 1.1397Z"
          fill="#6366F1"
        />
        <path
          d="M1.54517 10.9206C1.70753 11.8426 2.44484 12.5834 3.37039 12.7238C4.28941 12.8632 5.11928 13.0002 6.39385 13.0002C7.66841 13.0002 8.49829 12.8632 9.4173 12.7238C10.3429 12.5834 11.0802 11.8426 11.2425 10.9206C11.3678 10.2093 11.482 9.59154 11.482 8.39656C11.482 7.20159 11.3678 6.58387 11.2425 5.87253C11.0802 4.95058 10.3429 4.20973 9.4173 4.06931C8.49829 3.92988 7.66841 3.79297 6.39385 3.79297C5.11928 3.79297 4.28941 3.92988 3.37039 4.06931C2.44484 4.20973 1.70752 4.95058 1.54517 5.87253C1.41991 6.58387 1.30566 7.20159 1.30566 8.39656C1.30566 9.59154 1.41991 10.2093 1.54517 10.9206Z"
          fill="transparent"
        />
        <path
          d="M1.54517 10.9206C1.70753 11.8426 2.44484 12.5834 3.37039 12.7238C4.28941 12.8632 5.11928 13.0002 6.39385 13.0002C7.66841 13.0002 8.49829 12.8632 9.4173 12.7238C10.3429 12.5834 11.0802 11.8426 11.2425 10.9206C11.3678 10.2093 11.482 9.59154 11.482 8.39656C11.482 7.20159 11.3678 6.58387 11.2425 5.87253C11.0802 4.95058 10.3429 4.20973 9.4173 4.06931C8.49829 3.92988 7.66841 3.79297 6.39385 3.79297C5.11928 3.79297 4.28941 3.92988 3.37039 4.06931C2.44484 4.20973 1.70752 4.95058 1.54517 5.87253C1.41991 6.58387 1.30566 7.20159 1.30566 8.39656C1.30566 9.59154 1.41991 10.2093 1.54517 10.9206Z"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M11.6938 9.80158H8.81705C8.01415 9.80158 7.36328 9.1507 7.36328 8.34781C7.36328 7.54492 8.01416 6.89404 8.81705 6.89404H11.6938C12.246 6.89404 12.6938 7.34176 12.6938 7.89404V8.80158C12.6938 9.35386 12.246 9.80158 11.6938 9.80158Z"
          fill="white"
        />
        <path
          d="M11.6938 9.80158H8.81705C8.01415 9.80158 7.36328 9.1507 7.36328 8.34781C7.36328 7.54492 8.01416 6.89404 8.81705 6.89404H11.6938C12.246 6.89404 12.6938 7.34176 12.6938 7.89404V8.80158C12.6938 9.35386 12.246 9.80158 11.6938 9.80158Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M2.79004 4.244L7.9331 1.1397C8.42932 0.840196 9.07573 1.04029 9.31595 1.56777L10.8576 4.95292"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default OfferDetailsIcon;
