import * as yup from "yup";

const fullNameSchema = yup
  .string()
  .required("Full Name is required")
  .matches(/^[a-zA-Z\s]+$/, "Only English letters are allowed")
  .min(7, "Minimum 7 characters")
  .max(50, "Maximum 50 characters");

export { fullNameSchema };
