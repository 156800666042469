import { LoadingButton } from "@mui/lab";
import { ButtonProps } from "@mui/material";
import { ListingStatus } from "features/listing/types";
import { selectAuth } from "../../store/features/authSlice";
import { useAppSelector } from "../../store/hooks";
import { Portal } from "features/users/types";

type ExtendedButtonProps = {
  status: ListingStatus | "Highest" | null | undefined;
  sx?: object;
  variant?: string;
} & ButtonProps;

const StatusButton: React.FC<ExtendedButtonProps> = ({
  status,
  sx,
  variant,
}): JSX.Element | null => {
  const { portal } = useAppSelector(selectAuth);
  const isUser = portal === Portal.USER || portal === null;
  switch (status) {
    case ListingStatus.LIVE:
      return (
        <LoadingButton
          variant="info"
          size="small"
          sx={{ ...statusStyle, ...sx, pointerEvents: "none" }}
        >
          Live
        </LoadingButton>
      );
    case ListingStatus.SOLD:
      return (
        <LoadingButton
          variant="grey"
          size="small"
          sx={{ ...statusStyle, ...sx, pointerEvents: "none" }}
        >
          Sold
        </LoadingButton>
      );
    case ListingStatus.IN_CONTRACT:
      return (
        <LoadingButton
          variant="warning"
          size="small"
          sx={{ ...statusStyle, ...sx, pointerEvents: "none" }}
        >
          In Contract
        </LoadingButton>
      );
    case ListingStatus.NO_WINNER:
    case ListingStatus.FAILED_CONTRACT:
    case ListingStatus.FAILED_SALE:
    case ListingStatus.NO_OFFERS:
      return (
        <LoadingButton
          variant="error"
          size="small"
          sx={{ ...statusStyle, ...sx, pointerEvents: "none" }}
        >
          Failed
        </LoadingButton>
      );
    case ListingStatus.PENDING_CONTRACT:
      return (
        <LoadingButton
          variant="warningLight"
          size="small"
          sx={{ ...statusStyle, ...sx, pointerEvents: "none" }}
        >
          Pending Contract
        </LoadingButton>
      );

    case ListingStatus.PENDING_WINNER:
      return (
        <LoadingButton
          variant="warning"
          size="small"
          sx={{ ...statusStyle, ...sx, backgroundColor: "warning.light" }}
        >
          Pending Winner
        </LoadingButton>
      );
    case "published":
    case "publish_scheduled":
      return (
        <LoadingButton
          variant={isUser ? "success" : "primary"}
          size="small"
          sx={{ ...statusStyle, ...sx, pointerEvents: "none" }}
        >
          {isUser ? "Coming Soon" : "Published"}
        </LoadingButton>
      );
    case ListingStatus.DRAFT:
      return (
        <LoadingButton
          variant="success"
          size="small"
          sx={{ ...statusStyle, ...sx, pointerEvents: "none" }}
        >
          Draft
        </LoadingButton>
      );
    case "Highest":
      return (
        <LoadingButton
          variant={variant || "primary"}
          size="small"
          sx={{ ...statusStyle, ...sx, pointerEvents: "none" }}
        >
          Highest
        </LoadingButton>
      );
    default:
      return null;
  }
};

export const statusStyle = {
  borderRadius: 3,
  padding: "2px 8px",
  gap: 0.5,
  minWidth: 40,
  height: 24,
  alignSelf: "start",
  fontSize: "0.75rem",
  "&:hover": {
    // Change this to the desired background color
  },
};

export default StatusButton;
