import { LoadingButton } from "@mui/lab";
import { ButtonProps } from "@mui/material";
import { OfferorStatus } from "features/offerors/api";

type ExtendedButtonProps = {
  status: OfferorStatus | null | string;
  sx?: object;
} & ButtonProps;

const RequestStatusButton: React.FC<ExtendedButtonProps> = ({
  status,
  sx,
}): JSX.Element | null => {
  const data = getRequestStatusData(status);
  return (
    <LoadingButton
      variant={data.variant}
      size="small"
      sx={{ ...statusStyle, ...sx, pointerEvents: "none" }}
    >
      {data.statusText}
    </LoadingButton>
  );
};

export default RequestStatusButton;

const getRequestStatusData = (
  status: OfferorStatus | null | string
): {
  variant: any;
  statusText: string;
} => {
  switch (status) {
    case "approved":
      return { variant: "success", statusText: "Approved" };
    case "pending":
      return { variant: "warningLight", statusText: "Pending" };
    case "pending_payment":
      return { variant: "warningLight", statusText: "Pending Payment" };
    case "denied":
    case "declined":
      return { variant: "error", statusText: "Denied" };
    case "payment_failed":
      return { variant: "error", statusText: "Payment Failed" };
    default:
      return { variant: "error", statusText: "error" };
  }
};
const statusStyle = {
  borderRadius: 3,
  padding: "2px 8px",
  gap: 0.5,
  minWidth: 40,
  height: 24,
  alignSelf: "start",
  fontSize: "0.75rem",
  "&:hover": {
    // Change this to the desired background color
  },
};
