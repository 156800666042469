import { Typography } from "@mui/material";

export type ValidationItem = {
  text: string;
  validationFunction: Function;
};

interface ValidationTextListProps {
  password?: string;
  firstName?: string;
  lastName?: string;
  validationList: ValidationItem[];
}

const ValidationTextList: React.FC<ValidationTextListProps> = ({
  password,
  firstName,
  lastName,
  validationList,
}): JSX.Element => {
  return (
    <Typography
      sx={{ my: 1, listStylePosition: "inside", pl: 1 }}
      component={"ul"}
    >
      {validationList.map((item, idx) => (
        <Typography
          key={idx}
          variant="extraSmallRegularText"
          color={
            item.validationFunction(password, { firstName, lastName })
              ? "success.main"
              : "text.secondary"
          }
          lineHeight={"1.45rem"}
          fontWeight={
            item.validationFunction(password, { firstName, lastName })
              ? "500"
              : "300"
          }
          component={"li"}
        >
          {item.text}
        </Typography>
      ))}
    </Typography>
  );
};

export default ValidationTextList;
