import { Box, Typography } from "@mui/material";
import { useTimer } from "react-timer-hook";
import TimerFieldIcon from "styles/icons/TimerFieldIcon";
import { getTimerString } from "./TimerField";

interface ITimerField {
  expiryTimestamp: Date;
  sx?: object;
  withIcon?: boolean;
  units?: 2 | 3 | 4;
}

const TimerField: React.FC<ITimerField> = ({
  expiryTimestamp,
  sx,
  withIcon,
  units = 3,
}): JSX.Element => {
  const isExpired = expiryTimestamp > new Date();
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp:
      expiryTimestamp > new Date() ? expiryTimestamp : new Date(),
  });
  let timerString = "";
  if (isExpired) {
    timerString = getTimerString(seconds, minutes, hours, days, units);
  }
  return (
    <Box sx={sx}>
      {withIcon && <TimerFieldIcon sx={{ width: 16, height: 16 }} />}
      <Typography
        variant="h6Bold"
        component={"span"}
        sx={{ textDecoration: isExpired ? "underline" : "none" }}
        color={isExpired ? "warning.dark" : "error.light"}
      >
        {isExpired ? `${timerString.trim()}` : "0d 0h 00m"}
      </Typography>
    </Box>
  );
};

export default TimerField;

export const auctionColumnStyle = {
  bgcolor: "common.white",
  gap: 0.5,
  display: "flex",
  alignItems: "center",
  ".MuiTypography-root": {
    fontSize: "0.875rem",
    color: "primary.main",

    fontWeight: 400,
  },
};
