import { Divider as MUIDivider, DividerProps } from "@mui/material";
import { useMediaQuery } from "@mui/material";

interface DividerWithOrientationProps extends DividerProps {
  orientation: "horizontal" | "vertical";
  breakpoint?: "none" | "xs" | "sm" | "md" | "lg" | "xl";
}

function Divider(props: DividerWithOrientationProps) {
  const { orientation, breakpoint = "none", ...rest } = props;
  const isScreenSmall = useMediaQuery((theme: any) =>
    breakpoint !== "none" ? theme.breakpoints.down(breakpoint) : "false"
  );
  const dividerOrientation =
    isScreenSmall && orientation === "horizontal"
      ? "vertical"
      : isScreenSmall && orientation === "vertical"
      ? "horizontal"
      : orientation;

  return <MUIDivider orientation={dividerOrientation} {...rest} />;
}

export default Divider;
