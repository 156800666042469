import { Portal, TPortal } from "../../features/users/types";

export const initialUserPortal = (
  usertypes: string[] | null | undefined
): TPortal => {
  if (usertypes?.includes("admin")) {
    return Portal.ADMIN;
  } else if (usertypes?.includes("agent")) {
    return Portal.AGENT;
  } else if (usertypes?.includes("user")) {
    return Portal.USER;
  } else {
    return null;
  }
};
