import { IMyOffer, IUserOfferorRequest } from "features/offers/api";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

export interface IOptions {
  includeOfferStep: boolean;
  offerorRequestData: IUserOfferorRequest | undefined;
  myOfferData?: IMyOffer | undefined;
  cvv?: string;
  isAddPaymentOpen: boolean;
}
interface IStepperErrors {
  registerError: string | null;
  offerError: string | null;
}
interface IListingDetailsStepperProps {
  resetStepsToZero: () => void;
  completedStep: number;
  setCompletedStep: Dispatch<SetStateAction<number>>;
  stepToShow: number;
  setStepToShow: Dispatch<SetStateAction<number>>;
  submitError: IStepperErrors;
  setSubmitError: Dispatch<SetStateAction<IStepperErrors>>;
  setOptions: Dispatch<SetStateAction<IOptions>>;
  options: IOptions;
  selectedPaymentMethod: any;
  setSelectedPaymentMethod: any;
}
const ListingDetailsStepper = createContext<
  IListingDetailsStepperProps | undefined
>(undefined);

export const useListingDetailsStepperContext =
  (): IListingDetailsStepperProps => {
    const context = useContext(ListingDetailsStepper);
    if (!context) {
      throw new Error(
        "useListingDetailsStepperContext must be used within his Provider"
      );
    }
    return context;
  };

export const ListingDetailsStepperProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }): JSX.Element => {
  const [completedStep, setCompletedStep] = useState<number>(0);
  const [stepToShow, setStepToShow] = useState<number>(0);
  const [submitError, setSubmitError] = useState<IStepperErrors>({
    registerError: null,
    offerError: null,
  });
  const [options, setOptions] = useState<IOptions>({
    includeOfferStep: false,
    offerorRequestData: undefined,
    cvv: undefined,
    isAddPaymentOpen: false,
    myOfferData: undefined,
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>(null);
  const resetStepsToZero = () => {
    setCompletedStep(0);
    setStepToShow(0);
    setSubmitError({
      registerError: null,
      offerError: null,
    });
    setOptions((prev) => ({
      ...prev,
      cvv: undefined,
    }));
  };

  return (
    <ListingDetailsStepper.Provider
      value={{
        completedStep,
        setCompletedStep,
        stepToShow,
        setStepToShow,
        submitError,
        setSubmitError,
        options,
        setOptions,
        resetStepsToZero,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
      }}
    >
      {children}
    </ListingDetailsStepper.Provider>
  );
};
