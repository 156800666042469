import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import {
  ControlledPassword,
  ControlledTextField,
} from "Components/FormComponents";
import { Link } from "Components/StyledComponents";
import ErrorText from "Components/StyledComponents/ErrorText";
import { useLoginUserMutation } from "features/auth/api/authApi";
import { LoginSchema } from "features/auth/schema/LoginSchema";
import type { ErrorResponse } from "features/auth/types";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { setUser } from "store/features/authSlice";
import { useAppDispatch } from "store/hooks";
import { ForgotPassword } from "./forgotPassword";
import { useGetUserInfoQuery } from "features/users";

interface ILoginFormInputs {
  email: string;
  password: string;
}

const Login: React.FC = (): JSX.Element => {
  const [step, setStep] = useState<number>(1);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isFromEmailVerification = location.pathname === "/verify-email";
  const isFromUpdatedEmailVerification = location.pathname === "/update-email";
  const navigate = useNavigate();
  const [
    loginUser,
    {
      data: loginData,
      isLoading,
      error: loginError,
      isSuccess: isLoginSuccess,
    },
  ] = useLoginUserMutation();
  const { handleSubmit, control } = useForm<ILoginFormInputs>({
    resolver: yupResolver(LoginSchema),
  });
  const { data: userInfo, isSuccess: isUserInfoSuccess } = useGetUserInfoQuery(
    undefined,
    {
      skip: !isLoginSuccess,
    }
  );
  const errorMessage = (loginError as ErrorResponse)?.data?.message || "";
  const handleLoginForm: SubmitHandler<ILoginFormInputs> = ({
    email,
    password,
  }: ILoginFormInputs) => {
    loginUser({ email, password });
  };
  useEffect(() => {
    if (isUserInfoSuccess) {
      dispatch(
        setUser({
          username: loginData?.user_name,
          email: userInfo?.user.email,
          userTypes: loginData?.user_types,
          userId: loginData?.user_id,
          agentRequestStatus: loginData?.agent_request_status,
          portal: null,
          verified: loginData?.verified,
        })
      );
      if (isFromEmailVerification) navigate("/verify-email");
      else if (isFromUpdatedEmailVerification) navigate("/update-email");
    }
  }, [isLoginSuccess, isUserInfoSuccess]);

  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12} md={10} lg={8}>
        {step === 1 ? (
          <Box
            onSubmit={handleSubmit(handleLoginForm)}
            component="form"
            p={{ xs: 2, sm: 5 }}
            my={3}
            sx={{
              bgcolor: "secondary.main",
              color: "text.primary",
              borderRadius: 1.5,
            }}
          >
            <Typography variant="h5Bold" color={"primary"}>
              Login
            </Typography>
            <Typography variant="smallRegularText">
              Login to your account to access your listings.{" "}
            </Typography>
            <Grid
              container
              justifyContent={"space-between"}
              mt={3}
              sx={borderLoginFormStyle}
            >
              <ControlledTextField
                fullWidth
                control={control}
                name="email"
                inputMode="email"
                label="Email Address"
                maxLength={50}
              />
              <ControlledPassword
                fullWidth
                control={control}
                name="password"
                label="Password"
                mb={0}
                placeholder="Enter Password"
              />
              <Grid item mt={0.5}>
                {errorMessage && (
                  <ErrorText color="error.main" text={errorMessage} />
                )}
              </Grid>
              <Grid container justifyContent="end" alignItems="center" mt={1}>
                <Typography onClick={() => setStep(2)} sx={forgotPassStyle}>
                  Forgot Password
                </Typography>
              </Grid>
            </Grid>
            <LoadingButton
              variant="primary"
              type="submit"
              loading={isLoading}
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </LoadingButton>
            {/* <GoogleButton /> */}
            <Typography
              sx={{ textAlign: "center", my: 1 }}
              color="text.secondary"
            >
              No Account?{" "}
              <Link
                to="/signup"
                style={{ color: "#6366F1", textDecoration: "underline" }}
              >
                Sign Up
              </Link>
            </Typography>
          </Box>
        ) : (
          <ForgotPassword step={step} setStep={setStep} />
        )}
      </Grid>
    </Grid>
  );
};

export default Login;

const forgotPassStyle = {
  cursor: "pointer",
  color: "#6366F1",
  textDecoration: "underline",
};

const borderLoginFormStyle = {
  border: "1px solid #E5E7EB",
  borderWidth: "1px 0",
  pt: 2,
  pb: 3,
};
