import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IListing, IListingDetails } from "features/listing/types";
import { RootState } from "..";

export type TListingDetails = { listing: IListingDetails };
export type TListing = { listing: IListingDetails };
type ListingState = {
  listing: IListing | IListingDetails | undefined;
};

const initialState: ListingState = {
  listing: undefined,
};

const listingSlice = createSlice({
  name: "listing",
  initialState,
  reducers: {
    setCurrentListing: (
      state,
      action: PayloadAction<IListing | IListingDetails | undefined>
    ) => {
      state.listing = action.payload;
    },
  },
});

export const listingState = (state: RootState) =>
  state?.listing as ListingState;

export const { setCurrentListing } = listingSlice.actions;

export default listingSlice.reducer;
