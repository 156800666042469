import { InfoOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

interface IProps {
  noBorder?: boolean;
  photoUrl: string;
  photoName?: string;
}
const HouseAvatar: React.FC<IProps> = ({
  noBorder = false,
  photoUrl,
  photoName,
}): JSX.Element => {
  return (
    <Box
      rowGap={noBorder ? 0.5 : 0}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      <img
        src={photoUrl}
        alt={photoName}
        style={{ ...imageStyle, ...(noBorder && noBorderStyle) }}
      />
      {noBorder && (
        <Box sx={descriptionStyle}>
          <InfoOutlined sx={{ fontSize: "0.75rem", mr: 0.5 }} color="info" />
          <Typography
            variant="extraSmallRegularText"
            color="#6B7280"
            fontWeight={300}
          >
            {photoName}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default HouseAvatar;

const imageStyle = {
  width: "94px",
  height: "94px",
  borderRadius: "8px",
  border: "3px solid rgba(238, 242, 255, 1)",
  outline: "3px solid rgba(238, 242, 255, 0.5)",
};

const noBorderStyle = {
  border: "none",
  outline: "none",
};

const descriptionStyle = {
  display: "flex",
  alignItems: "center",
  width: "100%",
};
