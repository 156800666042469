import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import TextIcon from "Components/Fields/TextIcon";
import { ListingStatus, SaleType } from "features/listing/types";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { StyledBadge } from "styles/components/StyledAvatar";
import { ArchiveIcon, BiddersIcon, NotificationIcon } from "styles/icons";

interface IProps {
  setRedirectToPublish?: (value: boolean) => void;
}

const TopRightButtonPanel: React.FC<IProps> = ({
  setRedirectToPublish,
}): JSX.Element => {
  const {
    listing: { status, sale_type },
  } = useAppSelector(listingState) as TListingDetails;
  const isClosed = sale_type === SaleType.CLOSED;

  const handleButtonClick = () => {
    let onClick = () => {};
    let text = "";
    let showBadge = false;
    let icon = null;
    switch (status) {
      case ListingStatus.LIVE:
        onClick = () => setRedirectToPublish && setRedirectToPublish(true);
        text = isClosed ? "View Offerors" : "View Requests";
        showBadge = true;
        icon = <NotificationIcon stroke={"#6366F1"} />;
        break;
      case ListingStatus.PENDING_WINNER:
        onClick = () => alert("PUBLISHED function");
        text = "Archived";
        icon = <ArchiveIcon />;
        break;
      default:
        break;
    }

    return { onClick: onClick, text, showBadge, icon };
  };

  const { text, onClick, showBadge, icon } = handleButtonClick();

  return (
    <Grid container>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <TextIcon
            text="Bidders"
            fontColor={"primary"}
            variant={"h6Bold"}
            icon={<BiddersIcon />}
          />
        </Grid>

        <Grid item mb={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {showBadge ? (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              variant="dot"
              sx={{
                "& .MuiBadge-badge": {
                  top: 0,
                  right: 0,
                },
              }}
            >
              <LoadingButton
                onClick={onClick}
                variant="outlined"
                startIcon={icon}
                sx={{ borderColor: "grey.30" }}
              >
                {text}
              </LoadingButton>
            </StyledBadge>
          ) : (
            <LoadingButton
              onClick={onClick}
              variant="outlined"
              startIcon={icon}
              sx={{ borderColor: "grey.30" }}
            >
              {text}
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TopRightButtonPanel;
