import type {
  IHighestOffer,
  IListing,
  IListingDetails,
} from "features/listing/types";

import { ephrownApi } from "store/services/ephrownApi";
import {
  getSortListings,
  transformListing,
  transformListingDetails,
} from "store/services/helpers";
import type { ListingParam } from "./apiTypes";
import {
  IHighestOfferResponse,
  IListingDetailsResponse,
  IListingsResponse,
  ISellingPriceResponse,
  IWinningOfferAmountResponse,
} from "./responseTypes";

const agentListingsApi = ephrownApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserListings: builder.query<IListing[], ListingParam>({
      query: (params) => ({
        url: "/api/pub/get-listings",
        params,
      }),
      providesTags: ["userListings", "agentListings",  "offers"],
      transformResponse: (response: IListingsResponse) => {
        const sortedListings = getSortListings(response.listings);
        return sortedListings.map((listing) => transformListing(listing));
      },
    }),

    getHighestOfferAmount: builder.query<IHighestOffer, string>({
      query: (listingId) => ({
        url: `/api/pub/highest-offer-amount/${listingId}`,
      }),
      providesTags: ["userListings", "agentListings",  "offers"],
      transformResponse: (response: IHighestOfferResponse) =>
        response?.highest_offer,
    }),

    getUserListingDetails: builder.query<IListingDetails, string>({
      query: (listingId) => ({
        url: `/api/pub/view-listing/${listingId}`,
      }),
      providesTags: ["userListings", "agentListings"],
      transformResponse: (response: IListingDetailsResponse) =>
        transformListingDetails(response),
    }),
    getWinnerInfo: builder.query<any, string>({
      query: (listingId) => ({
        url: `/api/private/user/winner-info/${listingId}`,
      }),
      providesTags: ["userListings", "agentListings",  "offers"],
    }),

    registerToOffer: builder.mutation({
      query: ({ body }) => ({
        url: `/api/private/user/register-to-offer`,
        method: "POST",
        body,
      }),
    }),

    editOfferor: builder.mutation({
      query: ({ listingId, body }) => ({
        url: `/api/private/user/offeror-request/${listingId}/edit-offeror-request`,

        method: "PATCH",
        body,
      }),
      invalidatesTags: [
        "userListings",
        "agentListings",
        "offers",
        "offerors",
      ],
    }),

    getSellingPrice: builder.query<number, string>({
      query: (listingId) => ({
        url: `/api/pub/selling-price/${listingId}`,
      }),
      providesTags: ["userListings", "agentListings",  "offers"],
      transformResponse: (response: ISellingPriceResponse) =>
        response.selling_price,
    }),
    getWinningOfferAmount: builder.query<number, string>({
      query: (listingId) => ({
        url: `/api/pub/winning-offer-amount/${listingId}`,
      }),
      providesTags: ["userListings", "agentListings",  "offers"],
      transformResponse: (response: IWinningOfferAmountResponse) =>
        response.winning_offer_amount,
    }),
    setFavorite: builder.mutation({
      query: (listingId) => ({
        url: `/api/private/user/set-favorite-listing/${listingId}`,
        method: "POST",
      }),
      invalidatesTags: ["userListings", "agentListings",  "offers"],
    }),
    unsetFavorite: builder.mutation({
      query: (listingId) => ({
        url: `/api/private/user/remove-favorite-listing/${listingId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
    Favorite: builder.mutation({
      query: (listingId) => ({
        url: `/api/private/user/set-favorite-listing/${listingId}`,
        method: "POST",
      }),
      invalidatesTags: ["userListings", "agentListings",  "offers"],
    }),
    updateUserEmail: builder.mutation({
      query: (email) => ({
        url: `/api/unverified/update-user-info/email`,
        method: "POST",
        body: { email },
      }),
      invalidatesTags: ["userListings"],
    }),
    updateUserPhone: builder.mutation({
      query: (phone) => ({
        url: `/api/unverified/update-user-info/phone`,
        method: "POST",
        body: { phone_number: phone },
      }),
      invalidatesTags: ["userListings"],
    }),
    updateUsername: builder.mutation({
      query: (username) => ({
        url: `/api/unverified/update-user-info/username`,
        method: "POST",
        body: { username },
      }),
      invalidatesTags: ["userListings"],
    }),
    updateUserPassword: builder.mutation({
      query: (password) => ({
        url: `/api/unverified/update-password`,
        method: "POST",
        body: password,
      }),
      invalidatesTags: ["userListings"],
    }),
    agentVerification: builder.mutation({
      query: ({ body }) => ({
        url: `/api/private/agent-verification/signup`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userListings"],
    }),
    updateUserAddress: builder.mutation({
      query: (body) => ({
        url: `/api/unverified/update-user-info/address`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userListings"],
    }),
  }),
});

export const {
  useGetUserListingsQuery,
  useGetHighestOfferAmountQuery,
  useGetUserListingDetailsQuery,
  useGetWinnerInfoQuery,
  useRegisterToOfferMutation,
  useGetSellingPriceQuery,
  useGetWinningOfferAmountQuery,
  useSetFavoriteMutation,
  useUnsetFavoriteMutation,
  useUpdateUserEmailMutation,
  useUpdateUserPhoneMutation,
  useUpdateUsernameMutation,
  useUpdateUserPasswordMutation,
  useAgentVerificationMutation,
  useEditOfferorMutation,
  useUpdateUserAddressMutation,
} = agentListingsApi;
