import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface CheckIconProps extends SvgIconProps {
  fill?: string;
}

const CheckIcon: React.FC<CheckIconProps> = ({
  sx,
  fill = "#6366F1",
  ...props
}) => {
  return (
    <SvgIcon
      viewBox="0 0 14 10"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.9375 9.71416L0.8125 5.58916L1.99083 4.41083L4.93875 7.35541L4.9375 7.35666L12.0083 0.285828L13.1867 1.46416L6.11583 8.53583L4.93833 9.71333L4.9375 9.71416Z"
          fill={fill}
        />
      </svg>
    </SvgIcon>
  );
};

export default CheckIcon;
