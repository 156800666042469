import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { StatusButton } from "Components/Buttons";
import { Link } from "Components/StyledComponents";
import { reportBtnStyle } from "Components/TabsPanel";
import { handleOpenPDF } from "features/helpers";
import { ListingStatus } from "features/listing/types";
import { formatFullName } from "features/listing/utils/listingsUtils";
import { IListingOfferorRequests } from "features/offerors/api";
import {
  IAgentOfferListing,
  IOfferData,
  formatOfferData,
} from "features/offers/api";
import { AttorneyCard } from "styles/components";
import { attorneyCardStyle } from "styles/components/AttorneyCard";
import { ContractIcon } from "styles/icons";
import { ShowFullAddress } from "utils/helpersComponents";
import StatusOfferButton from "./StatusOfferButton";

interface MyRequestItemProps {
  request: IListingOfferorRequests;
  listing: IAgentOfferListing;
}

const MyRequestItem: React.FC<MyRequestItemProps> = ({
  request,
  listing,
}): JSX.Element => {
  const offerData: IOfferData = formatOfferData(request);
  const attorneyFullName = formatFullName(
    offerData.attorneyFirstName,
    offerData.attorneyLastName
  );
  return (
    <Grid container sx={requestItemContainerStyle}>
      <Grid item sx={{ ...myRequestRowStyle, px: 2, width: 290 }}>
        <img
          style={apartmentImageStyle}
          src={
            listing?.main_photo ||
            "https://generatorfun.com/code/uploads/Random-House-image-2.jpg"
          }
          width="50"
          height="50"
        />
        <Grid
          container
          justifyContent={"start"}
          flexDirection={"column"}
          gap={1}
        >
          <StatusButton status={ListingStatus.LIVE} />
          <ShowFullAddress
            address_line_1={listing?.address_line_1}
            address_line_2={listing?.address_line_2}
            city={listing?.city}
            state={listing?.state}
            zip={listing?.zip}
            variant="extraSmallBoldText"
          />
          <Link sx={viewListingStyle} to={`/myListings/${listing?.id}`}>
            View Listing
          </Link>
        </Grid>
      </Grid>
      <Grid item sx={{ ...myRequestRowStyle, px: 3 }}>
        <Grid container flexDirection={"column"} gap={1}>
          <Grid item>
            <Typography variant="extraSmallBoldText" color="primary.main">
              Buyers Info:
            </Typography>
          </Grid>
          <Grid item>
            <AttorneyCard
              sx={attorneyCardStyle}
              hideAvatar
              name={`${offerData.firstName} ${offerData.lastName}`}
              phone={"offerData.userNumber"}
              email={"offerData.Email"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ ...myRequestRowStyle, px: 3 }}>
        <Grid container flexDirection={"column"} gap={1}>
          <Grid item>
            <Typography variant="extraSmallBoldText" color="primary.main">
              Buyers Attorney :
            </Typography>
          </Grid>
          <Grid item>
            <AttorneyCard
              hideAvatar
              name={attorneyFullName}
              phone={offerData.attorneyNumber}
              email={offerData.attorneyEmail}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={myRequestRowStyle}>
        <Grid
          container
          flexDirection={"column"}
          gap={1}
          alignItems={"stretch"}
          justifyContent={"stretch"}
        >
          <Grid item>
            <Link to="#" onClick={() => handleOpenPDF(offerData?.proofOfFunds)}>
              <LoadingButton
                sx={reportBtnStyle}
                variant="secondary"
                startIcon={
                  <ContractIcon stroke={"#6366f1"} sx={{ width: "1rem" }} />
                }
              >
                Preapproval
              </LoadingButton>
            </Link>
          </Grid>
          <Grid item>
            <Link to="#" onClick={() => handleOpenPDF(offerData?.proofOfFunds)}>
              <LoadingButton
                sx={reportBtnStyle}
                variant="secondary"
                startIcon={
                  <ContractIcon stroke={"#6366f1"} sx={{ width: "1rem" }} />
                }
              >
                Proof of funds
              </LoadingButton>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{ ...myRequestRowStyle, borderRight: "none", px: { xs: 2, md: 3 } }}
      >
        <Grid
          container
          flexDirection={"column"}
          gap={1}
          alignItems={"stretch"}
          justifyContent={"stretch"}
        >
          <StatusOfferButton request={offerData} pendingListing={listing} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MyRequestItem;

const requestItemContainerStyle = {
  justifyContent: "space-between",
  backgroundColor: "common.white",
  overflowX: "auto",
  border: "1px solid",
  borderColor: "grey.600",
  borderRadius: 1,
  flexWrap: "nowrap",
  px: 1,
  py: 3,
  my: 1,
};

const myRequestRowStyle = {
  width: 280,
  minWidth: 280,
  borderRight: "1px solid",
  borderColor: "grey.600",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  px: { xs: 2, md: 5 },
  wordBreak: "break-word",
};

const apartmentImageStyle = {
  border: "3px solid #EEF2FF",
  outline: "3px solid #F6F8FF",
  borderRadius: "4px",
  marginRight: "20px",
};

const viewListingStyle = {
  color: "info.main",
  fontSize: "0.75rem",
  textDecoration: "underline",
};
