import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ComputerIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.10961 8.81143C1.18902 9.25342 1.53328 9.60929 1.97919 9.66238C3.57185 9.852 5.26851 9.92002 7 9.92002C8.73517 9.92002 10.4033 9.85171 12.0179 9.66117C12.4651 9.60839 12.8114 9.25211 12.8895 8.80862C13.0533 7.87745 13.25 7.05648 13.25 5.39608C13.25 3.67597 13.1642 2.83284 12.9379 1.87003C12.8462 1.47998 12.5165 1.19306 12.1185 1.14641C10.4973 0.956366 8.767 0.872131 7 0.872131C5.22879 0.872131 3.49403 0.934033 1.86975 1.13975C1.47765 1.18941 1.15581 1.4751 1.06303 1.85929C0.827183 2.83588 0.75 3.59359 0.75 5.39608C0.75 7.11755 0.945984 7.90072 1.10961 8.81143Z"
          fill="#EEF2FF"
        />
        <path
          d="M1.10961 8.81143C1.18902 9.25342 1.53328 9.60929 1.97919 9.66238C3.57185 9.852 5.26851 9.92002 7 9.92002C8.73517 9.92002 10.4033 9.85171 12.0179 9.66117C12.4651 9.60839 12.8114 9.25211 12.8895 8.80862C13.0533 7.87745 13.25 7.05648 13.25 5.39608C13.25 3.67597 13.1642 2.83284 12.9379 1.87003C12.8462 1.47998 12.5165 1.19306 12.1185 1.14641C10.4973 0.956366 8.767 0.872131 7 0.872131C5.22879 0.872131 3.49403 0.934033 1.86975 1.13975C1.47765 1.18941 1.15581 1.4751 1.06303 1.85929C0.827183 2.83588 0.75 3.59359 0.75 5.39608C0.75 7.11755 0.945984 7.90072 1.10961 8.81143Z"
          stroke="#6366F1"
          strokeWidth="1.5"
        />
        <path
          d="M5.63867 9.92004H8.36075L9.11075 13.1279H4.88867L5.63867 9.92004Z"
          fill="#EEF2FF"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M3.25781 13.1279H10.7426"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default ComputerIcon;
