import { Box, DialogContent, DialogTitle, Typography } from "@mui/material";
import PaymentForm from "features/membership/components/wizard/PaymentForm";
import SelectPlan from "features/membership/components/wizard/SelectPlan";
import { IMembershipType } from "features/subscriptions/api/apiTypes";
import { useGetMembershipTypesQuery } from "features/subscriptions/api/subscriptionApi";
import { useState } from "react";
import DialogContainer from "./DialogContainer";
export type Views = "select-plan" | "payment-form";
interface IProps {
  isEdit?: boolean;
}

const AddMembershipDialog: React.FC<IProps> = ({
  isEdit = false,
}): JSX.Element => {
  const { data: membershipTypes } = useGetMembershipTypesQuery();
  const [selectedPlan, setSelectedPlan] = useState<IMembershipType | null>(
    membershipTypes ? membershipTypes[0] : null
  );
  const [planSelect, setPlanSelect] = useState<Views>("select-plan");
  const nextStep = (view: Views, selectedPlan: IMembershipType) => {
    setSelectedPlan(selectedPlan);
    setPlanSelect(view);
  };

  return (
    <DialogContainer>
      <DialogTitle mt={3} color="primary" align="center" variant="h5Bold">
        Select A Membership
        <Typography variant="smallRegularText" color={"grey.500"} my={1}>
          Choose the right plan and start Posting listings when you are
          approved.
        </Typography>
      </DialogTitle>

      <DialogContent>
        {planSelect === "select-plan" && (
          <SelectPlan nextStep={nextStep} membershipTypes={membershipTypes} />
        )}
        {planSelect === "payment-form" && selectedPlan !== null && (
          <PaymentForm
            plan={selectedPlan}
            setPlanSelect={setPlanSelect}
            isEdit={isEdit}
          />
        )}

        <Box textAlign="center">
          <Typography variant="smallRegularText" color="text.secondary">
            Do you need more information on plans?
          </Typography>

          <Typography color="primary" variant="regularText" mt=".5em">
            Contact Us
          </Typography>
        </Box>
      </DialogContent>
    </DialogContainer>
  );
};

export default AddMembershipDialog;
