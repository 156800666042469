import { Grid, Typography } from "@mui/material";
import { ErrorTextIcon } from "styles/icons";

interface IProps {
  text: string | undefined;
  color?: string;
  sx?: object;
  icon?: React.ReactNode;
}

const ErrorText: React.FC<IProps> = ({
  text,
  color = "grey.300",
  sx,
  icon = <ErrorTextIcon />,
}): JSX.Element | null => {
  if (!text) return null;
  return (
    <Grid container sx={sx} alignItems="center" flexWrap={"nowrap"}>
      {icon}
      <Typography sx={errorTextStyle} color={color} component={"span"}>
        {text}
      </Typography>
    </Grid>
  );
};

export default ErrorText;

const errorTextStyle = {
  ml: 0.25,
  fontSize: "0.625rem",
};
