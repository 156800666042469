import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const FileCheckIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.3738 11.0838C12.4565 9.76567 12.5 8.39969 12.5 7C12.5 6.43312 12.4929 5.87177 12.4789 5.31688C12.4691 4.92707 12.3426 4.54824 12.1119 4.2339C11.2324 3.03578 10.5318 2.29092 9.3771 1.39977C9.05979 1.15487 8.67041 1.022 8.26969 1.01324C7.86786 1.00446 7.44825 1 7 1C5.64305 1 4.54845 1.04087 3.4157 1.11875C2.45206 1.18501 1.68673 1.95214 1.62622 2.91616C1.54349 4.23433 1.5 5.60031 1.5 7C1.5 8.39969 1.54349 9.76567 1.62622 11.0838C1.68673 12.0479 2.45206 12.815 3.4157 12.8812C4.54845 12.9591 5.64305 13 7 13C8.35695 13 9.45155 12.9591 10.5843 12.8812C11.5479 12.815 12.3133 12.0479 12.3738 11.0838Z"
          fill="#F7FCFF"
        />
        <path
          d="M12.3738 11.0838C12.4565 9.76567 12.5 8.39969 12.5 7C12.5 6.43312 12.4929 5.87177 12.4789 5.31688C12.4691 4.92707 12.3426 4.54824 12.1119 4.2339C11.2324 3.03578 10.5318 2.29092 9.3771 1.39977C9.05979 1.15487 8.67041 1.022 8.26969 1.01324C7.86786 1.00446 7.44825 1 7 1C5.64305 1 4.54845 1.04087 3.4157 1.11875C2.45206 1.18501 1.68673 1.95214 1.62622 2.91616C1.54349 4.23433 1.5 5.60031 1.5 7C1.5 8.39969 1.54349 9.76567 1.62622 11.0838C1.68673 12.0479 2.45206 12.815 3.4157 12.8812C4.54845 12.9591 5.64305 13 7 13C8.35695 13 9.45155 12.9591 10.5843 12.8812C11.5479 12.815 12.3133 12.0479 12.3738 11.0838Z"
          stroke="#111827"
          strokeWidth="1.5"
        />
        <path
          d="M5 8.625L6.81818 10.5C7.67375 8.04232 8.38628 6.96386 10 5.5"
          stroke="#111827"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default FileCheckIcon;
