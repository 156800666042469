import { Grid, Typography } from "@mui/material";
import ControlledDropzone, {
  columnsDropzoneStyle,
} from "Components/FormComponents/ControlledDropzone";
import ControlledRadio, {
  TRadioButton,
} from "Components/FormComponents/ControlledRadio";
import { useFormContext } from "react-hook-form";
import { RegisterToOfferIcon } from "styles/icons";

interface IProps {
  fileName?: string;
}

const UploadFilesStep: React.FC<IProps> = ({ fileName }): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Grid item maxWidth={{ xs: "90%", md: "80%" }} mx="auto">
      <Grid item textAlign={"center"} mb={4}>
        <Typography color="grey.800" variant="bigBoldText">
          Verification:
        </Typography>
        <Typography color="grey.500" variant="extraSmallRegularText">
          Complete this process to get verified.
        </Typography>
      </Grid>
      <ControlledDropzone
        icon={<RegisterToOfferIcon sx={{ fontSize: "2rem" }} />}
        name="proof_of_funds"
        control={control}
        style={{ ...columnsDropzoneStyle, minHeight: 150 }}
        initialName={fileName}
      />

      <Grid item py={1} px={2}>
        <ControlledRadio
          name="file_type"
          control={control}
          radioButtons={radioList}
          sx={radioContainerStyle}
        />
      </Grid>
    </Grid>
  );
};

export default UploadFilesStep;

const radioList: TRadioButton[] = [
  { label: "Proof of funds", value: "proof_of_funds" },
  { label: "Pre-Approval", value: "preapproval" },
];

const radioContainerStyle = {
  flexDirection: "column",
  ".MuiButtonBase-root": {
    p: 0.5,
  },
  ".MuiTypography-root": {
    fontWeight: 600,
    fontSize: "0.875rem",
  },
};
