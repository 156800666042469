import { Box, DialogContent, DialogTitle } from "@mui/material";
import DateTimePicker from "Components/DatesAndTime/DateTimePicker";
import PublishListingCard from "Components/Steppers/PublishStepper/PublishListingCard";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import dayjs from "dayjs";
import {
  useGetAvailablePublishDatesQuery,
  useUpdatePublishDateMutation,
} from "features/listing/api/agentApi";
import type { IListing, IListingDetails } from "features/listing/types";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDialogContext } from "store/hooks/DialogsContext";
import DialogActions from "../DialogActions";
import DialogContainer from "../DialogContainer";

interface IProps {
  publishDate: string | undefined;
  listing: IListing | IListingDetails | undefined;
}

interface IPublishDateForm {
  publish_date: string;
}
const UpdatePublishDateDialog: React.FC<IProps> = ({
  publishDate,
  listing,
}): JSX.Element => {
  const { dialog, closeDialog } = useDialogContext();
  const { data: availableDates, isLoading: availableDatesLoading } =
    useGetAvailablePublishDatesQuery(listing?.id || "", {
      skip: !dialog.name || !listing?.id,
    });
  const [
    updatePublishDate,
    { isSuccess, isLoading: updatePublishDateLoading },
  ] = useUpdatePublishDateMutation();
  const { handleSubmit, control } = useForm<IPublishDateForm>();
  const handlePublishDateForm: SubmitHandler<IPublishDateForm> = async ({
    publish_date,
  }) => {
    updatePublishDate({
      listing_id: listing?.id,
      publishDate: dayjs(publish_date).unix(),
    });
    closeDialog();
  };

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess]);

  const isLoading = availableDatesLoading || updatePublishDateLoading;
  return (
    <ConditionalWrapper isLoading={isLoading}>
      <DialogContainer exitBtnStyle={exitBtnStyle} sx={dialogStyle}>
        <DialogTitle
          sx={{ pb: 2 }}
          color="primary.dark"
          variant="h5Bold"
          align="center"
        >
          Publish Listing
        </DialogTitle>
        <PublishListingCard publishDate={publishDate} />
        <Box component="form" onSubmit={handleSubmit(handlePublishDateForm)}>
          <DialogContent sx={{ pt: 0, pb: 0, px: { xs: 1, sm: 0 } }}>
            <DateTimePicker
              publishDate={publishDate}
              name={"publish_date"}
              maxDate={String(availableDates)}
              control={control}
            />
          </DialogContent>
          <DialogActions submitText="Update" isLoading={isLoading} />
        </Box>
      </DialogContainer>
    </ConditionalWrapper>
  );
};

export default UpdatePublishDateDialog;

export const exitBtnStyle = {
  color: "primary.light",
  backgroundColor: "rgba(238, 242, 255, 0.5)",
  border: "none",
  ":hover": {
    border: "none",
  },
};
export const dialogStyle = {
  "& .MuiDialog-paper": {
    boxShadow: "0px 0px 10px 0px #818CF8",
    borderRadius: 2.25,
    px: 5,
  },
};

const actionButtonsStyle = {
  maxWidth: 70,
  margin: 0,
};
