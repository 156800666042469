import { Box, Typography } from "@mui/material";

interface ITextIcon {
  text: string | undefined | null;
  icon: React.ReactNode;
  sx?: object;
  variant?: any;
  fontColor?: any;
  ml?: number;
  iconPosition?: "start" | "end";
  component?: React.ElementType;
}
const TextIcon: React.FC<ITextIcon> = ({
  text,
  icon,
  sx,
  fontColor = "grey.800",
  variant = "extraSmallRegularText",
  component = "p",
  ml = 0,
  iconPosition = "center",
  ...props
}): JSX.Element => {
  return (
    <Box
      {...props}
      sx={{ alignItems: iconPosition }}
      display={"flex"}
      alignItems={"center"}
      ml={ml}
    >
      {icon}
      <Typography
        ml={1}
        color={fontColor}
        component={component}
        sx={{
          typography: variant,
          textTransform: "capitalize",
          whiteSpace: "pre-line",
          ...sx,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default TextIcon;
