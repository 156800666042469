import { Box, Grid } from "@mui/material";
import { CopyLinkButton } from "Components/Buttons";
import GoogleMap from "Components/Google/GoogleMap";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import ImagesSwiper from "Components/Swipers/ImagesSwiper";
import { convertToSwiperItems } from "Components/Swipers/helpers";
import TabsPanel from "Components/TabsPanel";
import { listingDetailsTabs } from "Components/TabsPanel/tabsData";
import PageNotFound from "PageNotFound";
import {
  ListingDetailsWrapper,
  LiveListingStatus,
  VerifiedUserBidding,
} from "features/listing";
import {
  useGetUserListingDetailsQuery,
  useGetWinnerInfoQuery,
} from "features/listing/api/userApi";
import { mapContainerStyle } from "features/listing/components/detailListing/ListingDetailsWrapper";
import {
  IListing,
  ListingStatus,
  SaleType,
  type IListingDetails,
} from "features/listing/types";
import {
  OfferorStatus,
  useGetUserOfferorRequestsQuery,
} from "features/offerors/api";
import { IUserOfferorRequest } from "features/offers/api";
import { Portal } from "features/users/types";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { selectAuth, setPortal } from "store/features/authSlice";
import { listingState, setCurrentListing } from "store/features/listingSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useCurrentListingContext } from "store/hooks/CurrentListingContext";
import { useListingDetailsStepperContext } from "store/hooks/ListingDetailsStepperContext";
import { listingDetailsTabsStyle } from "styles/components/StyledTabs";
import { ShowFullAddress } from "utils/helpersComponents";
import PublishedListingStatus from "./PublishedListingStatus";
import WinnerStatuses from "./WinnerStatuses/index";
import PastListingStatus from "./pastListingStatus";
import PendingWinnerStatus from "./pendingWinnerStatus";

const UserListingDetails: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { setImages } = useCurrentListingContext();
  const { resetStepsToZero, setOptions } = useListingDetailsStepperContext();
  const itemId = useParams().itemId || "";
  const { userId, portal, verified } = useAppSelector(selectAuth);
  const { listing: agentListing } = useAppSelector(listingState);
  const {
    data: listingData,
    isLoading: listingLoading,
    refetch: refetchListing,
  } = useGetUserListingDetailsQuery(itemId, {
    skip: portal !== null && portal !== Portal.USER,
  });
  const { data: winnerData, isLoading: winnerLoading } = useGetWinnerInfoQuery(
    itemId,
    {
      skip:
        !listingData ||
        !verified ||
        !winnerStatusesList.includes(listingData?.status),
    }
  );
  const listing = listingData || agentListing;
  const winnerId = winnerData?.winner_info.winning_user_id;
  const isUserWinner = winnerId === userId;
  const { data: offerorRequestData, isLoading: offerLoading } =
    useGetUserOfferorRequestsQuery(itemId, {
      skip: !userId || !verified,
    });
  const isOfferorVerified =
    offerorRequestData?.status !== OfferorStatus.NOT_VERIFIED &&
    offerorRequestData !== undefined;
  const rightComponent = getRightStatusComponent(
    listing,
    isUserWinner,
    isOfferorVerified,
    userId,
    offerorRequestData
  );
  const isLive = listing?.status === ListingStatus.LIVE;
  const isClosed = listing?.sale_type === SaleType.CLOSED;
  useEffect(() => {
    const mainPhoto = listing?.main_photo;
    const additionalPhotos = listing?.additional_photos || [];
    const formatImages = convertToSwiperItems(mainPhoto, additionalPhotos);
    setImages(formatImages);
    setOptions((prev) => ({
      ...prev,
      includeOfferStep: isClosed && isLive,
      offerorRequestData: offerorRequestData,
    }));
  }, [itemId, listingData, offerorRequestData]);
  // TEMPORARY - refresh every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        listingData?.sale_type === SaleType.OPEN &&
        listingData?.status === ListingStatus.LIVE
      )
        refetchListing();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [listingData]);

  useEffect(() => {
    if (
      portal &&
      portal !== Portal.USER &&
      ((listing?.status !== ListingStatus.DRAFT &&
        listing?.status !== ListingStatus.PUBLISH_SCHEDULED) ||
        listing?.archived === true)
    )
      dispatch(setPortal({ portal: Portal.USER }));
  }, []);

  useEffect(() => {
    if (listingData) {
      dispatch(
        setCurrentListing({ ...listingData, id: itemId } as IListingDetails)
      );
    }
  }, [itemId, listingData]);

  useEffect(() => {
    resetStepsToZero();
  }, [itemId]);

  const isLoading =
    listingLoading || winnerLoading || offerLoading || !agentListing;
  if (!listing && !isLoading && portal === Portal.USER) return <PageNotFound />;
  return (
    <ConditionalWrapper isLoading={isLoading}>
      <ListingDetailsWrapper
        leftWrapper={
          <Grid container>
            <Grid item margin={"0 auto"} width={"100%"} position={"relative"}>
              <ImagesSwiper
                className={"listingDetailsSwiper"}
                itemStatus={listing?.status}
              />
            </Grid>
            <Grid
              item
              container
              py={2}
              gap={2}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <ShowFullAddress
                  address_line_1={listing?.address_line_1}
                  address_line_2={listing?.address_line_2}
                  city={listing?.city}
                  state={listing?.state}
                  zip={listing?.zip}
                  variant="bigBoldText"
                />
              </Box>
              <CopyLinkButton />
            </Grid>
            <Grid item xs={12}>
              <TabsPanel
                sx={listingDetailsTabsStyle}
                tabsList={listingDetailsTabs(portal)}
              />
            </Grid>
          </Grid>
        }
        rightWrapper={
          <>
            {rightComponent}
            <Grid container mt={5} sx={mapContainerStyle}>
              <GoogleMap fullAddress={listing?.full_address} />
            </Grid>
          </>
        }
      />
    </ConditionalWrapper>
  );
};

export default UserListingDetails;

const getRightStatusComponent = (
  listing: IListingDetails | IListing | undefined,
  isUserWinner: boolean | undefined,

  isOfferorVerified: boolean | undefined,
  userId: number | null | undefined,
  offerorRequestData: IUserOfferorRequest | undefined
) => {
  if (isUserWinner) {
    return (
      <WinnerStatuses listing={listing as IListingDetails} userId={userId} />
    );
  } else {
    switch (listing?.status) {
      case ListingStatus.PUBLISHED:
        if (isOfferorVerified) {
          return <VerifiedUserBidding listing={listing as IListingDetails} />;
        } else {
          return <PublishedListingStatus />;
        }
      case ListingStatus.PENDING_WINNER:
        return <PendingWinnerStatus userId={userId} />;

      case ListingStatus.LIVE:
        return (
          <LiveListingStatus
            offerorRequestData={offerorRequestData}
            userId={userId}
          />
        );
      case ListingStatus.IN_CONTRACT:
      case ListingStatus.PENDING_CONTRACT:
      case ListingStatus.FAILED_CONTRACT:
      case ListingStatus.FAILED_SALE:
      case ListingStatus.NO_OFFERS:
      case ListingStatus.NO_WINNER:
      case ListingStatus.SOLD:
        return <PastListingStatus listing={listing} userId={userId} />;
      default:
        return null;
    }
  }
};

const winnerStatusesList = [
  ListingStatus.PENDING_CONTRACT,
  ListingStatus.IN_CONTRACT,
  ListingStatus.FAILED_CONTRACT,
  ListingStatus.SOLD,
  ListingStatus.FAILED_SALE,
];
