import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { ShareSnackbar } from "Components/Snackbar";
import { useState } from "react";
import { CopyLinkIcon } from "styles/icons";

const CopyLinkButton: React.FC = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(String(window.location));
  };

  return (
    <Box>
      <LoadingButton
        variant="tertiaryPrimary"
        onClick={handleClick}
        startIcon={<CopyLinkIcon />}
      >
        Copy Link
      </LoadingButton>
      <Box sx={open ? backdropStyle : {}}>
        <ShareSnackbar open={open} setOpen={setOpen} />
      </Box>
    </Box>
  );
};

export default CopyLinkButton;

const backdropStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  zIndex: 9,
  transition: "background-color 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94)",
};
const snackContentStyle = {
  backgroundColor: "secondary.main",
  margin: "0 auto",
  minWidth: 170,
  width: 170,
  height: 200,
  ".MuiSnackbarContent-message": {
    textAlign: "center",
    margin: "0 auto",
  },
  "@media (min-width: 600px)": {
    minWidth: 170,
    width: 170,
  },
};

const snackbarCenterStyle = {
  transform: "translate(-50%,-50%)",
  left: "50% !important",
  top: "50% !important",
  height: "fit-content",
  width: "fit-content",
  ".MuiPaper-root": {
    boxShadow: "0px 0px 10px 0px rgba(99, 102, 241, 0.1)",
  },
};
