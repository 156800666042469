import { Box, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { StyledSelect } from "styles/components";
import { ArrowIcon } from "styles/icons";

type TFilterOptions = {
  label: string;
  value: string;
};

interface FilterDropdownProps {
  onChange: (value: any) => void;
  value: string;
  options: TFilterOptions[];
  label?: string;
  sx?: object;
  mb?: number;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  onChange,
  value,
  options,
  label = "View",
  sx,
  mb = 1,
}): JSX.Element => {
  const handleChange = (event: any) => {
    onChange(event.target.value);
  };
  return (
    <Box width={"100%"} mb={mb}>
      <Typography
        fontWeight={600}
        color="grey.800"
        variant="extraSmallBoldText"
        mb={0.5}
      >
        {label}
      </Typography>

      <FormControl sx={{ width: 150, minWidth: "100%", top: 0, ...sx }}>
        <StyledSelect
          sx={{ bgcolor: "grey.50" }}
          IconComponent={ArrowIcon}
          value={value}
          onChange={handleChange}
        >
          {options.map(({ label, value }) => (
            <MenuItem key={label} sx={filterItemStyle} value={value}>
              {label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Box>
  );
};

export default FilterDropdown;

const filterItemStyle = {
  "&.MuiMenuItem-root.Mui-selected": {
    fontWeight: 800,
    color: "primary.main",
    backgroundColor: "common.white",
  },
  fontSize: "0.8rem",
  "&:hover": {
    backgroundColor: "common.white",
  },
};
