import { Grid, Typography } from "@mui/material";
import { getDaysHoursDuration } from "Components/DatesAndTime/helpers";
import LiveOffers from "Components/Fields/LiveOffers";

import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import { listingRowStyle } from "../AuctionListing";
import { ListingStatus } from "features/listing/types";
import { Portal } from "features/users/types";

interface IProps {
  auctionEndDate: string;
  auctionStartDate: string;
  auctionEnded: string;
  status: ListingStatus;
  offerCount: number;
}

const SixthColumn: React.FC<IProps> = ({
  status,
  auctionEndDate,
  auctionStartDate,
  auctionEnded,
  offerCount,
}): JSX.Element | null => {
  const auctionDuration = getDaysHoursDuration(
    auctionStartDate,
    auctionEndDate
  );

  const { portal } = useAppSelector(selectAuth);
  const isUserPortal = portal === Portal.USER || portal === null;
  const isauctionDuration =
    isUserPortal ||
    status === ListingStatus.DRAFT ||
    status === "published" ||
    status === "publish_scheduled";

  return isauctionDuration
    ? auctionDurationColumn(auctionDuration)
    : agentColumn(status, auctionEnded, offerCount);
};

const auctionDurationColumn = (auctionDuration: string) => {
  return (
    <Grid xs={2} item sx={{ minWidth: 140 }}>
      <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
        Auction Duration
      </Typography>
      <Typography variant="extraSmallRegularText" color={"grey.800"}>
        {auctionDuration}
      </Typography>
    </Grid>
  );
};

const agentColumn = (
  status: ListingStatus,
  auctionEnded: string,
  offerCount: number
) => {
  switch (status) {
    case ListingStatus.LIVE:
    case ListingStatus.PENDING_WINNER:
    case ListingStatus.PENDING_CONTRACT:
    case ListingStatus.FAILED_CONTRACT:
    case ListingStatus.FAILED_SALE:
    case ListingStatus.NO_WINNER:
    case ListingStatus.NO_OFFERS:
    case ListingStatus.SOLD:
      return (
        <Grid xs={2} item sx={{ ...listingRowStyle, minWidth: 140 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            Offers
          </Typography>
          <LiveOffers offerCount={offerCount} />
        </Grid>
      );

    case ListingStatus.IN_CONTRACT:
      return (
        <Grid xs={2} item sx={{ minWidth: 140 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            Auction Ended
          </Typography>
          <Typography variant="smallRegularText" color="grey.800">
            {auctionEnded}
          </Typography>
        </Grid>
      );

    default:
      return null;
  }
};
export default SixthColumn;
