import { Grid, Typography } from "@mui/material";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { TimerProgressLine } from "Components/Timers";
import dayjs from "dayjs";
import { useGetHighestOfferAmountQuery } from "features/listing/api/userApi";
import {
  MainStatusButton,
  MySubmittedOffers,
} from "features/listing/components/detailListing/user";
import { SaleType } from "features/listing/types";
import { getMyOffers } from "features/offers";
import { IAllOffers } from "features/offers/api/apiTypes";
import { useGetAllOffersListingQuery } from "features/offers/api/offersApi";
import { useParams } from "react-router-dom";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";

interface IProps {
  userId: number | null | undefined;
}

const OpenPendingWinner: React.FC<IProps> = ({ userId }): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const itemId: string = useParams().itemId!;
  const isOpenListing = listing?.sale_type === SaleType.OPEN;

  const { data: highestOffer, isLoading: highestOfferLoading } =
    useGetHighestOfferAmountQuery(itemId || listing?.id);
  const { data: allOffersData, isLoading: allOffersLoading } =
    useGetAllOffersListingQuery(itemId || listing?.id);
  const myOffers = getMyOffers(allOffersData, userId);

  //timer fields
  const auctionEnded = parseInt(listing?.auction_ended) || 0;
  const auctionEndedPlusHour = auctionEnded + 3600;
  const isLoading = highestOfferLoading || allOffersLoading;
  return (
    <ConditionalWrapper isLoading={isLoading}>
      <Grid container mb={3} mt={1.5}>
        <MainStatusButton
          text={"Highest offer recieved:"}
          price={highestOffer?.amount || 0}
        />
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ borderRight: { lg: "2px solid #E5E7EB" } }}
          pr={{ xs: 0, lg: 2 }}
          mt={2}
          style={{ maxHeight: 400, overflow: "auto" }}
        >
          <Typography
            color="primary.main"
            variant="smallBoldText"
            sx={{ mb: 1 }}
          >
            My Submitted Offers
          </Typography>
          {myOffers ? (
            myOffers.map((offer: IAllOffers) => (
              <MySubmittedOffers
                isOpenListing={isOpenListing}
                offer={offer}
                key={offer.offer_date}
              />
            ))
          ) : (
            <Typography color="text.secondary" variant="smallRegularText">
              You have not submitted an offer on this listing
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} lg={6} pl={{ xs: 0, lg: 2 }} mt={2}>
          <Grid item>
            <Typography
              color="text.secondary"
              variant="smallRegularText"
              sx={{ mb: 1 }}
            >
              Waiting for the agent to select a winner. Time left:
            </Typography>
          </Grid>
          <Grid item xs={12} mb={3}>
            <TimerProgressLine
              auctionStartDate={auctionEnded}
              actualEndTime={auctionEndedPlusHour}
              expiryTimestamp={dayjs.unix(auctionEndedPlusHour).toDate()}
            />
          </Grid>
        </Grid>
      </Grid>
    </ConditionalWrapper>
  );
};

export default OpenPendingWinner;
