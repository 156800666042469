import passwordValidations from "../schema/passwordValidations";
import { ValidationItem } from "./ValidationTextList";

const validationSignupList: ValidationItem[] = [
  {
    text: "Password must have at least 8 characters.",
    validationFunction: passwordValidations[0],
  },
  {
    text: "Both uppercase and lowercase letters.",
    validationFunction: passwordValidations[2],
  },
  {
    text: "Including numbers.",
    validationFunction: passwordValidations[3],
  },
  {
    text: "At least one special character.",
    validationFunction: passwordValidations[4],
  },
  {
    text: "Shouldn't include personal information.",
    validationFunction: passwordValidations[5],
  },
];

const validationPasswordResetList: ValidationItem[] = [
  {
    text: "Password must have at least 8 characters.",
    validationFunction: passwordValidations[0],
  },
  {
    text: "Both uppercase and lowercase letters.",
    validationFunction: passwordValidations[2],
  },
  {
    text: "Including numbers.",
    validationFunction: passwordValidations[3],
  },
  {
    text: "At least one special character.",
    validationFunction: passwordValidations[4],
  },
];
export { validationSignupList, validationPasswordResetList };
