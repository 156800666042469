import { TContingencyField } from "Components/FormComponents/ControlledContingencies";

export const filterContingencies = (
  filteredItems: TContingencyField[],
  fullList: TContingencyField[]
) => {
  if (fullList === null) return filteredItems;
  if (filteredItems === null) return null;
  return filteredItems.filter(
    (newCont) =>
      !fullList.some(
        (item) => item?.contingency_name === newCont?.contingency_name
      )
  );
};

export const modifyToAdd = (
  cbContingencies: TContingencyField[],
  existingList: TContingencyField[]
) => {
  const modifiedContingencies = contingenciesBody(
    filterContingencies(cbContingencies, existingList)
  );
  return modifiedContingencies
    ? { contingencies: modifiedContingencies }
    : null;
};

const contingenciesBody = (contingencies: TContingencyField[] | null) =>
  contingencies?.map((contingency) => ({
    contingency_id: contingency?.contingency_id ?? null,
    waive_required: contingency?.waive_required ?? false,
  }));

export const modifyToDelete = (
  existingList: TContingencyField[],
  cbContingencies: TContingencyField[]
) => {
  const modifiedContingencies = contingenciesDeleteBody(
    filterContingencies(existingList, cbContingencies)
  );
  return modifiedContingencies
    ? { contingency_ids: modifiedContingencies }
    : null;
};

const contingenciesDeleteBody = (contingencies: TContingencyField[] | null) => {
  if (!contingencies) {
    return []; // Return an empty array if contingencies are null
  }
  return contingencies.map((contingency) => contingency.contingency_id);
};

export const modifyToWaiveRequired = (cbContingencies: TContingencyField[]) => {
  const modifiedWaiveRequired = contingenciesBody(cbContingencies);
  return modifiedWaiveRequired
    ? { contingencies: modifiedWaiveRequired }
    : null;
};

export const modifyToWaiveRequire = (
  cbContingencies: TContingencyField[],
  existingList: TContingencyField[]
) => {
  const modifiedContingencies = contingenciesBody(
    filterContingencies(cbContingencies, existingList)
  );
  return modifiedContingencies
    ? { contingencies: modifiedContingencies }
    : null;
};
