import * as yup from "yup";
import {
  emailSchema,
  nameSchema,
  phoneSchema,
  priceSchema,
  stateSchema,
  stringSchema,
  zipCodeSchema,
} from "./reausableSchemas";

export const RegisterToOfferStepperSchema = (
  includeOfferStep: boolean,
  hasSecurityHold: boolean,
  selectedPaymentMethodId?: string | number | null
) => {
  const commonSchema = {
    proof_of_funds: yup
      .mixed()
      .required("Please upload a file")
      .test("fileType", "File must be a PDF", (value: any) => {
        if (typeof value === "string") return true;
        return value && value.type === "application/pdf";
      }),
    file_type: yup.string().required("Select a file type"),
    attorney_first_name: nameSchema,
    attorney_last_name: nameSchema,
    attorney_number: phoneSchema,
    attorney_email: emailSchema,
    purchaser_first_name: nameSchema,
    purchaser_last_name: nameSchema,
    purchaser_address_line_1: yup
      .string()
      .required("Address  is required")
      .matches(/^[a-zA-Z0-9\- '.,]+$/, "Invalid characters in the address")
      .max(50, "Maximum 50 characters"),
    purchaser_address_line_2: yup
      .string()
      .matches(/^[a-zA-Z0-9\- '.,]*$/, "Invalid characters in the address")
      .max(50, "Maximum 50 characters"),  
    purchaser_city: yup
      .string()
      .required("City is required")
      .matches(/^[a-zA-Z0-9\- '.,]+$/, "Invalid characters in the string")
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters"),
    purchaser_state: stateSchema,
    purchaser_zip: zipCodeSchema,
  };

  const offerDetailsSchema = {
    offer_amount: priceSchema,
    mortgage_amount: priceSchema.test(
      "lessThanPurchasePrice",
      "must not exceed the purchase price",
      (value, { parent }) => value <= parent.offer_amount
    ),
    on_contract: priceSchema.test(
      "lessThanPurchasePrice",
      "must not exceed the purchase price",
      (value, { parent }) => value <= parent.offer_amount
    ),
  };
  const cvvSchema = {
    cvv: yup
      .string()
      .required("CVV is required")
      .matches(/^\d{3,4}$/, "CVV (3 or 4 digits)"),
  };

  return yup.object().shape({
    ...commonSchema,
    ...(hasSecurityHold && includeOfferStep && cvvSchema),
    ...(includeOfferStep && offerDetailsSchema),
  });
};
