import { Grid, Typography } from "@mui/material";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import FilterDropdown from "Components/StyledComponents/FilterDropdown";
import { AuctionListing, NoListingYet } from "features/listing";
import { useGetUserListingsQuery } from "features/listing/api/userApi";
import { ListingStatusCategory, type IListing } from "features/listing/types";
import { Portal } from "features/users/types";
import { useEffect, useState } from "react";
import { selectAuth, setUser } from "store/features/authSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Collapse } from "styles/components";
import { useGetUserInfoQuery } from "features/users/api/userApi";

const UserListings: React.FC = () => {
  const { data: listings, isLoading: listingsLoading } =
    useGetUserListingsQuery({
      sort: "desc",
      limit: "90",
      offset: 0,
    });
  const authState = useAppSelector(selectAuth);
  console.log("aaauth", authState.portal);
  const { data: userData, isLoading: userLoading } = useGetUserInfoQuery(
    undefined,
    {
      skip: authState.portal === null,
    }
  );
  console.log("auth", authState);
  const isLoading = userLoading || listingsLoading;
  const { portal } = useAppSelector(selectAuth);
  const isUserPortal = portal === Portal.USER;
  const [filter] = useState<string>("Total Listings");
  const [filterValue, setFilterValue] = useState("All");
  const isArchived = filter === "archived";
  const [expanded, setExpanded] = useState<boolean>(false);
  const isListingsEmpty = !isLoading && !listings?.length;
  const dispatch = useAppDispatch();
  console.log("userData", userData);
  useEffect(() => {
    if (userData) {
      dispatch(
        setUser({
          ...authState,
          portal: authState.portal,
          verified: userData.user.verified,
        })
      );
    }
  }, [userData, dispatch]);

  console.log("c8888888c", portal);
  return (
    <ConditionalWrapper isLoading={isLoading}>
      <Grid
        container
        sx={{ mx: "auto", my: 3, px: { xs: 1, md: 5 } }}
        maxWidth="xl"
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          mb={3}
        >
          <Grid item>
            <Typography
              variant="bigBoldText"
              color="primary.main"
              fontWeight={700}
            >
              Listings
            </Typography>
          </Grid>

          {isUserPortal && (
            <Grid item>
              <FilterDropdown
                mb={0}
                onChange={setFilterValue}
                value={filterValue}
                options={filterOptions}
              />
            </Grid>
          )}
        </Grid>
        {isListingsEmpty ? (
          <NoListingYet />
        ) : (
          <Grid item xs={12}>
            {!isListingsEmpty &&
              listings
                ?.filter(
                  (listing: IListing) =>
                    !pastStatuses.includes(listing.statusCat)
                )
                .filter((listing: IListing) =>
                  filterListings(listing, filterValue)
                )
                .map((listing: IListing) => (
                  <AuctionListing key={listing.id} listing={listing} />
                ))}
          </Grid>
        )}
        {!isArchived && (
          <Collapse
            expanded={expanded}
            setExpanded={setExpanded}
            title="Past Listings"
            color="grey.500"
          >
            <Grid item xs={12}>
              {!isListingsEmpty &&
                listings
                  ?.filter((listing: IListing) =>
                    pastStatuses.includes(listing.statusCat)
                  )
                  .filter((listing: IListing) =>
                    filterListings(listing, filterValue)
                  )
                  .map((listing: IListing) => (
                    <AuctionListing key={listing.id} listing={listing} />
                  ))}
            </Grid>
          </Collapse>
        )}
      </Grid>
    </ConditionalWrapper>
  );
};

export default UserListings;

const filterListings = (listing: IListing, filterValue: string) => {
  if (filterValue === "Favorites") return listing.is_favorite === true;
  if (filterValue === "Shared") return listing.is_shared === true;
  return true;
};

const filterOptions = [
  { label: "All", value: "All" },
  { label: "Favorites", value: "Favorites" },
  { label: "Shared", value: "Shared" },
];

const pastStatuses = [
  ListingStatusCategory.PENDING,
  ListingStatusCategory.FAILED,
  ListingStatusCategory.SOLD,
  ListingStatusCategory.IN_CONTRACT,
];
