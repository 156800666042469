import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const PaymentIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 12"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 8.84796C1 9.64108 1.61923 10.2938 2.40076 10.429C3.43404 10.6077 4.41598 10.8448 7 10.8448C9.58402 10.8448 10.566 10.6077 11.5992 10.429C12.3808 10.2938 13 9.64108 13 8.84796V2.91381C13 2.22427 12.5294 1.62874 11.8485 1.51992C10.8101 1.35397 9.12191 1.15479 7 1.15479C4.41598 1.15479 3.43404 1.39195 2.40076 1.57065C1.61923 1.70581 1 2.35855 1 3.15168L1 8.84796Z"
          fill="white"
        />
        <path
          d="M1 8.84796C1 9.64108 1.61923 10.2938 2.40076 10.429C3.43404 10.6077 4.41598 10.8448 7 10.8448C9.58402 10.8448 10.566 10.6077 11.5992 10.429C12.3808 10.2938 13 9.64108 13 8.84796V2.91381C13 2.22427 12.5294 1.62874 11.8485 1.51992C10.8101 1.35397 9.12191 1.15479 7 1.15479C4.41598 1.15479 3.43404 1.39195 2.40076 1.57065C1.61923 1.70581 1 2.35855 1 3.15168L1 8.84796Z"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 4.59321H1V3.09521C1 2.33381 1.59455 1.70812 2.34513 1.5802C2.4818 1.55691 2.61707 1.5325 2.75472 1.50767C3.67884 1.34094 4.71067 1.15479 7 1.15479C9.15315 1.15479 10.8597 1.35987 11.8939 1.52722C12.5474 1.63297 13 2.2049 13 2.86686V4.59321Z"
          fill="#EEF2FF"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.55518 8.21826H10.5877"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default PaymentIcon;
