import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SelectedDate } from "Components/DatesAndTime/DateTimePicker";
import { formatToMMM, getRightDateType } from "Components/DatesAndTime/helpers";
import dayjs, { Dayjs } from "dayjs";
import { Control, useController } from "react-hook-form";

interface IProps {
  control: Control<any>;
  name: string;
  selectedDate: string | Dayjs;
  minDate?: Dayjs;
  maxDate: Dayjs;
  setSelectedDate: (value: SelectedDate) => void;
}

const CalendarPicker: React.FC<IProps> = ({
  minDate,
  maxDate,
  control,
  name,
  selectedDate,
  setSelectedDate,
}): JSX.Element => {
  const {
    field: { onChange, value },
  } = useController({ name, control });
  const selectedValue = value ? getRightDateType(value) : selectedDate;
  const handleDateChange = (newValue: any) => {
    const dateToString = formatToMMM(newValue.unix());
    onChange(dayjs(newValue) ?? null);
    setSelectedDate({ dateToShow: dateToString, timeStamp: newValue });
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          minDate={minDate || null}
          maxDate={maxDate || null}
          dayOfWeekFormatter={(day) => day.substring(0, 3)}
          sx={calendarStyles}
          value={selectedValue ?? null}
          onChange={(newValue) => handleDateChange(dayjs(newValue) ?? null)}
        />
      </LocalizationProvider>
    </>
  );
};

export default CalendarPicker;

const calendarStyles = {
  ".MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition": {
    minHeight: 200,
  },
  "&.MuiDateCalendar-root": {
    border: "1px solid #818cf8",
    boxShadow: "0px 0px 10px 0px rgba(99, 102, 241, 0.1)",
    borderRadius: 1.5,
    height: 295,
  },

  ".MuiDayCalendar-header": {
    borderBottom: "1px dashed",
    borderColor: "grey.400",
    ".MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel": {
      color: "grey.400",
      fontWeight: 500,
      fontSize: "0.75rem",
    },
  },
  ".MuiPickersCalendarHeader-root:first-of-type": {
    order: 0,
    paddingRight: "20px",
    paddingLeft: "20px",
    ".MuiPickersCalendarHeader-label": {
      fontWeight: 700,
      fontSize: "1rem",
      marginRight: 0,
      color: "#6366F1",
    },
    ".MuiSvgIcon-root.MuiPickersCalendarHeader-switchViewIcon": {
      display: "none",
    },
    ".MuiPickersFadeTransitionGroup-root": {
      display: "flex",
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
    },
    ".MuiSvgIcon-root": {
      background: "common.white",
      color: "grey.700",
      border: "1px solid",
      borderColor: "grey.600",
      borderRadius: 0.25,
    },
    ".MuiButtonBase-root.MuiIconButton-root.Mui-disabled .MuiSvgIcon-root ": {
      backgroundColor: "common.white",
      color: "grey.600",
      borderColor: "grey.600",
    },
    ".MuiButtonBase-root.MuiIconButton-root": {
      borderRadius: 0.25,
    },
    ".MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd": {
      marginRight: -2,
    },
    "& .MuiTouchRipple-root .MuiTouchRipple-child": {
      borderRadius: 0.25,
    },
    ".MuiPickersArrowSwitcher-spacer": {
      width: "225px",
    },
  },
};
