import { Box, Grid, Typography } from "@mui/material";
import { CvvCollapse } from "Components/Collapses";
import { useState } from "react";
import { Control } from "react-hook-form";
import { PaymentIcon } from "styles/icons";

interface IProps {
  lastFourDigits: string;
  nameOnCard: string;
  isSelected: boolean;
  errorText?: string | null;
  cvvError?: boolean;
  readOnly?: boolean;
  isCvvCollapsed: boolean;
  control: Control<any>;
  setValue?: any;
}

const PaymentMethodCard: React.FC<IProps> = ({
  lastFourDigits,
  nameOnCard,
  isSelected,
  readOnly = false,
  control,
  setValue,
  isCvvCollapsed,
}): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(true);
  return (
    <Box
      sx={{
        ...containerStyle,
        border:
          isSelected && !readOnly
            ? "1px solid #6366F1  !important"
            : "1px solid #CAD0D9",
        cursor: readOnly ? "auto" : "pointer",
      }}
    >
      <Grid item container xs={12}>
        <Grid item minWidth={20}>
          <PaymentIcon />
        </Grid>
        <Grid ml={0.5} xs={10} item container>
          <Typography variant="extraSmallBoldText" color={"grey.800"}>
            {`Ending in ${lastFourDigits}`}
          </Typography>

          <Grid item xs={12} sx={nameStyle}>
            <Typography
              component={"span"}
              variant="extraSmallRegularText"
              color={"grey.700"}
              sx={{ fontWeight: 500 }}
            >
              Name:
            </Typography>
            <Typography
              component={"span"}
              variant="extraSmallRegularText"
              color={"grey.500"}
              ml={0.5}
            >
              {nameOnCard}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {!readOnly && isCvvCollapsed && (
        <CvvCollapse
          control={control}
          setValue={setValue}
          setExpanded={setExpanded}
          expanded={isSelected && expanded}
          sx={cvvCollapseStyle}
        />
      )}
    </Box>
  );
};

export default PaymentMethodCard;

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  bgcolor: "common.white",
  border: "1px solid",
  borderColor: "grey.600",
  borderRadius: 1,
  padding: "16px 12px",
  position: "relative",
};

const cvvCollapseStyle = {
  ".MuiButtonBase-root.MuiIconButton-root": {
    right: 1,
    position: "absolute",
    top: "15px",
  },
  ".MuiSvgIcon-root": {
    fontSize: "1.5rem",
  },
};

const nameStyle = {
  color: "grey.500",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};
