import { Box, Grid, IconButton, Typography } from "@mui/material";
import { ReactNode, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Control, useController } from "react-hook-form";
import { Button } from "../StyledComponents";
import ErrorText from "../StyledComponents/ErrorText";
//icons
import {
  DeleteIcon,
  DownloadButtonIcon,
  PdfIcon,
  UploadButtonIcon,
} from "styles/icons";

export type TDropZone = {
  name: string;
  control: Control<any>;
  style?: object;
  text?: string;
  icon?: ReactNode;
  label?: string;
  hasDownloadButton?: boolean;
  hasDeleteButton?: boolean;
  initialName?: string | null;
};

const ControlledDropzone: React.FC<TDropZone> = ({
  name,
  control,
  style,
  text,
  label,
  icon,
  initialName = null,
  hasDownloadButton = false,
  hasDeleteButton = true,
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState,
  } = useController({ name, control, defaultValue: initialName });
  const onDrop = useCallback(
    (acceptedFile: any) => {
      onChange(acceptedFile[0]);
    },

    [onChange]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });
  const handleRemove = (e: any) => {
    e.stopPropagation();
    onChange(null);
  };
  const handleOpenPDF = () => {
    window.open(value, "_blank", "noopener,noreferrer");
  };
  const fileName: string = value?.name || initialName || "No File Name";
  useEffect(() => {
    if (!hasDownloadButton && value) {
      const file = new File([value], fileName, {
        type: "application/pdf",
      });
      onDrop([file]);
    }
  }, [onChange]);
  return (
    <>
      {label && (
        <Typography
          variant="extraSmallBoldText"
          sx={{ mb: 0.5, color: "grey.800" }}
        >
          {label}
        </Typography>
      )}
      <Box
        {...getRootProps()}
        sx={{
          ...dropzoneWrapper,
          ...style,
          ...(value ? { borderStyle: "solid" } : {}),
        }}
      >
        <input
          {...getInputProps({ name, onBlur, accept: "application/pdf" })}
        />
        {isDragActive ? (
          <Typography variant="extraSmallRegularText" color="grey.500">
            Drop file here...
          </Typography>
        ) : !value ? ( //Drag and drop browse
          <Grid
            className="dzContent dragDrop"
            container
            flexDirection={"column"}
            alignItems={"center"}
            gap={1}
          >
            {icon}
            <Typography
              textAlign="center"
              color="grey.500"
              variant="extraSmallRegularText"
            >
              {text || (
                <>
                  Drag & drop or{" "}
                  <Typography
                    sx={{ textDecoration: "underline" }}
                    variant="extraSmallRegularText"
                    component="span"
                    color="info.light"
                  >
                    browse
                  </Typography>
                </>
              )}
            </Typography>
          </Grid>
        ) : (
          ///File Uploaded to dropZone
          <Grid container p={1.5} className="dzContent">
            <Grid item flex={1} sx={{ overflow: "hidden", maxWidth: "100%" }}>
              <Button
                startIcon={<PdfIcon />}
                sx={fileIconStyle}
                className="dzFileButton"
                onClick={hasDownloadButton ? handleOpenPDF : () => {}}
              >
                <Typography sx={fileTextStyle}>{fileName}</Typography>
              </Button>
            </Grid>
            <Grid item justifyContent={"end"}>
              <IconButton
                sx={{ ...fileIconStyle, mx: 0.5 }}
                size="large"
                onClick={hasDownloadButton ? handleOpenPDF : () => {}}
                color="primary"
              >
                {hasDownloadButton ? (
                  <DownloadButtonIcon />
                ) : (
                  <UploadButtonIcon />
                )}
              </IconButton>
              {hasDeleteButton && (
                <IconButton
                  sx={{ ...fileIconStyle, mx: 0.5 }}
                  size="large"
                  onClick={handleRemove}
                  color="primary"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
      {fieldState.error && <ErrorText text={fieldState.error?.message || ""} />}
    </>
  );
};

export default ControlledDropzone;

const dropzoneWrapper = {
  border: "1px dashed",
  height: 64,
  borderColor: "grey.600",
  borderRadius: 1,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  justifyContent: "center",
  bgcolor: "common.white",
};

const fileIconStyle = {
  borderRadius: 1,
  border: "1px solid",
  borderColor: "grey.600",
  backgroundColor: "grey.50",
  height: "100%",
  minWidth: 40,
  pointerEvents: "auto",
  "&:hover": {
    background: "rgba(0, 0, 0, 0.04)",
    boxShadow: "inset 0px 0px 1px 1px rgba(238, 242, 255, 0.5)",
    transition: "0.2s ease",
  },
};

const fileTextStyle = {
  color: "common.black",
  fontSize: "0.625rem",
  fontWeight: 600,
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const columnsDropzoneStyle = {
  height: "100%",
  borderColor: "#6366f1",
  ".dzContent": {
    cursor: "pointer",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
    gap: 1,
  },
};

export const registerToBidDzStyle = {
  bgcolor: "transparent.main",
  border: "0px solid",
  cursor: "auto",
  pointerEvents: "none",
  height: 48,
  ".dzContent": {
    cursor: "pointer",
    p: 0,
    gap: 1,
  },
  ".dzContent.dragDrop": {
    height: 48,
    justifyContent: "center",
    pointerEvents: "auto",
    p: 0,
  },

  ".dzFileButton": {
    pointerEvents: "auto",
    width: "100%",
    justifyContent: "left",
  },
  ".MuiButtonBase-root": {
    bgcolor: "common.white",
    "&:hover": {
      border: "1px solid #E5E7EB",
      bgcolor: "common.white",
    },
    "&:focus": {
      border: "1px solid #E5E7EB",
      boxShadow: "none",
    },
  },
};

export const likeTextFieldStyle = {
  ".dzContent": {
    bgcolor: "common.white",
    border: "1.5px solid",
    borderColor: "grey.600",
    borderRadius: 1,
  },

  ".MuiButtonBase-root": {
    bgcolor: "grey.50",
  },
};
