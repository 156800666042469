import { DialogContent } from "@mui/material";
import { AddPaymentForm } from "features/payments";
import DialogContainer from "./DialogContainer";

const AddNewPaymentDialog: React.FC = () => {
  return (
    <DialogContainer hideExit sx={AddPaymentContainerStyle}>
      <DialogContent>{<AddPaymentForm />}</DialogContent>
    </DialogContainer>
  );
};

export default AddNewPaymentDialog;

const AddPaymentContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 450,
    height: "fit-content",

    "@media (max-width: 601px)": {
      width: "90%",
      maxHeight: "90%",
    },
  },
};
