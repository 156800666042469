import { Box, Typography } from "@mui/material";
import {
  useEmailVerificationMutation,
  useResetPasswordMutation,
} from "features/auth/api/authApi";
import { useTimer } from "react-timer-hook";
import { useNavigate } from "react-router";
import TimerFieldIcon from "styles/icons/TimerFieldIcon";
import { useEffect } from "react";
import { AccountServices } from "features/listing/types";
import { useUpdateUserEmailMutation } from "features/listing/api/userApi";

interface IProps {
  setStep?: React.Dispatch<React.SetStateAction<number>>;
  email: string | null | undefined;
  dialogType?: AccountServices;
}

const ResetOrVerify: React.FC<IProps> = ({
  email,
  setStep,
  dialogType,
}): JSX.Element => {
  const [resetPassword] = useResetPasswordMutation();
  const [emailVerification] = useEmailVerificationMutation();
  const [updateUserEmail] = useUpdateUserEmailMutation();
  const isEmailVerification = dialogType === AccountServices.EMAIL_VERIFY;
  const isEmailUpdate = dialogType === AccountServices.EMAIL_UPDATE;
  const navigate = useNavigate();
  const { seconds, restart, isRunning } = useTimer({
    expiryTimestamp: new Date(Date.now() + 30000),
  });

  const handleRetryReset = () => {
    restart(new Date(Date.now() + 30000));

    switch (dialogType) {
      case AccountServices.EMAIL_VERIFY:
        emailVerification();
        break;
      case AccountServices.RESET_PASSWORD:
        email && resetPassword({ email });
        break;
      case AccountServices.EMAIL_UPDATE:
        updateUserEmail(email);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isEmailVerification) emailVerification();
  }, []);
  return (
    <Box textAlign={"center"} color="grey.700">
      <Typography variant="h5Bold" color={"primary"}>
        {isEmailVerification || isEmailUpdate
          ? "Email Verification Link Sent"
          : "Password Assistance"}
      </Typography>
      <Typography sx={{ pt: 1 }} variant="smallRegularText">
        {isEmailUpdate ? (
          <>
            Email was sent to: {email}
            <br />
            <br />
            Your email address will be updated
            <br />
            once you verify it
          </>
        ) : (
          <>
            Email was sent to: {email}
            <br />
            Not your address?{" "}
          </>
        )}
        {!isEmailUpdate && (
          <Typography
            onClick={() =>
              isEmailVerification
                ? navigate("/my-account")
                : setStep && setStep(2)
            }
            component={"span"}
            sx={clickableTextStyle}
            variant="smallRegularText"
          >
            Update it
          </Typography>
        )}
      </Typography>

      <Typography sx={{ pt: 4 }} variant="smallRegularText">
        Didn't get the email? <br /> Check your spam folder. If you still can't
        <br />
        find it,{" "}
        {isRunning ? (
          <>
            retry in <TimerFieldIcon sx={{ mb: -0.25 }} /> {seconds}
          </>
        ) : (
          <Typography
            variant="smallRegularText"
            onClick={handleRetryReset}
            component={"span"}
            sx={clickableTextStyle}
          >
            Retry
          </Typography>
        )}
      </Typography>
      {!isEmailUpdate && (
        <Typography sx={{ pt: 1 }} variant="smallRegularText">
          For further assistance reach out to us at ---
        </Typography>
      )}
    </Box>
  );
};

export default ResetOrVerify;

export const clickableTextStyle = {
  cursor: "pointer",
  color: "primary.main",
  textDecoration: "underline",
};
