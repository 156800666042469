import { Box, Typography } from "@mui/material";
import { ProfileIcon } from "styles/icons";

interface IProps {
  name: string | null;
}

const WinnersName: React.FC<IProps> = ({ name }): JSX.Element => {
  return (
    <Box
      display={"flex"}
      gap={0.5}
      alignItems={"center"}
      sx={{ color: "primary.main" }}
    >
      <ProfileIcon sx={{ fontSize: "1.25rem" }} />
      <Typography
        sx={{
          textDecoration: "underline",
        }}
        variant="smallRegularText"
      >
        {name ? name : "No winner"}
      </Typography>
    </Box>
  );
};

export default WinnersName;
