import { Grid, Typography } from "@mui/material";
import { TimerTextField } from "Components/Timers";
import dayjs from "dayjs";
import { formatUSD } from "utils/helpers";

interface IProps {
  highestOffer: any;
  count: any;
  timerDate: Date;
}
const TopRightPanel: React.FC<IProps> = ({
  highestOffer,
  count,
  timerDate,
}): JSX.Element => {
  const isExpired = dayjs(timerDate).isBefore(dayjs());

  return (
    <Grid gap={2} justifyContent={"space-between"} container>
      <Grid flex={1} sx={boxStyle} item>
        <Typography variant={"h5Bold"} color={"primary.main"}>
          {formatUSD(highestOffer)}
        </Typography>
        <Typography fontSize={"0.625rem"} color="grey.500">
          Current highest offer
        </Typography>
      </Grid>
      <Grid flex={1} sx={boxStyle} item>
        <Typography variant={"h4Bold"} color={"info.main"}>
          {count || 0}
        </Typography>
        <Typography fontSize={"0.625rem"} color="grey.500">
          Amount of offers
        </Typography>
      </Grid>
      <Grid flex={1} sx={boxStyle} item>
        <Typography
          variant={"h6Bold"}
          color={true ? "warning.dark" : "error.main"}
        >
          <TimerTextField expiryTimestamp={timerDate} />
        </Typography>
        <Typography fontSize={"0.625rem"} color="grey.500">
          {isExpired ? "Listing is cloed" : "Time to listing closed"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TopRightPanel;

const boxStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  bgcolor: "common.white",
  height: 103,
  px: 1,
  textAlign: "center",
  borderRadius: 1,
  gap: 1,
};
