import GoogleMapReact from "google-map-react";
import { memo, useEffect, useState } from "react";
import { MarkerIcon } from "styles/icons";

interface IProps {
  fullAddress?: string | undefined;
}
const AnyReactComponent = ({}: any) => <MarkerIcon sx={{ fontSize: 26 }} />;

const GoogleMap: React.FC<IProps> = memo(({ fullAddress }) => {
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  useEffect(() => {
    if (fullAddress) {
      const handleGetCoordinates = async (fullAddress: string) => {
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
              fullAddress
            )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`
          );

          const data = await response.json();

          if (data.results.length > 0) {
            const lat = data.results[0]?.geometry?.location?.lat;
            const lng = data.results[0]?.geometry?.location?.lng;
            setCoordinates({ lat, lng });
          } else {
            console.error("No coordinates found for the given address.");
          }
        } catch (error) {
          console.error("Error fetching coordinates:", error);
        }
      };

      handleGetCoordinates(fullAddress || "");
    }
  }, [fullAddress]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {coordinates.lat !== 0 && coordinates.lng !== 0 && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API || "",
          }}
          center={coordinates}
          defaultZoom={15}
        >
          <AnyReactComponent lat={coordinates.lat} lng={coordinates.lng} />
        </GoogleMapReact>
      )}
    </div>
  );
});

export default GoogleMap;
