import { IAllOffers } from "./api/apiTypes";

export const getMyOffers = (
  offers: IAllOffers[] | undefined,
  userId: number | null | undefined
): IAllOffers[] | undefined => {
  const filteredOffers = offers?.filter((offer) => offer.user_id === userId);
  return filteredOffers && filteredOffers.length > 0
    ? filteredOffers
    : undefined;
};
