import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DeleteIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 15 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.897461 3.74524H13.3975"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M11.6385 3.74521H2.65292L2.58862 4.90786C2.46541 7.13545 2.57009 9.3698 2.90104 11.5761C3.04536 12.5382 3.87185 13.25 4.84473 13.25H9.44673C10.4196 13.25 11.2461 12.5382 11.3904 11.5761C11.7214 9.3698 11.8261 7.13545 11.7029 4.90786L11.6385 3.74521Z"
          fill="#EEF2FF"
        />
        <path
          d="M11.6385 3.74521H2.65292L2.58862 4.90786C2.46541 7.13545 2.57009 9.3698 2.90104 11.5761C3.04536 12.5382 3.87185 13.25 4.84473 13.25H9.44673C10.4196 13.25 11.2461 12.5382 11.3904 11.5761C11.7214 9.3698 11.8261 7.13545 11.7029 4.90786L11.6385 3.74521Z"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.64941 3.74521V3.24601C4.64941 2.58402 4.91239 1.94916 5.38048 1.48106C5.84857 1.01297 6.48344 0.75 7.14542 0.75C7.8074 0.75 8.44227 1.01297 8.91036 1.48106C9.37846 1.94916 9.64143 2.58402 9.64143 3.24601V3.74521"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.64746 6.41867V10.5566"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.64258 6.41867V10.5566"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default DeleteIcon;
