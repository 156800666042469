import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface ArrowIconProps extends SvgIconProps {
  fill?: string;
}

const BidderIcon: React.FC<ArrowIconProps> = ({ sx, fill, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 44 44"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="22"
          cy="22"
          r="21"
          fill="white"
          stroke="#CAD0D9"
          strokeWidth="2"
        />
        <g clipPath="url(#clip0_8561_21910)">
          <path
            d="M26.4213 20.752C25.4487 21.9037 24.0147 22.6333 22.4161 22.6333C20.8175 22.6333 19.3835 21.9039 18.4121 20.752C16.0707 22.1692 14.5 24.7889 14.5 27.7885C14.5 32.2931 30.3333 32.2931 30.3333 27.7885C30.3333 24.7891 28.7627 22.1692 26.4213 20.752Z"
            fill="#CAD0D9"
          />
          <path
            d="M22.4162 21.5074C23.5824 21.5074 24.6379 21.0192 25.3996 20.2309C26.1581 19.4474 26.6275 18.3647 26.6275 17.1702C26.6275 14.7743 24.7419 12.833 22.4162 12.833C20.0905 12.833 18.2061 14.7743 18.2061 17.1702C18.2061 18.3647 18.6755 19.4472 19.4328 20.2309C20.1958 21.0192 21.2511 21.5074 22.4162 21.5074Z"
            fill="#CAD0D9"
          />
        </g>
        <defs>
          <clipPath id="clip0_8561_21910">
            <rect
              width="15.8333"
              height="18.3333"
              fill="white"
              transform="translate(14.5 12.833)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default BidderIcon;
