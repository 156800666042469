import { ephrownApi } from "store/services/ephrownApi";
import { IAllOffers, IMyOffer } from "./apiTypes";
import { IAllOffersResponse, IMyOfferResponse } from "./responseTypes";

const offersApi = ephrownApi.injectEndpoints({
  endpoints: (builder) => ({
    setOfferApprove: builder.mutation({
      query: ({ listingId, offerorId }) => ({
        url: `/api/private/agent/listing/${listingId}/offeror/${offerorId}/approve`,
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),

    setOfferDecline: builder.mutation({
      query: ({ listingId, offerorId, body }) => ({
        url: `/api/private/agent/listing/${listingId}/offeror/${offerorId}/deny`,
        method: "POST",
        body: body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),

    getMyOffer: builder.query<IMyOffer, string>({
      query: (listingId) => ({
        url: `/api/private/user/offer-management/${listingId}/my-offer`,
      }),
      providesTags: ["userListings", "agentListings", "offers"],
      transformResponse: (response: IMyOfferResponse) => response.offer,
    }),
    getAllOffersListing: builder.query<IAllOffers[], string>({
      query: (listingId) => ({
        url: `/api/pub/all-offers/${listingId}`,
      }),
      providesTags: ["userListings", "agentListings", "offers"],
      transformResponse: (response: IAllOffersResponse) => response.offers,
    }),
    submitOffer: builder.mutation({
      query: ({ listingId, body }) => ({
        url: `/api/private/user/offer-management/${listingId}/submit-offer`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["offers"],
    }),
    editOffer: builder.mutation({
      query: ({ listingId, body }) => ({
        url: `/api/private/user/offer-management/${listingId}/edit-offer`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["offers"],
    }),
    selectWinner: builder.mutation({
      query: ({ listingId, body }) => ({
        url: `/api/private/agent/listing/${listingId}/select-winner`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
  }),
});

export const {
  useSetOfferApproveMutation,
  useSetOfferDeclineMutation,
  useGetMyOfferQuery,
  useSubmitOfferMutation,
  useGetAllOffersListingQuery,
  useEditOfferMutation,
  useSelectWinnerMutation,
} = offersApi;
