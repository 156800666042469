import React, { createContext, useContext, useState } from "react";

export type DialogName =
  | "Accept"
  | "Decline"
  | "PublishStepper"
  | "RegisterToOfferStepper"
  | "CVV"
  | "UpdatePublishDate"
  | "ChangeEmail"
  | "ChangePassword"
  | "EditPayment"
  | "DeletePayment"
  | "Confirm"
  | "AddMembership"
  | "SaleEnded"
  | "OfferorDetails"
  | "UpdateStatus"
  | "ConfirmVoidSale"
  | "ConfirmVoidContract"
  | "Images"
  | "ShareDirect"
  | "Delete"
  | "EmailVerification"
  | "AgentOfferorDetails"
  | "AcceptOffer"
  | "AddNewPayment"
  | "BlockUnverify"
  | "SubmitOffer"
  | "CVVRetry"
  | "UpdatePayment";

interface IDialogState {
  name: DialogName | null;
  props: any;
}

interface IDialogContextProps {
  dialog: IDialogState;
  openDialog: (name: DialogName, props?: any) => void;
  closeDialog: () => void;
}

const DialogContext = createContext<IDialogContextProps | undefined>(undefined);

export const useDialogContext = (): IDialogContextProps => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialogContext must be used within his Provider");
  }
  return context;
};

export const DialogProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }): JSX.Element => {
  const [dialog, setDialog] = useState<IDialogState>({
    name: null,
    props: null,
  });
  const openDialog = (name: DialogName, props?: any) => {
    setDialog({ name, props: props || null });
  };
  const closeDialog = () => setDialog({ name: null, props: null });

  return (
    <DialogContext.Provider value={{ dialog, openDialog, closeDialog }}>
      {children}
    </DialogContext.Provider>
  );
};
