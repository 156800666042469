import DialogContent from "@mui/material/DialogContent";
import ControlledRadio, {
  TRadioButton,
} from "Components/FormComponents/ControlledRadio";
import { useUpdateListingStatusMutation } from "features/listing/api/agentApi";
import type { IListing, IListingDetails } from "features/listing/types";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDialogContext } from "store/hooks/DialogsContext";
import { DialogContainer } from "..";
import DialogActions from "../DialogActions";
import { relativeContainerStyle } from "../DialogContainer";

interface IProps {
  listing: IListing | IListingDetails | undefined;
}

interface IUpdateStatusForm {
  status: string;
}

const UpdateStatusDialog: React.FC<IProps> = ({ listing }): JSX.Element => {
  const [updateStatus, { isLoading }] = useUpdateListingStatusMutation();
  const { closeDialog } = useDialogContext();

  const { handleSubmit, control } = useForm<IUpdateStatusForm>();
  const handleUpdateStatusForm: SubmitHandler<IUpdateStatusForm> = ({
    status,
  }) => {
    updateStatus({
      listingId: listing?.id,
      status,
    });
    closeDialog();
  };
  return (
    <>
      <DialogContainer sx={relativeContainerStyle} hideExit>
        <DialogContent sx={{ py: 0, pb: 3, px: { xs: 1, sm: 2 } }}>
          <ControlledRadio
            name="status"
            control={control}
            radioButtons={radioList}
            sx={{ justifyContent: "center" }}
          />
        </DialogContent>
        <DialogActions
          onSubmit={handleSubmit(handleUpdateStatusForm)}
          submitText="Confirm"
          isLoading={isLoading}
        />
      </DialogContainer>
    </>
  );
};

export default UpdateStatusDialog;

const radioList: TRadioButton[] = [
  { label: "In Contract", value: "in_contract" },
  { label: "Sold", value: "sold" },
];
