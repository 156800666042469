import { DialogContent } from "@mui/material";
import { DialogContainer } from "Components/Dialogs";
import ResetOrVerify from "features/auth/components/login/forgotPassword/ResetOrVerify";
import { AccountServices } from "features/listing/types";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import { VerifyEmailContainerStyle } from "./DialogContainer";

interface IProps {
  dialogType: AccountServices;
}

const EmailVerificationDialog: React.FC<IProps> = ({
  dialogType,
}): JSX.Element => {
  const authState = useAppSelector(selectAuth);
  return (
    <DialogContainer hideExit sx={VerifyEmailContainerStyle}>
      <DialogContent>
        <ResetOrVerify email={authState?.email} dialogType={dialogType} />
      </DialogContent>
    </DialogContainer>
  );
};

export default EmailVerificationDialog;
export const exitBtnStyle = {
  color: "primary.light",
  backgroundColor: "rgba(238, 242, 255, 0.5)",
  border: "none",
  ":hover": {
    border: "none",
  },
};
