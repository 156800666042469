import { Grid, Typography } from "@mui/material";
import { formatToMMM } from "Components/DatesAndTime/helpers";
import { TextIcon } from "Components/Fields";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { TimerProgressLine } from "Components/Timers";
import dayjs from "dayjs";
import {
  useGetSellingPriceQuery,
  useGetWinningOfferAmountQuery,
} from "features/listing/api/userApi";
import { MainStatusButton } from "features/listing/components/detailListing/user";
import {
  IListingDetails,
  ListingStatus,
  SaleType,
} from "features/listing/types";
import {
  getPastListingStatusDataStatus,
  getWinnerDataStatus,
} from "features/listing/utils/listingDetailHelper";
import { useParams } from "react-router-dom";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import { ErrorIcon } from "styles/icons";
import ClosedWinnerStatuses from "./ClosedWinnerStatuses";
import OpenWinnerStatuses from "./OpenWinnerStatuses";

interface IProps {
  listing: IListingDetails | undefined;
  userId: number | null | undefined;
}

const WinnerStatuses: React.FC<IProps> = ({ listing, userId }): JSX.Element => {
  const { verified } = useAppSelector(selectAuth);
  const isOpenListing = listing?.sale_type === SaleType.OPEN;
  const isPendingContract = listing?.status === ListingStatus.PENDING_CONTRACT;
  const status = getWinnerDataStatus(listing?.status);
  //timer fields
  const itemId = useParams().itemId || "";
  const contractDueDate = listing ? parseInt(listing.contract_due_date) : 0;
  const formatContractDueDate = formatToMMM(listing?.contract_due_date || "");
  const { data: winningAmount } = useGetWinningOfferAmountQuery(itemId, {
    skip: !verified || !isOpenListing,
  });
  const { data: sellingPrice } = useGetSellingPriceQuery(itemId, {
    skip: !verified || !isOpenListing,
  });
  const winnerSelectedDate =
    (listing && parseInt(listing?.winner_selected)) || 0;
  const winningOfferStatus = getPastListingStatusDataStatus(
    listing?.status,
    sellingPrice,
    winningAmount
  );
  return (
    <ConditionalWrapper>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection={"column"}
        mb={3}
      >
        <Grid item textAlign={"center"}>
          <Typography
            variant="h6Bold"
            color={status?.color || "success.main"}
            mb={1}
          >
            {status?.titleText}
          </Typography>
          {isPendingContract && (
            <>
              <TextIcon
                fontColor="grey.300"
                variant="extraSmallRegularText"
                text={`If the contract is not confirmed within  ${formatContractDueDate} , your penalty will be charged.`}
                icon={<ErrorIcon fill="#F87171" sx={{ fontSize: "0.8rem" }} />}
              />
            </>
          )}
        </Grid>
        {isPendingContract && (
          <Grid item xs={12} my={1} width={"100%"}>
            <TimerProgressLine
              linearColor="error"
              auctionStartDate={winnerSelectedDate}
              actualEndTime={contractDueDate}
              expiryTimestamp={dayjs.unix(contractDueDate).toDate()}
            />
          </Grid>
        )}
        {isOpenListing && (
          <MainStatusButton
            text={winningOfferStatus?.buttonText}
            price={winningOfferStatus?.price}
          />
        )}
      </Grid>
      {status?.showCountdown && !isPendingContract && (
        <Grid item xs={12} mb={3}>
          <TimerProgressLine
            linearColor="error"
            auctionStartDate={winnerSelectedDate}
            actualEndTime={contractDueDate}
            expiryTimestamp={dayjs.unix(contractDueDate).toDate()}
          />
        </Grid>
      )}
      {isOpenListing && (
        <OpenWinnerStatuses listing={listing} userId={userId} />
      )}
      {!isOpenListing && <ClosedWinnerStatuses />}
    </ConditionalWrapper>
  );
};

export default WinnerStatuses;
