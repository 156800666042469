import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

export type TPopupType = "snackbar" | "dialog";

interface IPopupState {
  isOpen: boolean;
  message?: string | null;
  type: TPopupType | null;
}

const initialState: IPopupState = {
  isOpen: true,
  message: null,
  type: null,
};

const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    openPopup: (state, action: PayloadAction<IPopupState>) => {
      state.isOpen = true;
      state.message = action.payload.message || "error";
      state.type = action.payload.type;
    },
    closePopup: (state) => {
      state.isOpen = false;
      state.message = null;
      state.type = null;
    },
  },
});

export const popupState = (state: RootState) => state.popup;

export const { openPopup, closePopup } = popupSlice.actions;

export default popupSlice.reducer;
