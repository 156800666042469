import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import ControlledDropzone, {
  registerToBidDzStyle,
} from "Components/FormComponents/ControlledDropzone";
import { ClosedBidOfferBox } from "features/listing/components/detailListing/agent/offerBox";
import {
  OfferorAttorneyCard,
  OfferorPurchaserCard,
} from "features/offerors/components";
import {
  IOfferData,
  TListingOffer,
  formatOfferData,
} from "features/offers/api";
import { useForm } from "react-hook-form";
import { useDialogContext } from "store/hooks/DialogsContext";
import { useListingDetailsStepperContext } from "store/hooks/ListingDetailsStepperContext";
import { IListingOfferorRequests } from "../api";
import { containerCardStyle } from "./RequestOfferOrDetails";

interface IProps {
  offer: TListingOffer | IListingOfferorRequests;
}

const AgentOfferorDetails: React.FC<IProps> = ({ offer }): JSX.Element => {
  const { options } = useListingDetailsStepperContext();
  const { openDialog } = useDialogContext();
  const offerData: IOfferData = formatOfferData(offer);
  const initialProofOfFounds = offerData?.proofOfFunds;
  const isProofOfFounds = offerData?.proofOfFunds !== "";
  const { control } = useForm<any>({
    mode: "onChange",
  });

  return (
    <Grid container>
      <Grid
        container
        flexDirection={{ xs: "column", sm: "row" }}
        gap={1}
        justifyContent={"start"}
        alignItems={"center"}
      >
        <Grid item flex={1} minWidth={160}>
          <Typography variant="h6Bold" color="primary">
            Offeror Details
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"center"}>
          <LoadingButton
            onClick={() => openDialog("AcceptOffer", { offer: offer })}
            sx={{ height: 32, minWidth: 250 }}
            fullWidth
            variant="successSecondary"
          >
            Accept this offer
          </LoadingButton>
        </Grid>
      </Grid>

      <Grid sx={boxContainersStyle} container gap={2.5}>
        <ClosedBidOfferBox
          sx={containerCardStyle}
          offer={offer}
          hideActionsAndDetails
        />
        {isProofOfFounds ? (
          <Grid item xs={12} py={1} sx={preApprovalContainerStyle}>
            <ControlledDropzone
              hasDownloadButton
              style={registerToBidDzStyle}
              control={control}
              name={"proof_of_funds"}
              label={"Pre-Approval:"}
              initialName={initialProofOfFounds}
              hasDeleteButton={false}
            />
          </Grid>
        ) : (
          <Grid item xs={12} py={1} sx={preApprovalContainerStyle}>
            <Typography variant="extraSmallBoldText" color="grey.800">
              Pre-Approval:
            </Typography>
            <Typography sx={proofOfFoundsValueStyle}>
              Pre-Approval not found
            </Typography>
          </Grid>
        )}

        <Grid container>
          <OfferorPurchaserCard offer={offer} options={options} />
        </Grid>
        <OfferorAttorneyCard offer={offer} options={options} />
      </Grid>
    </Grid>
  );
};

export default AgentOfferorDetails;

const boxContainersStyle = {
  mt: { xs: 2, md: 3 },
  p: { xs: 1.5, md: 3 },
  boxShadow: "0px 0px 10px 0px rgba(99, 102, 241, 0.1)",
  bgcolor: "common.white",
  borderRadius: 1.75,
};

const proofOfFoundsValueStyle = {
  fontSize: "0.6875rem",
  fontWeight: 500,
  color: "grey.500",
  flex: 1,
  display: "flex",
  justifyContent: "center",
  mb: 2,
};

const preApprovalContainerStyle = {
  border: "1px solid #E5E7EB",
  backgroundColor: "grey.50",
  borderRadius: 1,
  px: { xs: 2, sm: 3 },
};
