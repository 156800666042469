import { alpha, styled } from "@mui/material/styles";
import { DateTimeField } from "@mui/x-date-pickers";
export const StyledDateTimeField = styled(DateTimeField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: theme.palette.common.white,
    height: 40,
    padding: 12,
    alignItems: "center",
    cursor: "pointer",
    "& .MuiAutocomplete-input": {
      padding: 0,
      paddingLeft: 10,
    },
  },
  "& .MuiInputBase-input": {
    paddingLeft: 0,
    zIndex: 10,
    padding: 0,
    fontSize: "0.75rem",
    cursor: "pointer",

    "&:hover": {
      fontSize: "0.75rem",
    },
  },

  "& .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
    {
      border: `1.5px solid ${theme.palette.grey[600]}`,
    },
  "& .MuiInputBase-root.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
    {
      border: `1px solid ${theme.palette.grey[600]}`,
    },

  "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      backgroundColor: theme.palette.grey[50],
      border: `1.5px solid ${theme.palette.grey[600]}`,
    },
  "& .MuiInputBase-root.MuiAutocomplete-inputRoot.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[600]}`,
    },
  "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `${alpha(theme.palette.primary.main, 0.05)} 0 0 1px 0.1rem`,
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
    },
  "& .MuiInputBase-root.MuiAutocomplete-inputRoot.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
      border: `1px solid ${theme.palette.grey[600]}`,
      boxShadow: "none",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
    },

  "& .MuiInputLabel-root.Mui-error": {
    color: theme.palette.primary.main,
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline":
    {
      border: `1px solid ${theme.palette.error.main}`,
    },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "green",
  },
  "& .MuiSvgIcon-root": {
    zIndex: 10,
  },
  "& input::placeholder": {
    fontSize: "0.8rem",
  },
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[300],
  },
  "& .MuiInputBase-root.Mui-disabled:hover": {
    backgroundColor: theme.palette.grey[50],
  },
  "& .MuiInputBase-root.Mui-disabled:active": {
    backgroundColor: theme.palette.grey[50],
  },

  "& legend": {
    width: 0,
  },

  "& .MuiFormHelperText-root": {
    color: theme.palette.grey[300],
    display: "flex",
    marginLeft: 0,
    marginRight: 0,
    alignItems: "center",
  },
  "&.MuiTextField-root.passwordField .MuiFormLabel-root": {
    fontSize: "1rem",
  },
  ".MuiFormHelperText-root:before": {
    backgroundRepeat: "no-repeat",
    width: "10px",
    height: "10px",
    marginRight: "4px",
    content: "''",
    position: "relative",
    display: "inline",
    fontSize: "0.625rem",
  },
  ".MuiFormHelperText-root.Mui-error": {
    color: theme.palette.grey[300],
    fontSize: "0.625rem",
  },
  ".MuiFormHelperText-root.Mui-error:before": {
    backgroundImage: 'url("/static/images/statusIcons/errorIcon.svg")',
    backgroundSize: "0.625rem",
    backgroundPosition: "center",
  },
  ".MuiFormHelperText-root.Mui-success:before": {
    backgroundImage: 'url("/static/images/statusIcons/successIcon.svg")',
  },
  "& .MuiInputBase-multiline": {
    height: "auto",
  },
}));
