import {
  emailSchema,
  nameSchema,
  phoneSchema,
  priceSchema,
  stateSchema,
  stringSchema,
  zipCodeSchema,
} from "Components/Validation/reausableSchemas";
import * as yup from "yup";

export const NewListingSchema = yup.object().shape({
  address_line_1: stringSchema
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  address_line_2: yup
    .string()
    .matches(/^[a-zA-Z0-9\- '.,]*$/, "Invalid characters in the address")
    .max(50, "Maximum 50 characters")
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  city: yup
    .string()
    .matches(/^[a-zA-Z0-9\- '.,]*$/, "Invalid characters in the string")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  state: yup
    .string()
    .matches(/^[A-Z]+$/, "Capital letters only")
    .length(2, "Must be exactly 2 letters")
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  zip: yup
    .string()
    .matches(/^\d{5}$/, "must be exactly 5 digits")
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  description: yup
    .string()
    .min(3, "Description must be at least 3 characters")
    .max(500, "Description must be at most 500 characters")
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  property_type: yup.string().nullable(),
  sale_type: yup.string().nullable(),
  // title_report: yup
  //   .mixed()
  //   .nullable()
  //   .test("fileType", "File must be a PDF", (value: any) => {
  //     if (!value) return true;
  //     return value && value.type === "application/pdf";
  //   }),
  // inspection_report: yup
  //   .mixed()
  //   .nullable()
  //   .test("fileType", "File must be a PDF", (value: any) => {
  //     if (!value) return true;
  //     return value && value.type === "application/pdf";
  //   }),
  // deed: yup
  //   .mixed()
  //   .nullable()
  //   .test("fileType", "File must be a PDF", (value: any) => {
  //     if (!value) return true;
  //     return value && value.type === "application/pdf";
  //   }),
  // contract: yup
  //   .mixed()
  //   .nullable()
  //   .test("fileType", "File must be a PDF", (value: any) => {
  //     if (!value) return true;
  //     return value && value.type === "application/pdf";
  //   }),
  // other: yup
  //   .mixed()
  //   .nullable()
  //   .test("fileType", "File must be a PDF", (value: any) => {
  //     if (!value) return true;
  //     return value && value.type === "application/pdf";
  //   }),
  price: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .nullable()
    .typeError("Selling Price must be at least $1")
    .min(1, "Must be at least $1")
    .max(99999999, "Must be less than $99,999,999"),
  reserved_amount: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .nullable()
    .typeError("Reserved Amount must be at least $1")
    .min(1, "Must be at least $1")
    .max(99999999, "Must be less than $99,999,999"),
  offer_increment: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .nullable()
    .typeError("Offer increment must be at least $1")
    .min(1, "Must be at least $1")
    .max(99999999, "Offer increment must not exceed $99,999,999"),
  attorney_first_name: yup
    .string()
    .matches(/^[a-zA-Z\- ']+$/, "Invalid characters in the name")
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  attorney_last_name: yup
    .string()
    .matches(/^[a-zA-Z\- ']+$/, "Invalid characters in the name")
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  attorney_number: yup
    .string()
    .length(10, "Must be exactly 10 digits")
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  attorney_email_address: yup
    .string()
    .email("email must be vaild")
    .max(50, "Email cannot exceed 50 characters")
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  security_hold: yup
    .string()
    .max(4, "Security hold cannot exceed 4 digits")
    .transform((value) => (value === "" ? null : value))
    .nullable(),
});
