import { Grid, Typography } from "@mui/material";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import {
  useGetSellingPriceQuery,
  useGetWinningOfferAmountQuery,
} from "features/listing/api/userApi";
import {
  MainStatusButton,
  MySubmittedOffers,
} from "features/listing/components/detailListing/user";
import {
  IListing,
  SaleType,
  type IListingDetails,
} from "features/listing/types";
import { getPastListingStatusDataStatus } from "features/listing/utils/listingDetailHelper";
import { getMyOffers } from "features/offers";
import { IAllOffers } from "features/offers/api/apiTypes";
import { useGetAllOffersListingQuery } from "features/offers/api/offersApi";
import { useParams } from "react-router-dom";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";

interface IProps {
  listing: IListingDetails | IListing | undefined;
  userId: number | null | undefined;
}

const OpenPastListing: React.FC<IProps> = ({
  listing,
  userId,
}): JSX.Element => {
  const itemId = useParams().itemId || "";
  const { verified } = useAppSelector(selectAuth);
  const isOpenListing = listing?.sale_type === SaleType.OPEN;
  const { data: allOffersData, isLoading: allOffersLoading } =
    useGetAllOffersListingQuery(itemId);
  const myOffers = getMyOffers(allOffersData, userId);
  const { data: sellingPrice, isLoading: sellingPriceLoading } =
    useGetSellingPriceQuery(itemId);
  const { data: winningAmount, isLoading: winningAmountLoading } =
    useGetWinningOfferAmountQuery(itemId, {
      skip: !verified || !isOpenListing,
    });
  const status = getPastListingStatusDataStatus(
    listing?.status,
    sellingPrice,
    winningAmount
  );

  const hasOffers: boolean = !!myOffers && myOffers.length > 0;
  const isLoading =
    allOffersLoading || sellingPriceLoading || winningAmountLoading;
  return (
    <ConditionalWrapper isLoading={isLoading}>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={1}
      >
        <Grid item>
          <Typography color="primary" variant="h6Bold">
            {hasOffers ? (
              <>
                Your offer was not selected as the winning offer
                <br />
                Better luck next time!
              </>
            ) : (
              "You have not submitted an offer on this listing"
            )}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <MainStatusButton text={status?.buttonText} price={status?.price} />
      </Grid>

      {hasOffers && (
        <>
          <Grid>
            <Typography
              color="primary.main"
              variant="smallBoldText"
              sx={{ mt: 1 }}
            >
              My submitted offers
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            lg={5.5}
            mt={1}
            pr={{ xs: 0, lg: 2 }}
            style={{ maxHeight: 400, overflow: "auto" }}
          >
            {myOffers?.map((offer: IAllOffers) => (
              <MySubmittedOffers
                key={offer.offer_date}
                offer={offer}
                isOpenListing={isOpenListing}
              />
            ))}
          </Grid>
        </>
      )}
    </ConditionalWrapper>
  );
};

export default OpenPastListing;
