import {
  Box,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
  InputAdornment,
} from "@mui/material";
import { HelperText } from "Components/StyledComponents";
import ErrorText from "Components/StyledComponents/ErrorText";
import { useEffect, useState } from "react";
import { Control, useController } from "react-hook-form";
import { NumberFormatBase } from "react-number-format";
import { InfoIcon } from "styles/icons";

export type ExtendTextFieldProps = {
  control: Control<any>;
  name: string;
  label?: string;
  labelColor?: string;
  infoText?: string;
  editableText?: boolean;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  regex?: RegExp | undefined;
  maxLength?: number;
  outsideIcons?: any;
  mb?: number;
  autoCompleteValue?: string;
  setPasswordPopUp?: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdateClick?: any;
  isPriceRegex?: boolean;
  hideError?: boolean;
  basicInputStartAdornment?: React.ReactNode;
  customInput?: React.ComponentType<any>;
  format?: (numStr: any) => string;
} & TextFieldProps;

const ControlledNumericFormat: React.FC<ExtendTextFieldProps> = ({
  control,
  name,
  autoCompleteValue,
  label,
  outsideIcons,
  setPasswordPopUp,
  labelColor,
  placeholder,
  startAdornment,
  endAdornment,
  editableText = false,
  infoText,
  regex,
  mb = 1,
  onUpdateClick,
  maxLength = 500,
  hideError = false,
  isPriceRegex,
  customInput = TextField,
  basicInputStartAdornment = null,
  format = null,
  ...rest
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name, control, defaultValue: null });

  const [isEditMode, setIsEditMode] = useState(false);
  const inputProps = {
    startAdornment: (
      <InputAdornment position="start">{startAdornment}</InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position="end">{endAdornment}</InputAdornment>
    ),

    ...rest.inputProps,
  };

  const handleEdit = () => {
    if (setPasswordPopUp) {
      setPasswordPopUp(true);
    }
  };

  const editableInputProps = {
    // endAdornment: (
    //   <Box
    //     display="flex"
    //     sx={{ gap: 1, p: 0 }}
    //     onClick={() => {
    //       if (!isEditMode) setIsEditMode((prev) => !prev);
    //       if (isEditMode && !error) {
    //         setIsEditMode((prev) => !prev);
    //       }
    //     }}
    //   >
    //     {isEditMode ? (
    //       <>
    //         <IconButton
    //           sx={{ p: 0, m: 0 }}
    //           onClick={() => onUpdateClick(name, value)}
    //         >
    //           <CircleCheckIcon sx={iconsStyle} />
    //         </IconButton>
    //         <IconButton
    //           onClick={(e) => {
    //             e.stopPropagation();
    //             setIsEditMode(false);
    //           }}
    //           sx={{ p: 0, m: 0 }}
    //         >
    //           <ExitSquareIcon sx={iconsStyle} />
    //         </IconButton>
    //       </>
    //     ) : (
    //       <IconButton sx={{ p: 0, m: 0 }} onClick={handleEdit}>
    //         <EditPencilIcon sx={iconsStyle} />
    //       </IconButton>
    //     )}
    //   </Box>
    // ),
    // ...rest.InputProps,
  };

  useEffect(() => {
    if (autoCompleteValue) {
      onChange(autoCompleteValue);
    }
  }, [autoCompleteValue]);

  return (
    <Box width={"100%"} mb={mb}>
      {label && (
        <Typography
          sx={{ mb: 0.5 }}
          fontWeight={600}
          color={labelColor || "primary"}
          variant="extraSmallBoldText"
        >
          {label}
        </Typography>
      )}
      <Grid container display="flex" alignItems={"center"}>
        <Grid item flex={1}>
          <NumberFormatBase
            format={format || priceNumberFormat}
            style={{ width: "100%" }}
            name={name}
            value={value || ""}
            error={!!error}
            customInput={customInput}
            inputMode="numeric"
            thousandseparator=","
            onValueChange={({ value: v }) =>
              onChange({ target: { name, value: v } })
            }
            onBlur={onBlur}
            placeholder={placeholder || label}
            InputProps={editableText ? editableInputProps : inputProps}
            inputProps={{ maxLength: maxLength }}
            // startAdornment={basicInputStartAdornment}
            sx={{
              pointerEvents: editableText
                ? isEditMode
                  ? "auto"
                  : "none"
                : "auto",
              ...rest.sx,
            }}
          />
        </Grid>
        <Grid item>{outsideIcons || null}</Grid>
      </Grid>
      {infoText && (
        <HelperText
          icon={
            <InfoIcon
              sx={{ fontSize: "0.625rem", mr: 0.5, color: "info.main" }}
            />
          }
          text={infoText}
        />
      )}
      {error && !hideError && <ErrorText text={error?.message} />}
    </Box>
  );
};

export default ControlledNumericFormat;

export const CardExpiry = (props: any) => {
  const format = (val: any) => {
    if (val === "") return "";
    let month = val.substring(0, 2);
    const year = val.substring(2, 4);

    if (month.length === 1 && month[0] > 1) {
      month = `0${month[0]}`;
    } else if (month.length === 2) {
      // set the lower and upper boundary
      if (Number(month) === 0) {
        month = `01`;
      } else if (Number(month) > 12) {
        month = "12";
      }
    }

    return `${month}/${year}`;
  };

  return (
    <NumberFormatBase
      {...props}
      control={props.control}
      name={props.name}
      format={format}
    />
  );
};

export const expiryDateFormat = (val: any) => {
  if (val === "") return "";
  let month = val.substring(0, 2);
  const year = val.substring(2, 4);

  if (month.length === 1 && month[0] > 1) {
    month = `0${month[0]}`;
  } else if (month.length === 2) {
    // set the lower and upper boundary
    if (Number(month) === 0) {
      month = `01`;
    } else if (Number(month) > 12) {
      month = "12";
    }
  }

  return `${month}/${year}`;
};

export const priceNumberFormat = (numStr: any) => {
  if (numStr === "") return "";
  return new Intl.NumberFormat("en-US", {
    style: "decimal", // Change style to decimal
    maximumFractionDigits: 0,
  }).format(numStr);
};
