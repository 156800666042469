import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import PaymentMethodList from "features/payments/components/PaymentMethodList";
import { useDialogContext } from "store/hooks/DialogsContext";

const PaymentMethodTab: React.FC = (): JSX.Element | null => {
  const { openDialog } = useDialogContext();
  return (
    <Grid container>
      <Grid
        container
        item
        xs={10}
        md={11}
        mb={1}
        justifyContent={"space-between"}
      >
        <Typography
          variant="smallBoldText"
          sx={{ mt: 2, mb: 1, color: "grey.700" }}
        >
          My Payment Methods
        </Typography>

        <LoadingButton
          variant="primary"
          onClick={() => openDialog("AddNewPayment", { isStepper: false })}
        >
          Add a Payment Method
        </LoadingButton>
      </Grid>

      <PaymentMethodList />
    </Grid>
  );
};

export default PaymentMethodTab;
