import { Grid, Typography } from "@mui/material";
import React from "react";
import { formatUSD } from "utils/helpers";

interface IProps {
  offerAmount: number | undefined;
  mortgageAmount: number | undefined;
  onContract: number | undefined;
  balanceAtClosing: number | undefined;
  vertical?: boolean;
}

const ClosedBidOfferPrices: React.FC<IProps> = ({
  offerAmount,
  mortgageAmount,
  onContract,
  balanceAtClosing,
  vertical = false,
}): JSX.Element => {
  return (
    <Grid container gap={vertical ? 0 : 2} my={1} maxWidth={250}>
      <Grid
        container
        justifyContent={"space-between"}
        flexDirection={vertical ? "column" : "row"}
      >
        <Grid item>
          <Typography variant="extraSmallRegularText" color="grey.500">
            Purchase Price
          </Typography>
          <Typography variant="bigBoldText" color="primary">
            {formatUSD(offerAmount)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="extraSmallRegularText" color="grey.500">
            Mortgage Amount
          </Typography>
          <Typography variant="bigBoldText" color="primary">
            {formatUSD(mortgageAmount)}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"space-between"}
        flexDirection={vertical ? "column" : "row"}
      >
        <Grid item>
          <Typography variant="extraSmallRegularText" color="grey.500">
            On Contract
          </Typography>
          <Typography variant="bigBoldText" color="primary">
            {formatUSD(onContract)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="extraSmallRegularText" color="grey.500">
            Balance At Closing
          </Typography>
          <Typography variant="bigBoldText" color="primary">
            {formatUSD(balanceAtClosing)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClosedBidOfferPrices;
