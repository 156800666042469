import { styled } from "@mui/material/styles";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { FormControl, Typography } from "@mui/material";
import { CheckboxCheckedIcon } from "styles/icons";

export const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  border: "1px solid #E5E7EB",
  backgroundColor: theme.palette.common.white,
}));

export const BpCheckedIcon = styled(BpIcon)({});

export type ExtendCheckboxProps = {
  label?: string;
  labelColor?: string;
  notClickable?: boolean;
  bgColor?: string;
  sx?: object;
} & CheckboxProps;

const CustomizedCheckbox: React.FC<ExtendCheckboxProps> = ({
  label,
  labelColor,
  bgColor = "white.common",
  notClickable = false,
  checked,
  onChange,
  sx,
  ...props
}): JSX.Element => {
  return (
    <FormControl
      sx={{
        flexDirection: "row",
        alignItems: "center",
        ml: -1,
        ...sx,
      }}
    >
      <Checkbox
        {...props}
        checked={notClickable ? true : checked}
        onChange={onChange}
        sx={{
          "&.MuiButtonBase-root": {
            padding: 0,
            margin: 1,
          },
          cursor: notClickable ? "auto" : "pointer",
          "&.MuiButtonBase-root:not(.Mui-checked) > span": {
            bgcolor: notClickable ? "primary.main" : bgColor,
          },
          "&.MuiButtonBase-root.Mui-checked > span": {
            bgcolor: notClickable ? "primary.main" : "common.white",
          },
        }}
        disableRipple
        checkedIcon={<CheckboxCheckedIcon />}
        icon={<BpIcon />}
      />
      <Typography
        color={labelColor || "grey.700"}
        component={"span"}
        fontWeight={400}
        variant={"extraSmallRegularText"}
      >
        {label}
      </Typography>
    </FormControl>
  );
};

export default CustomizedCheckbox;
