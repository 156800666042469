import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { IContingency } from "features/offers/api";
import { Control, useController } from "react-hook-form";
import {
  CheckboxCheckedIcon,
  DisabledCheckboxIcon,
  EmptyCheckboxIcon,
} from "styles/icons";

interface IProps {
  contingencies: IContingency[] | undefined;
  name: string;
  control: Control<any>;
  isLoading?: boolean;
  readOnly?: boolean;
}

const ContingenciesLive: React.FC<IProps> = ({
  contingencies,
  name,
  control,
  readOnly = false,
}) => {
  const {
    field: { onChange, value },
  } = useController({ name, control });
  const handleCheckboxChange = (contingencyId: number) => {
    const updatedArray: IContingency[] = value.map(
      (contingency: IContingency) => {
        if (contingency.contingency_id === contingencyId) {
          return {
            ...contingency,
            is_waived: Boolean(!contingency.is_waived) ? true : false,
          };
        }
        return contingency;
      }
    );
    onChange(updatedArray);
  };

  const isChecked = (contingencyId: number) => {
    const matchedContingency = value?.find(
      (contingency: IContingency) =>
        contingency.contingency_id === contingencyId && contingency.is_waived
    );
    return !!matchedContingency;
  };

  return (
    <>
      <Typography mt={0} mb={0.5} color="primary.main" variant="smallBoldText">
        Waive Contingencies
      </Typography>
      <List disablePadding>
        {!contingencies && (
          <Typography color="grey.500" variant="smallRegularText">
            There are no contingencies on this listing{" "}
          </Typography>
        )}
        {value &&
          contingencies?.map(
            ({ contingency_name, waive_required, contingency_id }) => {
              return (
                <ListItem disableGutters disablePadding key={contingency_name}>
                  <ListItemText
                    sx={listItemStyle}
                    disableTypography
                    primary={
                      <>
                        <Checkbox
                          sx={{
                            cursor: "pointer",
                            pointerEvents: readOnly ? "none" : "default",
                          }}
                          icon={<EmptyCheckboxIcon />}
                          checkedIcon={
                            waive_required ? (
                              <DisabledCheckboxIcon />
                            ) : (
                              <CheckboxCheckedIcon />
                            )
                          }
                          checked={isChecked(contingency_id)}
                          disabled={waive_required ? true : false}
                          onChange={() => handleCheckboxChange(contingency_id)}
                        />
                        <Typography component="span" sx={listItemTextStyle}>
                          {contingency_name}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              );
            }
          )}
      </List>
    </>
  );
};

export default ContingenciesLive;

const listItemStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: { xs: 300, sm: 600, md: 350, lg: 250 },
};
const listItemTextStyle = {
  fontWeight: 400,
  fontSize: "0.75rem",
};
