import { IconButton } from "@mui/material";
import {
  useSetFavoriteMutation,
  useUnsetFavoriteMutation,
} from "features/listing/api/userApi";
import { FavoriteIcon } from "styles/icons";

interface IProps {
  isFavorite: boolean;
  listingId: string;
}

const FavoriteColumn: React.FC<IProps> = ({
  isFavorite,
  listingId,
}): JSX.Element => {
  const [setFavorite] = useSetFavoriteMutation();
  const [unsetFavorite] = useUnsetFavoriteMutation();
  const handleFavoriteClick = () => {
    !isFavorite ? setFavorite(listingId) : unsetFavorite(listingId);
  };
  return (
    <IconButton onClick={handleFavoriteClick}>
      <FavoriteIcon sx={{ fill: isFavorite ? "#FFD700" : "#fff" }} />
    </IconButton>
  );
};

export default FavoriteColumn;
