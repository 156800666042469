import { Box, Grid, Typography } from "@mui/material";
import { moreLinkStyle } from "Components/TabsPanel/ListingDetailsTabs/AgentListingDetailsTab";
import React, { useState } from "react";

interface IProps {
  text: string | undefined;
  lettersNumber?: number;
}

const ShowMoreText: React.FC<IProps> = ({
  text,
  lettersNumber = 700,
}): JSX.Element => {
  const [showMore, setShowMore] = useState(false);
  return (
    <Grid container>
      <Typography
        sx={{
          maxHeight: showMore ? "auto" : 90,
          lineHeight: "1.125rem",
          overflow: "hidden",
          wordBreak: "break-word",
          textOverflow: "ellipsis",
        }}
        variant="extraSmallRegularText"
        color="grey.700"
      >
        {showMore ? text : `${text?.substring(0, lettersNumber)}`}
      </Typography>
      {text && text?.length > lettersNumber && (
        <Box>
          <Typography
            onClick={() => setShowMore(!showMore)}
            component="span"
            sx={moreLinkStyle}
          >
            {showMore ? "Show less" : "Show more"}{" "}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default ShowMoreText;
