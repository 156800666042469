import { darkPrimary } from "./darkPrimary";
import { error } from "./error";
import { info } from "./info";
import { primary } from "./primary";
import { secondary } from "./secondary";
import { tertiary } from "./tertiary";
import { tertiaryPrimary } from "./tertiaryPrimary";
import { success } from "./success";
import { successLight } from "./successLight";
import { transparent } from "./transparent";
import { warning } from "./warning";
import { warningLight } from "./warningLight";
import { white } from "./white";
import { grey } from "./grey";
import { errorLight } from "./errorLight";
import { greyLight } from "./greyLight";
import { lightPrimary } from "./lightPrimary";
import { successSecondary } from "./successSecondary";
import { Theme } from "@mui/material/styles";

export const variantStyles: {
  [key: string]: (theme: Theme) => { [key: string]: any };
} = {
  primary,
  darkPrimary,
  lightPrimary,
  error,
  errorLight,
  tertiary,
  tertiaryPrimary,
  secondary,
  transparent,
  success,
  successLight,
  successSecondary,
  warning,
  warningLight,
  info,
  white,
  grey,
  greyLight,
};
