import { Grid, Typography } from "@mui/material";
import { FileCheckIcon } from "styles/icons";

interface IProps {
  name: string;
  size: string;
}

const DisplayFileUpload: React.FC<IProps> = ({ name, size }): JSX.Element => {
  return (
    <Grid container sx={fileUploadContainerStyle}>
      <FileCheckIcon />
      <Typography
        component={"span"}
        variant="extraSmallRegularText"
        flex={1}
        color={"grey.500"}
        ml={0.5}
        sx={nameStyle}
      >
        {name}
      </Typography>
      <Typography
        component={"span"}
        justifySelf={"end"}
        variant="extraSmallRegularText"
        color={"grey.500"}
      >
        {size}
      </Typography>
    </Grid>
  );
};

export default DisplayFileUpload;

const fileUploadContainerStyle = {
  bgcolor: "common.white",
  alignItems: "center",
  border: "1px solid #E5E7EB",
  height: 40,
  borderRadius: 1,
  padding: "11px 16px",
};

const nameStyle = {
  flex: 1,
  color: "grey.500",
  marginLeft: 0.5,
  marginRight: 0.5,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};
