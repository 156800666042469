import { Box, Divider, Typography } from "@mui/material";
import ReadOnlyContingenciesList from "Components/Contingencies/ReadOnlyContingenciesList";
import { ICollapseState } from "features/listing/components/detailListing/agent/offerBox/OpenBidOfferBox";
import { IContingencyOffer } from "features/offers/api";
import { Collapse } from "styles/components";

interface IProps {
  isOpenListing: boolean;
  title: string;
  idx: any;
  contingencies: IContingencyOffer[] | undefined;
  collapseState?: ICollapseState;
  setCollapseState?: (value: ICollapseState) => void;
}

const ContingenciesCollapse: React.FC<IProps> = ({
  contingencies,
  isOpenListing,
  title,
  idx,
  collapseState,
  setCollapseState,
}) => {
  const handleCollapse = (idx: number, expanded: boolean) => {
    if (collapseState === undefined || setCollapseState === undefined) return;
    const { isCollapsed, isOfferExpand } = collapseState;
    const updatedExpandState = [...isOfferExpand];
    updatedExpandState[idx] = expanded;
    setCollapseState({
      isCollapsed: isCollapsed ? false : isCollapsed,
      isOfferExpand: updatedExpandState,
    });
  };
  return (
    <Collapse
      sx={{ justifyContent: "space-between" }}
      setExpanded={(expanded) => handleCollapse(idx, expanded)}
      expanded={collapseState?.isOfferExpand[idx] || false}
      title={title}
      color="grey.500"
      titleStyle={titleStyle}
    >
      {!isOpenListing && contingencies && contingencies?.length > 0 && (
        <Box>
          <Divider
            sx={{ borderColor: "grey.600", my: 1.5 }}
            orientation={"horizontal"}
          />
          <Box>
            <Typography mb={0.5} variant="extraSmallBoldText" color="grey.700">
              Waived Contingencies
            </Typography>
          </Box>
          <ReadOnlyContingenciesList contingencies={contingencies} />
        </Box>
      )}
    </Collapse>
  );
};

export default ContingenciesCollapse;

const titleStyle = {
  color: "primary.main",
  fontWeight: 700,
  fontSize: "1.25rem",
};
