import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CalendarPrimaryIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.09183 10.525C1.25317 11.9644 2.42311 13.1072 3.86983 13.1769C4.86978 13.225 5.89127 13.2501 6.99991 13.2501C8.10855 13.2501 9.13003 13.225 10.13 13.1769C11.5767 13.1072 12.7466 11.9644 12.908 10.525C13.0166 9.55566 13.1058 8.56188 13.1058 7.54953C13.1058 6.53719 13.0166 5.54341 12.908 4.5741C12.7466 3.13471 11.5767 1.99187 10.13 1.92219C9.13003 1.87403 8.10855 1.849 6.99991 1.849C5.89127 1.849 4.86978 1.87403 3.86983 1.92219C2.42311 1.99187 1.25317 3.13471 1.09183 4.5741C0.983182 5.54341 0.894043 6.53719 0.894043 7.54953C0.894043 8.56188 0.983182 9.55566 1.09183 10.525Z"
          fill="white"
        />
        <path
          d="M4.75342 0.75L4.75342 3.28918"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M9.23975 0.75L9.23975 3.28918"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M12.6682 6.60474L1.33177 6.60474L1.4909 2.56996L6.93316 2.17524L12.6682 2.56996L12.6682 6.60474Z"
          fill="#EEF2FF"
        />
        <path
          d="M1.09183 10.525C1.25317 11.9644 2.42311 13.1072 3.86983 13.1769C4.86978 13.225 5.89127 13.2501 6.99991 13.2501C8.10855 13.2501 9.13003 13.225 10.13 13.1769C11.5767 13.1072 12.7466 11.9644 12.908 10.525C13.0166 9.55566 13.1058 8.56188 13.1058 7.54953C13.1058 6.53719 13.0166 5.54341 12.908 4.5741C12.7466 3.13471 11.5767 1.99187 10.13 1.92219C9.13003 1.87403 8.10855 1.849 6.99991 1.849C5.89127 1.849 4.86978 1.87403 3.86983 1.92219C2.42311 1.99187 1.25317 3.13471 1.09183 4.5741C0.983182 5.54341 0.894043 6.53719 0.894043 7.54953C0.894043 8.56188 0.983182 9.55566 1.09183 10.525Z"
          stroke="#4F46E5"
          strokeWidth="1.5"
        />
        <path d="M1.18628 6.22461H12.8738" stroke="#4F46E5" strokeWidth="1.5" />
      </svg>
    </SvgIcon>
  );
};

export default CalendarPrimaryIcon;
