import { DigitalClock, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dispatch, SetStateAction } from "react";
import { Control, useController } from "react-hook-form";
import { convertToAmPm, getRightDateType } from "../DatesAndTime/helpers";
import { Dayjs } from "dayjs";

interface ITimePicker {
  control: Control<any>;
  hourSelected: any;
  name: string;
  selectedDate: Dayjs | string;
  setHourSelected: Dispatch<SetStateAction<string>>;
}

const TimePicker: React.FC<ITimePicker> = ({
  control,
  name,
  selectedDate,
  setHourSelected,
  hourSelected,
}): JSX.Element => {
  const {
    field: { onChange, value },
  } = useController({ name, control });

  const selectedValue = getRightDateType(selectedDate) ?? null;
  const handleTimeChange = (newValue: any) => {
    const newTime = `${newValue.hour()}:${newValue.minute()}`;
    setHourSelected(convertToAmPm(newTime));
    onChange(getRightDateType(newValue) ?? null);
  };
  const handleChangeHour = (newValue: any) => {
    handleTimeChange(newValue);
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DigitalClock
          sx={timeStyles}
          value={selectedValue ?? null}
          onChange={handleChangeHour}
          timeStep={15}
        />
      </LocalizationProvider>
    </>
  );
};

export default TimePicker;

const timeStyles = {
  "&.MuiDigitalClock-root": {
    height: 295,
    maxHeight: 295,
    ".MuiMenuItem-root": {
      p: 1,
      mx: 1,
      color: "grey.500",
      "&:hover": {
        color: "grey.700",
        background: "#EEF2FF",
      },
      "&:focus": {
        color: "primary.main",
        fontWeight: 600,
      },
    },
  },

  "& .MuiButtonBase-root.MuiMenuItem-root.MuiDigitalClock-item": {
    fontSize: "0.75rem",
  },
  "& .Mui-selected": {
    backgroundColor: "common.white",
  },
};
