import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { RequestStatusButton } from "Components/Buttons";
import { formatToMMDDYY } from "Components/DatesAndTime/helpers";
import { exitBtnStyle } from "Components/Dialogs/ChangeEmailDialog";
import {
  contentContainerStyle,
  dialogContainerStyle,
  exitButtonStyle,
  registerToOfferContainerStyle,
} from "Components/Dialogs/DialogContainer";
import { RegisterToOfferDialogStyle } from "Components/Steppers/RegisterToOfferStepper";
import { RegisterStepName } from "Components/Steppers/StepsProgress";
import { Link } from "Components/StyledComponents";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import OffersTabsPanel from "Components/TabsPanel/OffersTabsPanel";
import { ProcessStepper, RegisterToOfferStepper } from "features/listing";
import { useGetHighestOfferAmountQuery } from "features/listing/api/userApi";
import { ISteps } from "features/listing/components/detailListing/ProcessStepper";
import {
  MainStatusButton,
  OpenOfferSubmitForm,
} from "features/listing/components/detailListing/user";
import { IListingDetails, SaleType } from "features/listing/types";
import { OfferorStatus } from "features/offerors/api";
import { getMyOffers } from "features/offers";
import { IUserOfferorRequest } from "features/offers/api";
import { useGetAllOffersListingQuery } from "features/offers/api/offersApi";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { selectAuth } from "store/features/authSlice";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { openPopup } from "store/features/popupSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  IOptions,
  useListingDetailsStepperContext,
} from "store/hooks/ListingDetailsStepperContext";
import OpenLiveDeniedOfferor from "./OpenLiveDeniedOfferor";
import OpenLivePendingOfferor from "./OpenLivePendingOfferor";

interface IProps {
  offerorRequestData: IUserOfferorRequest | undefined;
  userId: number | null | undefined;
}

const OpenLive: React.FC<IProps> = ({
  offerorRequestData,
  userId,
}): JSX.Element => {
  const { portal, verified } = useAppSelector(selectAuth);
  const [isVisible, setIsVisible] = useState(false);
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isOpenListing = listing?.sale_type === SaleType.OPEN;
  const itemId: string = useParams().itemId!;
  const { options } = useListingDetailsStepperContext();
  const {
    data: allOffersData,
    refetch: refetchAllOffersData,
    isSuccess: isAllOffersSuccess,
    isLoading: allOffersLoading,
  } = useGetAllOffersListingQuery(itemId);
  const {
    data: highestOfferData,
    refetch: refetchHighestOfferData,
    isSuccess: isHighestOfferSuccess,
    isLoading: highestOfferLoading,
  } = useGetHighestOfferAmountQuery(itemId);
  const isRefetchSuccess = isAllOffersSuccess && isHighestOfferSuccess;
  const myOffers = getMyOffers(allOffersData, userId);
  const isApproved = offerorRequestData?.status === OfferorStatus.APPROVED;
  const isPending = offerorRequestData?.status === OfferorStatus.PENDING;
  const isDenied = offerorRequestData?.status === OfferorStatus.DENIED;
  const isPaymemtFailed =
    offerorRequestData?.status === OfferorStatus.PAYMENT_FAILED;
  const isNotVerified =
    offerorRequestData?.status === OfferorStatus.NOT_VERIFIED ||
    !offerorRequestData;
  const isDeniedOrPending =
    offerorRequestData?.status === OfferorStatus.DENIED ||
    offerorRequestData?.status === OfferorStatus.PENDING;
  const lastFourDigits = offerorRequestData?.payment_method_last_four_digits;
  const dateSubmitted = formatToMMDDYY(offerorRequestData?.date_created || "");

  const handleRegisterOfferButton = () => {
    verified || !portal
      ? setIsVisible(true)
      : dispatch(
          openPopup({
            isOpen: true,
            type: "dialog",
          })
        );
  };

  // TEMPORARY - refresh every 5 seconds
  useEffect(() => {
    if (isAllOffersSuccess) {
      const intervalId = setInterval(() => {
        refetchHighestOfferData();
        refetchAllOffersData();
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [isOpenListing, isRefetchSuccess]);

  const isLoading = highestOfferLoading || allOffersLoading;
  return (
    <>
      <ConditionalWrapper isLoading={isLoading}>
        <Grid container>
          {!isDeniedOrPending && (
            <MainStatusButton
              text="Current highest offer:"
              price={highestOfferData?.amount || 0}
            />
          )}
          <Grid container>
            {/* Start Left side */}
            <Grid
              item
              xs={12}
              sm={6.5}
              sx={{ borderRight: { sm: "2px solid #E5E7EB" } }}
              pr={{ xs: 0, sm: 2 }}
              mt={2}
            >
              <Grid
                container
                alignItems={"center"}
                gap={1}
                justifyContent={"space-between"}
              >
                <Typography color="primary.main" variant="smallBoldText">
                  {isApproved
                    ? "Enter Offer Amount"
                    : isPending
                    ? "Register To Offer"
                    : "Listing Verification"}
                </Typography>
                {(isDeniedOrPending || isPaymemtFailed) && (
                  <RequestStatusButton status={offerorRequestData.status} />
                )}
              </Grid>
              {(isNotVerified || !portal) && (
                <Grid
                  container
                  mt={2}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  {!portal ? (
                    <Link
                      to={"/login"}
                      state={{ redirectToPublished: location.pathname }}
                    >
                      <LoadingButton
                        variant="primary"
                        sx={{ mb: 2, maxWidth: "100%" }}
                      >
                        Log In to submit Verification
                      </LoadingButton>
                    </Link>
                  ) : (
                    <LoadingButton
                      onClick={handleRegisterOfferButton}
                      variant="primary"
                      sx={{ mb: 2, width: 200 }}
                    >
                      Register To Offer
                    </LoadingButton>
                  )}
                  <ProcessStepper
                    setIsVisible={setIsVisible}
                    steps={registerToOfferSteps(options, listing)}
                  />
                </Grid>
              )}
              {isPending && (
                <OpenLivePendingOfferor
                  listing={listing}
                  dateSubmitted={dateSubmitted}
                />
              )}
              {(isDenied || isPaymemtFailed) && (
                <OpenLiveDeniedOfferor
                  isBlocked={offerorRequestData.blocked}
                  listing={listing}
                  isPaymemtFailed={isPaymemtFailed}
                  denialNotes={offerorRequestData.denial_notes}
                  denialReason={offerorRequestData.denial_reason_name}
                  dateSubmitted={dateSubmitted}
                  lastFourDigits={lastFourDigits}
                />
              )}
              {isApproved && (
                <OpenOfferSubmitForm
                  highestOffer={highestOfferData}
                  userId={userId}
                />
              )}
            </Grid>
            {/*END Left side */}
            {/*START Right side */}
            <Grid
              sx={offersTabsStyle}
              item
              container
              flexDirection={"column"}
              xs={12}
              sm={5.5}
            >
              <Grid container gap={2} mb={1}>
                <OffersTabsPanel
                  isApproved={isApproved}
                  myOffersData={myOffers}
                  allOffersData={allOffersData}
                  isOpenListing={isOpenListing}
                  userId={userId}
                  highestOffer={highestOfferData}
                />
              </Grid>
              {/* END Right side */}
            </Grid>
          </Grid>
        </Grid>
      </ConditionalWrapper>
      <Dialog
        open
        disableScrollLock
        onClose={() => setIsVisible(false)}
        maxWidth={"lg"}
        sx={{
          ...dialogContainerStyle,
          ...registerToOfferContainerStyle,
          visibility: isVisible ? "visible" : "hidden",
        }}
      >
        <Box m={{ xs: 1, sm: 2 }} sx={contentContainerStyle}>
          <LoadingButton
            onClick={() => setIsVisible(false)}
            variant="tertiary"
            sx={{ ...exitButtonStyle, ...exitBtnStyle }}
          >
            ✕
          </LoadingButton>

          <DialogContent sx={{ p: 0 }}>
            <RegisterToOfferStepper
              isNewRegister={true}
              dialogContentStyle={RegisterToOfferDialogStyle}
              setIsVisible={setIsVisible}
            />
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default OpenLive;

const offersTabsStyle = {
  pr: 1,
  pl: { xs: 0, sm: 2 },
  mt: 2,
  maxHeight: 725,
  overflowY: "auto",
};

const registerToOfferSteps = (
  options: IOptions,
  listing: IListingDetails
): ISteps[] => {
  const stepsData = [
    {
      name: RegisterStepName.PURCHASER,
      label: "Purchaser",
    },
    {
      name: RegisterStepName.FILEUPLOAD,
      label: "Proof of Funds",
    },
    {
      name: RegisterStepName.ATTORNEY,
      label: "Attorney",
    },
  ];

  if (options.includeOfferStep) {
    stepsData.push({
      name: RegisterStepName.OFFERDETAILS,
      label: "Offer Details",
    });
  }

  if (listing?.security_hold) {
    stepsData.push({
      name: RegisterStepName.PAYMENT,
      label: "Payment",
    });
  }

  stepsData.push({
    name: RegisterStepName.SUBMIT,
    label: "Submit",
  });

  return stepsData.map((step, index) => ({
    ...step,
    number: index,
  }));
};
