import { LoadingButtonProps } from "@mui/lab";
import LoadingButton from "@mui/lab/LoadingButton";

const Button: React.FC<LoadingButtonProps> = ({
  size = "large",
  sx,
  variant,
  loading,
  ...props
}) => {
  return (
    <LoadingButton
      sx={{
        maxWidth: "100%",
        borderRadius: 1,
        ...sx,
      }}
      loading={loading}
      variant={variant || "contained"}
      size={size}
      {...props}
    />
  );
};

export default Button;
