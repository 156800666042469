import { Grid, Typography } from "@mui/material";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import AgentDetails from "features/listing/components/AgentDetails";
import { MySubmittedOffers } from "features/listing/components/detailListing/user";
import { IListingDetails, SaleType } from "features/listing/types";
import { getMyOffers } from "features/offers";
import { useGetAllOffersListingQuery } from "features/offers/api/offersApi";
import { useParams } from "react-router-dom";

interface IProps {
  listing: IListingDetails | undefined;
  userId: number | null | undefined;
}

const OpenWinnerStatuses: React.FC<IProps> = ({
  listing,
  userId,
}): JSX.Element => {
  const itemId = useParams().itemId || "";
  const { data: allOffersData, isLoading } =
    useGetAllOffersListingQuery(itemId);
  const myOffers = getMyOffers(allOffersData, userId);
  const isOpenListing = listing?.sale_type === SaleType.OPEN;

  return (
    <ConditionalWrapper isLoading={isLoading}>
      <Grid container>
        <Grid container>
          {/* Start Left side */}
          <Grid
            item
            xs={12}
            sm={6.5}
            sx={{ borderRight: { sm: "2px solid #E5E7EB" } }}
            pr={{ xs: 0, sm: 2 }}
            mt={2}
          >
            <Grid container gap={1}>
              <Typography
                variant="bigBoldText"
                color={"primary.main"}
                sx={{ mb: 1 }}
              >
                Agent Details
              </Typography>

              <AgentDetails />
            </Grid>
          </Grid>
          {/*END Left side */}
          {/*START Right side */}
          <Grid
            sx={offersTabsStyle}
            item
            container
            flexDirection={"column"}
            xs={12}
            sm={5.5}
          >
            <Grid container>
              <Typography
                color="primary.main"
                variant="smallBoldText"
                sx={{ mb: 1 }}
              >
                My submitted offers
              </Typography>
              {myOffers?.map((offer) => (
                <MySubmittedOffers
                  offer={offer}
                  key={offer.user_id}
                  isOpenListing={isOpenListing}
                />
              ))}
            </Grid>
            {/* END Right side */}
          </Grid>
        </Grid>
      </Grid>
    </ConditionalWrapper>
  );
};

export default OpenWinnerStatuses;

// <ConditionalWrapper isLoading={isLoading}>
// <Grid container gap={1}>
//   <Grid bgcolor="common.white" item p={1} xs={12} lg={6.5} mt={2}>

//   </Grid>
//   <Grid
//     item
//     xs={12}
//     lg={4.5}
//     pl={2}
//     mt={2}
//     sx={{
//       borderLeft: { lg: "2px solid #E5E7EB" },
//     }}
//   >

//   </Grid>
//   <GoogleMap />
// </Grid>
// </ConditionalWrapper>
const offersTabsStyle = {
  pr: 1,
  pl: { xs: 0, sm: 2 },
  mt: 2,
  maxHeight: 725,
  overflowY: "auto",
};
