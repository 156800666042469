import * as yup from "yup";
import { validationSignupList } from "../components";
import { passwordSchema } from "Components/Validation/reausableSchemas";

export const ResetPasswordTokenSchema = yup.object().shape({
  new_password: yup
    .string()
    .required("New Password is required")
    .min(8, validationSignupList[0].text)
    .max(50, "password can't have more than 50 characters")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])/, validationSignupList[1].text)
    .matches(/^(?=.*[0-9])/, validationSignupList[2].text)
    .matches(
      /^(?=.*[$@#%&*()_+={}\[\]|~,\^"'<>,.\-=?])/,
      validationSignupList[3].text
    ),
  confirmPassword: passwordSchema
    .label("Confirm Password")
    .required("Please confirm your password")
    .max(50, "password can't have more than 50 characters")
    .oneOf([yup.ref("new_password")], "Passwords must match"),
});
