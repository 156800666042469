import ImagesSwiper from "Components/Swipers/ImagesSwiper";
import { NavbarImageSwiper } from "features/listing/components/images";
import { DialogContainer } from ".";

const ImagesDialog: React.FC = (): JSX.Element => {
  return (
    <DialogContainer sx={imagesDialogStyle} exitBtnStyle={exitImagesStyle}>
      <NavbarImageSwiper />

      <ImagesSwiper
        className={"imagesSwiperDialogContainer"}
        showThumbnails={false}
        hideDescription
      />
    </DialogContainer>
  );
};

export default ImagesDialog;

const imagesDialogStyle = {
  width: "100%",
  height: "100%",
  "& .MuiBackdrop-root.MuiModal-backdrop": {
    backgroundColor: "grey.900",
    opacity: "0.95 !important",
  },
  "& .MuiDialog-paper": {
    maxHeight: "none",
    maxWidth: "none",
    borderRadius: 0,
    m: 0,
    position: "static",
    backgroundColor: "rgba(0, 0, 0, 0)",
    "&.MuiDialog-paper > div:first-of-type": {
      m: 0,
      position: "static",
    },
    "@media (max-width: 601px)": {
      width: "90%",
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
};
const exitImagesStyle = {
  right: { xs: 24, sm: 20 },
  top: { xs: 24, sm: 20 },
  color: "common.white",
  bgcolor: "rgba(0,0,0,0)",
};
