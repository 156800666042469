import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { initialUserPortal } from "./helpers";
import { IAuthstate, TPortal } from "features/users/types";

const initialState: IAuthstate = {
  username: null,
  email: null,
  userTypes: null,
  agentRequestStatus: null,
  portal: null,
  userId: null,
  verified: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IAuthstate>) => {
      state.username = action.payload?.username;
      state.email = action.payload?.email;
      state.userTypes = action.payload?.userTypes;
      state.agentRequestStatus = action.payload?.agentRequestStatus;
      state.portal =
        action.payload?.portal || initialUserPortal(state.userTypes);
      state.userId = action.payload?.userId;
      state.verified = action.payload?.verified;
    },
    setPortal: (
      state,
      action: PayloadAction<{
        portal: TPortal;
      }>
    ) => {
      state.portal = action.payload?.portal;
    },
    logout: (state) => {
      localStorage.removeItem("user");
      state.username = null;
      state.email = null;
      state.userTypes = null;
      state.userId = null;
      state.verified = null;
      state.agentRequestStatus = null;
      state.portal = null;
      document.cookie =
        "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
  },
});

export const selectAuth = (state: RootState) => state.auth;

export const { setUser, logout, setPortal } = authSlice.actions;

export default authSlice.reducer;
