import { Grid, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import {
  ArchiveIcon,
  CircleCheckIcon,
  DeleteIcon,
  EditPencilIcon,
  ExitSquareIcon,
} from "styles/icons";

import { ControlledTextField } from "Components/FormComponents";
import { iconsStyle } from "Components/FormComponents/ControlledTextField/ControlledTextField";
import {
  useArchiveContingencyMutation,
  useDeleteContingencyMutation,
  useEditContingencyMutation,
  useUnArchiveContingencyMutation,
} from "features/contingencies";
import {
  useArchiveAgentFileTypeMutation,
  useUnArchiveAgentFileTypeMutation,
} from "features/listing/api/agentApi";
import { IFileType } from "features/listing/types";
import { IContingencySettings } from "features/offers/api/apiTypes";
import { Control, useFormContext } from "react-hook-form";

interface IProps {
  control: Control<any>;
  item: IContingencySettings | IFileType;
  isContingency: boolean;
  isArchived: boolean;
}

const ContingencyOrFileTypeItem: React.FC<IProps> = ({
  item,
  isContingency,
  isArchived,
  control,
}): JSX.Element => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { watch, trigger } = useFormContext();
  const [updateContingency] = useEditContingencyMutation();
  const isEditable = (item as IContingencySettings)?.editable;
  const [deleteContingency] = useDeleteContingencyMutation();
  const [archiveFileType] = useArchiveAgentFileTypeMutation();
  const [unArchiveFileType] = useUnArchiveAgentFileTypeMutation();
  const [archiveContingency] = useArchiveContingencyMutation();
  const [unArchiveContingency] = useUnArchiveContingencyMutation();

  const archive = () => {
    isContingency ? archiveContingency(item.id) : archiveFileType(item.id);
  };

  const unarchive = () => {
    isContingency ? unArchiveContingency(item.id) : unArchiveFileType(item.id);
  };

  const handleEditItem = async () => {
    let isValid = false;
    const fieldNames = [`${item.id}`];
    isValid = await trigger(fieldNames);
    if (isValid) {
      if (isContingency) {
        updateContingency({ id: item.id, name: watch(String(item.id)) });
        setIsEditMode((prev) => !prev);
      }
    }
  };

  return (
    <>
      <Grid item container alignItems={"center"} mb={1.5} xs={12} md={8}>
        <ControlledTextField
          sx={{
            input: {
              textOverflow: "ellipsis",
            },
          }}
          control={control}
          name={String(item.id)}
          fullWidth
          disabled={!isEditMode}
          mb={0}
          outsideIcons={
            <Grid
              container
              justifyContent={{ xs: "end", sm: "start" }}
              minWidth={96}
            >
              {!isEditMode ? (
                <>
                  {isEditable && (
                    <>
                      {!isArchived && (
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => setIsEditMode((prev) => !prev)}
                          >
                            <EditPencilIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Delete">
                        <IconButton onClick={() => deleteContingency(item.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}

                  <Tooltip title={isArchived ? "Unarchive" : "Archive"}>
                    <IconButton onClick={isArchived ? unarchive : archive}>
                      <ArchiveIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Grid container>
                  <IconButton sx={{ p: 1 }} onClick={handleEditItem}>
                    <CircleCheckIcon sx={iconsStyle} />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditMode(false);
                    }}
                    sx={{ p: 1 }}
                  >
                    <ExitSquareIcon sx={iconsStyle} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          }
        />
      </Grid>
    </>
  );
};

export default ContingencyOrFileTypeItem;
