import { IAgentOfferListing } from "features/offers/api";
import {
  IListing,
  IListingDetails,
  ListingStatus,
  ListingStatusCategory,
  SaleType,
} from "features/listing/types";
import { IListingDetailsResponse } from "features/listing/api/responseTypes";

export const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRODUCTION
    : process.env.REACT_APP_LOCAL;

export const transformListing = (listing: IListing): IListing => {
  let statusText: string = "";
  let statusCat: any;
  let saleType: SaleType;
  let isArchivable: boolean = false;
  switch (listing.status) {
    case ListingStatus.LIVE:
      statusText = ListingStatus.LIVE;
      statusCat = ListingStatusCategory.LIVE;
      break;
    case ListingStatus.FAILED_SALE:
    case ListingStatus.FAILED_CONTRACT:
      statusText = "Failed";
      statusCat = ListingStatusCategory.FAILED;
      break;
    case ListingStatus.SOLD:
      statusText = ListingStatus.SOLD;
      statusCat = ListingStatusCategory.SOLD;
      break;
    case ListingStatus.IN_CONTRACT:
      statusText = "In Contract";
      statusCat = ListingStatusCategory.IN_CONTRACT;
      break;
    case ListingStatus.PENDING_CONTRACT:
    case ListingStatus.PENDING_WINNER:
      statusText = "Pending";
      statusCat = ListingStatusCategory.PENDING;
      break;
    case "published":
    case "publish_scheduled":
      statusText = "Publish";
      statusCat = ListingStatusCategory.PUBLISH;
      break;
    case ListingStatus.DRAFT:
      statusText = ListingStatus.DRAFT;
      statusCat = ListingStatusCategory.DRAFT;
      break;
    case ListingStatus.NO_WINNER:
      statusText = "Failed";
      statusCat = ListingStatusCategory.FAILED;
      break;
    case ListingStatus.NO_OFFERS:
      statusText = "Failed";
      statusCat = ListingStatusCategory.FAILED;
      break;
    default:
      break;
  }

  saleType = formatSaleType(listing.sale_type);
  isArchivable = canBeArchived(listing.status, listing.archived);
  const fullAddress = formatFullAddress(listing);
  return {
    ...listing,
    statusText,
    statusCat,
    sale_type: saleType,
    isArchivable: isArchivable,
    full_address: fullAddress,
  };
};

export const getPropForStatus = (
  key: string
): { title: string; color: string } => {
  switch (key) {
    case "total_listings":
      return { title: "Total Listings", color: "grey.800" };
    case "drafts":
      return { title: "Draft Listings", color: "success.main" };
    case "published":
      return { title: "Publish", color: "primary.main" };
    case "live":
      return { title: "Live Listings", color: "info.main" };
    case "pending":
      return { title: "Pending", color: "warning.light" };
    case "in_contract":
      return { title: "In Contract", color: "warning.main" };
    case "sold":
      return { title: "Sold", color: "grey.500" };
    case "failed":
      return { title: "Failed", color: "error.light" };
    default:
      return { title: "", color: "primary.main" };
  }
};

export const transformListingDetails = (response: IListingDetailsResponse) => {
  const { listing, agent_info } = response;
  const fullAddress = formatFullAddress(listing);
  listing.full_address = fullAddress;
  if (agent_info) {
    listing.agentName = agent_info.legal_name;
    listing.agentCompany = agent_info.company;
    listing.agentPhone = agent_info.phone;
    listing.agentEmail = agent_info.email;
  }
  listing.sale_type = formatSaleType(listing.sale_type);

  return listing || null;
};

export const canBeArchived = (
  status: ListingStatus,
  archived: boolean
): boolean => {
  return (
    !archived &&
    [
      ListingStatus.PUBLISHED,
      ListingStatus.PUBLISH_SCHEDULED,
      ListingStatus.FAILED_SALE,
      ListingStatus.FAILED_CONTRACT,
      ListingStatus.SOLD,
      ListingStatus.NO_OFFERS,
      ListingStatus.NO_WINNER,
    ].includes(status)
  );
};
export const formatFullAddress = (
  item: IListing | IAgentOfferListing | IListingDetails
): string => {
  if (!item.address_line_1) {
    return "No address entered";
  }
  const addressLine1 = item.address_line_1 || "";
  const addressLine2 = item.address_line_2 ? ` ${item.address_line_2}` : "";
  const city = item.city ? ` ${item.city}` : "";
  const state = item.state ? `, ${item.state}` : "";
  const zip = item.zip ? ` ${item.zip}` : "";
  return `${addressLine1}${addressLine2}${city}${state}${zip}`;
};

type TErrorResponse = {
  data: {
    message: string;
  };
};

export { TErrorResponse };
export const getSortListings = (listings: IListing[]): IListing[] => {
  const PastListingStatuss = [
    ListingStatus.PENDING_WINNER,
    ListingStatus.PENDING_CONTRACT,
    ListingStatus.IN_CONTRACT,
    ListingStatus.SOLD,
    ListingStatus.NO_OFFERS,
    ListingStatus.NO_WINNER,
    ListingStatus.FAILED_CONTRACT,
    ListingStatus.FAILED_SALE,
  ];

  return listings?.sort((a: IListing, b: IListing) => {
    const statusA = a.status;
    const statusB = b.status;
    const isPastA = PastListingStatuss.includes(statusA);
    const isPastB = PastListingStatuss.includes(statusB);

    if (isPastA && isPastB) {
      return (
        new Date(b.auction_ended).getTime() -
        new Date(a.auction_ended).getTime()
      );
    }

    if (statusA === ListingStatus.LIVE && statusB === ListingStatus.LIVE) {
      return (
        new Date(a.auction_end_date).getTime() -
        new Date(b.auction_end_date).getTime()
      );
    }

    if (isPastA) {
      return -1;
    }

    if (isPastB) {
      return 1;
    }
    if (statusA !== statusB) {
      return statusOrder[statusA] - statusOrder[statusB];
    }

    return (
      new Date(a.auction_start_date).getTime() -
      new Date(b.auction_start_date).getTime()
    );
  });
};
const statusOrder: Record<string, number> = {
  live: 1,
  published: 2,
};

const formatSaleType = (saleType: string): SaleType => {
  if (saleType === "closed") return SaleType.CLOSED;
  if (saleType === "open") return SaleType.OPEN;
  return SaleType.CLOSED;
};
