import { Grid, IconButton } from "@mui/material";
import { ControlledTextField } from "Components/FormComponents";
import { iconsStyle } from "Components/FormComponents/ControlledTextField/ControlledTextField";
import { Control, useFormContext } from "react-hook-form";
import { CircleCheckIcon, ExitSquareIcon } from "styles/icons";

interface IProps {
  control: Control<any>;
  isContingency: boolean;
  addNewContingency: (value: string) => void;
  setIsAddingNewItem: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddNewItemInput: React.FC<IProps> = ({
  control,
  isContingency,
  addNewContingency,
  setIsAddingNewItem,
}): JSX.Element => {
  const { watch } = useFormContext();
  const name = isContingency ? "new_contingency" : "new_file";
  const label = isContingency ? "Add new contingency" : "Add new file";

  return (
    <Grid item xs={12} md={7} mt={2}>
      <ControlledTextField
        fullWidth
        label={label}
        labelColor="#4B5563"
        control={control}
        name={name}
        placeholder={isContingency ? "New contingency" : "New file type"}
        mb={0}
        outsideIcons={
          <>
            <IconButton
              sx={{ p: 1 }}
              onClick={() => addNewContingency(watch(name))}
            >
              <CircleCheckIcon sx={iconsStyle} />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsAddingNewItem(false);
              }}
              sx={{ p: 1 }}
            >
              <ExitSquareIcon sx={iconsStyle} />
            </IconButton>
          </>
        }
      />
    </Grid>
  );
};

export default AddNewItemInput;
