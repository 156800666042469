import { Container, Grid } from "@mui/material";

interface IProps {
  leftWrapper: React.ReactNode;
  rightWrapper: React.ReactNode;
}
const ListingDetailsWrapper: React.FC<IProps> = ({
  leftWrapper,
  rightWrapper,
}): JSX.Element => {
  return (
    <Container className="listingDetails" maxWidth={"xl"} disableGutters>
      <Grid container>
        {/* Start Left side */}
        <Grid
          item
          xs={12}
          md={6.5}
          px={{ xs: 0, md: 2 }}
          bgcolor="common.white"
        >
          <Grid py={2} px={1} container>
            {leftWrapper}
          </Grid>
        </Grid>
        {/* End Left side */}
        {/* Start Right side */}
        <Grid
          item
          minHeight={"100vh"}
          position={"relative"}
          md={5.5}
          xs={12}
          sx={rightSideContainerStyle}
          px={{ xs: 1, sm: 2 }}
        >
          <Grid
            py={2}
            px={1}
            container
            flexDirection={"column"}
            minHeight={"100%"}
          >
            {rightWrapper}
          </Grid>
        </Grid>
      </Grid>
      {/* End Right side */}
    </Container>
  );
};

export default ListingDetailsWrapper;

const rightSideContainerStyle = {
  backgroundColor: "grey.50",
  borderWidth: { xs: "1px 0 0 0", md: "0 0 0 1px" },
  borderStyle: "solid",
  borderColor: "grey.600",
};

export const mapContainerStyle = {
  border: "0.5px solid",
  borderColor: "primary.light",
  borderRadius: 0.5,
  overflow: "hidden",
  height: { xs: 250, md: 300 },
};
