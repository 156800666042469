import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { ListingDrawer } from "Components/Drawers";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { AuctionListing, NoListingYet } from "features/listing";
import {
  useGetAgentListingsQuery,
  useGetCountListingsQuery,
} from "features/listing/api/agentApi";
import MyOverview from "features/listing/components/detailListing/agent/MyOverview";
import type { IListing } from "features/listing/types";
import { useEffect, useState } from "react";
import { setCurrentListing } from "store/features/listingSlice";
import { useAppDispatch } from "store/hooks";
import { useCurrentListingContext } from "store/hooks/CurrentListingContext";
import { ArchiveIcon, NotificationIcon } from "styles/icons";

const AgentMyListings: React.FC = () => {
  const dispatch = useAppDispatch();
  const { setDrawerState } = useCurrentListingContext();
  const [filter, setFilter] = useState<string>("Total Listings");
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [allListings, setAllListings] = useState<IListing[]>([]);
  const { data: countData, isLoading: countLoading } =
    useGetCountListingsQuery();
  const {
    data: listings,
    isLoading: listingsLoading,
    refetch,
  } = useGetAgentListingsQuery({
    sort: "desc",
    limit: "1000",
    offset,
    archived: isArchived,
  });
  // TEMPORARY - infinite fetch more
  // const fetchMoreData = () => {
  //   if (listings) {
  //     setOffset((prevOffset) => prevOffset + 10);
  //   }
  // };

  const addListingDrawer = () => {
    dispatch(setCurrentListing(undefined));
    setDrawerState((prev) => ({
      isOpen: !prev.isOpen,
      mode: "add",
    }));
  };

  const handleCategoryFilter = (e: any, newFilter: string) => {
    setFilter(newFilter);
  };

  const isListingsEmpty = !listings?.length;

  const Row = ({ index }: { index: number }) => {
    const filteredListings =
      allListings &&
      allListings?.filter(
        (listing: IListing) =>
          filter === "Total Listings" || listing.statusCat === filter
      );

    const listing = filteredListings && filteredListings[index];

    return (
      <div>
        {listing && <AuctionListing key={listing.id} listing={listing} />}
      </div>
    );
  };

  const isLoading = countLoading || listingsLoading;

  useEffect(() => {
    if (listings) {
      setAllListings((prevListings) => [...prevListings, ...(listings || [])]);
    }
  }, [listings]);

  useEffect(() => {
    if (offset !== 0) {
      refetch();
    }
  }, [offset]);
  return (
    <ConditionalWrapper isLoading={isLoading}>
      <Grid
        container
        sx={{ mx: "auto", my: 3, px: { xs: 1, md: 5 } }}
        maxWidth="xl"
      >
        <Grid gap={2} container justifyContent="space-between" mb={3}>
          <Grid item>
            <Typography
              variant="bigBoldText"
              color="primary.main"
              fontWeight={700}
            >
              My Listings
            </Typography>
          </Grid>

          <Grid item>
            <ListingDrawer />
            <LoadingButton
              variant="tertiary"
              startIcon={
                isArchived ? (
                  <NotificationIcon stroke="#6366F1" sx={{ width: 14 }} />
                ) : (
                  <ArchiveIcon sx={{ width: 14 }} />
                )
              }
              onClick={() => setIsArchived((isArchived) => !isArchived)}
            >
              {isArchived ? "Active" : "Archived"}
            </LoadingButton>
            <LoadingButton
              variant="primary"
              sx={{ marginLeft: 2 }}
              onClick={addListingDrawer}
            >
              Add a Listing
            </LoadingButton>
          </Grid>
        </Grid>

        {/*
         // TEMPORARY - infinite fetch more
        {isListingsEmpty && !isLoading ? (
          <NoListingYet />
        ) : (
          <Grid item xs={12}>
            {!isArchived && (
              <MyOverview filter={filter} handleFilter={handleCategoryFilter} />
            )}
            {!isListingsEmpty && countData && (
              <Grid item xs={12}>

                 <InfiniteScroll
                  dataLength={allListings.length}
                  next={fetchMoreData}
                  hasMore={Number(countData[0].count) > allListings.length}
                  loader={<LoadingSpinner relative={true} size={50} />}
                  endMessage={""}
                >
                  {allListings.map((_item, index) => (
                    <Row key={index} index={index} />
                  ))}
                </InfiniteScroll>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </> */}
        {isListingsEmpty && !isLoading ? (
          <NoListingYet />
        ) : (
          <Grid item xs={12}>
            {!isArchived && (
              <MyOverview filter={filter} handleFilter={handleCategoryFilter} />
            )}
            {!isListingsEmpty &&
              listings
                ?.filter(
                  (listing: IListing) =>
                    filter === "Total Listings" || listing.statusCat === filter
                )
                .map((listing: IListing) => (
                  <AuctionListing key={listing.id} listing={listing} />
                ))}
          </Grid>
        )}
      </Grid>
    </ConditionalWrapper>
  );
};

export default AgentMyListings;
