import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { HomeBanner } from "Components/HomeComponents";
import { Login, Signup } from "features/auth";
import VerifyEmailWithToken from "features/auth/components/emailVerify/VerifyEmailWithToken";
import ResetPasswordWithToken from "features/auth/components/login/forgotPassword/ResetPasswordWithToken";
import { useLocation } from "react-router-dom";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";

const MobileTemp: React.FC = (): JSX.Element => {
  const { pathname } = useLocation();
  const { portal } = useAppSelector(selectAuth);
  const isGuest = portal === null;
  let page;
  switch (pathname) {
    case "/login":
      page = <Login />;
      break;
    case "/signup":
      page = <Signup />;
      break;
    case "/reset-password":
      page = <ResetPasswordWithToken />;
      break;
    case "/verify-email":
      !isGuest ? (page = <VerifyEmailWithToken />) : (page = <Login />);
      break;
    default:
      page = <Login />;
      break;
  }

  return (
    <Grid
      flex={1}
      minHeight={"calc(100vh - 80px)"}
      flexDirection={"column"}
      container
      alignItems="center"
      justifyContent={"space-around"}
    >
      <Grid item>
        <Typography variant="bigBoldText" textAlign={"center"} color="primary">
          Mobile App Coming Soon <br /> To Access please open on your
          <br />
          PC
        </Typography>
      </Grid>
      <Grid item>
        <HomeBanner />
      </Grid>
    </Grid>
  );
};

export default MobileTemp;
