import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Badge,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar as MUIAvatar,
  Stack,
  Typography,
} from "@mui/material";
import { Portal, TPortal } from "features/users/types";
import { useState } from "react";
import { useNavigate } from "react-router";
import { selectAuth, setPortal } from "store/features/authSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { StatusBadgeStyle, StyledBadge } from "styles/components/StyledAvatar";
import {
  ComputerIcon,
  EditPencilIcon,
  PersonIcon,
  SettingsIcon,
} from "styles/icons";

interface IProps {
  isPendingRequest?: boolean;
  isAgent?: boolean;
  isAdmin?: boolean;
}

const UserAvatarNestedMenu: React.FC<IProps> = ({
  isPendingRequest,
  isAgent,
  isAdmin,
}): JSX.Element => {
  const navigate = useNavigate();
  const { username, portal } = useAppSelector(selectAuth);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const isUserPortal = portal === Portal.USER;
  const isAgentPortal = portal === Portal.AGENT;
  const isAdminPortal = portal === Portal.ADMIN;

  const handleClick = () => {
    setOpen(!open);
  };

  const handlePortalChange = (newPortal: TPortal) => {
    dispatch(setPortal({ portal: newPortal }));
    navigate(newPortal === Portal.USER || null ? "/listings" : "/myListings");
  };
  return (
    <List component="nav" aria-labelledby="nested-list">
      <Grid container alignItems={"center"}>
        <ListItemButton onClick={handleClick}>
          <Grid item>
            <ListItemIcon>
              <Stack direction="row">
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  variant="dot"
                >
                  <MUIAvatar
                    alt="Remy Sharp"
                    src="/static/images/avatar/1.jpg"
                  />
                </StyledBadge>
              </Stack>
            </ListItemIcon>
          </Grid>
          <Grid item ml={1}>
            <Grid item>
              <ListItemText>
                <Typography
                  component={"span"}
                  variant="extraSmallBoldText"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {username}
                  {open ? <ExpandLess /> : <ExpandMore />}
                </Typography>
              </ListItemText>
            </Grid>
            {isPendingRequest && (
              <Grid item>
                <ListItemText>
                  <Typography variant="extraSmallRegularText">
                    RE Agent
                    <>
                      <Badge
                        overlap="circular"
                        variant="dot"
                        sx={StatusBadgeStyle}
                      ></Badge>
                      <Typography
                        sx={{ ml: 1, color: "orange" }}
                        component={"span"}
                        variant="extraSmallRegularText"
                      >
                        Pending
                      </Typography>
                    </>
                  </Typography>
                </ListItemText>
              </Grid>
            )}
          </Grid>
        </ListItemButton>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          <ListItemButton
            sx={nestedItemStyle}
            onClick={() => navigate("/my-account")}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <Typography variant={"extraSmallBoldText"}>My Account</Typography>
          </ListItemButton>
          {/*{!isPendingRequest && isUserPortal && !isAgent && !isAdmin && (
            <ListItemButton sx={nestedItemStyle}>
              <ListItemIcon>
                <EditPencilIcon />
              </ListItemIcon>
              <Typography variant={"extraSmallBoldText"}>
                Sign Up as Agent
              </Typography>
            </ListItemButton>
          )}*/}
          {isUserPortal && isAdmin && (
            <>
              <ListItemButton
                sx={nestedItemStyle}
                onClick={() => handlePortalChange(Portal.ADMIN)}
              >
                <ListItemIcon>
                  <ComputerIcon />
                </ListItemIcon>
                <Typography variant={"extraSmallBoldText"}>
                  Admin Portal
                </Typography>
              </ListItemButton>
              <ListItemButton
                sx={nestedItemStyle}
                onClick={() => handlePortalChange(Portal.AGENT)}
              >
                <ListItemIcon>
                  <ComputerIcon />
                </ListItemIcon>
                <Typography variant={"extraSmallBoldText"}>
                  Agent Portal
                </Typography>
              </ListItemButton>
            </>
          )}
          {isUserPortal && isAgent && (
            <ListItemButton
              sx={nestedItemStyle}
              onClick={() => handlePortalChange(Portal.AGENT)}
            >
              <ListItemIcon>
                <ComputerIcon />
              </ListItemIcon>
              <Typography variant={"extraSmallBoldText"}>
                Agent Portal
              </Typography>
            </ListItemButton>
          )}
          {isAgentPortal && (
            <>
              <ListItemButton
                sx={nestedItemStyle}
                onClick={() => handlePortalChange(Portal.USER)}
              >
                <ListItemIcon>
                  <ComputerIcon />
                </ListItemIcon>
                <Typography variant={"extraSmallBoldText"}>
                  User Portal
                </Typography>
              </ListItemButton>
              {isAdmin && (
                <ListItemButton
                  sx={nestedItemStyle}
                  onClick={() => handlePortalChange(Portal.ADMIN)}
                >
                  <ListItemIcon>
                    <ComputerIcon />
                  </ListItemIcon>
                  <Typography variant={"extraSmallBoldText"}>
                    Admin Portal
                  </Typography>
                </ListItemButton>
              )}
            </>
          )}
          {isAgentPortal && (
            <ListItemButton
              sx={nestedItemStyle}
              onClick={() => navigate("/settings")}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <Typography variant={"extraSmallBoldText"}>Settings</Typography>
            </ListItemButton>
          )}
          {isAdminPortal && (
            <>
              <ListItemButton
                sx={nestedItemStyle}
                onClick={() => handlePortalChange(Portal.USER)}
              >
                <ListItemIcon>
                  <ComputerIcon />
                </ListItemIcon>
                <Typography variant={"extraSmallBoldText"}>
                  User Portal
                </Typography>
              </ListItemButton>
              <ListItemButton
                sx={nestedItemStyle}
                onClick={() => handlePortalChange(Portal.AGENT)}
              >
                <ListItemIcon>
                  <ComputerIcon />
                </ListItemIcon>
                <Typography variant={"extraSmallBoldText"}>
                  Agent Portal
                </Typography>
              </ListItemButton>
            </>
          )}
        </List>
      </Collapse>
    </List>
  );
};

export default UserAvatarNestedMenu;

const nestedItemStyle = {
  pl: 3,
  mx: 0.5,
  borderRadius: 0.5,
  minWidth: "max-content",
  ".MuiListItemIcon-root": {
    minWidth: 24,
  },
};
