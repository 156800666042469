import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DollarIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 12 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 12.3569V1.64258"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.80329 4.74958C8.80329 3.6489 7.89972 2.71387 5.99972 2.71387C4.09972 2.71387 3.19615 3.59453 3.19615 4.74958C3.19615 5.64971 4.09972 6.51387 5.99972 6.7853C7.89972 7.05672 9.23186 7.68315 9.23186 9.03958C9.23186 10.2603 7.89972 11.2853 5.99972 11.2853C4.09972 11.2853 2.76758 10.4125 2.76758 9.24958"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default DollarIcon;
