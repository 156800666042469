import { Grid, Typography } from "@mui/material";
import { ControlledTextField } from "Components/FormComponents";
import { digitsRegex, textRegex } from "Components/Validation/regex";
import { useFormContext } from "react-hook-form";

const AttorneyInformationStep: React.FC = (): JSX.Element => {
  const { control } = useFormContext();
  return (
    <Grid item>
      <Grid item textAlign={"center"} mb={4}>
        <Typography color="grey.800" variant="bigBoldText">
          Verification:
        </Typography>
        <Typography color="grey.500" variant="extraSmallRegularText">
          Complete this process to get verified.
        </Typography>
      </Grid>
      <Grid container item className="attorneyInformationContainerStyle">
        <Grid item xs={12} sm={6} px={1}>
          <ControlledTextField
            sx={{ width: "100%" }}
            name={"attorney_first_name"}
            control={control}
            label={"Attorney's First Name"}
            placeholder="First Name"
            labelColor={"#111827"}
            regex={textRegex}
            maxLength={50}
          />
        </Grid>
        <Grid item xs={12} sm={6} px={1}>
          <ControlledTextField
            name={"attorney_last_name"}
            sx={{ width: "100%" }}
            control={control}
            label={"Attorney's Last Name"}
            placeholder="Last Name"
            labelColor={"#111827"}
            regex={textRegex}
            maxLength={50}
          />
        </Grid>
        <Grid item xs={12} sm={6} px={1}>
          <ControlledTextField
            sx={{ width: "100%" }}
            name={"attorney_email"}
            control={control}
            inputMode="email"
            label={"Attorney's Email"}
            placeholder="Email"
            labelColor={"#111827"}
            maxLength={50}
          />
        </Grid>
        <Grid item xs={12} sm={6} px={1}>
          <ControlledTextField
            name={"attorney_number"}
            sx={{ width: "100%" }}
            control={control}
            label={"Attorney's Phone Number"}
            placeholder="Phone Number"
            labelColor={"#111827"}
            regex={digitsRegex}
            maxLength={10}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AttorneyInformationStep;
