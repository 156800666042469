import { Box, Typography, useMediaQuery } from "@mui/material";
import { ListingStatus } from "features/listing/types";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useCurrentListingContext } from "store/hooks/CurrentListingContext";
import "../../css/imageSwiperDialog.css";
import "../../css/listingDetailsSwiper.css";
import { StatusButton } from "../Buttons";
import { NextButton, PreviousButton } from "./ArrowsButtons";

interface IProps {
  thumbnailPosition?: "bottom" | "left" | "right" | "top" | undefined;
  className?: string;
  showThumbnails?: boolean;
  itemStatus?: ListingStatus;
  hideDescription?: boolean;
}

const ImagesSwiper: React.FC<IProps> = ({
  thumbnailPosition = "right",
  showThumbnails = true,
  className,
  itemStatus,
  hideDescription = false,
}): JSX.Element => {
  const { images, imgIndex, setImgIndex } = useCurrentListingContext();
  const isFirstSlide = imgIndex === 0;
  const isLastSlide =
    images?.length !== undefined && imgIndex === images?.length - 1;
  const isLgOrLess = useMediaQuery("(max-width:1200px)");
  const thumbnailsResponsive = isLgOrLess ? "bottom" : thumbnailPosition;
  const handleSlide = (index: number) => {
    setImgIndex(index);
  };
  return (
    <>
      <ImageGallery
        startIndex={imgIndex}
        items={images}
        slideDuration={200}
        thumbnailPosition={thumbnailsResponsive || "right"}
        infinite={false}
        additionalClass={className}
        showThumbnails={showThumbnails}
        showPlayButton={false}
        showFullscreenButton={false}
        onSlide={handleSlide}
        renderLeftNav={(onClick) => (
          <PreviousButton onClick={onClick} disabled={isFirstSlide} />
        )}
        renderRightNav={(onClick) => (
          <NextButton onClick={onClick} disabled={isLastSlide} />
        )}
      />
      <StatusButton sx={statusButtonStyle} status={itemStatus || null} />
      <Box
        sx={{
          ...descriptionStyle,
          display: hideDescription ? "none" : "block",
        }}
      >
        <Typography color="grey.500" variant="extraSmallRegularText">
          Description:
          <Typography
            ml={1}
            color="grey.500"
            variant="extraSmallBoldText"
            component={"span"}
          >
            {images && images[imgIndex]?.description}
          </Typography>
        </Typography>
      </Box>
    </>
  );
};

export default ImagesSwiper;

const statusButtonStyle = {
  position: "absolute",
  top: 12,
  left: { xs: 20, lg: 28 },
  borderRadius: 3,
  margin: 0,
  height: "28px",
  padding: "5px 16px",
  zIndex: 1,
};

const descriptionStyle = {
  position: "absolute",
  bottom: { xs: "unset", lg: 20 },
  left: { xs: "unset", lg: 28 },
  top: { xs: 16, lg: "unset" },
  right: { xs: 20, lg: "unset" },
};
