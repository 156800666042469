import { Box, Grid, Typography } from "@mui/material";
import HouseAvatar from "Components/HouseImage/HouseAvatar";
import { moreLinkStyle } from "Components/TabsPanel/ListingDetailsTabs/AgentListingDetailsTab";
import { IAdditionalPhoto } from "features/listing/types";
import React, { useState } from "react";
import { Link } from "react-router-dom";

interface IProps {
  photosList: IAdditionalPhoto[] | undefined;
}

const ViewMorePhotos: React.FC<IProps> = ({ photosList }): JSX.Element => {
  const [viewAll, setViewAll] = useState(false);

  if (!photosList) return <Typography color="grey.600">--</Typography>;

  return (
    <>
      <Grid
        container
        gap={2}
        sx={{
          maxHeight: viewAll ? "auto" : 116,
          overflow: viewAll ? "auto" : "hidden",
        }}
      >
        {photosList.map(({ photo_name, photo_url }, idx) => (
          <HouseAvatar
            key={idx}
            noBorder={true}
            photoUrl={photo_url}
            photoName={photo_name}
          />
        ))}
      </Grid>
      {photosList.length > 5 && (
        <Box>
          <Typography sx={moreLinkStyle} onClick={() => setViewAll(!viewAll)}>
            {viewAll ? "View Less" : "View All"}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ViewMorePhotos;
