import { Theme } from "@mui/material/styles";

export const white = (theme: Theme) => ({
  color: theme.palette.error.light,
  backgroundColor: theme.palette.common.white,
  borderColor: theme.palette.grey[600],
  transitionProperty: "all",
  "&:hover": {
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.grey[400],
  },
  "&:focus": {
    backgroundColor: theme.palette.grey[100],
  },
  "&:active": {
    backgroundColor: theme.palette.grey[200],
    borderColor: theme.palette.grey[200],
  },
});
