import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";

import {
  Grid,
  Avatar as MUIAvatar,
  Typography,
  Badge,
  Stack,
} from "@mui/material";
import UserAvatarMenu from "./UserAvatarMenu";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { StatusBadgeStyle, StyledBadge } from "styles/components/StyledAvatar";

interface IProps {
  isPendingRequest?: boolean;
  isAgent?: boolean;
  isAdmin?: boolean;
}

const UserAvatar: React.FC<IProps> = ({
  isPendingRequest,
  isAgent,
  isAdmin,
}): JSX.Element => {
  const { username } = useAppSelector(selectAuth);

  return (
    <Grid ml={2} container>
      <Grid item>
        <Stack direction="row">
          <MUIAvatar alt="user-avatar" src="/static/images/avatar/1.jpg" />
          {isPendingRequest && <StyledBadge overlap="circular" variant="dot" />}
        </Stack>
      </Grid>
      <Grid item ml={1} display={"flex"} alignItems={"center"}>
        <Grid item>
          <Typography component={"span"} variant="extraSmallBoldText">
            {username}
            <UserAvatarMenu
              sx={{
                color: { md: "white.main" },
              }}
              icon={<KeyboardArrowDownIcon />}
              isPendingRequest={isPendingRequest}
              isAgent={isAgent}
              isAdmin={isAdmin}
            />
          </Typography>
        </Grid>
        {isPendingRequest && (
          <Grid item>
            <Typography variant="extraSmallRegularText">
              RE Agent
              <>
                <Badge
                  overlap="circular"
                  variant="dot"
                  sx={StatusBadgeStyle}
                ></Badge>
                <Typography
                  sx={{ ml: 0.5, color: "warning.main" }}
                  component={"span"}
                  variant="extraSmallRegularText"
                >
                  Pending
                </Typography>
              </>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default UserAvatar;
