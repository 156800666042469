import { LoadingButton } from "@mui/lab";
import { ButtonProps } from "@mui/material";
import { PaymentStatus } from "../types";

type ExtendedButtonProps = {
  status: string | null;
  sx?: object;
} & ButtonProps;

const StatusPaymentButton: React.FC<ExtendedButtonProps> = ({
  status,
  sx,
}): JSX.Element | null => {
  switch (status) {
    case PaymentStatus.COMPLETED:
      return (
        <LoadingButton
          variant="successLight"
          size="small"
          sx={{ ...paymentStatusBtnStyle, ...sx }}
        >
          Completed
        </LoadingButton>
      );
    case PaymentStatus.IN_HOLD:
      return (
        <LoadingButton
          variant="warning"
          size="small"
          sx={{ ...paymentStatusBtnStyle, ...sx }}
        >
          In Hold
        </LoadingButton>
      );
    case PaymentStatus.FAILED:
      return (
        <LoadingButton
          variant="errorLight"
          size="small"
          sx={{ ...paymentStatusBtnStyle, ...sx }}
        >
          Failed
        </LoadingButton>
      );
    case PaymentStatus.REFUNDED:
      return (
        <LoadingButton
          variant="grey"
          size="small"
          sx={{ ...paymentStatusBtnStyle, ...sx }}
        >
          Refunded
        </LoadingButton>
      );
    case PaymentStatus.RELEASED:
      return (
        <LoadingButton
          variant="primary"
          size="small"
          sx={{ ...paymentStatusBtnStyle, ...sx }}
        >
          Released
        </LoadingButton>
      );
    default:
      return (
        <LoadingButton
          variant="white"
          size="small"
          sx={{ ...paymentStatusBtnStyle, ...sx }}
        >
          No Status
        </LoadingButton>
      );
  }
};

const paymentStatusBtnStyle = {
  display: "flex",
  padding: "4px, 10px",
  borderRadius: 2,
  width: 95,
  height: 20,
  fontSize: "0.625rem",
  fontWeight: 700,
};
export default StatusPaymentButton;
