import DialogTitle from "@mui/material/DialogTitle";
import { useSetOfferApproveMutation } from "features/offers/api";
import { useDialogContext } from "store/hooks/DialogsContext";
import DialogActions from "./DialogActions";
import DialogContainer, { relativeSmallCircleStyle } from "./DialogContainer";

const AcceptDialog: React.FC<any> = ({ offerId, listingId }): JSX.Element => {
  const [setOfferApprove, { isLoading }] = useSetOfferApproveMutation();
  const { closeDialog } = useDialogContext();

  const handleConfirmClick = () => {
    setOfferApprove({
      listingId: listingId,
      offerorId: offerId,
    });
    closeDialog();
  };

  return (
    <DialogContainer
      sx={{ ...relativeSmallCircleStyle, borderRadius: 3 }}
      hideExit
    >
      <DialogTitle
        sx={{ pt: 0 }}
        textAlign={"center"}
        color="primary"
        fontWeight={700}
      >
        Accept Offer
      </DialogTitle>
      <DialogActions
        onSubmit={handleConfirmClick}
        submitText="Confirm"
        isLoading={isLoading}
      />
    </DialogContainer>
  );
};

export default AcceptDialog;
