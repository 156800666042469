import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface EnvelopeSendIconProps extends SvgIconProps {
  stroke?: string;
}

const EnvelopeSendIcon: React.FC<EnvelopeSendIconProps> = ({
  sx,
  stroke = "white",
  ...props
}) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.75 7H2"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.75 9.5H2"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.75 7H2"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.75 4.5H2"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.66758 10.9089C5.74865 11.5602 6.27361 12.074 6.92639 12.1419C8.00827 12.2544 9.1324 12.3806 10.2859 12.3806C11.4393 12.3806 12.5634 12.2544 13.6453 12.1419C14.2981 12.074 14.8231 11.5602 14.9041 10.9089C15.0209 9.97054 15.143 8.99739 15.143 7.99985C15.143 7.00231 15.0209 6.02916 14.9041 5.09075C14.8231 4.43947 14.2981 3.92567 13.6453 3.85778C12.5634 3.74526 11.4393 3.61914 10.2859 3.61914C9.1324 3.61914 8.00827 3.74526 6.92639 3.85778C6.27361 3.92567 5.74865 4.43947 5.66758 5.09075C5.55077 6.02916 5.42871 7.00231 5.42871 7.99985C5.42871 8.99739 5.55077 9.97054 5.66758 10.9089Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M5.66895 5.0498L9.44345 8.02588C9.9373 8.41526 10.6337 8.41526 11.1276 8.02588L14.9021 5.0498"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default EnvelopeSendIcon;
