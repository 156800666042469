import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const PlusIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 7 8"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="7"
        height="8"
        viewBox="0 0 7 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4 4.5V7.5H3V4.5H0V3.5H3V0.5H4V3.5H7V4.5H4Z" fill="#6366F1" />
      </svg>
    </SvgIcon>
  );
};

export default PlusIcon;
