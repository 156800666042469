import { ListingStatus } from "../types";

export const getWinnerDataStatus = (
  status: ListingStatus | undefined
): {
  titleText: string;
  showCountdown: boolean;
  color?: string;
} => {
  switch (status) {
    case ListingStatus.PENDING_CONTRACT:
      return {
        titleText: "Your submitted offer was selected!",
        showCountdown: true,
      };
    case ListingStatus.IN_CONTRACT:
      return {
        titleText: "Congratulations, you're under contract for this listing!",
        showCountdown: false,
      };
    case ListingStatus.FAILED_CONTRACT:
      return {
        titleText: "The contract has not been accepted",
        showCountdown: false,
        color: "error.main",
      };
    case ListingStatus.SOLD:
      return {
        titleText: "Congratulations on the purchase of this listing!",
        showCountdown: false,
      };
    case ListingStatus.FAILED_SALE:
      return {
        titleText: "This sale has failed",
        showCountdown: false,
        color: "error.main",
      };
    default:
      return {
        titleText: "Your submitted offer was selected!",
        showCountdown: true,
      };
  }
};

export const getPastListingStatusDataStatus = (
  status: ListingStatus | undefined,
  sellingPrice: number | undefined,
  winningAmount: number | undefined
): {
  buttonText: string;
  price: number;
} => {
  switch (status) {
    case ListingStatus.SOLD:
      return {
        buttonText: "Selling Price:",
        price: sellingPrice || 0,
      };

    default:
      return {
        buttonText: "Winning Offer:",
        price: winningAmount || 0,
      };
  }
};
