export enum PaymentMethodStatus {
  ACTIVE = "active",
  PENDING = "pending",
  REVOKED = "revoked",
  DECLINED = "declined",
  CANCELLED = "cancelled",
  EXPIRING_SOON = "expiring_soon",
}

export enum PaymentStatus {
  COMPLETED = "completed",
  IN_HOLD = "in_hold",
  FAILED = "failed",
  REFUNDED = "refunded",
  RELEASED = "released",
}

export type PaymentItem = {
  id: number;
  last_four_digits: string;
  name_on_card: string;
  exp: string;
  address_line_1: string;
  zip: string;
};

export type NewPaymentMethod = {
  payment_method: string;
  card_number: string;
  card_expiration_date: string;
  card_cvv: string;
  card_holder_name: string;
  address_line_1: string;
  zip: string;
};

export type SubscriptionItem = {
  membership_id: number;
  membership_name: string;
  status: string;
  membership_fee: number;
  competition_fee: number;
  sale_fee: number;
  max_allowed_competition_at_reduced_fee: number;
  max_allowed_sales_at_reduced_fee: number;
};
