import { ToggleButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ListingToggle = styled(ToggleButton)(() => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: "white",
    pointerEvents: "none",
  },

  "&.MuiToggleButtonGroup-grouped": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    alignItems: "start",
    border: 0,
    paddingBottom: 20,
    borderRadius: "8px 8px 0 0",
    alignSelf: "stretch",
  },

  "&.MuiToggleButtonGroup-grouped:before": {
    position: "absolute",
    content: "''",
    width: "100%",
    height: "5px",
    bottom: 0,
    left: 0,
    borderRadius: "8px 8px 0 0",
  },
}));
