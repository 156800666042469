import LiveListingStatus from "Pages/myListings/agentListingDetails/LiveListingStatus";
import PendingWinnerStatus from "Pages/myListings/agentListingDetails/PendingWinnerStatus";
import PublishedListingStatus from "Pages/myListings/agentListingDetails/PublishedListingStatus";
import { ListingStatus } from "features/listing/types";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import EditOrAddListing from "./EditOrAddListing";
import PendingContractStatus from "Pages/myListings/agentListingDetails/PendingContractStatus";

interface IProps {
  redirectToPublish: boolean;
  setRedirectToPublish: (value: boolean) => void;
}

const AgentRightScreen: React.FC<IProps> = ({
  redirectToPublish,
  setRedirectToPublish,
}): JSX.Element | null => {
  const { listing } = useAppSelector(listingState) as TListingDetails;

  if (listing) {
    switch (listing?.status) {
      case ListingStatus.LIVE:
        return redirectToPublish ? (
          <PublishedListingStatus redirectToPublish={redirectToPublish} />
        ) : (
          <LiveListingStatus setRedirectToPublish={setRedirectToPublish} />
        );
      case ListingStatus.DRAFT:
        return <EditOrAddListing isDrawer={false} />;
      case ListingStatus.PUBLISHED:
      case ListingStatus.PUBLISH_SCHEDULED:
        return <PublishedListingStatus redirectToPublish={redirectToPublish} />;
      case ListingStatus.PENDING_WINNER:
      case ListingStatus.FAILED_SALE:
      case ListingStatus.FAILED_CONTRACT:
      case ListingStatus.NO_OFFERS:
        return <PendingWinnerStatus />;
      case ListingStatus.PENDING_CONTRACT:
        return <PendingContractStatus />;

      default:
        return null;
    }
  }
  return null; // Handle the case when listing is undefined
};

export default AgentRightScreen;
