import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import ErrorText from "Components/StyledComponents/ErrorText";
import { ErrorResponse } from "features/auth/types";
import { useRetryPaymentMutation } from "features/offerors/api/offerorsApi";
import { useSubmitOfferMutation } from "features/offers/api/offersApi";
import { AddPaymentForm } from "features/payments";
import {
  IPaymentMethod,
  useGetPaymentMethodsQuery,
} from "features/payments/api";
import { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { useListingDetailsStepperContext } from "store/hooks/ListingDetailsStepperContext";
import { PaymentMethodCard } from "styles/components";
import { PlusIcon } from "styles/icons";
import { useUpdatePaymentMethodMutation } from "../api/paymentApi";
import DialogActions from "Components/Dialogs/DialogActions";

enum Steps {
  CVV = "cvv",
  PAYMENT_METHODS = "paymentMethods",
}

interface IUpdateMethodForm {
  cvv: string;
  offer_amount: string;
}

interface IProps {
  methods: any;
  isCVVDialog?: boolean;
  canInsertCVV?: boolean;
  setStep?: React.Dispatch<React.SetStateAction<Steps>>;
  isUpdateOnly?: boolean;
}

const SelectOrAddPaymentMethod: React.FC<IProps> = ({
  methods,
  canInsertCVV = true,
  isCVVDialog = false,
  isUpdateOnly = false,
  setStep,
}): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const { closeDialog } = useDialogContext();
  const [paymentAdded, setPaymentAdded] = useState(false);
  const {
    options,
    setOptions,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
  } = useListingDetailsStepperContext();

  const {
    data: paymentMethods,
    error: paymentMethodsError,
    isLoading: paymentMethodsLoading,
  } = useGetPaymentMethodsQuery();
  const [
    submitOffer,
    { error: submitOfferError, isLoading: submitOfferLoading },
  ] = useSubmitOfferMutation();
  const [
    updatePaymentMethod,
    { error: updatePaymentError, isLoading: updatePaymentLoading },
  ] = useUpdatePaymentMethodMutation();
  const [retryPayment] = useRetryPaymentMutation();
  const hasCvvError = !!methods.formState.errors.cvv?.message;
  const getOfferPayment = (): IPaymentMethod | null => {
    if (options?.offerorRequestData?.payment_method_id && paymentMethods) {
      const foundPayment = paymentMethods.find(
        (paymentMethod) =>
          paymentMethod.id === options?.offerorRequestData?.payment_method_id
      );

      return foundPayment || null;
    }

    return null;
  };

  const handleUpdatePayment: SubmitHandler<IUpdateMethodForm> = async (
    data
  ) => {
    try {
      const response = await updatePaymentMethod({
        listingId: listing?.id,
        payment_method_id: selectedPaymentMethod?.id,
      }).unwrap();

      if (isCVVDialog || !isUpdateOnly) {
        const body = {
          offer_amount: Number(data.offer_amount),
          cvv: data.cvv,
          ...(methods.getValues().hasOwnProperty("on_contract") && {
            on_contract: Number(methods.watch("on_contract")),
            mortgage_amount: Number(methods.watch("mortgage_amount")),
            contingencies: methods
              ?.watch("contingencies")
              ?.filter((contingency: any) => contingency?.is_waived)
              ?.map((contingency: any) => contingency?.contingency_id),
          }),
        };
        await submitOffer({ listingId: listing?.id, body }).unwrap();
        closeDialog();
      } else if (isUpdateOnly && response.status === "success") {
        const body = {
          cvv: data.cvv,
        };
        retryPayment({ listingId: listing?.id, body });
        closeDialog();
      }
    } catch (error) {
      console.error("Error adding payment method:", error);
    }
  };
  const handleCancel = () => {
    if (isCVVDialog) {
      setStep && setStep(Steps.CVV);
    } else {
      closeDialog();
    }
  };
  useEffect(() => {
    if (paymentAdded && paymentMethods) {
      setSelectedPaymentMethod(paymentMethods[paymentMethods?.length - 1]);
    } else if (selectedPaymentMethod) {
      setSelectedPaymentMethod(selectedPaymentMethod);
    } else if (paymentMethods && paymentMethods.length > 0) {
      options?.offerorRequestData?.payment_method_id
        ? setSelectedPaymentMethod(getOfferPayment())
        : setSelectedPaymentMethod(paymentMethods[0]);
    }
  }, [paymentMethods, setSelectedPaymentMethod]);

  const isLoading =
    paymentMethodsLoading || updatePaymentLoading || submitOfferLoading;
  const errorMessage =
    (updatePaymentError as ErrorResponse)?.data?.message ||
    (paymentMethodsError as ErrorResponse)?.data?.message ||
    (submitOfferError as ErrorResponse)?.data?.message;
  return (
    <Grid item>
      {!options.isAddPaymentOpen && (
        <Box
          component="form"
          onSubmit={methods.handleSubmit(handleUpdatePayment)}
        >
          <ConditionalWrapper relative isLoading={paymentMethodsLoading}>
            <Grid item textAlign={"center"} mb={4}>
              {!options.isAddPaymentOpen && (
                <>
                  <Typography color="grey.800" variant="bigBoldText">
                    Payment:
                  </Typography>
                  <Typography
                    sx={{ display: "inline" }}
                    color="grey.500"
                    variant="extraSmallRegularText"
                  >
                    A{" "}
                    <Typography
                      sx={{ display: "inline" }}
                      color="grey.500"
                      variant="extraSmallBoldText"
                    >
                      ${listing?.security_hold}
                    </Typography>{" "}
                    security hold will be placed on your card within 4 days of
                    closing.
                    <br /> If your offer is the winning offer and you don't
                    enter into a contract the security hold will be charged.
                    <br />
                    Otherwise, if your offer is not the winning offer the
                    security hold will be released immediately after closing.
                    For more see our terms and conditions.
                  </Typography>
                  {errorMessage && (
                    <ErrorText
                      sx={{ justifyContent: "center", mt: 1 }}
                      text={errorMessage}
                    />
                  )}
                </>
              )}
            </Grid>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Grid
                item
                p={2}
                borderRadius={1.5}
                bgcolor={"grey.50"}
                className="paymentContainer"
              >
                <Typography
                  variant="extraSmallRegularText"
                  color={"primary.main"}
                  textAlign="center"
                >
                  Select Payment Method
                </Typography>

                <Grid container sx={paymentsMethodListStyle}>
                  {paymentMethods?.map((paymentMethod: IPaymentMethod) => (
                    <Grid
                      xs={12}
                      item
                      p={0.5}
                      key={paymentMethod?.id}
                      onClick={() =>
                        paymentMethod && setSelectedPaymentMethod(paymentMethod)
                      }
                    >
                      <PaymentMethodCard
                        control={methods.control}
                        setValue={methods.setValue}
                        lastFourDigits={paymentMethod?.last_four_digits}
                        nameOnCard={paymentMethod?.name_on_card}
                        isSelected={
                          selectedPaymentMethod !== null &&
                          selectedPaymentMethod?.id === paymentMethod?.id
                        }
                        isCvvCollapsed={canInsertCVV}
                      />
                    </Grid>
                  ))}

                  <Grid item px={1}>
                    {selectedPaymentMethod && selectedPaymentMethod?.id < 0 && (
                      <ErrorText text={"Choose payment method"} />
                    )}
                    {canInsertCVV &&
                      hasCvvError &&
                      selectedPaymentMethod &&
                      selectedPaymentMethod?.id > 0 && (
                        <ErrorText text={"Insert right CVV to chosen card"} />
                      )}
                  </Grid>

                  <Grid item p={1}>
                    <LoadingButton
                      variant="tertiary"
                      startIcon={<PlusIcon sx={iconStyle} />}
                      onClick={() =>
                        setOptions((prev) => ({
                          ...prev,
                          isAddPaymentOpen: true,
                        }))
                      }
                      fullWidth
                    >
                      Add Payment Method
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ConditionalWrapper>
          {(isCVVDialog || isUpdateOnly) && (
            <DialogActions onCancel={handleCancel} isLoading={isLoading} />
          )}
        </Box>
      )}
      {options.isAddPaymentOpen && (
        <AddPaymentForm setPaymentAdded={setPaymentAdded} isStepper={true} />
      )}
    </Grid>
  );
};

export default SelectOrAddPaymentMethod;

const iconStyle = {
  "&.MuiSvgIcon-root": {
    fontSize: "0.75rem",
  },
};

const paymentsMethodListStyle = {
  maxHeight: 300,
  maxWidth: 300,
  overflowY: "auto",
  justifyContent: "center",
};

const AddPaymentContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 600,
    height: "fit-content",

    "@media (max-width: 601px)": {
      width: "90%",
      maxHeight: "90%",
    },
  },
};
