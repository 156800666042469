import { DialogContent } from "@mui/material";
import DialogContainer from "./DialogContainer";
import { RequestOfferorDetails } from "features/offerors/components";

const OfferorDetailsDialog: React.FC = (): JSX.Element => {
  return (
    <DialogContainer sx={dialogContainerStyle} hideExit>
      <DialogContent>
        <RequestOfferorDetails />
      </DialogContent>
    </DialogContainer>
  );
};

export default OfferorDetailsDialog;

export const dialogContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 800,
    height: "max-content",
  },
};
