import { Box, Typography, type SxProps, Grid } from "@mui/material";
import {
  IAddSubscription,
  IMembershipType,
} from "features/subscriptions/api/apiTypes";
import React, { useState } from "react";
import PlanBoxSelected from "./PlanBoxSelected";
import { Views } from "./AddMembership";
import { LoadingButton } from "@mui/lab";
import { formatUSD } from "utils/helpers";
import {
  useAddAgentSubscriptionMutation,
  useUpdateAgentSubscriptionMutation,
} from "features/subscriptions/api/subscriptionApi";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { SelectOrAddPaymentMethod } from "features/payments";
import { IPaymentMethod } from "features/payments/api";
import { useListingDetailsStepperContext } from "store/hooks/ListingDetailsStepperContext";

interface IProps {
  plan: IMembershipType;
  setPlanSelect: React.Dispatch<React.SetStateAction<Views>>;
  isEdit: boolean;
}

const PaymentForm: React.FC<IProps> = ({
  plan,
  setPlanSelect,
  isEdit,
}): JSX.Element | null => {
  const methods = useForm<IAddSubscription>();
  const { userId } = useAppSelector(selectAuth);
  const { closeDialog } = useDialogContext();
  const { selectedPaymentMethod } = useListingDetailsStepperContext();
  const isLoggedIn = userId !== null;
  const [isAddPaymentOpen, setIsAddPaymentOpen] = useState(false);
  const [addAgentSubscription] = useAddAgentSubscriptionMutation();
  const [updateAgentSubscription] = useUpdateAgentSubscriptionMutation();
  const planCost = formatUSD(plan.membership_fee);
  const handleAddSubscription: SubmitHandler<IAddSubscription> = async (
    data
  ) => {
    const addBody = {
      membership_type_id: plan.id,
      payment_method_id: selectedPaymentMethod && selectedPaymentMethod.id,
      terms: true,
      cvv: data.cvv,
    };

    const updateBody = {
      membership_type_id: plan.id,
    };

    !isEdit
      ? addAgentSubscription(addBody)
      : updateAgentSubscription(updateBody);

    closeDialog();
  };
  if (!isLoggedIn) return null;
  return (
    <FormProvider {...methods}>
      <Box
        sx={boxContainerStyle}
        component={"form"}
        onSubmit={methods.handleSubmit(handleAddSubscription)}
      >
        <PlanBoxSelected plan={plan} setPlanSelect={setPlanSelect} />
        <Typography
          textAlign={"center"}
          variant="regularText"
          fontSize={18}
          my={2}
        >
          Payment Details
        </Typography>
        <SelectOrAddPaymentMethod methods={methods} />
        <Grid container spacing={2} mt={1} justifyContent={"center"}>
          <Grid item xs={5}>
            <LoadingButton fullWidth variant="tertiary" onClick={() => {}}>
              Cancel
            </LoadingButton>
          </Grid>
          <Grid item xs={5}>
            <LoadingButton fullWidth variant="primary" type="submit">
              {`${planCost} Confirm Subscription`}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default PaymentForm;

const boxContainerStyle: SxProps = {
  padding: "20px",
};
