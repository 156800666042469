import {
  FormControl,
  Grid,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import { HelperText } from "Components/StyledComponents";
import ErrorText from "Components/StyledComponents/ErrorText";
import { ReactNode } from "react";
import { useController } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { InfoIcon } from "styles/icons";

interface IProps {
  name: string;
  hasInfoText?: ReactNode;
  label?: string;
  disabled?: boolean;
  sx?: object;
  readOnly?: boolean;
}

const AmountField: React.FC<IProps> = ({
  name,
  disabled = false,
  sx,
  label,
  readOnly = false,
  hasInfoText = false,
}): JSX.Element => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name });

  return (
    <FormControl sx={{ minWidth: "100%" }}>
      {label && (
        <Typography mb={1} color="primary.main" variant="smallBoldText">
          {label}
        </Typography>
      )}
      {hasInfoText && (
        <HelperText
          icon={
            <InfoIcon
              sx={{ fontSize: "0.7rem", mr: 0.5, color: "info.main" }}
            />
          }
          text={"Purchase Price - On Contract"}
        />
      )}
      <Grid
        container
        justifyContent={"center"}
        p={2}
        py={3}
        gap={2}
        sx={{ ...containerStyle, py: 1.5, ...sx }}
      >
        <NumericFormat
          allowNegative={false}
          value={value}
          name={name}
          customInput={Input}
          sx={{
            ...inputPriceStyle,
            pointerEvents: readOnly ? "none" : "default",
          }}
          placeholder={"000"}
          inputMode="numeric"
          error={!!error}
          thousandSeparator=","
          onValueChange={({ value }) => {
            onChange({ target: { name, value } });
          }}
          disabled={disabled}
          startAdornment={
            <InputAdornment sx={{ fontWeight: 700, my: 2 }} position="start">
              <span style={dollarStyle}>$</span>
            </InputAdornment>
          }
        />
      </Grid>
      {error && <ErrorText text={error?.message} color={"error.main"} />}
    </FormControl>
  );
};

export default AmountField;

export const transparentContainerStyle = {
  bgcolor: "transparent",
  border: "none",
  pb: 0,
};

export const containerStyle = {
  zIndex: 1,
  backgroundColor: "common.white",
  border: "1px solid",
  borderColor: "grey.400",
  borderRadius: 1.5,
};

export const inputPriceStyle = {
  flex: 1,
  flexBasis: 125,
  mr: 1,
  fontSize: "1.25rem",
  fontWeight: 700,
  color: "primary.main",

  input: {
    fontSize: "1.25rem !important",
    padding: "4px 0 2px",
  },
  "& input::placeholder": {
    fontSize: "1.25rem",
  },
  "&.MuiInputBase-root.MuiInput-root:before": {
    borderBottom: "1px solid",
    borderColor: "grey.400",
  },
  "& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active":
    {
      WebkitTextFillColor: "#6366F1",
    },
};

export const dollarStyle = {
  fontWeight: "700",
  color: "#6366F1",
  fontSize: "1.25rem",
};
