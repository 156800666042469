import FormControlLabel from "@mui/material/FormControlLabel";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Control, useController } from "react-hook-form";
import ErrorText from "../StyledComponents/ErrorText";

export type TRadioButton = {
  label: string;
  value: string;
};

interface ExtendRadioProps extends RadioProps {
  radioButtons: TRadioButton[];
  control: Control<any>;
  name: string;
  disabled?: boolean;
  sx?: object;
}

const ControlledRadio: React.FC<ExtendRadioProps> = ({
  radioButtons,
  name,
  control,
  disabled,
  sx,
}): JSX.Element => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: radioButtons[0].value,
  });

  return (
    <>
      <RadioGroup
        sx={sx}
        row
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        {radioButtons.map((btn) => (
          <FormControlLabel
            key={btn.label}
            value={btn.value}
            control={<Radio />}
            label={btn.label}
            sx={{ color: "common.black", fontWeight: 300 }}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
      {error && <ErrorText text={error?.message || ""} />}
    </>
  );
};

export default ControlledRadio;
