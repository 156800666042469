import { Grid, Typography } from "@mui/material";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { ClosedOfferSubmitForm } from "features/listing/components/detailListing/user";
import { useGetMyOfferQuery } from "features/offers/api/offersApi";
import { useParams } from "react-router-dom";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";

const ClosedPastListing: React.FC = (): JSX.Element => {
  const itemId = useParams().itemId || "";
  const { verified } = useAppSelector(selectAuth);
  const { data: myOfferData, isLoading: myOfferLoading } = useGetMyOfferQuery(
    itemId,
    {
      skip: !verified,
    }
  );
  return (
    <ConditionalWrapper isLoading={myOfferLoading}>
      {myOfferData && (
        <Grid container>
          <Grid
            container
            flexDirection={"column"}
            gap={1}
            mb={1}
            alignItems={"center"}
            textAlign={"center"}
          >
            <Typography color="primary" variant="h6Bold">
              Your Submitted Offer Has Not Been Selected
            </Typography>
            <Typography color="grey.300" variant="smallBoldText">
              Better Luck Next Time
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container>
        {!myOfferData && (
          <Grid container mt={3} justifyContent={"center"}>
            <Typography color="grey.500" variant="h5Bold">
              You Have Not Offered On This Listing
            </Typography>
          </Grid>
        )}
        {myOfferData && (
          <>
            <Typography color="primary.main" variant="boldText" sx={{ my: 3 }}>
              My Submitted Offers
            </Typography>
            <ClosedOfferSubmitForm readOnly />
          </>
        )}
      </Grid>
    </ConditionalWrapper>
  );
};

export default ClosedPastListing;
