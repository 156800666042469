import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import { HomeBanner } from "Components/HomeComponents";
import { Login, Signup } from "features/auth";
import VerifyEmailWithToken from "features/auth/components/emailVerify/VerifyEmailWithToken";
import ResetPasswordWithToken from "features/auth/components/login/forgotPassword/ResetPasswordWithToken";
import { useLocation } from "react-router-dom";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";

const Home: React.FC = (): JSX.Element => {
  const { pathname } = useLocation();
  const { portal } = useAppSelector(selectAuth);
  const isGuest = portal === null;
  let page;
  switch (pathname) {
    case "/login":
      page = <Login />;
      break;
    case "/signup":
      page = <Signup />;
      break;
    case "/reset-password":
      page = <ResetPasswordWithToken />;
      break;
    case "/verify-email":
      !isGuest ? (page = <VerifyEmailWithToken />) : (page = <Login />);
      break;
    case "/update-email":
      !isGuest ? (page = <VerifyEmailWithToken />) : (page = <Login />);
      break;
    default:
      page = <Login />;
      break;
  }

  return (
    <Container
      maxWidth="xl"
      sx={{ px: 1, display: "flex", minHeight: "100%", position: "relative" }}
    >
      <Grid container alignItems="center">
        <Grid item xs={12} sm={7} lg={6}>
          {page}
        </Grid>
        <Grid item xs={12} sm={5} lg={6}>
          <HomeBanner />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
