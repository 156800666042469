import { ephrownApi } from "store/services/ephrownApi";
import {
  IAddSubscription,
  IMembershipType,
  ISubscriptionInfo,
  IUpdateSubscription,
} from "./apiTypes";
import {
  IMembershipTypesResponse,
  ISubscriptionInfoResponse,
  ISubscriptionResponse,
} from "./responseTypes";

const subscriptionApi = ephrownApi.injectEndpoints({
  endpoints: (builder) => ({
    getAgentSubscription: builder.query<ISubscriptionInfo, void>({
      query: () => ({
        url: `/api/private/agent/subscription`,
      }),
      providesTags: ["agentListings"],
      transformResponse: (response: ISubscriptionInfoResponse) =>
        response?.subscription,
    }),
    cancelAgentSubscription: builder.mutation<ISubscriptionResponse, void>({
      query: () => ({
        url: `/api/private/agent/subscription/cancel`,
        method: "POST",
      }),
      invalidatesTags: ["agentListings"],
    }),
    reActivateAgentSubscription: builder.mutation<ISubscriptionResponse, void>({
      query: () => ({
        url: `/api/private/agent/subscription/reactivate`,
        method: "POST",
      }),
      invalidatesTags: ["agentListings"],
    }),
    updateAgentSubscription: builder.mutation<
      ISubscriptionResponse,
      IUpdateSubscription
    >({
      query: (body) => ({
        url: `/api/private/agent/subscription/update`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["agentListings"],
    }),
    addAgentSubscription: builder.mutation<
      ISubscriptionResponse,
      IAddSubscription
    >({
      query: (body) => ({
        url: `/api/private/subscription/add`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["agentListings"],
    }),
    getMembershipTypes: builder.query<IMembershipType[], void>({
      query: () => ({
        url: `/api/pub/get-membership-types`,
      }),
      providesTags: ["agentListings"],
      transformResponse: (response: IMembershipTypesResponse) =>
        response?.membership_types,
    }),
  }),
});

export const {
  useGetAgentSubscriptionQuery,
  useCancelAgentSubscriptionMutation,
  useReActivateAgentSubscriptionMutation,
  useUpdateAgentSubscriptionMutation,
  useAddAgentSubscriptionMutation,
  useGetMembershipTypesQuery,
} = subscriptionApi;
