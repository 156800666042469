import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import { ListingDrawer } from "Components/Drawers";
import HouseAvatar from "Components/HouseImage/HouseAvatar";
import { StatusButton, TimeButton } from "Components/StyledComponents";
import TopPanelButtons from "features/listing/components/detailListing/agent/TopPanelButtons";
import { ListingStatus } from "features/listing/types";
import { Dispatch, SetStateAction } from "react";
import { listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { CalendarPrimaryIcon } from "styles/icons";
import { ShowFullAddress } from "utils/helpersComponents";
import StatusOptions from "../../StatusOptions";

interface IProps {
  setIsSharedDisplay: Dispatch<SetStateAction<boolean>>;
  isSharedDisplay: boolean;
}

const TopDetailsPanel: React.FC<IProps> = ({
  setIsSharedDisplay,
  isSharedDisplay,
}): JSX.Element => {
  const { listing } = useAppSelector(listingState);
  const { openDialog } = useDialogContext();
  const showUpdatePublishButton =
    listing?.status === ListingStatus.PUBLISH_SCHEDULED;
  const showTimerButton = listing?.status === ListingStatus.LIVE;

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"space-between"}
      rowGap={{ xs: 1, sm: 2 }}
    >
      <Grid
        lg={4.8}
        md={12}
        sm={7}
        xs={12}
        item
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
      >
        <HouseAvatar photoUrl={listing?.main_photo} />
        <Box display={"flex"} flexDirection={"column"} gap={1} ml={2}>
          <StatusButton
            sx={{ minWidth: 56, height: 28 }}
            status={listing?.status}
          />
          <Box>
            <ShowFullAddress
              address_line_1={listing?.address_line_1}
              address_line_2={listing?.address_line_2}
              city={listing?.city}
              state={listing?.state}
              zip={listing?.zip}
            />
          </Box>
        </Box>
      </Grid>
      <Grid
        lg={4.5}
        md={6}
        xs={4}
        item
        m={{ xs: "0 auto", sm: 0 }}
        textAlign={"center"}
        display={"flex"}
        justifyContent={{ xs: "center", md: "start", lg: "end" }}
      >
        <TopPanelButtons
          showShares={isSharedDisplay}
          setShowShares={setIsSharedDisplay}
        />
      </Grid>
      <Grid
        m={{ xs: "0 auto", sm: 0 }}
        item
        lg={2.7}
        md={5}
        xs={12}
        gap={2}
        flexDirection={{ xs: "row", md: "column" }}
        display={"flex"}
        alignItems={{ xs: "stretch", md: "end" }}
        justifyContent={{ xs: "center", lg: "center" }}
        sx={{
          button: { width: { md: "auto", sm: 225 } },
        }}
      >
        <StatusOptions listing={listing} panelWidth />
        {showUpdatePublishButton && (
          <>
            <LoadingButton
              sx={{
                minWidth: "none",
                maxWidth: 150,
                gap: 0,
              }}
              onClick={() =>
                openDialog("UpdatePublishDate", {
                  listing,
                  publishDate: listing?.publish_date,
                })
              }
              variant="tertiaryPrimary"
              startIcon={<CalendarPrimaryIcon />}
            >
              Update Publish
            </LoadingButton>
          </>
        )}
        {showTimerButton && (
          <TimeButton
            sx={{ borderRadius: 0.5, height: "none" }}
            startDate={listing?.auction_end_date}
          />
        )}
      </Grid>
      <ListingDrawer />
    </Grid>
  );
};

export default TopDetailsPanel;
