import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";

export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#10B981",
    height: 10,
    width: 10,
    borderRadius: "50%",
    right: 5,
  },
}));

export const StatusBadgeStyle = {
  backgroundColor: "#F59E0B",
  height: 5,
  width: 5,
  borderRadius: "50%",
  marginLeft: 1.5,
};
