import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const SaleTypeIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3105_154624)">
          <path
            d="M13.7556 14.9588L14.6914 16.2506L16.4293 13.7556"
            stroke="#6366F1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.4379 15.0037C19.4379 17.455 17.4539 19.439 15.0027 19.439C12.5514 19.439 10.5674 17.455 10.5674 15.0037C10.5674 12.5524 12.5514 10.5684 15.0027 10.5684C17.4539 10.5684 19.4379 12.5524 19.4379 15.0037Z"
            stroke="#6366F1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.87671 17.2763V7.44873"
            stroke="#6366F1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.9397 9.16412V7.44873"
            stroke="#6366F1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.562256 17.2763H9.54276"
            stroke="#6366F1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.562256 6.75785L8.91933 0.562195L17.2764 6.75785"
            stroke="#6366F1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.87671 8.58443L8.91944 3.37079L15.9397 8.58443"
            stroke="#6366F1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.2871 3.81554V1.18561"
            stroke="#6366F1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.2036 5.21984V1.18561"
            stroke="#6366F1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.7974 1.18561H15.7159"
            stroke="#6366F1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.4925 17.2763V11.393C7.4925 10.4123 6.6904 9.61017 5.70964 9.61017C4.72888 9.61017 3.9043 10.4123 3.9043 11.393V17.2763"
            stroke="#6366F1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.89355 7.42627H9.94503"
            stroke="#6366F1"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3105_154624">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default SaleTypeIcon;
