import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Grid from "@mui/material/Grid";
import {
  ControlledContingencies,
  ControlledTextField,
} from "Components/FormComponents";
import ControlledCheckbox from "Components/FormComponents/ControlledCheckbox";
import ControlledRadio, {
  TRadioButton,
} from "Components/FormComponents/ControlledRadio";
import ControlledNumericFormat from "Components/FormComponents/ControlledTextField/ControlledNumericFormat";
import DateTimePickerField from "Components/FormComponents/DateTimePickerField";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { digitsRegex } from "Components/Validation/regex";
import { useGetContingenciesQuery } from "features/listing/api/agentApi";
import { SaleType } from "features/listing/types";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { DollarIcon } from "styles/icons";

const NewOfferDetailsTab: React.FC = (): JSX.Element => {
  const { control, watch } = useFormContext();
  const { listing } = useAppSelector(listingState);
  const { data: contingenciesData, isLoading: contingenciesLoading } =
    useGetContingenciesQuery({ archived: false });
  const [isReservedChecked, setIsReservedChecked] = useState(false);
  const handleReservedChange = () => {
    setIsReservedChecked(!isReservedChecked);
  };
  useEffect(() => {
    const reservedChecked = listing?.reserved;
    if (reservedChecked) setIsReservedChecked(reservedChecked);
  }, []);
  return (
    <ConditionalWrapper isLoading={contingenciesLoading}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <ControlledNumericFormat
            fullWidth
            labelColor="#4B5563"
            control={control}
            name="price"
            startAdornment={<DollarIcon />}
            label="Selling/Starting Price *"
            placeholder="Selling/Starting Price"
          />
          <ControlledRadio
            radioButtons={saleTypeList}
            control={control}
            name="sale_type"
          />

          <ControlledNumericFormat
            fullWidth
            labelColor="#4B5563"
            control={control}
            name="offer_increment"
            startAdornment={<DollarIcon />}
            placeholder="Selling/Starting Price"
            label="Offer Increment"
            infoText="Minimum increment amount for counter offers"
          />
          <Grid container spacing={3}>
            <Grid item xs={6} sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <DateTimePickerField
                name="auction_start_date"
                label="Auction start date *"
                infoText="Or select publish now on top"
              />
            </Grid>
            <Grid item xs={6} sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <DateTimePickerField
                name="auction_end_date"
                label="Auction end date *"
                infoText="Or select publish now on top"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ControlledCheckbox
              name="reserved"
              control={control}
              label="Reserved Sale"
              onClick={handleReservedChange}
            />
          </Grid>
          {isReservedChecked && (
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <ControlledNumericFormat
                  fullWidth
                  labelColor="#4B5563"
                  regex={digitsRegex}
                  control={control}
                  name="reserved_amount"
                  startAdornment={<AttachMoneyIcon color="primary" />}
                  label="Price:"
                  placeholder={
                    listing?.reserved_amount
                      ? listing?.reserved_amount.toString()
                      : "Enter Price"
                  }
                  value={listing?.reserved_amount}
                />
              </Grid>
              <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                <ControlledCheckbox
                  name="reserved_disclosed"
                  control={control}
                  label="Disclosed"
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <ControlledCheckbox
              name="automatic_winner"
              control={control}
              label="Automatic Winner"
              value=""
            />
          </Grid>

          {watch("sale_type") === "closed" ? (
            <Grid item xs={12} sx={{ marginBottom: "2rem", marginTop: "1rem" }}>
              <ControlledContingencies
                items={contingenciesData?.contingencies || []}
                name="contingencies"
                control={control}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sx={{ marginBottom: "2rem", marginTop: "1rem" }}>
              <ControlledTextField
                multiline
                fullWidth
                labelColor="#4B5563"
                control={control}
                name="contingency_disclaimer"
                label="Contingency Disclaimer"
                placeholder="Contingency Disclaimer..."
                maxLength={225}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </ConditionalWrapper>
  );
};

export default NewOfferDetailsTab;

const saleTypeList: TRadioButton[] = [
  { label: "Open", value: SaleType.OPEN },
  { label: "Closed", value: SaleType.CLOSED },
];
