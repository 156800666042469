import { LoadingButton } from "@mui/lab";
import { Box, ListItemIcon, Menu as MUIMenu, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { ShareSnackbar } from "Components/Snackbar";
import {
  IListing,
  IListingDetails,
  ListingStatus,
} from "features/listing/types";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { useState } from "react";
import { useDialogContext } from "store/hooks/DialogsContext";
import { CopyLinkIcon, EnvelopeIcon, ShareIcon } from "styles/icons";
interface IProps {
  panelWidth: boolean;
  listing: IListing | IListingDetails | undefined;
}

const ShareMenu: React.FC<IProps> = ({ listing, panelWidth }): JSX.Element => {
  const isLive = listing?.status === ListingStatus.LIVE;
  const { openDialog } = useDialogContext();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCopyLink = () => {
    const currentUrl = String(window.location);
    const urlWithId = currentUrl.endsWith("/")
      ? `${currentUrl}${listing?.id}`
      : `${currentUrl}/${listing?.id}`;
    setOpenSnackbar(true);
    navigator.clipboard.writeText(urlWithId);
  };

  const options = [
    {
      text: "Share Direct",
      icon: <EnvelopeIcon stroke={"#686F7A"} />,
      onClick: () => openDialog("ShareDirect"),
    },
    {
      text: "Copy Link",
      icon: <CopyLinkIcon />,
      onClick: handleCopyLink,
    },
  ];

  return (
    <PopupState variant="popover" popupId="share-menu">
      {(popupState) => (
        <>
          <Box {...bindTrigger(popupState)}>
            <LoadingButton
              variant={isLive ? "primary" : "tertiaryPrimary"}
              sx={{ minWidth: panelWidth ? "none" : "178px" }}
              startIcon={
                <ShareIcon
                  fill="transparent"
                  stroke={isLive ? "white" : "#6366f1"}
                />
              }
            >
              Share
            </LoadingButton>
          </Box>
          <MUIMenu
            MenuListProps={{ disablePadding: true }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            {...bindMenu(popupState)}
          >
            {options.map((option, index) => (
              <MenuItem
                key={index}
                onClick={option.onClick}
                style={{ minWidth: "140px" }}
              >
                <ListItemIcon style={{ minWidth: 26 }}>
                  {option.icon}
                </ListItemIcon>
                <Typography variant={"extraSmallBoldText"}>
                  {option.text}
                </Typography>
              </MenuItem>
            ))}
          </MUIMenu>
          <ShareSnackbar open={openSnackbar} setOpen={setOpenSnackbar} />
        </>
      )}
    </PopupState>
  );
};

export default ShareMenu;
