import * as yup from "yup";

const nameSchema = yup
  .string()
  .required("Name is required")
  .matches(/^[a-zA-Z\- ']+$/, 'Invalid characters in the name') 
  .min(3, "Minimum 3 letters")
  .max(50, "Maximum 50 letters");

export { nameSchema };
