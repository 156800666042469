import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { RegisterToOfferStepperSchema } from "Components/Validation/RegisterToOfferStepperSchema";
import { ErrorResponse } from "features/auth/types";
import {
  useEditOfferorMutation,
  useRegisterToOfferMutation,
} from "features/listing/api/userApi";
import {
  OfferorStatus,
  useLazyGetUserOfferorRequestsQuery,
} from "features/offerors/api";
import { IContingency, IUserOfferorRequest } from "features/offers/api";
import {
  useEditOfferMutation,
  useSubmitOfferMutation,
} from "features/offers/api/offersApi";
import { SelectOrAddPaymentMethod } from "features/payments";
import { IPaymentMethod } from "features/payments/api";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { useListingDetailsStepperContext } from "store/hooks/ListingDetailsStepperContext";
import StepsProgress, { IStep, RegisterStepName } from "../StepsProgress";
import AfterSubmitStep from "./AfterSubmitStep";
import AttorneyInformationStep from "./AttorneyInformationStep";
import OfferDetailsStep from "./OfferDetailsStep";
import PurchaserInformationStep from "./PurchaserInformationStep";
import UploadFilesStep from "./UploadFilesStep";
import {
  attorneyFields,
  newPaymentFields,
  offerFields,
  offerorRequestFieldsToGet,
  offerorRequestFieldsToSend,
  purchaserFields,
  submitFields,
  uploadFilesFields,
} from "./helpers";
import { SubmitStep } from "./submitStep";

export interface IRegisterToOfferForm {
  listing_id: number;
  attorney_first_name: string;
  attorney_last_name: string;
  attorney_number: string;
  attorney_email: string;
  payment_method_id: number;
  cvv: string;
  proof_of_funds: File;
  file_type: string;
  purchaser_first_name: string;
  purchaser_last_name: string;
  purchaser_address_line_1: string;
  purchaser_address_line_2: string;
  purchaser_city: string;
  purchaser_state: string;
  purchaser_zip: string;
  comments: string;
  contingencies: IContingency[];
  mortgage_amount: string;
  on_contract: string;
  offer_amount: string;
}

interface IProps {
  dialogContentStyle: object;
  isNewRegister?: boolean;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegisterToOfferStepper: React.FC<IProps> = ({
  dialogContentStyle,
  isNewRegister = false,
  setIsVisible,
}): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const {
    completedStep,
    setCompletedStep,
    stepToShow,
    setStepToShow,
    setSubmitError,
    submitError,
    options,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    resetStepsToZero,
    setOptions,
  } = useListingDetailsStepperContext();
  const { closeDialog } = useDialogContext();
  const validationSchema = RegisterToOfferStepperSchema(
    options.includeOfferStep,
    !!listing?.security_hold,
    selectedPaymentMethod?.id
  );
  const itemId: string = useParams().itemId!;
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const [fetchUserOfferor, { isLoading: fetchOfferorLoading }] =
    useLazyGetUserOfferorRequestsQuery();
  const [registerToOffer, { isLoading: isRegisterLoading }] =
    useRegisterToOfferMutation();
  const [editOfferor, { isLoading: isEditLoading }] = useEditOfferorMutation();
  const [submitOffer, { isLoading: isSubmitLoading }] =
    useSubmitOfferMutation();
  const [editOffer] = useEditOfferMutation();
  const handleNext = async () => {
    let isValid = false;
    const currentStepName = getSteps()[stepToShow]?.name;
    switch (currentStepName) {
      case RegisterStepName.PURCHASER:
        isValid = await methods.trigger(purchaserFields);
        break;
      case RegisterStepName.FILEUPLOAD:
        isValid = await methods.trigger(uploadFilesFields);
        break;
      case RegisterStepName.ATTORNEY:
        isValid = await methods.trigger(attorneyFields);
        break;
      case RegisterStepName.OFFERDETAILS:
        isValid = await methods.trigger(offerFields);
        if (submitError.offerError) {
          offerDetailsSubmit();
        }
        break;
      case RegisterStepName.PAYMENT:
        if (selectedPaymentMethod) {
          if (options.includeOfferStep) {
            isValid = await methods.trigger(["cvv"]);
          } else {
            isValid = true;
          }
        }
        if (!selectedPaymentMethod) {
          setSelectedPaymentMethod((prev: IPaymentMethod | null) => ({
            ...prev!,
            id: -1,
          }));
        }
        setOptions((prev) => ({
          ...prev,
          cvv: String(methods.watch("cvv")),
        }));

        break;
      case RegisterStepName.SUBMIT:
        isValid = await methods.trigger(submitFields);
        methods.handleSubmit(handleSubmitForm)();

        break;
      case RegisterStepName.AFTERSUBMIT:
        const { registerError, offerError } = submitError;
        if (registerError || offerError) {
          methods.reset();
        } else if (isNewRegister) {
          fetchUserOfferor(itemId || listing.id);
        }
        closeDialog();
        isNewRegister && setIsVisible && setIsVisible(false);
        resetStepsToZero();
        break;
    }
    if (isValid) {
      if (stepToShow === completedStep) {
        setStepToShow((prev) => prev + 1);
        setCompletedStep((prev) => prev + 1);
      } else if (stepToShow < completedStep) {
        setStepToShow((prev) => prev + 1);
      } else setCompletedStep((prev) => prev + 1);
    }
  };
  const offerDetailsSubmit = async () => {
    const hasPreviousOffer = options?.myOfferData;
    const contingenciesData = !hasPreviousOffer
      ? methods
          ?.watch("contingencies")
          ?.filter((contingency: any) => contingency?.is_waived)
          ?.map((contingency: any) => contingency?.contingency_id)
      : methods?.watch("contingencies");
    const body = {
      [!hasPreviousOffer ? "contingencies" : "contingencies_waived"]:
        contingenciesData,
      offer_amount: Number(methods.watch("offer_amount")),
      cvv: methods.watch("cvv"),
      on_contract: Number(methods.watch("on_contract")),
      mortgage_amount: Number(methods.watch("mortgage_amount")),
    };
    const offerResult = !hasPreviousOffer
      ? await submitOffer({ listingId: itemId || listing.id, body })
      : await editOffer({ listingId: itemId || listing.id, body });
    if ("error" in offerResult) {
      const errorMessage =
        (offerResult.error as ErrorResponse)?.data?.message || "";

      setSubmitError((state) => ({ ...state, offerError: errorMessage }));
      setStepToShow(3);
    } else {
      setSubmitError((state) => ({ ...state, offerError: null }));
      setStepToShow(getSteps().length - 1);
    }
  };
  const handleSubmitForm: SubmitHandler<IRegisterToOfferForm> = async (
    data
  ) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(offerorRequestFieldsToSend)) {
      const fieldData = (data as any)[key] ?? "";
      formData.append(value, fieldData);
    }
    formData.append("listing_id", String(listing.id));
    if (selectedPaymentMethod !== null) {
      formData.append("payment_method_id", String(selectedPaymentMethod?.id));
    }
    if (data.proof_of_funds instanceof File) {
      formData.append("proof_of_funds", data.proof_of_funds);
    }
    const registerResult = await (options?.offerorRequestData?.status ===
    OfferorStatus.PENDING
      ? editOfferor({ listingId: listing.id, body: formData })
      : registerToOffer({ body: formData }));
    if ("error" in registerResult) {
      const errorMessage =
        (registerResult.error as ErrorResponse)?.data?.message || "";
      setSubmitError((state) => ({ ...state, registerError: errorMessage }));
    } else if (options.includeOfferStep) {
      offerDetailsSubmit();
    }
  };
  useEffect(() => {
    if (options?.offerorRequestData) {
      Object.keys(offerorRequestFieldsToGet).forEach((fieldName) => {
        methods.setValue(
          fieldName,
          options?.offerorRequestData?.[fieldName as keyof IUserOfferorRequest]
        );
      });
    }
  }, [options.offerorRequestData]);
  const getSteps = (): IStep[] => {
    const stepsData = [
      {
        name: RegisterStepName.PURCHASER,
        label: "Purchaser",
        content: <PurchaserInformationStep />,
      },
      {
        name: RegisterStepName.FILEUPLOAD,
        label: "Proof of Funds",
        content: (
          <UploadFilesStep
            fileName={options.offerorRequestData?.proof_of_funds}
          />
        ),
      },
      {
        name: RegisterStepName.ATTORNEY,
        label: "Attorney",
        content: <AttorneyInformationStep />,
      },
    ];

    if (options.includeOfferStep) {
      stepsData.push({
        name: RegisterStepName.OFFERDETAILS,
        label: "Offer Details",
        content: <OfferDetailsStep />,
      });
    }

    if (listing?.security_hold) {
      stepsData.push({
        name: RegisterStepName.PAYMENT,
        label: "Payment",
        content: (
          <SelectOrAddPaymentMethod
            canInsertCVV={options.includeOfferStep}
            methods={methods}
          />
        ),
      });
    }

    stepsData.push(
      {
        name: RegisterStepName.SUBMIT,
        label: "Submit",
        content: <SubmitStep />,
      },
      {
        name: RegisterStepName.AFTERSUBMIT,
        label: "After Submit",
        content: <AfterSubmitStep />,
      }
    );

    const steps = stepsData.map((step, index) => ({
      ...step,
      number: index,
    }));

    return steps;
  };
  const isLoading =
    fetchOfferorLoading ||
    isRegisterLoading ||
    isEditLoading ||
    isSubmitLoading;

  return (
    <FormProvider {...methods}>
      <Grid container minWidth={"100%"} sx={dialogContentStyle}>
        <Grid alignSelf={"center"} item my={3}>
          <Typography variant="bigBoldText" color="primary">
            Register to Offer
          </Typography>
        </Grid>
        <ConditionalWrapper relative isLoading={isLoading}>
          <Grid item maxWidth={"100%"}>
            <StepsProgress
              handleNext={handleNext}
              activeStep={completedStep}
              steps={getSteps()}
            />
          </Grid>
        </ConditionalWrapper>
      </Grid>
    </FormProvider>
  );
};

export default RegisterToOfferStepper;

export const RegisterToOfferDialogStyle = {
  flexDirection: "column",
  ".attorneyInformationContainerStyle": {
    margin: "0 auto",

    "@media (min-width: 600px)": {
      width: "90%",
    },
    "@media (min-width: 960px)": {
      width: "80%",
    },
  },
};
export const RegisterToOfferWithoutDialogStyle = {
  minHeight: 350,
  flexDirection: "column",
  height: "max-content",
  position: "relative",
  ".attorneyInformationContainerStyle": {
    margin: "0 auto",
    width: "100%",
  },
  ".paymentContainer": {
    maxHeight: 850,
    overflowY: "auto",
    minWidth: "fit-content",
  },
};
