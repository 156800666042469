import { Grid, Typography } from "@mui/material";
import { ClosedOfferSubmitForm } from "features/listing/components/detailListing/user";
import { useGetMyOfferQuery } from "features/offers/api";
import { useParams } from "react-router-dom";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import { useListingDetailsStepperContext } from "store/hooks/ListingDetailsStepperContext";
import { ErrorTextIcon } from "styles/icons";

const OfferDetailsStep: React.FC = (): JSX.Element => {
  const itemId = useParams().itemId || "";
  const {
    submitError: { offerError },
  } = useListingDetailsStepperContext();
  const { verified } = useAppSelector(selectAuth);
  const { data: myOfferData, isLoading } = useGetMyOfferQuery(itemId, {
    skip: !verified,
  });
  return (
    <Grid item>
      <Grid item textAlign={"center"} mb={4}>
        <Grid container justifyContent={"center"} alignItems="center" gap={0.5}>
          {offerError && <ErrorTextIcon sx={{ fontSize: 28 }} />}
          <Typography color="grey.800" variant="bigBoldText">
            {offerError ? "Error Submitting Offer" : "Offer Details"}
          </Typography>
        </Grid>
        <Typography color="grey.500" variant="extraSmallRegularText">
          {offerError || "Submit your offer"}
        </Typography>
      </Grid>
      <ClosedOfferSubmitForm
        isStepper
        myOfferLoading={isLoading}
        myOfferData={myOfferData}
        sx={{ mx: 3, gap: 5, justifyContent: "center" }}
      />
    </Grid>
  );
};

export default OfferDetailsStep;
