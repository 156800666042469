import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { Control, useController } from "react-hook-form";
import { StyledSelect } from "styles/components";

export type TContingencyField = {
  id: number;
  contingency_id?: number;
  name: string;
  contingency_name?: string;
  archived: boolean;
  waive_required?: boolean;
};

type TMultiDropdown = {
  items?: TContingencyField[];
  name: string;
  control: Control<any>;
  defaultValue?: TContingencyField[];
};

const ControlledContingencies: React.FC<TMultiDropdown> = ({
  items,
  name,
  control,
  defaultValue = [],
}) => {
  const {
    field: { onChange, value },
  } = useController({ name, control });
  // useEffect(() => {
  //   onChange(defaultValue);
  //   // Temporary
  // }, [defaultValue, onChange]);
  const isChecked = (item: string) =>
    value?.some((menuItem: any) => menuItem?.contingency_name === item);

  const handleAddContingency = (item: TContingencyField) => {
    const itemName = item?.name;
    let updatedValue = value || [];
    if (!isChecked(itemName || "")) {
      updatedValue.push({
        contingency_id: item.id,
        contingency_name: itemName,
        waive_required: item?.waive_required || false,
      });
    }
    onChange(updatedValue);
  };

  const handleWaiveRequired = (item: TContingencyField) => {
    const updatedValue = value.map((contingency: any) =>
      contingency.contingency_id === item.contingency_id
        ? { ...contingency, waive_required: !contingency.waive_required }
        : contingency
    );
    onChange(updatedValue);
  };

  const handleDelete = (item: any) => {
    const itemName = item.contingency_name;
    const updatedValue = value.filter(
      (contingency: any) => contingency.contingency_name !== itemName
    );
    onChange(updatedValue);
  };
  const filteredItems = items?.filter((item) => !isChecked(item.name || ""));
  return (
    <FormControl sx={{ my: 1 }}>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item sx={{ px: 1 }}>
          <Typography
            fontWeight={600}
            variant="extraSmallBoldText"
            sx={{ mb: 1, color: "grey.800", px: 0 }}
          >
            Select Contingencies
          </Typography>
          <StyledSelect
            renderValue={
              filteredItems?.length === 0
                ? () => "All options are selected"
                : () => "select..."
            }
            displayEmpty
            name={name}
            disabled={filteredItems?.length === 0 ? true : false}
            size="small"
            multiple
            value={value || []}
            MenuProps={MenuProps}
            sx={{ backgroundColor: "common.white", width: 300 }}
          >
            {filteredItems?.map((item) => (
              <MenuItem
                key={item.id}
                onClick={() => handleAddContingency(item)}
                value={item.id}
              >
                <Checkbox
                  checked={isChecked(item.name || "") ?? false}
                  sx={{ pl: 0, pr: 0.5 }}
                />
                {item.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 1,
          }}
        >
          {value?.map((item: any, idx: number) => (
            <Grid key={idx} item sx={containerListStyle}>
              <IconButton
                sx={{ color: "primary.main", p: 0.1 }}
                onClick={() => handleDelete(item)}
              >
                <RemoveCircleOutlineIcon sx={{ fontSize: 18 }} />
              </IconButton>
              <Checkbox
                checked={item?.waive_required ?? false}
                onClick={() => handleWaiveRequired(item)}
                sx={waiveCheckboxStyle}
              />

              <Typography sx={itemTextStyle}>
                {item?.contingency_name}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default ControlledContingencies;

const MenuProps = {
  MenuListProps: {
    disablePadding: true,
  },
};

const containerListStyle = {
  display: "flex",
  alignItems: "center",
  mt: 1,

  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const waiveCheckboxStyle = {
  p: 1.5,
  ".MuiSvgIcon-root": {
    fontSize: 18,
  },
};

const itemTextStyle = {
  ml: 1,
  fontWeight: 500,
  maxWidth: { xs: 300, sm: 400 },
  overflow: "hidden",
  textOverflow: "ellipsis",
};
