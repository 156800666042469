import { Theme } from "@mui/material/styles";

export const darkPrimary = (theme: Theme) => ({
  color: theme.palette.common.white,
  outline: "none",
  backgroundColor: "rgba(0, 0, 0, 0.05)",
  border: "1px solid transparent",
  height: "48px",
  transitionProperty: "all",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.02)",
    borderColor: theme.palette.grey[50],
  },
  "&:focus": {
    borderColor: theme.palette.grey[50],
  },
  "&:active": {
    borderColor: theme.palette.grey[50],
  },
});
