import { Box, DialogTitle, Divider, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import ReadOnlyContingenciesList from "Components/Contingencies/ReadOnlyContingenciesList";
import { ClosedBidOfferPrices } from "features/listing/components/detailListing/agent/offerBox";
import { TListingOffer } from "features/offers/api";
import { DialogContainer } from ".";
import DialogActions from "./DialogActions";
import { relativeSmallCircleStyle } from "./DialogContainer";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { useSelectWinnerMutation } from "features/offers/api/offersApi";
import { SubmitHandler } from "react-hook-form";
import { useDialogContext } from "store/hooks/DialogsContext";
import { ErrorResponse } from "features/auth/types";
import ErrorText from "Components/StyledComponents/ErrorText";

interface IProps {
  offer: TListingOffer;
}
interface ISelectWinner {
  offer_id: number;
}

const AcceptOfferDialog: React.FC<IProps> = ({ offer }): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const { closeDialog } = useDialogContext();
  const [
    selectWinner,
    { error: selectWinnerError, isLoading: selectWinnerLoading },
  ] = useSelectWinnerMutation();
  const offerAmount = offer?.offer_amount;
  const mortgageAmount = offer?.mortgage_amount;
  const onContract = offer?.on_contract;
  const balanceAtClosing = Number(offerAmount) - Number(onContract);

  const handleSubmit: SubmitHandler<ISelectWinner> = async () => {
    const body = {
      offer_id: offer?.id,
    };
    try {
      await selectWinner({ listingId: listing?.id, body }).unwrap();
      closeDialog();
    } catch (selectWinnerError) {
      console.error("Error adding payment method:", selectWinnerError);
    }
  };
  const errorMessage = (selectWinnerError as ErrorResponse)?.data?.message;

  return (
    <DialogContainer
      sx={{
        ...relativeSmallCircleStyle,
        "& .MuiDialog-paper": { width: 400, borderRadius: 3 },
      }}
      hideExit
    >
      <DialogTitle textAlign={"center"} color="primary" fontWeight={700}>
        Accept Offer
        {errorMessage && (
          <ErrorText
            sx={{ justifyContent: "center", mb: 1 }}
            text={errorMessage}
          />
        )}
      </DialogTitle>

      <DialogContent sx={{ py: 0, px: { xs: 1, sm: 2 } }}>
        <Typography variant="regularText" color="grey.00" mb={1}>
          Offeror Details:
        </Typography>
        <ClosedBidOfferPrices
          vertical
          offerAmount={offerAmount}
          mortgageAmount={mortgageAmount}
          onContract={onContract}
          balanceAtClosing={balanceAtClosing}
        />
        <Box>
          <Divider
            sx={{ borderWidth: 1, borderColor: "grey.600", my: 1.5 }}
            orientation={"horizontal"}
          />
          <Box>
            <Typography mb={0.5} variant="extraSmallBoldText" color="grey.700">
              Waived Contingencies
            </Typography>
          </Box>
          <ReadOnlyContingenciesList contingencies={offer?.contingencies} />
        </Box>
      </DialogContent>
      <DialogActions
        onSubmit={handleSubmit}
        submitText="Confirm"
        isLoading={selectWinnerLoading}
      />
    </DialogContainer>
  );
};

export default AcceptOfferDialog;
