import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { selectAuth } from "store/features/authSlice";
import { Portal } from "features/users/types";

interface IProps {
  allowedRoles: string[];
  redirect?: string;
}

const ProtectedRoute: React.FC<IProps> = ({ allowedRoles, redirect }) => {
  const location = useLocation();
  const { userTypes, portal } = useAppSelector(selectAuth);
  const isAgent = userTypes ? userTypes.includes("agent") : false;
  const isUserOrGuestPortal = portal === Portal.USER || portal === null;

  if (userTypes) {
    // if user logged in, check for page role permission
    // if allowed display the page if not display the unauthorized page
    return allowedRoles.find((role) => userTypes.includes(role)) &&
      allowedRoles.includes(portal || "") ? (
      <Outlet />
    ) : (
      <Navigate
        to={isAgent && !isUserOrGuestPortal ? "/myListings" : "/listings"}
        state={{ from: location }}
        replace
      />
    );
  } else {
    // if user not logged in, redirect to specifed page or to loggin page
    return (
      <Navigate to={redirect || "/login"} state={{ from: location }} replace />
    );
  }
};

export default ProtectedRoute;
