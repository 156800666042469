import { Theme } from "@mui/material/styles";

export const grey = (theme: Theme) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.grey[500],
  borderColor: theme.palette.transparent,
  "&:hover": {
    backgroundColor: theme.palette.grey[400],
  },
  "&:focus": {
    backgroundColor: theme.palette.grey[400],
  },
  "&:active": {
    backgroundColor: theme.palette.grey[400],
  },
});
