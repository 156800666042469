import { Stack, Typography, type SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import { CheckboxCheckedIcon } from "styles/icons";

import { formatUSD } from "utils/helpers";

import type { MembershipItem } from "features/membership/types";
import type { Views } from "./AddMembership";
import { IMembershipType } from "features/subscriptions/api/apiTypes";

interface IProps {
  membership: IMembershipType;
  nextStep: (view: Views, plan: IMembershipType) => void;
  features: any;
}

const PlanBox: React.FC<IProps> = ({
  membership,
  nextStep,
  features,
}): JSX.Element => {
  const selectPlan = () => {
    nextStep("payment-form", membership);
  };
  return (
    <Box sx={stackContainerStyle}>
      <Stack sx={stackInnerStyle}>
        <Typography variant="bigBoldText">{membership.name}</Typography>

        <Typography
          component="span"
          variant="smallRegularText"
          sx={{ verticalAlign: "text-top" }}
        >
          {formatUSD(membership.membership_fee)} / month
        </Typography>

        <Typography my={3} variant="smallRegularText">
          Features Included:
        </Typography>

        <Stack sx={{ marginBottom: "40px" }} gap="20px">
          {features.map((item: any) => (
            <Stack direction="row" alignItems="center" gap="10px">
              <CheckboxCheckedIcon />
              <Typography variant="smallRegularText" sx={{ fontSize: "14px" }}>
                {item}
              </Typography>
            </Stack>
          ))}
        </Stack>

        <LoadingButton
          variant="contained"
          color={"white"}
          sx={{ marginTop: "auto" }}
          fullWidth
          onClick={selectPlan}
        >
          Select Plan
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default PlanBox;

const stackContainerStyle: SxProps = [
  {
    borderRadius: "16px",
    boxSizing: "border-box",
    maxWidth: { xs: "unset", md: "320px" },
    color: "primary.light",
    backgroundColor: "grey.50",
  },
  {
    "&:nth-child(2n)": {
      color: "common.white",
      background:
        "linear-gradient(192.05deg, rgba(120, 114, 236, 0.744553) 10.94%, #6366F1 59.96%)",
      border: "1px solid",
      borderColor: "primary.dark",
    },
  },
  {
    "&:nth-child(2n)> *": {
      borderColor: "rgba(0,0,0,0)", // just to make it transparent
    },
  },
];

const stackInnerStyle: SxProps = [
  {
    p: 4,
    border: "6px solid",
    borderColor: "secondary.main",
    borderRadius: "16px",
    height: "100%",
    boxSizing: "border-box",
  },
];
