import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const EditPencilIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 16 15"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.44691 12.7215L12.9156 6.59704C13.9908 5.57904 13.9077 3.77696 12.7322 2.62139C11.5844 1.49303 9.83381 1.4155 8.82206 2.4482L2.52978 8.87075C2.52978 8.87075 3.92366 9.42531 4.90322 10.3883C5.88279 11.3512 6.44691 12.7215 6.44691 12.7215Z"
          fill="#EEF2FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.44691 12.7215L12.9156 6.59704C13.9908 5.57904 13.9077 3.77696 12.7322 2.62139C11.5844 1.49303 9.83381 1.4155 8.82206 2.4482L2.52978 8.87075C2.52978 8.87075 3.92366 9.42531 4.90322 10.3883C5.88279 11.3512 6.44691 12.7215 6.44691 12.7215Z"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.02406 13.8642L6.44356 12.7266C6.44356 12.7266 5.88071 11.3559 4.90203 10.392C3.92335 9.42816 2.52998 8.87234 2.52998 8.87234L1.36804 13.2025C1.26682 13.5797 1.64547 13.9616 2.02406 13.8642Z"
          fill="white"
        />
        <path
          d="M2.02406 13.8642L6.44356 12.7266C6.44356 12.7266 5.88071 11.3559 4.90203 10.392C3.92335 9.42816 2.52998 8.87234 2.52998 8.87234L1.36804 13.2025C1.26682 13.5797 1.64547 13.9616 2.02406 13.8642Z"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default EditPencilIcon;
