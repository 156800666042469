import { Grid, Typography } from "@mui/material";
import {
  formatToMMM,
  getRemainingTime,
  getTimeToEndDate,
} from "Components/DatesAndTime/helpers";
import { StatusButton } from "Components/StyledComponents";
import ListingOptionsMenu from "features/listing/components/detailListing/agent/menus/ListingOptionsMenu";
import { useGetUserOfferorRequestsQuery } from "features/offerors/api";
import { Portal } from "features/users/types";
import { useLocation, useNavigate } from "react-router-dom";
import { selectAuth } from "store/features/authSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ListingStatus, type IListing } from "../types";
import { formatFullName } from "../utils/listingsUtils";
import NinthColumn from "./listingColumns/NinthColumn";
import {
  FavoriteColumn,
  FifthColumn,
  PriceColumn,
  SeventhColumn,
  SixthColumn,
} from "./listingColumns/index";
import { setCurrentListing } from "store/features/listingSlice";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";

interface IProps {
  listing: IListing;
}

const AuctionListing: React.FC<IProps> = ({ listing }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { portal, verified } = useAppSelector(selectAuth);
  const isUserPortal = portal === Portal.USER;
  const isAgentPortal = portal === Portal.AGENT || portal === Portal.ADMIN;

  const { data: verificationDetails, isLoading: isLoadingVerification } =
    useGetUserOfferorRequestsQuery(listing.id, {
      skip: !isUserPortal || !verified,
    });

  const auctionStartDateFormat: string = formatToMMM(
    listing.auction_start_date
  );
  const auctionEndDateFormat: string = formatToMMM(listing.auction_end_date);
  const auctionEndedFormat: string = formatToMMM(listing.auction_ended);
  const contractSignedFormat: string = formatToMMM(listing.contract_signed);
  const contractDueDate: string = formatToMMM(listing.contract_due_date);

  const publishDateFormat: string = formatToMMM(listing.publish_date);
  const timeToStartDate: Date = getRemainingTime(listing.auction_start_date);
  const timeToPublishDate: Date = getRemainingTime(listing.publish_date);
  const winnerDueDate: Date = getRemainingTime(listing.auction_ended + 3600);
  const timeToEndDate: Date = getTimeToEndDate(
    parseInt(listing.auction_end_date),
    parseInt(listing.auction_end_delay)
  );
  const winnerFullName = formatFullName(
    listing.winner_first_name,
    listing.winner_last_name
  );
  const navigate = useNavigate();
  const location = useLocation();
  const handleItemNavigate = () => {
    dispatch(setCurrentListing(listing));
    const currentPath = location.pathname;
    navigate(`${currentPath}/${String(listing.id)}`, {
      state: { status: listing?.status },
    });
  };

  return (
    <ConditionalWrapper isLoading={isLoadingVerification}>
      <Grid
        style={{ cursor: "pointer" }}
        onClick={handleItemNavigate}
        container
        sx={listingContainerStyle}
      >
        <Grid xs={3} ml={2} item container sx={imgAddressContainerStyle}>
          <img
            style={imageStyle}
            src={
              listing.main_photo ||
              "https://generatorfun.com/code/uploads/Random-House-image-2.jpg"
            }
            width="50"
            height="50"
          />
          <Typography variant="extraSmallBoldText" color={"grey.800"} mr={2}>
            {listing.full_address}
          </Typography>
        </Grid>
        <PriceColumn listing={listing} />
        <Grid xs={2} item sx={{ ...listingRowStyle, minWidth: 120 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            Sale Type
          </Typography>
          <Typography variant={"smallRegularText"} color={"grey.800"}>
            {listing?.sale_type || "none"}
          </Typography>
        </Grid>
        <FifthColumn
          publishDate={publishDateFormat}
          status={listing?.status}
          auctionStartDate={auctionStartDateFormat}
          auctionEndDate={auctionEndDateFormat}
          auctionEnded={auctionEndedFormat}
          contractSigned={contractSignedFormat}
          contractDueDate={contractDueDate}
        />
        <SixthColumn
          status={listing?.status}
          auctionStartDate={listing.auction_start_date}
          auctionEndDate={listing.auction_end_date}
          auctionEnded={auctionEndedFormat}
          offerCount={listing.offer_count}
        />
        <SeventhColumn
          status={listing?.status}
          winnerName={winnerFullName}
          timeToEndDate={timeToEndDate}
          timeToStartDate={timeToStartDate}
          timeToPublishDate={timeToPublishDate}
          winnerDueDate={winnerDueDate}
          verification={verificationDetails}
        />
        <Grid
          xs={2}
          item
          container
          alignItems={"center"}
          sx={{ ...listingRowStyle, minWidth: 130 }}
        >
          <StatusButton status={listing.status} />
        </Grid>
        <Grid
          onClick={
            listing.status !== ListingStatus.PENDING_WINNER
              ? (e: any) => e.stopPropagation()
              : undefined
          }
          item
          xs={2}
          sx={ninthColumnStyle}
          container
        >
          <NinthColumn
            listing={listing}
            timeToEndDate={timeToEndDate}
            timeToStartDate={timeToStartDate}
            auctionEnded={auctionEndedFormat}
          />
        </Grid>
        <Grid
          xs={1}
          onClick={(e: any) => e.stopPropagation()}
          item
          container
          alignItems={"center"}
          justifyContent={"end"}
          sx={{ ...listingRowStyle, minWidth: 0 }}
        >
          {isUserPortal ? (
            <FavoriteColumn
              isFavorite={listing.is_favorite}
              listingId={listing.id}
            />
          ) : (
            isAgentPortal &&
            !noListingMenu.includes(listing.status) && (
              <ListingOptionsMenu listing={listing} />
            )
          )}
        </Grid>
      </Grid>
    </ConditionalWrapper>
  );
};

export default AuctionListing;

export const listingRowStyle = {
  flexDirection: "column",
  display: "flex",
  justifyContent: "center",
};

const listingContainerStyle = {
  alignItems: "center",
  wordBreak: "break-word",
  height: 100,
  backgroundColor: "common.white",
  overflowX: "auto",
  border: "1px solid",
  borderColor: "grey.600",
  borderRadius: 1,
  flexWrap: "nowrap",
  px: 1,
  py: 1,
  my: 1,
};

export const imgAddressContainerStyle = {
  alignItems: "center",
  flexWrap: "nowrap",
  minWidth: 240,
};

const imageStyle = {
  border: "3px solid #EEF2FF",
  outline: "3px solid #F6F8FF",
  borderRadius: "4px",
  marginRight: "20px",
};

const ninthColumnStyle = {
  display: "flex",
  justifyContent: "start",
  minWidth: 200,
  flexDirection: "row",
  gap: "0 10px",
  alignItems: "center",
};

const noListingMenu = [ListingStatus.PENDING_WINNER];
