import { Theme } from "@mui/material/styles";

export const secondary = (theme: Theme) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.secondary.main,
  borderColor: theme.palette.primary.main,
  transitionProperty: "all",
  "&:hover": {
    borderColor: theme.palette.primary.light,
  },
  "&:focus": {
    backgroundColor: theme.palette.secondary.main,
  },
  "&:active": {
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.primary.dark,
  },
  "&.Mui-disabled .MuiSvgIcon-root": {
    fill: theme.palette.grey[400],
  },
});
