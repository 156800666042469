import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";

interface IProps {
  avatar: string;
  fullName: string | null;
  email: string;
}

const UsersAvatarCard: React.FC<IProps> = ({ avatar, fullName, email }) => {
  return (
    <>
      <Divider sx={{ borderColor: "grey.600", borderWidth: "1px" }} />
      <ListItem sx={{ height: 50, pl: 1 }} disablePadding disableGutters>
        <ListItemAvatar sx={{ minWidth: 0, mr: 1 }}>
          <Avatar
            sx={{ width: 30, height: 30 }}
            alt="user avatar image"
            src={avatar}
          />
        </ListItemAvatar>
        <Box sx={textStyle}>
          <Typography
            lineHeight={"0.8rem"}
            color="grey.500"
            fontSize={"0.625rem"}
          >
            {fullName}
          </Typography>
          <Typography
            lineHeight={"0.8rem"}
            component="span"
            color="grey.700"
            fontSize={"0.625rem"}
          >
            {email}
          </Typography>
        </Box>
      </ListItem>
    </>
  );
};

export default UsersAvatarCard;

const textStyle = { display: "flex", flexDirection: "column" };
