import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const TimeIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="7"
          cy="7"
          r="6.25"
          stroke="#686F7A"
          strokeWidth="1.5"
        />
        <path
          d="M7 4.51221V7.01221L8.5 8.51221"
          stroke="#686F7A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default TimeIcon;
