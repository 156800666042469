import { Grid, Typography } from "@mui/material";
import ReadOnlyContingenciesList from "Components/Contingencies/ReadOnlyContingenciesList";
import { IContingency } from "features/offers/api";
import { useFormContext } from "react-hook-form";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { formatUSD } from "utils/helpers";

const OfferDetailsCard: React.FC = (): JSX.Element => {
  const { watch } = useFormContext();
  const purchasePrice = watch("offer_amount");
  const mortgageAmount = watch("mortgage_amount");
  const onContract = watch("on_contract");
  const balanceAtClosing = watch("offer_amount") - watch("on_contract");
  const contingencies = watch("contingencies");
  const hasContingencies =
    contingencies && contingencies.length > 0 ? contingencies : null;
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const selectedContingencies = contingencies
    .filter((contingency: IContingency) => contingency.is_waived === true)
    .map((selectedContingency: IContingency) => {
      const matchingContingency = listing.contingencies.find(
        (contingency) =>
          contingency.contingency_id === selectedContingency.contingency_id
      );
      return {
        ...selectedContingency,
        contingency_name: matchingContingency?.contingency_name || "",
      };
    });

  return (
    <Grid container sx={offerDetailsStyle}>
      <Grid container gap={2}>
        <Grid item xs={hasContingencies ? 6 : 12}>
          <Typography variant="boldText" color="primary.900" mb={0.5}>
            Offer Details:
          </Typography>
          <Grid
            mt={1}
            item
            gap={{ xs: 4, lg: 1, xl: 4 }}
            container
            justifyContent={"center"}
          >
            <Grid gap={1} item display={"flex"} flexDirection={"column"}>
              <Grid
                justifyContent={"space-between"}
                flexDirection={hasContingencies ? "column" : "row"}
                minWidth={hasContingencies ? "auto" : 250}
                container
              >
                <Typography variant="smallBoldText" color="grey.700">
                  Purchase Price:
                </Typography>
                <Typography
                  component={"span"}
                  variant="smallBoldText"
                  color="primary.main"
                >
                  {formatUSD(purchasePrice)}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent={"space-between"}
                flexDirection={hasContingencies ? "column" : "row"}
                minWidth={hasContingencies ? "auto" : 250}
              >
                <Typography variant="smallBoldText" color="grey.700">
                  Mortgage Amount:
                </Typography>
                <Typography
                  component={"span"}
                  variant="smallBoldText"
                  color="primary.main"
                >
                  {formatUSD(mortgageAmount)}
                </Typography>
              </Grid>
            </Grid>
            <Grid gap={1} item display={"flex"} flexDirection={"column"}>
              <Grid
                container
                justifyContent={"space-between"}
                flexDirection={hasContingencies ? "column" : "row"}
                minWidth={hasContingencies ? "auto" : 250}
              >
                <Typography variant="smallBoldText" color="grey.700">
                  On Contract:
                </Typography>
                <Typography
                  component={"span"}
                  variant="smallBoldText"
                  color="primary.main"
                >
                  {formatUSD(onContract)}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent={"space-between"}
                flexDirection={hasContingencies ? "column" : "row"}
                minWidth={hasContingencies ? "auto" : 250}
              >
                <Typography variant="smallBoldText" color="grey.700">
                  Balance at Closing:
                </Typography>
                <Typography
                  component={"span"}
                  variant="smallBoldText"
                  color="primary.main"
                >
                  {formatUSD(balanceAtClosing)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {hasContingencies && (
          <Grid item xs={5}>
            <>
              <Typography
                mb={0.5}
                variant="extraSmallBoldText"
                color="grey.700"
              >
                Waived Contingencies
              </Typography>
            </>
            <ReadOnlyContingenciesList
              sx={contingenciesContainerStyle}
              contingencies={selectedContingencies}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default OfferDetailsCard;

const offerDetailsStyle = {
  borderRadius: 1.5,
  maxWidth: "100%",
  bgcolor: "secondary.light",
  mb: 2,
  p: 1,
};

const contingenciesContainerStyle = {
  maxHeight: 100,
  overflowY: "auto",
  borderRadius: 1,
};
