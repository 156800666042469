import { Typography, TypographyTypeMap } from "@mui/material";

interface IProps {
  variant?: TypographyTypeMap["props"]["variant"];
  address_line_1: string | undefined;
  address_line_2: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zip: string | undefined;
  sx?: object;
  color?: string;
  textAlign?: any;
}

export const ShowFullAddress: React.FC<IProps> = ({
  variant = "smallBoldText",
  color = "grey.800",
  address_line_1,
  address_line_2,
  city,
  state,
  zip,
  textAlign = "left",
  sx = null,
}): JSX.Element | null => {
  if (!address_line_1)
    return (
      <Typography textAlign={textAlign} variant={variant} color={color} sx={sx}>
        Full address not provided
      </Typography>
    );
  const address = address_line_1 + (address_line_2 ? (' ' + address_line_2) : '')
  return (
    <Typography textAlign={textAlign} variant={variant} color={color} sx={sx}>
      {`${address}`}
      <br />
      {`${city}, ${state} ${zip}`}
    </Typography>
  );
};
