import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { IListingDetails } from "features/listing/types";
import { useDialogContext } from "store/hooks/DialogsContext";

interface IProps {
  dateSubmitted: string;
  listing: IListingDetails;
}

const OpenLivePendingOfferor: React.FC<IProps> = ({
  dateSubmitted,
  listing,
}): JSX.Element => {
  const { openDialog } = useDialogContext();
  return (
    <Grid container mt={2}>
      <Typography
        component={"span"}
        color="grey.700"
        variant="extraSmallRegularText"
      >
        Date Submitted:
        <Typography
          ml={1}
          component={"span"}
          variant="extraSmallBoldText"
          color="grey.700"
        >
          {dateSubmitted}
        </Typography>
      </Typography>
      <Grid container justifyContent={"center"} mt={2} gap={2}>
        <Grid item xs={10}>
          <LoadingButton
            fullWidth
            variant="tertiary"
            onClick={() =>
              openDialog("OfferorDetails", {
                listing,
              })
            }
          >
            View
          </LoadingButton>
        </Grid>
        <Grid item xs={10}>
          <LoadingButton
            fullWidth
            variant="primary"
            onClick={() => openDialog("RegisterToOfferStepper")}
          >
            Edit
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OpenLivePendingOfferor;
