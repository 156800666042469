import * as yup from "yup";

const usernameSchema = yup
  .string()
  .required("Username is required")
  .matches(/^[a-zA-Z0-9\- ']+$/, 'Invalid characters in the username') 
  .min(8, "Minimum 8 characters")
  .max(50, "Maximum 50 characters");

export { usernameSchema };
