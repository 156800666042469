import React, { useState } from "react";

import { Tab, Tabs } from "../../styles/components";
import { ITabsList } from "./tabsData";

interface IProps {
  fullWidth?: boolean;
  tabsList: ITabsList[];
  sx: object;
}

const TabsPanel: React.FC<IProps> = ({ tabsList, sx = {} }) => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleChange = (e: any, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Tabs
        sx={{ ...sx }}
        value={tabIndex}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {tabsList.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            icon={tab.icon}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      {tabsList.map((tab, index) => (
        <TabPanel key={index} value={tabIndex} index={index}>
          {tab.tabPanel}
        </TabPanel>
      ))}
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`general-tabpanel-${index}`}
      aria-labelledby={`general-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `general-tab-${index}`,
    "aria-controls": `general-tabpanel-${index}`,
  };
}

export default TabsPanel;

export const rowsKeysStyle = {
  flexBasis: 100,
  mr: { xs: 0, lg: 4 },
  color: "grey.500",
  fontSize: "0.75rem",
  "+ *": {
    flex: 1,
  },
};

export const reportBtnStyle = {
  height: 30,
  border: "1px solid transparent",
  borderRadius: 0.625,
  fontSize: "0.75rem",
  fontWeight: 400,
  padding: "6px 16px",
  gap: 0,
  justifyContent: "start",
  minWidth: 136,
};
