import { IContingency } from "features/offers/api";
import { Collapse } from "styles/components";
import ReadOnlyContingenciesList from "../Contingencies/ReadOnlyContingenciesList";

interface IProps {
  contingencies: IContingency[];
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const MySubmittedOffersCollapse: React.FC<IProps> = ({
  contingencies,
  setExpanded,
  expanded,
}) => {
  const contNumber = contingencies?.length;

  return (
    <Collapse
      sx={containerStyle}
      setExpanded={setExpanded}
      expanded={expanded}
      title={`${contNumber} Contingencies Waived`}
      color="grey.500"
      titleStyle={{
        ...titleStyle,
        pointerEvents: contNumber === 0 && "none",
      }}
      iconStyle={{
        display: contNumber === 0 && "none",
        p: 0,
      }}
    >
      <ReadOnlyContingenciesList contingencies={contingencies} />
    </Collapse>
  );
};

export default MySubmittedOffersCollapse;

const containerStyle = {
  flexWrap: "nowrap",
  flexDirection: "row-reverse",
  justifyContent: "start",
  gap: 1,
};
const titleStyle = {
  lineHeight: "1rem",
  fontWeight: 400,
  fontSize: "0.625rem",
};
