import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const PdfIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 18 20"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_7099_56780)">
          <path
            d="M17.4533 5.2996L16.4407 4.372L12.9767 1.1976L11.8428 0.158403C11.7292 0.0540033 11.5821 -0.000396729 11.4334 -0.000396729H3.42625C2.61756 -0.000396729 1.95996 0.652403 1.95996 1.4552V9.8016H3.16636V1.4556C3.16636 1.3132 3.28321 1.1976 3.42625 1.1976H10.831V5.7396C10.831 6.0704 11.101 6.3384 11.4342 6.3384H16.4411V18.5444C16.4411 18.6868 16.3243 18.8024 16.1812 18.8024H3.42625C3.28321 18.8024 3.16636 18.6868 3.16636 18.5444V16.5784H1.95996V18.5444C1.95996 19.3472 2.61756 20 3.42625 20H16.1812C16.9895 20 17.6475 19.3472 17.6475 18.5444V5.74C17.6475 5.576 17.5798 5.4152 17.4537 5.2996H17.4533Z"
            fill="#FF5B57"
          />
          <path
            d="M2.99 12.2092C2.95172 12.1992 2.86227 12.1868 2.65919 12.1868H2.00684V13.0468H2.66685C2.89008 13.0468 3.04722 13.0076 3.13506 12.9308C3.22129 12.8552 3.2632 12.7492 3.2632 12.6072C3.2632 12.504 3.23821 12.4188 3.18664 12.3472C3.13627 12.2768 3.0722 12.2316 2.99 12.2092Z"
            fill="#FF5B57"
          />
          <path
            d="M5.89855 12.2396C5.80708 12.2048 5.65235 12.1868 5.4392 12.1868H4.91699V14.1936H5.44887C5.62737 14.1936 5.768 14.1772 5.86672 14.1452C5.96141 14.1144 6.03756 14.0712 6.09196 14.0168C6.17255 13.9368 6.23661 13.8268 6.28215 13.6888C6.32929 13.5476 6.35306 13.3724 6.35306 13.1684C6.35306 12.8884 6.30753 12.6728 6.21768 12.5268C6.12943 12.3832 6.02185 12.2864 5.89855 12.2388V12.2396Z"
            fill="#FF5B57"
          />
          <path
            d="M11.0099 15.048V11.3324C11.0099 10.9288 10.6803 10.602 10.2741 10.602H0.735764C0.3292 10.602 0 10.9292 0 11.3324V15.048C0 15.4516 0.329603 15.7784 0.735764 15.7784H10.2737C10.6803 15.7784 11.0095 15.4512 11.0095 15.048H11.0099ZM3.54988 13.2548C3.37984 13.4388 3.08892 13.528 2.6614 13.528H2.00744V14.6748H1.482V11.7052H2.62877C2.82379 11.7052 2.96925 11.7144 3.07441 11.7332C3.2231 11.758 3.35123 11.8064 3.45439 11.8768C3.55915 11.9488 3.64377 12.05 3.70662 12.1776C3.76908 12.304 3.80051 12.4444 3.80051 12.5952C3.80051 12.8532 3.71629 13.0748 3.55028 13.2544L3.54988 13.2548ZM6.81166 13.766C6.75928 13.9384 6.69038 14.084 6.60697 14.1988C6.52316 14.314 6.42968 14.406 6.32894 14.4732C6.22821 14.54 6.10612 14.5912 5.9659 14.6252C5.82849 14.6584 5.66893 14.6756 5.49164 14.6756H4.39122V11.706H5.4449C5.67377 11.706 5.84461 11.72 5.96791 11.7484C6.14158 11.788 6.29268 11.8616 6.41638 11.9664C6.57514 12.0996 6.69562 12.272 6.77419 12.4788C6.85155 12.682 6.89064 12.9164 6.89064 13.1756C6.89064 13.396 6.86404 13.5952 6.81166 13.7668V13.766ZM9.56896 12.1868H8.02773V12.912H9.36145V13.3932H8.02773V14.6748H7.5023V11.7052H9.56896V12.1864V12.1868Z"
            fill="#FF5B57"
          />
        </g>
        <defs>
          <clipPath id="clip0_7099_56780">
            <rect width="17.6471" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default PdfIcon;
