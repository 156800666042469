import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useSchedulePublishDateMutation } from "features/listing/api/agentApi";
import { IListing, IListingDetails, SaleType } from "features/listing/types";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useListingDetailsStepperContext } from "store/hooks/ListingDetailsStepperContext";
import StepsProgress, { PublishStepName } from "../StepsProgress";
import ConfirmPublishStep from "./ConfirmPublishStep";
import DateTimePickerStep from "./DateTimePickerStep";
import PublishListingCard from "./PublishListingCard";
import ScheduleToPublishStep from "./ScheduleToPublishStep";
import { IPaymentMethod } from "features/payments/api";
import { SelectOrAddPaymentMethod } from "features/payments";
import { PaymentMethodSchema } from "features/payments/schema/PaymentMethodSchema";
import { formatToMMM } from "Components/DatesAndTime/helpers";

interface IUpdatePublishForm {
  publish_date: string;
  payment_method_id: number;
  proofOfFunds: string;
  listing_id: number;
  nameOnCard: string;
  cardNumber: string;
  mm: string;
  yy: string;
  cvv: string;
  newCardCvv: string;
  billingAddress: string;
  zipCode: string;
}

interface IProps {
  listing: IListing | IListingDetails | undefined;
  publishDate: string;
}

const PublishStepper: React.FC<IProps> = ({
  listing,
  publishDate,
}): JSX.Element => {
  const [publishNow, setPublishNow] = useState({
    date: dayjs(),
    isPublishNow: false,
  });
  const currentUnixTime: string = Math.floor(Date.now() / 1000).toString();
  const [isAddPaymentOpen, setIsAddPaymentOpen] = useState(false);
  const { selectedPaymentMethod, setSelectedPaymentMethod } =
    useListingDetailsStepperContext();
  // const [selectedPaymentMethod, setSelectedPaymentMethod] =
  //   useState<IPaymentMethod | null>(null);
  const methods = useForm<IUpdatePublishForm>({
    resolver: isAddPaymentOpen ? yupResolver(PaymentMethodSchema) : undefined,
  });
  const publishDateConvert =
    methods.watch("publish_date") === undefined
      ? publishDate
      : Math.floor(new Date(methods.watch("publish_date")).getTime() / 1000);
  const [schedulePublishDate] = useSchedulePublishDateMutation();
  const { completedStep, setCompletedStep, stepToShow, setStepToShow } =
    useListingDetailsStepperContext();
  const handleNext = async (stepIncrement = 1) => {
    let isValid = false;
    switch (stepToShow) {
      case 0:
        isValid = true;
        break;
      case 1:
        isValid = await methods.trigger(["publish_date"]);
        break;
      case 2:
        if (isAddPaymentOpen) {
          isValid = await methods.trigger([
            "nameOnCard",
            "cardNumber",
            "mm",
            "yy",
            "newCardCvv",
            "billingAddress",
            "zipCode",
          ]);
          isValid ? setIsAddPaymentOpen(false) : setIsAddPaymentOpen(true);
        }
        isValid = selectedPaymentMethod
          ? await methods.trigger(["cvv"])
          : false;
        if (!selectedPaymentMethod) {
          setSelectedPaymentMethod((prev: any) => ({
            ...(prev as IPaymentMethod),
            id: -1,
          }));
        }
        break;
      case 3:
        isValid = true;
        methods.handleSubmit(handlePublishForm)();
        break;
    }
    if (isValid) {
      if (stepToShow === completedStep) {
        setStepToShow((prev) => prev + stepIncrement);
        setCompletedStep((prev) => prev + stepIncrement);
      } else if (stepToShow < completedStep) {
        setStepToShow((prev) => prev + stepIncrement);
      } else setCompletedStep((prev) => prev + stepIncrement);
    }
  };

  const handlePublishForm: SubmitHandler<IUpdatePublishForm> = async ({
    publish_date,
    cvv,
  }) => {
    const mutationBody = {
      publish_date: dayjs(publish_date).unix(),
      publish_now: publishNow.isPublishNow,
      payment_method_id: selectedPaymentMethod?.id,
      cvv: cvv,
      expected_fee: 1000,
    };
    await schedulePublishDate({
      listing_id: listing?.id,
      body: mutationBody,
    });
  };

  useEffect(() => {
    return () => {
      setStepToShow(0);
      setCompletedStep(0);
    };
  }, []);

  useEffect(() => {
    setPublishNow((prev) => ({
      ...prev,
      isPublishNow: false,
      date: dayjs(methods.watch("publish_date")),
    }));
  }, [methods.watch("publish_date")]);

  return (
    <FormProvider {...methods}>
      <Box minWidth={"100%"} sx={publishStepperDialogStyle}>
        <PublishListingCard
          narrowStyle={completedStep === 3 ? true : false}
          publishDate={
            publishNow.isPublishNow
              ? currentUnixTime
              : publishNow.date.unix().toString()
          }
        />
        <StepsProgress
          activeStep={completedStep}
          hideStepper
          handleNext={handleNext}
          steps={[
            {
              name: PublishStepName.PUBLISH,
              label: "Publish",
              content: (
                <ScheduleToPublishStep
                  setPublishNow={setPublishNow}
                  handleNext={handleNext}
                />
              ),
              number: 0,
            },
            {
              name: PublishStepName.DATEANDTIME,
              label: "Date and Time",
              content: (
                <DateTimePickerStep
                  name={"publish_date"}
                  maxDate={String(dayjs().add(1, "week").unix())}
                  control={methods.control}
                />
              ),
              number: 1,
            },
            {
              name: PublishStepName.PAYMENT,
              label: "Payment",
              content: <SelectOrAddPaymentMethod methods={methods} />,
              number: 2,
            },
            {
              name: PublishStepName.CONFIRM,
              label: "Confirm",
              content: (
                <ConfirmPublishStep paymentMethod={selectedPaymentMethod} />
              ),
              number: 3,
            },
          ]}
        />
      </Box>
    </FormProvider>
  );
};

export default PublishStepper;

export const publishStepperDialogStyle = {
  justifyContent: "stretch",
  alignItems: "stretch",
  flexDirection: "column",
  backgroundColor: "common.white",
  p: 0,
  ".stepsButtonsStyle": {
    position: "absolute",
    display: "flex",
    bottom: 0,
    left: 0,
  },
};
