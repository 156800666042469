import MenuIcon from "@mui/icons-material/Menu";
import { LoadingButton } from "@mui/lab";
import {
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { Link } from "Components/StyledComponents";
import { Portal } from "features/users/types";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { logout, selectAuth } from "store/features/authSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { EnvelopeSendIcon, NotificationIcon, SettingsIcon } from "styles/icons";
import UserAvatarNestedMenu from "./UserAvatar/UserAvatarNestedMenu";
import { iconNavbarStyle, messageIconStyle } from "./index";
import { useLogoutMutation } from "features/auth/api/authApi";

interface IProps {
  isAgent?: boolean;
  isPendingRequest?: boolean;
  isAdmin?: boolean;
}

const HamburgerMenu: React.FC<IProps> = ({
  isAgent,
  isPendingRequest,
  isAdmin,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [logoutUser] = useLogoutMutation();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const { username, portal } = useAppSelector(selectAuth);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(logout());
    logoutUser();
    navigate("/login");
  };

  const isUserPortal = portal === Portal.USER;
  const isAgentPortal = portal === Portal.AGENT;
  const isAdminPortal = portal === Portal.ADMIN;

  return (
    <>
      <IconButton
        sx={{
          display: { xs: "inline-flex", md: "none" },
        }}
        size="large"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        PaperProps={nestedListStyle}
        sx={{
          display: { xs: "inline-flex", md: "none" },
        }}
      >
        {username && (
          <MenuList sx={{ my: 2, minWidth: 200 }}>
            <MenuItem disableTouchRipple sx={{ p: 0, m: 0.5 }}>
              {username && (
                <UserAvatarNestedMenu
                  isPendingRequest={isPendingRequest}
                  isAgent={isAgent}
                  isAdmin={isAdmin}
                />
              )}
            </MenuItem>
            {isAgentPortal && (
              <div>
                <MenuItem sx={{ mb: 1, py: 1 }}>
                  <Link to="/myrequests">
                    <Typography sx={{ fontWeight: 600 }}>
                      My Requests
                    </Typography>
                  </Link>
                </MenuItem>
                <MenuItem sx={{ mb: 1, py: 1 }}>
                  <Link to="/myListings">
                    <Typography sx={{ fontWeight: 600 }}>
                      My Listings
                    </Typography>
                  </Link>
                </MenuItem>
              </div>
            )}
            {isUserPortal && (
              <MenuItem sx={{ mb: 1, py: 1 }}>
                <Typography sx={{ fontWeight: 600 }}>Listings</Typography>
              </MenuItem>
            )}
            {isAdminPortal && (
              <MenuItem sx={{ mb: 1, py: 1 }}>
                <Link to="/myrequests">
                  <Typography sx={{ fontWeight: 600 }}>My Requests</Typography>
                </Link>
              </MenuItem>
            )}
            <MenuItem disableRipple sx={iconsItemStyle}>
              {isAgentPortal && (
                <IconButton
                  sx={{
                    ...iconNavbarStyle,
                    ...messageIconStyle,
                  }}
                  onClick={() => alert("need to go to inviteUser")}
                >
                  <EnvelopeSendIcon stroke="#6366F1" />
                </IconButton>
              )}
              {isAdminPortal && (
                <Link to="/settings">
                  <IconButton sx={{ ...iconNavbarStyle, ...messageIconStyle }}>
                    <SettingsIcon fontSize="medium" />
                  </IconButton>
                </Link>
              )}
              {/*<IconButton sx={{ ...iconNavbarStyle, ...messageIconStyle }}>
                <NotificationIcon stroke="#6366F1" />
              </IconButton>*/}
              </MenuItem>
            <MenuItem sx={{ mb: 0, py: 1 }}>
              <Typography onClick={handleLogout} sx={{ fontWeight: 600 }}>
                Logout
              </Typography>
            </MenuItem>
          </MenuList>
        )}
        {!username && (
          <MenuList>
            <MenuItem onClick={() => navigate("/login")}>
              <LoadingButton variant="primary">Log in</LoadingButton>
            </MenuItem>
          </MenuList>
        )}
      </Menu>
    </>
  );
};

export default HamburgerMenu;

const nestedListStyle = {
  sx: {
    ".MuiList-root": {
      p: 0,
    },
  },
};

const iconsItemStyle = {
  gap: 0.5,
  pl: 0.5,
  ":hover": {
    background: "transparent",
  },
};
