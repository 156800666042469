import { LoadingButton } from "@mui/lab";
import { Grid, DialogActions as MUIDialogActions } from "@mui/material";
import { useDialogContext } from "store/hooks/DialogsContext";

interface IProps {
  onCancel?: any;
  onSubmit?: any;
  submitText?: string;
  isLoading?: boolean;
}

const DialogActions: React.FC<IProps> = ({
  onCancel = null,
  onSubmit = null,
  submitText = "Submit",
  isLoading = false,
}) => {
  const { closeDialog } = useDialogContext();
  return (
    <MUIDialogActions sx={{ pb: 2 }}>
      <Grid container sx={actionsContainer}>
        <Grid item xs={5.5}>
          <LoadingButton
            fullWidth
            variant="tertiary"
            onClick={onCancel || closeDialog}
          >
            Cancel
          </LoadingButton>
        </Grid>
        <Grid item xs={5.5}>
          <LoadingButton
            loading={isLoading}
            type={onSubmit ? "button" : "submit"}
            fullWidth
            variant="primary"
            onClick={onSubmit}
          >
            {submitText}
          </LoadingButton>
        </Grid>
      </Grid>
    </MUIDialogActions>
  );
};

export default DialogActions;

const actionsContainer = {
  mx: "auto",
  mt: 2,
  maxWidth: 300,
  justifyContent: "space-around",
};
