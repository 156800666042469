import type { JSXElementConstructor, ReactElement } from "react";
import AgentListingDetailsTab from "Components/TabsPanel/ListingDetailsTabs/AgentListingDetailsTab";
import { Portal } from "features/users/types";
import {
  BidderIcon,
  ContractIcon,
  HomeTabIcon,
  ListTabIcon,
  OfferDetailsIcon,
  PersonIcon,
} from "styles/icons";
import NewContractTab from "./DrawerNewListingsTabs/NewContractTab";
import NewOfferDetailsTab from "./DrawerNewListingsTabs/NewOfferDetailsTab";
import NewPropertyDetailsTab from "./DrawerNewListingsTabs/NewPropertyDetailsTab";
import UserListingDetailsTab from "./ListingDetailsTabs/UserListingDetailsTab";
import AgentDetailsTab from "./ListingDetailsTabs/AgentDetailsTab";

export interface ITabsList {
  label: string;
  icon:
    | string
    | ReactElement<any, string | JSXElementConstructor<any>>
    | undefined;
  tabPanel: React.ReactNode;
}

export const listingDetailsTabs = (portal: Portal | null) => {
  const isUserPortal = portal === Portal.USER || portal === null;

  const tabs = [
    {
      label: "Listing Details",
      icon: <HomeTabIcon />,
      tabPanel: isUserPortal ? (
        <UserListingDetailsTab />
      ) : (
        <AgentListingDetailsTab />
      ),
    },
  ];

  if (isUserPortal) {
    tabs.push({
      label: "Agent",
      icon: <PersonIcon stroke={"white"} fill={"transparent"} />,
      tabPanel: <AgentDetailsTab />,
    });
  }

  return tabs;
};

export const addOrEditListing = [
  {
    label: "Property Details",
    icon: <HomeTabIcon />,
    tabPanel: <NewPropertyDetailsTab />,
  },
  {
    label: "Offer Details",
    icon: <OfferDetailsIcon />,
    tabPanel: <NewOfferDetailsTab />,
  },
  { label: "Contract", icon: <ContractIcon />, tabPanel: <NewContractTab /> },
];

export const offerTabs = [
  {
    label: "Property Details",
    icon: <HomeTabIcon />,
    tabPanel: <NewPropertyDetailsTab />,
  },
  {
    label: "Offer Details",
    icon: <OfferDetailsIcon />,
    tabPanel: <NewOfferDetailsTab />,
  },
  { label: "Contract", icon: <ContractIcon />, tabPanel: <NewContractTab /> },
];

const tabsData = { listingDetailsTabs, offerTabs, addOrEditListing };

export default tabsData;
