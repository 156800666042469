import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const OffersIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.9331 1.1397L2.79004 4.244L10.8576 4.95292L9.31595 1.56777C9.07573 1.04029 8.42932 0.840196 7.9331 1.1397Z"
          fill="#EEF2FF"
        />
        <path
          d="M1.54566 10.9206C1.70801 11.8426 2.44533 12.5834 3.37088 12.7238C4.2899 12.8632 5.11977 13.0002 6.39434 13.0002C7.6689 13.0002 8.49877 12.8632 9.41779 12.7238C10.3433 12.5834 11.0807 11.8426 11.243 10.9206C11.3683 10.2093 11.4825 9.59154 11.4825 8.39656C11.4825 7.20159 11.3683 6.58387 11.243 5.87253C11.0807 4.95058 10.3433 4.20973 9.41779 4.06931C8.49877 3.92988 7.6689 3.79297 6.39434 3.79297C5.11977 3.79297 4.2899 3.92988 3.37088 4.06931C2.44533 4.20973 1.70801 4.95058 1.54566 5.87253C1.4204 6.58387 1.30615 7.20159 1.30615 8.39656C1.30615 9.59154 1.4204 10.2093 1.54566 10.9206Z"
          fill="#EEF2FF"
        />
        <path
          d="M1.54566 10.9206C1.70801 11.8426 2.44533 12.5834 3.37088 12.7238C4.2899 12.8632 5.11977 13.0002 6.39434 13.0002C7.6689 13.0002 8.49877 12.8632 9.41779 12.7238C10.3433 12.5834 11.0807 11.8426 11.243 10.9206C11.3683 10.2093 11.4825 9.59154 11.4825 8.39656C11.4825 7.20159 11.3683 6.58387 11.243 5.87253C11.0807 4.95058 10.3433 4.20973 9.41779 4.06931C8.49877 3.92988 7.6689 3.79297 6.39434 3.79297C5.11977 3.79297 4.2899 3.92988 3.37088 4.06931C2.44533 4.20973 1.70801 4.95058 1.54566 5.87253C1.4204 6.58387 1.30615 7.20159 1.30615 8.39656C1.30615 9.59154 1.4204 10.2093 1.54566 10.9206Z"
          stroke="#6366F1"
          strokeWidth="1.5"
        />
        <path
          d="M11.6938 9.80207H8.81705C8.01415 9.80207 7.36328 9.15119 7.36328 8.3483C7.36328 7.5454 8.01416 6.89453 8.81705 6.89453H11.6938C12.246 6.89453 12.6938 7.34225 12.6938 7.89453V8.80207C12.6938 9.35435 12.246 9.80207 11.6938 9.80207Z"
          fill="white"
        />
        <path
          d="M11.6938 9.80207H8.81705C8.01415 9.80207 7.36328 9.15119 7.36328 8.3483C7.36328 7.5454 8.01416 6.89453 8.81705 6.89453H11.6938C12.246 6.89453 12.6938 7.34225 12.6938 7.89453V8.80207C12.6938 9.35435 12.246 9.80207 11.6938 9.80207Z"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M2.79004 4.244L7.9331 1.1397C8.42932 0.840196 9.07573 1.04029 9.31595 1.56777L10.8576 4.95292"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default OffersIcon;
