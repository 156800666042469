import BasicTabs from "Components/TabsPanel/BasicTabs";
import { AgentSettingsTab } from "features/settings";
import { Portal } from "features/users/types";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import AccountSettingsContainer from ".";

const Settings = () => {
  const { portal } = useAppSelector(selectAuth);

  const settingsTabs = [
    {
      label: "Contingencies",
      display: portal === Portal.AGENT || portal === Portal.ADMIN,
      component: <AgentSettingsTab isContingency />,
    },
    {
      label: "Listing File Type",
      display: portal === Portal.AGENT || portal === Portal.ADMIN,
      component: <AgentSettingsTab isContingency={false} />,
    },
  ];

  return (
    <AccountSettingsContainer>
      <BasicTabs tabs={settingsTabs} />
    </AccountSettingsContainer>
  );
};

export default Settings;
