import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import ContingenciesLive from "Components/Contingencies/ContingenciesLive";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { SubmitClosedOfferSchema } from "Components/Validation/SubmitClosedOfferSchema";
import { BalanceAtClosingForm } from "features/listing/components/detailListing/user";
import { IContingency, IMyOffer } from "features/offers/api/apiTypes";
import {
  useEditOfferMutation,
  useSubmitOfferMutation,
} from "features/offers/api/offersApi";
import { useEffect } from "react";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";

interface ISubmitClosedOfferForm {
  contingencies: IContingency[];
  offer_amount: string | null | undefined;
  mortgage_amount: string | null | undefined;
  on_contract: string | null | undefined;
  cvv: number;
}

interface IProps {
  sx?: object;
  readOnly?: boolean;
  lastFourDigits?: string | undefined;
  isStepper?: boolean;
  myOfferData?: IMyOffer | undefined;
  myOfferLoading?: boolean;
}

const ClosedOfferSubmitForm: React.FC<IProps> = ({
  lastFourDigits,
  readOnly = false,
  isStepper = false,
  sx,
  myOfferData,
  myOfferLoading,
}): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const { closeDialog, openDialog } = useDialogContext();
  const itemId: string = useParams().itemId!;
  const listingContigencies = listing?.contingencies;
  const [submitOffer, { isLoading: submitOfferLoading }] =
    useSubmitOfferMutation();
  const [editOffer, { isLoading: editOfferLoading }] = useEditOfferMutation();
  const resolver = isStepper ? undefined : yupResolver(SubmitClosedOfferSchema);
  const methods = useForm<ISubmitClosedOfferForm>({
    mode: "all",
    resolver,
  });
  const stepperMethods = useFormContext<ISubmitClosedOfferForm>();
  const handleMethods = isStepper ? stepperMethods : methods;

  const handleClosedOfferSubmit: SubmitHandler<ISubmitClosedOfferForm> = (
    data
  ) => {
    const contingenciesData = !myOfferData
      ? handleMethods
          ?.watch("contingencies")
          ?.filter((contingency: any) => contingency?.is_waived)
          ?.map((contingency: any) => contingency?.contingency_id)
      : handleMethods?.watch("contingencies");
    const body = {
      [!myOfferData ? "contingencies" : "contingencies_waived"]:
        contingenciesData,
      offer_amount: Number(data.offer_amount),
      cvv: data.cvv,
      on_contract: data.on_contract,
      mortgage_amount: data.mortgage_amount,
    };
    try {
      !myOfferData
        ? submitOffer({ listingId: itemId, body })
        : editOffer({ listingId: itemId, body });
    } catch (error) {
      console.error("Error submitting offer", error);
    }
  };
  useEffect(() => {
    if (myOfferData) {
      handleMethods.reset();
      const { mortgage_amount, offer_amount, contingencies, on_contract } =
        myOfferData;
      handleMethods.setValue("mortgage_amount", mortgage_amount || "");
      handleMethods.setValue("on_contract", on_contract || "");
      handleMethods.setValue("offer_amount", offer_amount || "");
      handleMethods.setValue(
        "contingencies",
        formatContingencies(contingencies) || []
      );
    }
    if (!myOfferData) {
      handleMethods.setValue(
        "contingencies",
        formatContingencies(listing?.contingencies) || []
      );
    }
  }, [myOfferData]);

  const isDirty =
    handleMethods.getFieldState("mortgage_amount").isDirty ||
    handleMethods.getFieldState("on_contract").isDirty ||
    handleMethods.getFieldState("offer_amount").isDirty;

  const handleSubmitClick = () => {
    closeDialog();
    handleMethods.handleSubmit(handleClosedOfferSubmit)();
  };

  const isFormValidExceptCVV = () => {
    const isOtherFieldsValid = Object.keys(
      handleMethods.formState?.errors || {}
    ).every((fieldName: string) => {
      return fieldName !== "cvv"
        ? !handleMethods.formState?.errors[
            fieldName as keyof ISubmitClosedOfferForm
          ]
        : true;
    });
    return (
      isOtherFieldsValid &&
      Object.values(handleMethods.getValues()).every(Boolean)
    );
  };
  useEffect(() => {
    if (isDirty) {
      methods.trigger("offer_amount");
      methods.trigger("mortgage_amount");
      methods.trigger("on_contract");
    }
  }, [
    methods.watch("offer_amount"),
    methods.watch("mortgage_amount"),
    methods.watch("on_contract"),
  ]);
  const isLoading = editOfferLoading || submitOfferLoading || myOfferLoading;
  return (
    <FormProvider {...handleMethods}>
      <ConditionalWrapper relative isLoading={isLoading}>
        <Grid container component="form" pt={2} minHeight={350}>
          <Grid container gap={2} sx={{ justifyContent: "center", ...sx }}>
            <Grid item xs={12} lg={5}>
              <BalanceAtClosingForm readOnly={readOnly} />
            </Grid>
            <Grid item container xs={12} lg={5} flexDirection={"column"}>
              <ContingenciesLive
                isLoading={isLoading}
                readOnly={readOnly}
                contingencies={listingContigencies}
                name={"contingencies"}
                control={handleMethods.control}
              />
              {!readOnly && !isStepper && (
                <LoadingButton
                  onClick={handleSubmitClick}
                  loading={isLoading}
                  sx={{ minWidth: 120, margin: "auto auto 0 auto" }}
                  size="large"
                  variant="primary"
                  disabled={!isDirty || !isFormValidExceptCVV()}
                >
                  Submit
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </ConditionalWrapper>
    </FormProvider>
  );
};

export default ClosedOfferSubmitForm;

const formatContingencies = (contingencies: IContingency[] | undefined) => {
  return contingencies?.map(
    ({ contingency_id, waive_required, is_waived }) => ({
      contingency_id,
      is_waived: Boolean(is_waived) || waive_required ? true : false,
    })
  );
};
