import Drawer from "@mui/material/Drawer";
import EditOrAddListing from "features/listing/components/detailListing/agent/EditOrAddListing";
import { setCurrentListing } from "store/features/listingSlice";
import { useAppDispatch } from "store/hooks";
import { useCurrentListingContext } from "store/hooks/CurrentListingContext";

const ListingDrawer: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { setDrawerState, drawerState } = useCurrentListingContext();
  const onDrawerClose = () => {
    dispatch(setCurrentListing(undefined));
    setDrawerState({ isOpen: false, mode: "" });
  };

  return (
    <Drawer
      ModalProps={{
        onBackdropClick: () => setDrawerState({ isOpen: false, mode: "" }),
      }}
      sx={backdropStyle}
      PaperProps={paperStyle}
      anchor={"right"}
      open={drawerState.isOpen}
      onClose={onDrawerClose}
    >
      <EditOrAddListing isDrawer={true} />
    </Drawer>
  );
};

export default ListingDrawer;

const backdropStyle = {
  ".MuiBackdrop-root.MuiModal-backdrop": {
    background: "transparent",
    cursor: "pointer",
  },
};

const paperStyle = {
  sx: {
    bgcolor: "grey.50",
    boxShadow: "-6px 0px 10px 0px rgba(99, 102, 241, 0.2)",
    borderLeft: "2px solid #818CF8",
    "@media (max-width: 900px)": {
      width: "100%",
    },
  },
};
