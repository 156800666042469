import { ThemeProvider } from "@mui/material/styles";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { DialogProvider } from "store/hooks/DialogsContext";
import { ListingDetailsStepperProvider } from "store/hooks/ListingDetailsStepperContext";
import App from "./App";
import "./index.css";
import { persistor, store } from "./store"; // Import the persistor
import theme from "./styles/theme";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
  <Provider store={store}>
    <DialogProvider>
      <ListingDetailsStepperProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <PersistGate loading={null} persistor={persistor}>
                <App />
              </PersistGate>
            </BrowserRouter>
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ListingDetailsStepperProvider>
    </DialogProvider>
  </Provider>
);
