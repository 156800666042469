import { DialogContent } from "@mui/material";
import AgentOfferorDetails from "features/offerors/components/AgentOfferorDetails";
import DialogContainer from "./DialogContainer";
import { TListingOffer } from "features/offers/api";
import { IListingOfferorRequests } from "features/offerors/api";

interface IProps {
  offer: TListingOffer | IListingOfferorRequests;
}

const AgentOfferDetailsDialog: React.FC<IProps> = ({ offer }): JSX.Element => {
  return (
    <DialogContainer sx={dialogContainerStyle} hideExit>
      <DialogContent>
        <AgentOfferorDetails offer={offer} />
      </DialogContent>
    </DialogContainer>
  );
};

export default AgentOfferDetailsDialog;

export const dialogContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 800,
    height: "max-content",
  },
};
