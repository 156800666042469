import { Grid, Typography } from "@mui/material";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { ClosedOfferSubmitForm } from "features/listing/components/detailListing/user";
import { useGetMyOfferQuery } from "features/offers/api/offersApi";
import { useParams } from "react-router-dom";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";

const ClosedWinnerStatuses: React.FC = (): JSX.Element => {
  const itemId = useParams().itemId || "";
  const { verified } = useAppSelector(selectAuth);
  const { data: myOfferData, isLoading } = useGetMyOfferQuery(itemId, {
    skip: !verified,
  });
  return (
    <ConditionalWrapper isLoading={isLoading}>
      <Grid container>
        {!myOfferData && (
          <Grid container mt={3} justifyContent={"center"}>
            <Typography color="grey.500" variant="h5Bold">
              You Have Not Offered On This Listing
            </Typography>
          </Grid>
        )}
        {myOfferData && (
          <>
            <Typography color="primary.main" variant="boldText" sx={{ mb: 3 }}>
              My Submitted Offers
            </Typography>
            <ClosedOfferSubmitForm
              readOnly
              myOfferLoading={isLoading}
              myOfferData={myOfferData}
            />
          </>
        )}
      </Grid>
    </ConditionalWrapper>
  );
};

export default ClosedWinnerStatuses;
