import {
  emailSchema,
  licenseSchema,
  nameSchema,
  phoneSchema,
} from "Components/Validation/reausableSchemas";
import * as yup from "yup";

export const AgentInformationSchema = yup.object().shape({
  license_number: licenseSchema,
  full_name: nameSchema,
  document: yup
    .mixed()
    .nullable()
    .test("fileType", "File must be a PDF", (value: any) => {
      if (!value) return true;
      return value && value.type === "application/pdf";
    }),
  phone_number: phoneSchema,
  email: emailSchema,
  company_name: yup
    .string()
    .required("Company Name is required")
    .matches(/^[a-zA-Z\- ']+$/, 'Invalid characters in the name') 
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters"),
});
