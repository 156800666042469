import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { IContingency, IContingencyOffer } from "features/offers/api";
import { CheckIcon } from "styles/icons";
interface IProps {
  contingencies: IContingencyOffer[] | IContingency[] | undefined;
  sx?: object;
}

const ReadOnlyContingenciesList: React.FC<IProps> = ({ contingencies, sx }) => {
  return (
    <>
      {!contingencies || contingencies.length === 0 ? (
        <Typography color="grey.500" variant="smallRegularText">
          There are no contingencies on this listing
        </Typography>
      ) : (
        <List sx={sx} disablePadding>
          {contingencies.map((contingency) => (
            <ListItem
              sx={{ px: 1 }}
              disablePadding
              key={
                "contingency_id" in contingency
                  ? contingency.contingency_id
                  : contingency.id
              }
            >
              <ListItemText
                disableTypography
                sx={{ my: 0 }}
                primary={
                  <Grid container>
                    <Checkbox
                      sx={{ pointerEvents: "none" }}
                      icon={<CheckIcon fill="#CAD0D9" />}
                      checkedIcon={<CheckIcon />}
                      checked={
                        "waive_required" in contingency
                          ? contingency.waive_required
                          : contingency.is_waived
                          ? true
                          : false
                      }
                    />
                    <Typography component="span" sx={listItemStyle}>
                      {"name" in contingency
                        ? contingency.name
                        : contingency.contingency_name}
                    </Typography>
                  </Grid>
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default ReadOnlyContingenciesList;

const listItemStyle = {
  fontWeight: 400,
  fontSize: "0.75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "calc(100% - 30px)",
};
