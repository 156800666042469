import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./features/authSlice";
import listingReducer from "./features/listingSlice";
import popupReducer from "./features/popupSlice";
import { ephrownApi } from "./services/ephrownApi";
import rtkQueryErrorMiddleware from "./services/rtkQueryErrorMiddleware";

const persistConfig = {
  key: "auth",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    listing: listingReducer,
    popup: popupReducer,
    [ephrownApi.reducerPath]: ephrownApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      ephrownApi.middleware,
      rtkQueryErrorMiddleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
setupListeners(store.dispatch);
export const persistor = persistStore(store);
