import { yupResolver } from "@hookform/resolvers/yup";
import { Box, List, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { ControlledTextField } from "Components/FormComponents";
import ControlledAutoComplete from "Components/FormComponents/ControlledAutoComplete";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import UsersAvatarList from "Components/StyledComponents/UsersAvatarCard";
import { InviteUserSchema } from "Components/Validation/updateUserInfoSchema";
import {
  useGetInvitedUsersQuery,
  useInviteNewUserMutation,
} from "features/listing/api/agentApi";
import { IInvitedUser } from "features/listing/types";
import { formatFullName } from "features/listing/utils/listingsUtils";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDialogContext } from "store/hooks/DialogsContext";
import { DialogContainer } from "..";
import DialogActions from "../DialogActions";

interface IInviteUserForm {
  email_address: string;
  first_name: string;
  last_name: string;
  message: string;
}

const ShareDirectDialog: React.FC = (): JSX.Element => {
  const { closeDialog } = useDialogContext();
  const { data: invitedUsersData, isLoading: invitedUsersLoading } =
    useGetInvitedUsersQuery();
  const emailsOptions = Array.from(
    new Set([
      "All",
      ...(invitedUsersData?.map(({ email_address }) => email_address) ?? []),
    ])
  );
  const { handleSubmit, control, watch } = useForm<IInviteUserForm>({
    mode: "onChange",
    resolver: yupResolver(InviteUserSchema),
    defaultValues: {
      email_address: emailsOptions[0],
    },
  });

  const [inviteUser, { isLoading: inviteUserLoading, isSuccess }] =
    useInviteNewUserMutation();

  const handleInviteUserForm: SubmitHandler<IInviteUserForm> = async (data) => {
    const selectedUser = findUserByEmail(data.email_address, invitedUsersData);

    inviteUser({
      email_address: data.email_address,
      first_name: selectedUser?.first_name,
      last_name: selectedUser?.last_name,
      message: data.message,
    });
    isSuccess && closeDialog();
  };
  return (
    <ConditionalWrapper isLoading={invitedUsersLoading}>
      <DialogContainer sx={dialogContainer} hideExit>
        <Box onSubmit={handleSubmit(handleInviteUserForm)} component="form">
          <DialogContent sx={{ py: 0, pb: 2, px: 1 }}>
            <Typography
              mb={1}
              color="grey.800"
              fontWeight={600}
              fontSize={"0.625rem"}
            >
              Send direct to user:
            </Typography>
            <ControlledAutoComplete
              list={emailsOptions}
              control={control}
              name="email_address"
            />
            <ControlledTextField
              multiline
              minRows={1}
              fullWidth
              labelColor="#4B5563"
              control={control}
              name="message"
              placeholder="Add your message:"
            />
            <Typography
              mb={1}
              color="grey.700"
              fontWeight={600}
              fontSize={"0.625rem"}
            >
              Select User:
            </Typography>

            <List
              disablePadding
              sx={{
                maxHeight: 220,
                overflowY: "auto",
              }}
            >
              {invitedUsersData
                ?.filter((user) =>
                  watch("email_address") === "All" ||
                  watch("email_address") === null
                    ? true
                    : user.email_address === watch("email_address")
                )
                .map(({ first_name, last_name, email_address }) => (
                  <UsersAvatarList
                    key={email_address}
                    avatar="https://upload.wikimedia.org/wikipedia/commons/4/41/Sunflower_from_Silesia2.jpg"
                    fullName={formatFullName(first_name, last_name)}
                    email={email_address}
                  />
                ))}
            </List>
          </DialogContent>
          <DialogActions isLoading={inviteUserLoading} submitText="Send" />
        </Box>
      </DialogContainer>
    </ConditionalWrapper>
  );
};

export default ShareDirectDialog;

const dialogContainer = {
  "& .MuiDialog-paper": {
    px: 0,
    borderRadius: 1,
    width: 300,
  },
  "@media (max-width: 601px)": {
    "& .MuiDialog-paper": {
      minWidth: "90%",
      py: 2,
    },
  },
};

const findUserByEmail = (
  email: string,
  invitedUsersData: IInvitedUser[] | undefined
): IInvitedUser | undefined => {
  if (!invitedUsersData) return undefined;
  return invitedUsersData.find(
    (user: IInvitedUser) => user.email_address === email
  );
};
