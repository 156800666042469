import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import ReadOnlyContingenciesList from "Components/Contingencies/ReadOnlyContingenciesList";
import { formatToDDMMYYYYHHMMA } from "Components/DatesAndTime/helpers";
import { Divider } from "Components/StyledComponents";
import { ListingStatus } from "features/listing/types";
import {
  formatFullName,
  formatPhone,
} from "features/listing/utils/listingsUtils";
import { IListingOfferorRequests } from "features/offerors/api";
import {
  IOfferData,
  TListingOffer,
  formatOfferData,
} from "features/offers/api";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { BidderIcon } from "styles/icons";
import ClosedBidOfferPrices from "./ClosedBidOfferPrices";

interface IProps {
  offer: TListingOffer | IListingOfferorRequests;
  hideActionsAndDetails?: boolean;
  sx?: object;
}

const ClosedBidOfferBox: React.FC<IProps> = ({
  offer,
  hideActionsAndDetails = false,
  sx,
}): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const { openDialog } = useDialogContext();
  const isLiveBid = "offeror_request" in offer;
  const offerData: IOfferData = formatOfferData(offer);
  const userFullName = formatFullName(offerData.firstName, offerData.lastName);
  const userPhoneNumber = formatPhone(offerData.userPhoneNumber);
  const userEmail = formatPhone(offerData.userEmail);
  const offerAmount = offerData?.offerAmount;
  const mortgageAmount = offerData?.mortgageAmount;
  const onContract = offerData?.onContract;
  const balanceAtClosing = Number(offerAmount) - Number(onContract);

  return (
    <Grid container sx={{ ...containerStyle, ...sx }}>
      {/* Start Left Side */}
      <Grid
        item
        xs={12}
        sm={7}
        md={12}
        lg={7}
        display={"flex"}
        flexDirection={"column"}
      >
        {hideActionsAndDetails && (
          <Typography variant="extraSmallBoldText" color="grey.800">
            Offeror Details:
          </Typography>
        )}
        {!hideActionsAndDetails && (
          <>
            <Grid container>
              <Grid container flexWrap={"nowrap"} mt={2} gap={2}>
                <BidderIcon sx={{ fontSize: 44 }} />
                <Box sx={userAttorneyDetailsStyle}>
                  <Typography variant="smallBoldText" color="grey.800">
                    {userFullName}
                  </Typography>
                  <Typography variant="extraSmallRegularText" color="grey.700">
                    {userEmail}
                  </Typography>
                  <Typography variant="extraSmallRegularText" color="grey.700">
                    {userPhoneNumber}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider
              sx={{ my: 2, borderColor: "grey.600" }}
              orientation={"horizontal"}
            />
          </>
        )}
        <Box sx={offerDetailsStyle}>
          <ClosedBidOfferPrices
            offerAmount={offerAmount}
            mortgageAmount={mortgageAmount}
            onContract={onContract}
            balanceAtClosing={balanceAtClosing}
          />
        </Box>
        {!hideActionsAndDetails &&
          (listing.status === ListingStatus.PENDING_CONTRACT ||
            listing.status === ListingStatus.PENDING_WINNER) && (
            <LoadingButton
              onClick={() =>
                openDialog("AgentOfferorDetails", {
                  listing,
                  offer,
                })
              }
              sx={bidBoxButton}
              fullWidth
              variant="lightPrimary"
            >
              View Offer
            </LoadingButton>
          )}
      </Grid>
      {/* End Left Side */}

      <Grid
        item
        sx={{
          display: { xs: "block", sm: "flex", md: "block", lg: "flex" },
          alignItems: "center",
          justifyContent: "center",
        }}
        xs={12}
        sm={1}
        md={12}
        lg={1}
      >
        <Divider
          sx={{
            borderColor: "grey.600",
            display: { xs: "block", md: "none", lg: "block" },
          }}
          orientation={"vertical"}
        />
      </Grid>
      {/* Right Side */}
      <Grid container mt={2} item xs={12} sm={4} md={12} lg={4}>
        {isLiveBid ? (
          <Box minWidth={"100%"}>
            <>
              <Typography
                mb={0.5}
                variant="extraSmallBoldText"
                color="grey.700"
              >
                Waived Contingencies
              </Typography>
            </>
            <ReadOnlyContingenciesList
              sx={contingenciesContainerStyle(hideActionsAndDetails)}
              contingencies={offerData.contingencies}
            />
          </Box>
        ) : (
          <Grid container sx={{ pr: 1 }}>
            <Grid
              item
              gap={1}
              container
              alignItems={"center"}
              justifyContent={"end"}
            >
              <Typography
                component={"span"}
                variant="extraSmallRegularText"
                color="grey.400"
              >
                {formatToDDMMYYYYHHMMA(offer.date_created)}
              </Typography>
            </Grid>
          </Grid>
        )}
        {!hideActionsAndDetails &&
          (listing.status === ListingStatus.PENDING_CONTRACT ||
            listing.status === ListingStatus.PENDING_WINNER) && (
            <LoadingButton
              onClick={() =>
                openDialog("AcceptOffer", {
                  offer,
                })
              }
              sx={bidBoxButton}
              fullWidth
              variant="successSecondary"
            >
              Accept this offer
            </LoadingButton>
          )}
        {/* End Right Side */}
      </Grid>
    </Grid>
  );
};

export default ClosedBidOfferBox;

const containerStyle = {
  p: { xs: 1, sm: 2 },
  background: "white",
  border: "1px solid",
  borderColor: "grey.600",
  borderRadius: 1,
  mb: 1,
  ":last-of-type": {
    mb: 0,
  },
};

const userAttorneyDetailsStyle = {
  wordBreak: "break-word",
  display: "flex",
  flexDirection: "column",
  gap: 1,
};

const bidBoxButton = {
  height: 32,
  mt: "auto",
  alignSelf: "center",
};

const offerDetailsStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
};

const contingenciesContainerStyle = (isHideDetailsActions: boolean) => ({
  maxHeight: isHideDetailsActions ? 100 : 180,
  overflowY: "auto",
  border: isHideDetailsActions ? "1px solid #E5E7EB" : "none",
  borderRadius: 1,
  py: 1,
});
