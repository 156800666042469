import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface IProps {
  relative?: boolean;
  size?: number;
}

const LoadingSpinner: React.FC<IProps> = ({ relative = false, size }) => {
  return (
    <Box sx={relative ? relativeSpinnerStyle : fixedSpinnerStyle}>
      <CircularProgress size={size || 100} />
    </Box>
  );
};

export default LoadingSpinner;

const fixedSpinnerStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
};

const relativeSpinnerStyle = {
  display: "flex",
  position: "relative",
  margin: "0px auto",
  justifyContent: "center",
  alignItems: "center",
  p: 3,
};
