//Each color in Figma as name: 400-light/500-main/600-dark excluding secondary and grey.

declare module "@mui/material/styles" {
  interface Palette {
    white: Palette["primary"];
    transparent: Palette["primary"];
    black: Palette["primary"];
    offwhite: Palette["primary"];
  }

  interface PaletteColor {
    secondary: string;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    white?: PaletteOptions["primary"];
    transparent: PaletteOptions["primary"];
    offwhite?: PaletteOptions["primary"];
    black?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    white: true;
    transparent: true;
    offwhite: true;
    black: true;
    light: true;
  }
}

export const palette = {
  primary: {
    light: "#818CF8",
    main: "#6366F1",
    dark: "#4F46E5",
    900: "#312E81",
    contrastText: "#fff",
  },
  secondary: {
    light: "rgba(238, 242, 255, 0.5)",
    main: "#EEF2FF", //primary/50
  },
  success: {
    secondary: "#ECFDF5",
    light: "#34D399",
    main: "#10B981",
    dark: "#059669",
    contrastText: "#fff",
  },
  info: {
    light: "#60A5FA",
    main: "#3B82F6",
    dark: "#2563EB",
  },
  warning: {
    light: "#FBBF24",
    main: "#F59E0B",
    dark: "#D97706",
  },
  error: {
    light: "#F87171",
    main: "#EF4444",
    dark: "#DC2626",
    50: "#FEF2F2",
  },
  text: {
    primary: "#686F7A",
    secondary: "#9CA3AF",
    disabled: "#9CA3AF",
  },
  action: {
    disabledBackground: "#E5E7EB",
    disabled: "#9CA3AF",
  },
  grey: {
    30: "#D0D9E0",
    50: "#F9FAFB",
    300: "#6B7280", //helper text
    400: "#CAD0D9",
    500: "#9CA3AF",
    600: "#E5E7EB", //grey/60
    700: "#686F7A",
    800: "#4B5563",
    900: "#111827",
  },
  white: {
    light: "#F9FAFB",
    main: "#fff",
    dark: "#E5E7EB",
    contrastText: "#6366F1",
  },
  transparent: {
    main: "rgba(0,0,0,0)",
    contrastText: "#686F7A",
  },
  offwhite: {
    light: "white",
    main: "#F9FAFB",
    dark: "#E5E7EB",
    contrastText: "#6366F1",
  },
  black: {
    main: "#000000",
    contrastText: "#fff",
  },
};
