import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CircleCheckIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 15"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 13.5C10.4518 13.5 13.25 10.7018 13.25 7.25C13.25 3.79822 10.4518 1 7 1C3.54822 1 0.75 3.79822 0.75 7.25C0.75 10.7018 3.54822 13.5 7 13.5Z"
          fill="#EEF2FF"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 7.875L6.31818 9.75C7.17375 7.29232 7.88628 6.21386 9.5 4.75"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default CircleCheckIcon;
