import * as yup from "yup";

export const SubmitOpenOfferSchema = (
  minOffer: number | null,
  isApproved: boolean
) =>
  yup.object().shape({
    offer_amount: yup
      .mixed()
      .required("Offer Amount is required")
      .test(
        "is-approved",
        "You are not verified to offer for this listing",
        function (value) {
          return isApproved || Number(value) >= (minOffer || 0);
        }
      )
      .test(
        "is-greater-than-minOffer",
        `Offer amount must be equal or higher than ${minOffer?.toLocaleString()}$`,
        function (value) {
          return Number(value) >= (minOffer || 0);
        }
      ),
  });
