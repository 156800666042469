import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DownloadButtonIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 13 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.849609 9.9119V10.727C0.849609 11.1593 1.02136 11.574 1.32707 11.8797C1.63279 12.1854 2.04742 12.3571 2.47977 12.3571H9.81548C10.2478 12.3571 10.6625 12.1854 10.9682 11.8797C11.2739 11.574 11.4456 11.1593 11.4456 10.727V9.9119"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.14781 5.49735C6.70822 5.49735 7.50627 5.57555 8.16049 5.65319C8.76731 5.72521 9.15486 6.33458 8.90212 6.89094C8.41052 7.9731 7.67381 8.97044 6.59254 9.65745C6.32098 9.82999 5.97464 9.83 5.70307 9.65747C4.62177 8.97052 3.88508 7.97315 3.39349 6.89099C3.14077 6.33465 3.52829 5.7253 4.13508 5.65326C4.78931 5.57558 5.58739 5.49735 6.14781 5.49735Z"
          fill="#EEF2FF"
        />
        <path
          d="M6.14781 5.49735C6.70822 5.49735 7.50627 5.57555 8.16049 5.65319C8.76731 5.72521 9.15486 6.33458 8.90212 6.89094C8.41052 7.9731 7.67381 8.97044 6.59254 9.65745C6.32098 9.82999 5.97464 9.83 5.70307 9.65747C4.62177 8.97052 3.88508 7.97315 3.39349 6.89099C3.14077 6.33465 3.52829 5.7253 4.13508 5.65326C4.78931 5.57558 5.58739 5.49735 6.14781 5.49735Z"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.14746 5.49728L6.14746 1.64279"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default DownloadButtonIcon;
