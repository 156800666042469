import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface TimerFieldIconProps extends SvgIconProps {
  stroke?: string;
  fill?: string;
}

const TimerFieldIcon: React.FC<TimerFieldIconProps> = ({
  stroke,
  fill = "#EEF2FF",
  sx,
  ...props
}) => {
  return (
    <SvgIcon
      viewBox="0 0 15 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.50061 13.1176C10.3595 13.1176 12.677 10.8 12.677 7.94117C12.677 5.08232 10.3595 2.76477 7.50061 2.76477C4.64177 2.76477 2.32422 5.08232 2.32422 7.94117C2.32422 10.8 4.64177 13.1176 7.50061 13.1176Z"
          fill={fill}
          stroke={stroke || "#4F46E5"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.38281 2.76477C2.04377 1.99702 2.84161 1.35875 3.73572 0.882446"
          stroke={stroke || "#4F46E5"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6176 2.76477C12.9566 1.99702 12.1588 1.35875 11.2646 0.882446"
          stroke={stroke || "#4F46E5"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 5.11768V7.94116H9.85291"
          stroke={stroke || "#4F46E5"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default TimerFieldIcon;
