import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, DialogTitle, Stack, type SxProps } from "@mui/material";
import { ControlledTextField } from "Components/FormComponents";
import { digitsRegex } from "Components/Validation/regex";
import {
  IPaymentMethod,
  useEditPaymentMethodMutation,
} from "features/payments/api";
import { EditPaymentMethodSchema } from "features/payments/schema";
import { SubmitHandler, useForm } from "react-hook-form";

interface IEditPaymentMethodForm {
  expDate: string;
}

type Props = {
  setOpen: (state: boolean) => void;
  editedPaymentItem: IPaymentMethod;
  onEdited: () => void;
};

export default function EditPaymentMethod({
  setOpen,
  editedPaymentItem,
  onEdited,
}: Props) {
  const [editItem] = useEditPaymentMethodMutation();

  const { handleSubmit, control } = useForm<IEditPaymentMethodForm>({
    mode: "onChange",
    resolver: yupResolver(EditPaymentMethodSchema),
  });

  const handleRegisterForm: SubmitHandler<IEditPaymentMethodForm> = async (
    data: IEditPaymentMethodForm
  ) => {
    editItem({
      expiration_date: data.expDate,
      cvv: "123",
      payment_method_id: editedPaymentItem.id,
    }).then(onEdited);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <Box onSubmit={handleSubmit(handleRegisterForm)} component="form">
      <DialogTitle textAlign={"center"} color="primary" fontWeight={700}>
        Edit Payment Method Ending In {editedPaymentItem.last_four_digits}
      </DialogTitle>

      <Box>
        <ControlledTextField
          control={control}
          fullWidth
          name="expDate"
          label="Exp. Date"
          placeholder="MM/YY"
          regex={digitsRegex}
          maxLength={2}
        />
      </Box>

      <Stack sx={buttonsGroup} gap="15px">
        <LoadingButton type="submit" variant="contained" fullWidth>
          Submit
        </LoadingButton>
        <LoadingButton type="button" fullWidth onClick={closeDialog}>
          Cancel
        </LoadingButton>
      </Stack>
    </Box>
  );
}

const buttonsGroup: SxProps = {
  marginTop: 3,
};
