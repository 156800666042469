import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useGetSharedListingQuery } from "features/listing/api/agentApi";
import * as React from "react";
import { formatToMMDDYY } from "../DatesAndTime/helpers";
import ConditionalWrapper from "./ConditionalWrapper";

interface IProps {
  listingId?: string | undefined;
}

interface IShare {
  email_address: string;
  date_last_clicked: string;
  click_count: string;
}

const SharesTable: React.FC<IProps> = ({ listingId }): JSX.Element => {
  const { data: sharedListings, isLoading: sharedListingsLoading } =
    useGetSharedListingQuery(listingId);

  if (sharedListings?.shares === null) {
    return (
      <Typography variant="extraSmallRegularText" color="text.secondary">
        No Shares
      </Typography>
    );
  }
  return (
    <ConditionalWrapper isLoading={sharedListingsLoading}>
      <TableContainer sx={tableStyle}>
        <Table aria-label="shares-table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="extraSmallRegularText"
                  color="text.secondary"
                >
                  Email Address
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="extraSmallRegularText"
                  color="text.secondary"
                >
                  Opened Link Count
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="extraSmallRegularText"
                  color="text.secondary"
                >
                  Last Opened
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sharedListings?.shares?.map((share: IShare, idx: number) => (
              <TableRow key={idx}>
                <TableCell component="th" scope="row">
                  <Typography variant="extraSmallRegularText" color="grey.800">
                    {share?.email_address}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="smallBoldText" color="grey.800">
                    {share?.click_count || "0"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="extraSmallRegularText" color="grey.800">
                    {formatToMMDDYY(share?.date_last_clicked)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ConditionalWrapper>
  );
};

export default SharesTable;

const tableStyle = {
  ".MuiTableCell-root.MuiTableCell-body": {
    py: 1,
    px: 0,
  },
  ".MuiTableCell-root.MuiTableCell-head": {
    py: 2,
    px: 0,
  },
};
