import { FormControl } from "@mui/material";
import { CheckboxProps } from "@mui/material/Checkbox";
import { ReactNode } from "react";
import { Control, useController } from "react-hook-form";
import ErrorText from "../StyledComponents/ErrorText";
import { Checkbox } from "Components/StyledComponents";

export type ExtendCheckboxProps = {
  label?: ReactNode;
  labelColor?: string;
  name: string;
  control?: Control<any>;
  sx?: object;
} & CheckboxProps;

const ControlledCheckbox: React.FC<ExtendCheckboxProps> = ({
  label,
  labelColor,
  name,
  control,
  sx,
  ...props
}): JSX.Element => {
  const {
    field: { ref, value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: false,
  });
  return (
    <>
      <FormControl
        sx={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Checkbox
          {...props}
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          disableRipple
          sx={{ minWidth: 36 }}
        />
        {label}
      </FormControl>
      {error && <ErrorText text={error.message || ""} />}
    </>
  );
};

export default ControlledCheckbox;
