import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogContent, DialogTitle } from "@mui/material";
import { DialogContainer } from "Components/Dialogs";
import { ControlledPassword } from "Components/FormComponents";
import {
  ChangePasswordDialogSchema,
  ChangePasswordDialogType,
} from "features/auth";
import { validationPasswordResetList } from "features/auth/components";
import ValidationTextList from "features/auth/components/ValidationTextList";
import { useUpdateUserPasswordMutation } from "features/listing/api/userApi";
import { useForm } from "react-hook-form";
import { useDialogContext } from "store/hooks/DialogsContext";
import DialogActions from "./DialogActions";

interface Props {
  open: boolean;
  setOpen: (s: boolean) => void;
}

export default function ChangePasswordDialog({ open, setOpen }: Props) {
  const { closeDialog } = useDialogContext();
  const { register, handleSubmit, control, watch } =
    useForm<ChangePasswordDialogType>({
      mode: "onChange",
      resolver: yupResolver(ChangePasswordDialogSchema),
    });
  const [updateUserPassword, { isLoading }] = useUpdateUserPasswordMutation();
  const onSubmit = (data: ChangePasswordDialogType) => {
    const body = {
      ["current_password"]: data.currentPassword,
      ["new_password"]: data.newPassword,
    };
    updateUserPassword(body);
    closeDialog();
  };

  return (
    <DialogContainer sx={changePasswordContainerStyle}>
      <DialogTitle
        sx={{ py: 3 }}
        color="primary"
        fontWeight={700}
        textAlign={"center"}
      >
        Change Password
      </DialogTitle>

      <DialogContent sx={{ py: 0, px: { xs: 1, sm: 4 } }}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <ControlledPassword
            {...register("currentPassword")}
            control={control}
            placeholder="Current Password"
            label="Current Password"
            fullWidth
          />
          <ControlledPassword
            {...register("newPassword")}
            control={control}
            placeholder="New Password"
            label="New Password"
            fullWidth
          />
          <ControlledPassword
            {...register("confirmPassword")}
            control={control}
            placeholder="Confirm Password"
            label="Confirm Password"
            fullWidth
          />
          <ValidationTextList
            password={watch("newPassword", "")}
            validationList={validationPasswordResetList}
          />
          <DialogActions submitText="Confirm" isLoading={isLoading} />
        </Box>
      </DialogContent>
    </DialogContainer>
  );
}
const changePasswordContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    mx: 0,
    "@media (max-width: 601px)": {
      width: "90%",
      maxHeight: "90%",
    },
  },
};
