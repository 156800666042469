import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface DotsIconProps extends SvgIconProps {
  vertical?: boolean;
}

const DotsIcon: React.FC<DotsIconProps> = ({
  sx,
  vertical = false,
  ...props
}) => {
  return (
    <SvgIcon
      viewBox="0 0 16 4"
      {...props}
      sx={{
        fontSize: "1rem",
        transform: vertical ? "rotate(90deg)" : null,
        ...sx,
      }}
    >
      <svg
        width="16"
        height="4"
        viewBox="0 0 16 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 2C16 3.10457 15.1046 4 14 4C12.8954 4 12 3.10457 12 2C12 0.89543 12.8954 -5.63677e-07 14 -6.11959e-07C15.1046 -6.60242e-07 16 0.89543 16 2ZM10 2C10 3.10457 9.10457 4 8 4C6.89543 4 6 3.10457 6 2C6 0.89543 6.89543 -3.01409e-07 8 -3.49691e-07C8.53043 -3.72877e-07 9.03914 0.210713 9.41421 0.585786C9.78929 0.960859 10 1.46957 10 2ZM4 2C4 3.10457 3.10457 4 2 4C0.89543 4 -3.91405e-08 3.10457 -8.74228e-08 2C-1.35705e-07 0.895431 0.89543 -3.91405e-08 2 -8.74228e-08C2.53043 -1.10609e-07 3.03914 0.210713 3.41421 0.585786C3.78929 0.960859 4 1.46957 4 2Z"
          fill="#9CA3AF"
        />
      </svg>
    </SvgIcon>
  );
};

export default DotsIcon;
