import BasicTabs from "Components/TabsPanel/BasicTabs";
import {
  AccountSettingsTab,
  AgentInformationTab,
  PaymentHistoryTab,
  MySubscriptionTab,
} from "features/myAccount";
import PaymentMethodsTab from "features/myAccount/components/PaymentMethodsTab";
import { Portal } from "features/users/types";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import AccountSettingsContainer from ".";

const MyAccount = () => {
  const { portal } = useAppSelector(selectAuth);

  const accountTabs = [
    {
      label: "Account Settings",
      display: true,
      component: <AccountSettingsTab />,
    },
    // temporary - comment out {
    //   label: "Agent Information",
    //   display: true,
    //   component: <AgentInformationTab />,
    // },
    {
      label: "Payment Methods",
      display: true,
      component: <PaymentMethodsTab />,
    },
    {
      label: "Payment History",
      display: true,
      component: <PaymentHistoryTab />,
    },
    // temporary - comment out {
    //   label: "My Subscription",
    //   display: portal === Portal.AGENT || portal === Portal.ADMIN,
    //   component: <MySubscriptionTab />,
    // },
  ];

  return (
    <AccountSettingsContainer>
      <BasicTabs tabs={accountTabs} />
    </AccountSettingsContainer>
  );
};

export default MyAccount;
