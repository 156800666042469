import { Theme } from "@mui/material/styles";

export const tertiaryPrimary = (theme: Theme) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.grey[50],
  borderColor: theme.palette.grey[600],
  "&:hover": {
    borderColor: theme.palette.grey[400],
  },
  "&:focus": {
    backgroundColor: theme.palette.grey[50],
  },
  "&:active": {
    borderColor: theme.palette.grey[500],
  },
});
