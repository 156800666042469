import {
  TextFieldProps,
  InputAdornment,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Control, useController } from "react-hook-form";
import { useState } from "react";
import { TextField } from "@mui/material";
import { HelperText } from "Components/StyledComponents";
import { InfoIcon } from "styles/icons";

export type TExtendTextFieldProps = {
  control: Control<any>;
  name: string;
  label?: string;
  labelColor?: string;
  infoText?: string;
  placeholder?: string;
  mb?: number;
} & TextFieldProps;

const ControlledPassword: React.FC<TExtendTextFieldProps> = ({
  control,
  name,
  label,
  labelColor,
  placeholder,
  infoText,
  mb = 1,
  ...rest
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name, control });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          edge="end"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
    ...rest.InputProps,
  };

  return (
    <Box width={"100%"} mb={mb}>
      <Typography
        fontWeight={600}
        color={labelColor || "primary"}
        variant="extraSmallBoldText"
        mb={0.5}
      >
        {label}
      </Typography>
      <TextField
        {...rest}
        name={name}
        value={value || ""}
        onChange={handleChange}
        onBlur={onBlur}
        error={!!error}
        helperText={error?.message}
        placeholder={placeholder || label}
        InputProps={inputProps}
        type={showPassword ? "text" : "password"}
        className="passwordField"
        sx={{
          "& .MuiInputBase-root": {
            paddingLeft: 2.5,
          },
          ...rest.sx,
        }}
      />
      {infoText && (
        <HelperText
          icon={<InfoIcon sx={{ fontSize: "0.7rem" }} />}
          text={infoText}
        />
      )}
    </Box>
  );
};

export default ControlledPassword;
