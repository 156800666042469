import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface CheckRoundedIconProps extends SvgIconProps {
  stroke?: string;
}
const CheckRoundedIcon: React.FC<CheckRoundedIconProps> = ({
  sx,
  stroke = "white",
  ...props
}) => {
  return (
    <SvgIcon
      viewBox="0 0 13 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.14282 8.33949L5.03893 12.3574C6.87228 7.09089 8.39914 4.77991 11.8571 1.64307"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default CheckRoundedIcon;
