import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const FavoriteIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 15 14"
      {...props}
      sx={{
        fontSize: "1.3rem",
        ...sx,
      }}
    >
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.03739 1.37802C7.20706 0.96431 7.79294 0.96431 7.96261 1.37802L9.40248 4.88897L13.2798 5.22884C13.7261 5.26796 13.8997 5.82896 13.5536 6.11329L10.9095 8.28532C10.7648 8.40424 10.6986 8.59411 10.7382 8.77723L11.5652 12.6063C11.6601 13.0457 11.1722 13.3774 10.7985 13.1275L7.5 10.9216L4.20146 13.1275C3.82781 13.3774 3.33988 13.0457 3.43478 12.6063L4.26182 8.77723C4.30137 8.59411 4.23524 8.40424 4.09047 8.28532L1.44644 6.11329C1.10032 5.82895 1.27395 5.26796 1.72016 5.22884L5.59752 4.88897L7.03739 1.37802Z" />
        <path
          d="M7.03739 1.37802C7.20706 0.96431 7.79294 0.96431 7.96261 1.37802L9.40248 4.88897L13.2798 5.22884C13.7261 5.26796 13.8997 5.82896 13.5536 6.11329L10.9095 8.28532C10.7648 8.40424 10.6986 8.59411 10.7382 8.77723L11.5652 12.6063C11.6601 13.0457 11.1722 13.3774 10.7985 13.1275L7.5 10.9216L4.20146 13.1275C3.82781 13.3774 3.33988 13.0457 3.43478 12.6063L4.26182 8.77723C4.30137 8.59411 4.23524 8.40424 4.09047 8.28532L1.44644 6.11329C1.10032 5.82895 1.27395 5.26796 1.72016 5.22884L5.59752 4.88897L7.03739 1.37802Z"
          stroke="#9CA3AF"
          strokeWidth="1"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default FavoriteIcon;
