import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import { TextIcon } from "Components/Fields";
import { ControlledPassword } from "Components/FormComponents";
import { Link } from "Components/StyledComponents";
import { useResetPasswordTokenMutation } from "features/auth/api/authApi";
import { ResetPasswordTokenSchema } from "features/auth/schema/ResetPasswordTokenSchema";
import { ErrorResponse } from "features/auth/types";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { ErrorIcon } from "styles/icons";
import { validationPasswordResetList } from "../..";
import ValidationTextList from "../../ValidationTextList";
import { borderEmailFormStyle } from "./ForgotPassword";

interface IResetPasswordForm {
  new_password: string;
}

const ResetPasswordWithToken: React.FC = (): JSX.Element => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const [resetPasswordToken, { isLoading, error, isSuccess }] =
    useResetPasswordTokenMutation();
  const { handleSubmit, control, watch } = useForm<IResetPasswordForm>({
    mode: "onChange",
    resolver: yupResolver(ResetPasswordTokenSchema),
  });

  const errorMessage = (error as ErrorResponse)?.data?.message || "";
  const handleResetPasswordForm: SubmitHandler<IResetPasswordForm> = ({
    new_password,
  }: IResetPasswordForm) => {
    resetPasswordToken({
      token,
      new_password,
    });
  };

  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12} md={10} lg={8}>
        {!isSuccess ? (
          <Box
            onSubmit={handleSubmit(handleResetPasswordForm)}
            component="form"
            p={{ xs: 2, sm: 5 }}
            my={3}
            sx={{
              bgcolor: "secondary.main",
              color: "text.primary",
              borderRadius: 1.5,
            }}
          >
            <Typography variant="h5Bold" color={"primary"} textAlign={"center"}>
              Password Assistance
            </Typography>
            <Grid container justifyContent={"center"}>
              <TextIcon
                variant="smallRegularText"
                text={
                  errorMessage
                    ? `There was an error: ${errorMessage}`
                    : "Enter below your new password."
                }
                icon={
                  errorMessage ? (
                    <ErrorIcon sx={{ fontSize: "1.375rem" }} />
                  ) : (
                    ""
                  )
                }
                fontColor={errorMessage ? "error.main" : "text.primary"}
                sx={{ textTransform: "LOOKING FOR DEFAULT MODE" }}
              />
            </Grid>
            <Grid
              container
              justifyContent={"space-between"}
              mt={3}
              mb={2}
              sx={borderEmailFormStyle}
            >
              <ControlledPassword
                fullWidth
                control={control}
                name="new_password"
                label="Password"
                placeholder="Create Password"
              />
              <ControlledPassword
                mb={1}
                fullWidth
                control={control}
                name="confirmPassword"
                label="Confirm Password"
              />
              <ValidationTextList
                password={watch("new_password", "")}
                validationList={validationPasswordResetList}
              />
            </Grid>
            <Grid
              container
              justifyContent={"center"}
              gap={3}
              sx={borderEmailFormStyle}
            >
              <LoadingButton
                variant="primary"
                type="submit"
                loading={isLoading}
                fullWidth
                sx={{ mb: 3 }}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Box>
        ) : (
          <Grid
            container
            flexDirection={"column"}
            gap={3}
            alignContent={"center"}
          >
            <Typography variant="h5Bold" color={"primary"} textAlign={"center"}>
              Password reset successfully!
            </Typography>
            <Link
              to="/login"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <LoadingButton
                variant="primary"
                loading={isLoading}
                sx={{ minWidth: 250 }}
              >
                Login
              </LoadingButton>
            </Link>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ResetPasswordWithToken;
