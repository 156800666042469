import { styled } from "@mui/material/styles";
import MuiTabs from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  "&.MuiTabs-root": {
    backgroundColor: theme.palette.primary.main,
    minHeight: 40,
    height: 40,
    padding: 4,
    borderRadius: 8,
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
}));

export const Tab = styled(MuiTab)(({ theme }) => ({
  "&.MuiTab-root": {
    flex: "1 1 33%",
    borderRadius: 8,
    color: theme.palette.common.white,
    fontWeight: 700,
    minHeight: 40,
    height: 40,
    textTransform: "none",
    "&.MuiButtonBase-root.MuiTab-root": {
      flexDirection: "row",

      "&.MuiButtonBase-root.MuiTab-root.Mui-selected>.MuiTab-iconWrapper": {
        mr: 0,
        mb: 0,
      },
    },
    "&.Mui-selected": {
      height: "20px",
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export const listingDetailsTabsStyle = {
  "&.MuiTabs-root": {
    width: 250,
    maxWidth: "100%",
    borderRadius: 6,
    ".MuiTab-root": {
      display: "flex",
      borderRadius: 8,
      fontWeight: 700,
      minHeight: 40,
      height: 40,
      minWidth: 40,
      textTransform: "none",

      "&.MuiButtonBase-root.MuiTab-root": {
        display: "flex",
        px: 1,
        flexDirection: "row",
        alignItems: "center",
        fontSize: 0,
        flex: 0,
        mb: 0,
        "&.MuiButtonBase-root.MuiTab-root>.MuiTab-iconWrapper": {
          mb: 0,
        },
        "&.MuiButtonBase-root.MuiTab-root.Mui-selected>.MuiTab-iconWrapper": {
          mr: 1,
        },
        "&.Mui-selected": {
          flex: 1,
          fontSize: "0.75rem",
        },
      },
    },
  },
};

export const fullWidthTabsStyle = {
  mb: 2,
  ".MuiButtonBase-root.MuiTab-root>.MuiTab-iconWrapper": {
    mr: 1,
    mb: 0,
  },
  ".MuiTab-root.MuiButtonBase-root.MuiTab-root.MuiButtonBase-root.MuiTab-root.Mui-selected>.MuiTab-iconWrapper":
    {
      mr: 1,
    },
};
