import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CheckboxUncheckedIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#6366F1" />
        <path
          d="M10.7969 5.3125L6.6875 9.42188L5.1875 7.90625C5.10938 7.84375 4.98438 7.84375 4.92188 7.90625L4.46875 8.35938C4.40625 8.42188 4.40625 8.54688 4.46875 8.625L6.5625 10.7031C6.64062 10.7812 6.75 10.7812 6.82812 10.7031L11.5156 6.01562C11.5781 5.95312 11.5781 5.82812 11.5156 5.75L11.0625 5.3125C11 5.23438 10.875 5.23438 10.7969 5.3125Z"
          fill="#6366F1"
        />
        <path
          d="M10.1464 5.14645C10.3417 4.95118 10.6583 4.95118 10.8536 5.14645C11.0488 5.34171 11.0488 5.65829 10.8536 5.85355L8.70711 8L10.8536 10.1464C11.0488 10.3417 11.0488 10.6583 10.8536 10.8536C10.6583 11.0488 10.3417 11.0488 10.1464 10.8536L8 8.70711L5.85355 10.8536C5.65829 11.0488 5.34171 11.0488 5.14645 10.8536C4.95118 10.6583 4.95118 10.3417 5.14645 10.1464L7.29289 8L5.14645 5.85355C4.95118 5.65829 4.95118 5.34171 5.14645 5.14645C5.34171 4.95118 5.65829 4.95118 5.85355 5.14645L8 7.29289L10.1464 5.14645Z"
          fill="white"
        />
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="3.5"
          stroke="#6366F1"
        />
      </svg>
    </SvgIcon>
  );
};

export default CheckboxUncheckedIcon;
