import { LoadingButton } from "@mui/lab";
import { DialogContent, Grid, Typography } from "@mui/material";
import DialogContainer, { relativeContainerStyle } from "./DialogContainer";

interface IProps {
  text: string;
  hideReloadButton: boolean;
}
const SaleEndedDialog: React.FC<IProps> = ({
  text,
  hideReloadButton = false,
}): JSX.Element => {
  return (
    <DialogContainer sx={relativeContainerStyle} hideExit preventBackdrop>
      <DialogContent sx={{ py: 0 }}>
        <Grid container gap={2} justifyContent={"center"}>
          <Typography
            color="common.black"
            lineHeight={1.5}
            textAlign={"center"}
          >
            {text}
          </Typography>
          {!hideReloadButton && (
            <LoadingButton
              onClick={() => window.location.reload()}
              variant="primary"
            >
              Reload Now
            </LoadingButton>
          )}
        </Grid>
      </DialogContent>
    </DialogContainer>
  );
};

export default SaleEndedDialog;
