import {
  Box,
  Snackbar as MUISnackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";

interface IProps {
  open: any;
  setOpen: any;
}
const ShareSnackbar: React.FC<IProps> = ({ open, setOpen }): JSX.Element => {
  return (
    <Box>
      <Box sx={open ? backdropStyle : {}}>
        <MUISnackbar
          sx={snackbarCenterStyle}
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={1500}
        >
          <SnackbarContent
            sx={snackContentStyle}
            message={
              <Box sx={{ width: "100%", margin: "0 auto" }}>
                <img
                  src={"/static/images/linkCopied.svg"}
                  width={120}
                  height={120}
                />
                <Typography
                  mt={2}
                  variant="extraSmallBoldText"
                  color="grey.700"
                >
                  Link Copied!
                </Typography>
              </Box>
            }
          />
        </MUISnackbar>
      </Box>
    </Box>
  );
};

export default ShareSnackbar;

const backdropStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  zIndex: 9,
  transition: "background-color 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94)",
};
const snackContentStyle = {
  backgroundColor: "secondary.main",
  margin: "0 auto",
  minWidth: 170,
  width: 170,
  height: 200,
  ".MuiSnackbarContent-message": {
    textAlign: "center",
    margin: "0 auto",
  },
  "@media (min-width: 600px)": {
    minWidth: 170,
    width: 170,
  },
};

const snackbarCenterStyle = {
  transform: "translate(-50%,-50%)",
  left: "50% !important",
  top: "50% !important",
  height: "fit-content",
  width: "fit-content",
  ".MuiPaper-root": {
    boxShadow: "0px 0px 10px 0px rgba(99, 102, 241, 0.1)",
  },
};
