import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import ErrorText from "Components/StyledComponents/ErrorText";
import { useEffect, useState } from "react";
import { Control, useController } from "react-hook-form";
import {
  CircleCheckIcon,
  EditPencilIcon,
  ExitSquareIcon,
  InfoIcon,
} from "styles/icons";
import { HelperText } from "../../StyledComponents";

export type ExtendTextFieldProps = {
  control: Control<any>;
  name: string;
  label?: string;
  labelColor?: string;
  infoText?: string;
  editableText?: boolean;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  regex?: RegExp | undefined;
  maxLength?: number;
  outsideIcons?: any;
  mb?: number;
  autoCompleteValue?: string;
  setPasswordPopUp?: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdateClick?: any;
  isPriceRegex?: boolean;
  hideError?: boolean;
  resetField?: any;
  isExpDate?: boolean;
} & TextFieldProps;

const ControlledTextField: React.FC<ExtendTextFieldProps> = ({
  control,
  name,
  autoCompleteValue,
  label,
  outsideIcons,
  setPasswordPopUp,
  labelColor,
  placeholder,
  startAdornment,
  endAdornment,
  editableText = false,
  infoText,
  regex,
  mb = 1,
  onUpdateClick,
  maxLength = 500,
  isPriceRegex,
  hideError = false,
  resetField = null,
  ...rest
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name, control });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (!regex) {
      onChange(inputValue);
    } else if (regex.test(inputValue) || inputValue === "") {
      onChange(inputValue);
    }
  };

  const [isEditMode, setIsEditMode] = useState(false);

  const inputProps = {
    startAdornment: (
      <InputAdornment position="start">{startAdornment}</InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position="end">{endAdornment}</InputAdornment>
    ),

    ...rest.InputProps,
  };

  const handleEdit = () => {
    if (setPasswordPopUp) {
      setPasswordPopUp(true);
    }
  };

  const editableInputProps = {
    endAdornment: (
      <Box
        display="flex"
        sx={{ gap: 1, p: 0 }}
        onClick={() => {
          if (!isEditMode) setIsEditMode((prev) => !prev);
          if (isEditMode && !error) {
            setIsEditMode((prev) => !prev);
          }
        }}
      >
        {isEditMode ? (
          <>
            <IconButton
              sx={{ p: 0, m: 0 }}
              onClick={() => onUpdateClick(name, value)}
            >
              <CircleCheckIcon sx={iconsStyle} />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsEditMode(false);
                resetField && resetField(name);
              }}
              sx={{ p: 0, m: 0 }}
            >
              <ExitSquareIcon sx={iconsStyle} />
            </IconButton>
          </>
        ) : (
          <IconButton sx={{ p: 0, m: 0 }} onClick={handleEdit}>
            <EditPencilIcon sx={iconsStyle} />
          </IconButton>
        )}
      </Box>
    ),
    ...rest.InputProps,
  };

  useEffect(() => {
    if (autoCompleteValue) {
      onChange(autoCompleteValue);
    }
  }, [autoCompleteValue, onChange]);

  return (
    <Box width={"100%"} mb={mb}>
      {label && (
        <Typography
          sx={{ mb: 0.5 }}
          fontWeight={600}
          color={labelColor || "primary"}
          variant="extraSmallBoldText"
        >
          {label}
        </Typography>
      )}
      <Grid container display="flex" alignItems={"center"}>
        <Grid item flex={1}>
          <TextField
            {...rest}
            name={name}
            value={value || ""}
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={placeholder || label}
            InputProps={editableText ? editableInputProps : inputProps}
            inputProps={{ maxLength: maxLength }}
            sx={{
              pointerEvents: editableText
                ? isEditMode
                  ? "auto"
                  : "none"
                : "auto",
              ...rest.sx,
            }}
          />
        </Grid>
        <Grid item>{outsideIcons || null}</Grid>
      </Grid>
      {infoText && (
        <HelperText
          icon={
            <InfoIcon
              sx={{ fontSize: "0.625rem", mr: 0.5, color: "info.main" }}
            />
          }
          text={infoText}
        />
      )}
      {error && !hideError && <ErrorText text={error?.message} />}
    </Box>
  );
};

export default ControlledTextField;

export const iconsStyle: SxProps = {
  cursor: "pointer",
  pointerEvents: "auto",
};
