import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";

export const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1": {
    boxShadow: "0px 0px 10px 0px #6366F11A",
    color: "red",
  },
  "&.MuiInputBase-root": {
    height: 40,
    fontSize: "0.8rem",

    "&:hover": {
      borderRadius: 8,
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.grey[600],
      },
    },

    fieldset: {
      borderColor: theme.palette.grey[600],
      fontSize: "0.8rem",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.grey[600]}`,
      boxShadow: "none",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
    },
  },
}));
