import { Box, Divider } from "@mui/material";
import { getTimeToEndDate } from "Components/DatesAndTime/helpers";
import { Checkbox } from "Components/StyledComponents";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { SearchPanel, TopRightPanel } from "features/listing";
import { useGetListingOffersQuery } from "features/listing/api/agentApi";
import {
  TopRightButtonPanel,
  sortHighestOffers,
} from "features/listing/components/detailListing/agent";
import ClosedBidOfferBox from "features/listing/components/detailListing/agent/offerBox/ClosedBidOfferBox";
import OpenBidOfferBox, {
  ICollapseState,
} from "features/listing/components/detailListing/agent/offerBox/OpenBidOfferBox";
import { SaleType } from "features/listing/types";
import { TListingOffer } from "features/offers/api";
import { useEffect, useState } from "react";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";

interface IProps {
  redirectToPublish?: boolean;
  setRedirectToPublish?: (value: boolean) => void;
}

const LiveListingStatus: React.FC<IProps> = ({
  redirectToPublish,
  setRedirectToPublish,
}): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const { data: offersData, isLoading: offerLoading } =
    useGetListingOffersQuery(listing.id, {
      skip: !listing.id,
    });
  const [collapseState, setCollapseState] = useState<ICollapseState>({
    isOfferExpand: new Array(offersData?.offers?.length).fill(true),
    isCollapsed: false,
  });
  const sortedOffers = sortHighestOffers(
    offersData?.offers,
    offersData?.highest_offer
  );

  const [filteredList, setFilteredList] = useState(sortedOffers);
  useEffect(() => {
    setFilteredList(sortedOffers);
  }, [!sortedOffers === null]);
  const handleCollapseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCollapseState({
      isCollapsed: e.target.checked,
      isOfferExpand: new Array(offersData?.offers?.length).fill(false),
    });
  };
  const isOpenListing = listing?.sale_type === SaleType.OPEN;
  const timeToEndDate: Date = getTimeToEndDate(
    parseInt(listing.auction_end_date),
    parseInt(listing.auction_end_delay)
  );
  return (
    <ConditionalWrapper isLoading={offerLoading}>
      <TopRightButtonPanel setRedirectToPublish={setRedirectToPublish} />
      <TopRightPanel
        highestOffer={offersData?.highest_offer}
        count={offersData?.offers?.length}
        timerDate={timeToEndDate}
      />
      <Divider sx={{ mt: 1, mb: 2, borderColor: "grey.600" }} />
      <SearchPanel offers={sortedOffers} setFilteredList={setFilteredList} />
      <Checkbox
        sx={checkboxCollapseStyle}
        label="Collapse All"
        labelColor="grey.800"
        checked={collapseState.isCollapsed}
        onChange={(e) => handleCollapseChange(e)}
      />

      <Box sx={{ height: 490, overflow: "auto" }} pr={1.5}>
        {filteredList?.map((offer: TListingOffer, idx) => {
          const isHighest = offer.offer_amount === offersData?.highest_offer;
          return isOpenListing ? (
            <OpenBidOfferBox
              key={offer.id}
              offer={offer}
              isOpenListing={true}
              index={idx}
              setCollapseState={setCollapseState}
              collapseState={collapseState}
              isHighest={isHighest}
              redirectToPublish={redirectToPublish}
            />
          ) : (
            <ClosedBidOfferBox key={offer.id} offer={offer} />
          );
        })}
      </Box>
    </ConditionalWrapper>
  );
};

export default LiveListingStatus;

const checkboxCollapseStyle = {
  mb: 1,
};
