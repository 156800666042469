import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  ControlledFullAddress,
  ControlledTextField,
} from "Components/FormComponents";
import ControlledRadio, {
  TRadioButton,
} from "Components/FormComponents/ControlledRadio";
import FileUploader from "Components/FormComponents/FileUploader";
import { ApartmentsImageList } from "features/listing/components/images";
import { useFormContext } from "react-hook-form";

const NewPropertyDetailsTab: React.FC = (): JSX.Element => {
  const { control } = useFormContext();
  const isDisabled = true;
  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12}>
        <ControlledFullAddress isEditable />
        <ControlledTextField
          multiline
          minRows={4}
          fullWidth
          labelColor="#4B5563"
          control={control}
          name="description"
          label="Description"
          placeholder="Property Description"
          disabled={!isDisabled}
        />
        {/* TEMPORARY - waiting for BE
         <Typography
          fontWeight={600}
          variant="extraSmallBoldText"
          sx={{ pt: 2, color: "grey.800", borderTop: "1px solid #EEF2FF" }}
        >
          Upload Photos
        </Typography>
        <ApartmentsImageList /> */}
        <Typography
          fontWeight={600}
          variant="extraSmallBoldText"
          sx={saleTypeStyle}
        >
          Property Type:
        </Typography>
        <ControlledRadio
          disabled={!isDisabled}
          radioButtons={propertyTypeList}
          control={control}
          name="property_type"
        />
        {/* TEMPORARY - waiting for BE
        <Grid container mb={3} flexDirection={"column"} gap={2} mt={2}>
          <FileUploader />
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default NewPropertyDetailsTab;

const saleTypeStyle = {
  mt: 2,
  pt: 2,
  color: "grey.800",
  border: "solid #EEF2FF",
  borderWidth: "1px 0 0 0 ",
};

const propertyTypeList: TRadioButton[] = [
  { label: "Residential", value: "residential" },
  { label: "Commercial", value: "commercial" },
];
