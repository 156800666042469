import { Typography } from "@mui/material";
import { ElementType } from "react";
import { Link as RouterLink } from "react-router-dom";

interface IProps {
  to: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  color?: string;
  underline?: boolean;
  component?: ElementType<any>;
  sx?: object;
  state?: object;
  onClick?: () => void;
}

const Link: React.FC<IProps> = ({
  to,
  children,
  color,
  underline,
  component,
  sx,
  state,
  onClick,
  ...props
}): JSX.Element => {
  return (
    <Typography
      variant={"inherit"}
      color={color}
      component={component || "span"}
      sx={{ textDecoration: underline ? "underline" : "none", ...sx }}
      {...props}
    >
      <RouterLink
        {...props}
        className="link"
        to={to}
        state={state}
        onClick={onClick}
      >
        {children}
      </RouterLink>
    </Typography>
  );
};

export default Link;
