import { CheckBoxOutlined, Delete } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ControlledTextField } from "Components/FormComponents";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useCurrentListingContext } from "store/hooks/CurrentListingContext";
import { transparentEditField } from "styles/components/StyledTextField";
import HamburgerNavbarImageSwiper from "./HamburgerNavbarImageSwiper";

const NavbarImageSwiper: React.FC = (): JSX.Element => {
  const { images, setImages, imgIndex, setImgIndex } =
    useCurrentListingContext();

  const initialCheck = imgIndex === 0 ? true : false;
  const handleCheckBox = () => {
    setImgIndex(0);
    if (images && imgIndex >= 0 && imgIndex < images.length) {
      const selectedImage = images[imgIndex];
      const updatedImages = [
        selectedImage,
        ...images.slice(0, imgIndex),
        ...images.slice(imgIndex + 1),
      ];

      setImages(updatedImages);
    }
  };

  const handleDeleteImage = () => {
    if (images && imgIndex >= 0 && imgIndex < images.length) {
      const updatedImages = [...images];
      updatedImages.splice(imgIndex, 1);
      setImages(updatedImages);
    }
  };

  const { control, setValue, resetField, watch } = useForm({
    // resolver: yupResolver(updateUserInfoSchema),
    mode: "onChange",
    defaultValues: {
      description: images[imgIndex]?.description,
    },
  });

  const handleDescription = () => {
    if (images && imgIndex >= 0 && imgIndex < images.length) {
      const updatedImages = [...images];
      updatedImages[imgIndex] = {
        ...updatedImages[imgIndex],
        description: watch("description"),
      };
      setImages(updatedImages);
    }
  };

  useEffect(() => {
    setValue("description", images[imgIndex]?.description);
  }, [images[imgIndex]]);

  return (
    <AppBar position="fixed" sx={{ background: "transparent" }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={toolbarStyle}>
          <Box>
            <ControlledTextField
              mb={0}
              onUpdateClick={handleDescription}
              editableText
              resetField={resetField}
              control={control}
              name="description"
              sx={transparentEditField}
            />
          </Box>
          {/* Right Side */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <HamburgerNavbarImageSwiper
              initialCheck={initialCheck}
              handleCheckBox={handleCheckBox}
            />
          </Box>
          <Box
            sx={{
              gap: 2,
              display: { xs: "none", md: "flex" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                size="medium"
                icon={<CheckBoxOutlined />}
                onChange={handleCheckBox}
                checked={initialCheck}
              />
              <Typography
                component={"span"}
                color="white"
                variant="smallRegularText"
                fontWeight={300}
                fontSize="12px"
                sx={{ mr: 1 }}
              >
                This is set as main photo
              </Typography>
            </Box>

            <IconButton sx={deleteIconStyle} onClick={handleDeleteImage}>
              <Delete sx={{ color: "white.main" }} fontSize="small" />
            </IconButton>
            <FormControlLabel
              control={<Switch defaultChecked size="small" />}
              label="Public Photo"
              sx={{ mr: 0, ml: 1 }}
            />
          </Box>
          {/* End Right Side */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavbarImageSwiper;

const deleteIconStyle = {
  p: 1,
  borderRadius: 1,
  border: "1.5px solid rgba(238, 242, 255, 0.5)",
  color: "white",
  "& .MuiTouchRipple-root .MuiTouchRipple-child": {
    borderRadius: 1,
  },
};

const toolbarStyle = {
  mr: { xs: "30px", sm: "60px" },
  py: 1,
  justifyContent: "space-between",
};
