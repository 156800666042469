import { ephrownApi } from "store/services/ephrownApi";
import { IListingOfferorRequests, IUserOfferorRequest } from ".";
import {
  IListingOfferorRequestsResponse,
  IUserOfferorRequestResponse,
} from "./responseTypes";
import { IAgentOfferListing } from "features/offers/api";
import { transformAgentOfferListing } from "features/offers/api/helpers";
import { IAgentOfferListingResponse } from "features/offers/api/responseTypes";

const offerorsApi = ephrownApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserOfferorRequests: builder.query<IUserOfferorRequest, string>({
      query: (listingId) => ({
        url: `/api/private/user/offeror-request/${listingId}/details`,
      }),
      providesTags: ["userListings", "agentListings"],
      transformResponse: (response: IUserOfferorRequestResponse) =>
        response.offeror_request,
    }),

    getAgentOfferorRequests: builder.query<IAgentOfferListing[], void>({
      query: () => ({
        url: "/api/private/agent/listings/offeror_requests",
      }),
      providesTags: ["userListings", "agentListings", "offers"],
      transformResponse: (response: IAgentOfferListingResponse) =>
        response?.listings?.map(transformAgentOfferListing),
    }),

    getListingOfferorRequests: builder.query<IListingOfferorRequests[], any>({
      query: (listingId) => ({
        url: `/api/private/agent/listing/${listingId}/offeror_requests`,
      }),
      providesTags: ["userListings", "agentListings", "offers"],
      transformResponse: (response: IListingOfferorRequestsResponse) =>
        response.offeror_requests,
    }),
    retryPayment: builder.mutation({
      query: ({ listingId, body }) => ({
        url: `/api/private/user/offeror-request/${listingId}/retry-security-hold`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
  }),
});

export const {
  useGetAgentOfferorRequestsQuery,
  useGetUserOfferorRequestsQuery,
  useLazyGetUserOfferorRequestsQuery,
  useGetListingOfferorRequestsQuery,
  useRetryPaymentMutation,
} = offerorsApi;
