import { SaleType } from "features/listing/types";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import ClosedPendingWinner from "./ClosedPendingWinner";
import OpenPendingWinner from "./OpenPendingWinner";

export interface Step {
  label: string;
}

interface IProps {
  userId: number | null | undefined;
}

const PendingWinnerStatus: React.FC<IProps> = ({ userId }): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const isOpenListing = listing?.sale_type === SaleType.OPEN;

  return (
    <>
      {isOpenListing && <OpenPendingWinner userId={userId} />}
      {!isOpenListing && <ClosedPendingWinner />}
    </>
  );
};

export default PendingWinnerStatus;
