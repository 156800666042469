export const getFormattedSigningContractTime = (hours: string): string => {
  const numHours = parseInt(hours);

  if (numHours >= 24) {
    const days = Math.floor(numHours / 24);
    const remainingHours = numHours % 24;
    let formattedString = "";
    if (days > 0) {
      formattedString += `${days} Day${days > 1 ? "s" : ""}`;
    }
    if (remainingHours > 0) {
      formattedString += ` ${remainingHours} Hour${
        remainingHours > 1 ? "s" : ""
      }`;
    }
    return formattedString.trim();
  } else {
    const minutes = numHours * 60;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    let formattedString = "";
    if (hours > 0) {
      formattedString += `${hours} Hour${hours > 1 ? "s" : ""}`;
    }
    if (remainingMinutes > 0) {
      formattedString += ` ${remainingMinutes} Minute${
        remainingMinutes > 1 ? "s" : ""
      }`;
    }
    return formattedString.trim();
  }
};

export const getDurationInHours = (startDate: string, endDate: string) => {
  const startDateTime = new Date(startDate).getTime();
  const endDateTime = new Date(endDate).getTime();

  // Calculate the duration in milliseconds between the two dates
  const durationInMilliseconds = Math.abs(endDateTime - startDateTime);

  // Convert the duration to hours
  const durationInHours = durationInMilliseconds / (1000 * 60 * 60);

  // Round the duration to the nearest integer value
  return Math.round(durationInHours).toString();
};

export const formatFullName = (
  firstName: string | undefined,
  lastName: string | undefined
): string | null => {
  if (!firstName && !lastName) {
    return null;
  }
  return `${firstName || ""} ${lastName || ""}`;
};

export const formatPhone = (phoneNum: string | undefined): string | null => {
  if (!phoneNum) return null;
  if (phoneNum.includes("-")) return phoneNum;
  return `${phoneNum.slice(0, 3)}-${phoneNum.slice(3, 6)}-${phoneNum.slice(6)}`;
};
