// import { AccessAlarm, WarningAmber } from "@mui/icons-material";
// import { LoadingButton } from "@mui/lab";
// import { Button, ButtonProps, Typography } from "@mui/material";

// import { useTimer } from "react-timer-hook";
// import { ClockIcon } from "styles/icons";
// import TimerFieldIcon from "styles/icons/TimerFieldIcon";

// interface IProps {
//   expiry: Date;
// }
// const ContractDueButton: React.FC<IProps> = ({ expiry }) => {
//   const { minutes, seconds } = useTimer({
//     expiryTimestamp: expiry,
//     onExpire: () => {},
//   });

//   return (
//     <LoadingButton
//       fullWidth
//       variant="errorLight"
//       sx={{
//         pointerEvents: "none",
//         height: "43px",
//         gap: 2,
//       }}
//       startIcon={
//         <TimerFieldIcon
//           stroke="white"
//           fill="transparent"
//           sx={{ height: "1.5rem" }}
//         />
//       }
//     >

//     </LoadingButton>
//   );
// };

// export default ContractDueButton;

import { WarningAmber } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import { getTimerString } from "Components/Timers/TimerField";
import { useTimer } from "react-timer-hook";
import TimerFieldIcon from "styles/icons/TimerFieldIcon";

interface IProps {
  expiryTime: Date;
}

const ContractDueButton: React.FC<IProps> = ({ expiryTime }): JSX.Element => {
  const isExpired = expiryTime < new Date();
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: expiryTime > new Date() ? expiryTime : new Date(),
  });
  let timerString = "";
  if (!isExpired) {
    timerString = getTimerString(seconds, minutes, hours, days, 2);
  }
  return (
    <LoadingButton
      fullWidth
      variant="errorLight"
      sx={{
        pointerEvents: "none",
        height: "43px",
        gap: 2,
      }}
      startIcon={
        <TimerFieldIcon
          stroke="white"
          fill="transparent"
          sx={{ height: "1.5rem" }}
        />
      }
    >
      <Typography variant="extraSmallBoldText" component={"span"}>
        Contract Due:
      </Typography>
      <Typography
        variant="bigBoldText"
        component={"span"}
        color={!isExpired ? "" : "grey.400"}
      >
        {!isExpired ? `${timerString.trim()}` : "--"}
      </Typography>
      <WarningAmber sx={{ position: "absolute", right: "20px" }} />
    </LoadingButton>
  );
};

export default ContractDueButton;

const timerStyle = {
  justifyContent: "start",
  pointerEvents: "none",
  minWidth: 128,
  border: "1px solid transparent",
  ".MuiButton-startIcon": {
    mr: 0,
    ml: 0,
  },
};
