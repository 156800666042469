import { Grid, Typography } from "@mui/material";
import { ListingStatus } from "features/listing/types";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import { Portal } from "features/users/types";

interface IProps {
  auctionStartDate: string;
  auctionEndDate: string;
  auctionEnded: string;
  publishDate: string;
  status: ListingStatus;
  contractSigned: string;
  contractDueDate: string;
}

const FifthColumn: React.FC<IProps> = ({
  auctionStartDate,
  auctionEndDate,
  auctionEnded,
  publishDate,
  status,
  contractSigned,
  contractDueDate,
}): JSX.Element | null => {
  const { portal } = useAppSelector(selectAuth);
  const isUserPortal = portal === Portal.USER || portal === null;
  return isUserPortal
    ? userDateColumn(status, auctionEndDate, auctionStartDate)
    : agentColumn(
        status,
        auctionStartDate,
        auctionEndDate,
        auctionEnded,
        publishDate,
        contractSigned,
        contractDueDate
      );
};

const userDateColumn = (
  status: ListingStatus,
  auctionEndDate: string,
  auctionStartDate: string
) => {
  const isStartDate = status === "published";
  const auctionDateLabel = isStartDate
    ? "Auction start date"
    : "Auction end Date";
  const auctionDate = isStartDate ? auctionStartDate : auctionEndDate;

  return (
    <Grid xs={2} item sx={{ minWidth: 130 }}>
      <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
        {auctionDateLabel}
      </Typography>
      <Typography variant="smallRegularText" color={"grey.800"}>
        {auctionDate}
      </Typography>
    </Grid>
  );
};
const agentColumn = (
  status: ListingStatus,
  auctionStartDate: string,
  auctionEndDate: string,
  auctionEnded: string,
  publishDate: string,
  contractSigned: string,
  contractDueDate: string
) => {
  switch (status) {
    case ListingStatus.DRAFT:
    case ListingStatus.PUBLISHED:
      return (
        <Grid xs={2} item sx={{ minWidth: 130 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            Auction start date
          </Typography>
          <Typography variant="smallRegularText" color="grey.800">
            {auctionStartDate}
          </Typography>
        </Grid>
      );

    case ListingStatus.LIVE:
      return (
        <Grid xs={2} item sx={{ minWidth: 130 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            Auction End Date
          </Typography>
          <Typography variant="smallRegularText" color="grey.800">
            {auctionEndDate}
          </Typography>
        </Grid>
      );

    case ListingStatus.PUBLISH_SCHEDULED:
      return (
        <Grid xs={2} item sx={{ minWidth: 130 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            Publish Date
          </Typography>
          <Typography variant="smallRegularText" color="grey.800">
            {publishDate}
          </Typography>
        </Grid>
      );

    case ListingStatus.NO_WINNER:
    case ListingStatus.NO_OFFERS:
    case ListingStatus.FAILED_SALE:
    case ListingStatus.FAILED_CONTRACT:
    case ListingStatus.PENDING_WINNER:
      return (
        <Grid xs={2} item sx={{ minWidth: 130 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            Auction Ended
          </Typography>
          <Typography variant="smallRegularText" color="grey.800">
            {auctionEnded}
          </Typography>
        </Grid>
      );

    case ListingStatus.PENDING_CONTRACT:
      return (
        <Grid xs={2} item sx={{ minWidth: 130 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            Contract Due Date
          </Typography>
          <Typography variant="smallRegularText" color="grey.800">
            {contractDueDate || "--"}
          </Typography>
        </Grid>
      );

    case ListingStatus.IN_CONTRACT:
    case ListingStatus.SOLD:
      return (
        <Grid xs={2} item sx={{ minWidth: 130 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            Contract Signed:
          </Typography>
          <Typography variant="smallRegularText" color="grey.800">
            {contractSigned || "--"}
          </Typography>
        </Grid>
      );

    default:
      return null;
  }
};
export default FifthColumn;
