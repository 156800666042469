import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { ControlledTextField } from "Components/FormComponents";
import ErrorText from "Components/StyledComponents/ErrorText";
import { digitsRegex, textRegex } from "Components/Validation/regex";
import { ErrorResponse } from "features/auth/types";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useDialogContext } from "store/hooks/DialogsContext";
import { useListingDetailsStepperContext } from "store/hooks/ListingDetailsStepperContext";
import { useAddPaymentMethodMutation } from "../api/paymentApi";
import DatePayment from "./DatePayment";
import { PaymentMethodSchema } from "../schema";

interface IPaymentMethodForm {
  card_holder_name: string;
  payment_method: string;
  card_number: string;
  mm: string;
  yy: string;
  new_card_cvv: string;
  name_on_card: string;
  billing_address: string;
  zip_code: string;
}

interface IProps {
  setPaymentAdded?: (open: boolean) => void;
  isStepper?: boolean;
}

const AddPaymentForm: React.FC<IProps> = ({
  setPaymentAdded,
  isStepper = false,
}): JSX.Element => {
  const { closeDialog } = useDialogContext();
  const { setOptions } = useListingDetailsStepperContext();
  const [
    addPaymentMethod,
    { error: addPaymentError, isLoading: addPaymentLoading },
  ] = useAddPaymentMethodMutation();

  // const [
  //   updatePaymentMethod,
  //   { error: updatePaymentError, isLoading: updatePaymentLoading },
  // ] = useUpdatePaymentMethodMutation();

  const methods = useForm<IPaymentMethodForm>({
    mode: "onChange",
    resolver: yupResolver(PaymentMethodSchema),
  });

  const handleNewPaymentForm: SubmitHandler<IPaymentMethodForm> = async (
    data
  ) => {
    //TEMPORARY - PAYMENT METHOD "credit_card"
    const body = {
      payment_method: "credit_card",
      card_number: data.card_number,
      card_expiration_date: data.mm + data.yy,
      card_cvv: data.new_card_cvv,
      card_holder_name: data.card_holder_name,
      address_line_1: data.billing_address,
      zip: data.zip_code,
    };

    try {
      await addPaymentMethod({ body }).unwrap();
      // await updatePaymentMethod({
      //   listingId: listing?.id,
      //   body,
      // }).unwrap();
      // setOptions(prev=>({...prev, isAddPaymentOpen: false});
      methods.reset();
      if (setPaymentAdded) setPaymentAdded(true);
      if (!isStepper) closeDialog();
      setOptions((prev) => ({
        ...prev,
        isAddPaymentOpen: false,
      }));
    } catch (error) {
      console.error("Error adding payment method:", error);
    }
  };
  const isLoading = addPaymentLoading;
  const errorMessage = (addPaymentError as ErrorResponse)?.data?.message;
  return (
    <Grid
      component={"form"}
      onSubmit={methods.handleSubmit(handleNewPaymentForm)}
      container
      maxWidth={400}
      bgcolor={"grey.50"}
      justifyContent={"center"}
      borderRadius={3}
      mx="auto"
      p={4}
    >
      <FormProvider {...methods}>
        <Typography color={"primary.main"} variant="h6Bold">
          Add Payment Method
        </Typography>
        {errorMessage && (
          <ErrorText sx={{ justifyContent: "center" }} text={errorMessage} />
        )}
        <Grid item xs={12} mt={4}>
          <Grid container>
            <Grid item xs={12}>
              <ControlledTextField
                fullWidth
                name={"card_holder_name"}
                maxLength={50}
                control={methods.control}
                label={"Name on Card"}
                labelColor={!isStepper ? "primary.main" : "#111827"}
                regex={textRegex}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                fullWidth
                name={"card_number"}
                control={methods.control}
                label={"Card Number"}
                labelColor={!isStepper ? "primary.main" : "#111827"}
                regex={digitsRegex}
                maxLength={16}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent={"space-between"} gap={0}>
          <Grid item xs={8} md={6.5}>
            <DatePayment />
          </Grid>
          <Grid item xs={5} sm={3.5} justifySelf={"end"}>
            <ControlledTextField
              fullWidth
              mb={2}
              name={"new_card_cvv"}
              control={methods.control}
              label={"CVV"}
              placeholder="000"
              labelColor={!isStepper ? "primary.main" : "#111827"}
              regex={digitsRegex}
              maxLength={4}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={isStepper ? 12 : 7} md={isStepper ? 12 : 8}>
            <ControlledTextField
              name={"billing_address"}
              control={methods.control}
              label={"Billing Address"}
              labelColor={!isStepper ? "primary.main" : "#111827"}
              fullWidth
              maxLength={50}
            />
          </Grid>
          {!isStepper && (
            <Grid item xs={isStepper ? 12 : 5} md={isStepper ? 12 : 4}>
              <ControlledTextField
                mb={0}
                name={"zip_code"}
                control={methods.control}
                label={"Zip Code"}
                placeholder="00000"
                labelColor={!isStepper ? "primary.main" : "#111827"}
                regex={digitsRegex}
                maxLength={5}
              />
            </Grid>
          )}
        </Grid>
        {isStepper && (
          <Grid
            container
            item
            xs={12}
            alignItems={"end"}
            spacing={2}
            justifyContent={"space-between"}
          >
            <Grid item xs={4}>
              <ControlledTextField
                mb={0}
                name={"zip_code"}
                control={methods.control}
                label={"Zip Code"}
                placeholder="00000"
                labelColor={"#111827"}
                regex={digitsRegex}
                maxLength={5}
              />
            </Grid>
            <Grid item container xs={8} spacing={2}>
              <Grid item xs={6}>
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="primary"
                  loading={isLoading}
                >
                  Save
                </LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  fullWidth
                  variant="secondary"
                  onClick={() =>
                    setOptions((prev) => ({
                      ...prev,
                      isAddPaymentOpen: false,
                    }))
                  }
                >
                  Back
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isStepper && (
          <>
            <Grid container pt={2} spacing={2}>
              <Grid item xs={6}>
                <LoadingButton
                  fullWidth
                  variant="tertiary"
                  onClick={() => closeDialog()}
                >
                  Cancel
                </LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  type="submit"
                  loading={isLoading}
                  fullWidth
                  variant="primary"
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </>
        )}
      </FormProvider>
    </Grid>
  );
};

export default AddPaymentForm;
