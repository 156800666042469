import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";

type Tab = {
  label: string;
  display: boolean;
  component: string;
};

interface IProps {
  tabs: any;
}
const BasicTabs: React.FC<IProps> = ({ tabs }): JSX.Element => {
  const [activeTabValue, setActiveTabValue] = useState(tabs[0].label);
  const handleChange = (e: any, selectedTabValue: string) => {
    setActiveTabValue(selectedTabValue);
  };

  return (
    <Box sx={{ width: "100%", minHeight: 40, height: 40 }}>
      <TabContext value={activeTabValue}>
        <Box
          sx={{
            borderBottom: 1,
            height: 0,
            minHeight: 38,
            borderColor: "divider",
          }}
        >
          <TabList
            sx={tabsStyle}
            onChange={handleChange}
            aria-label="acount-settings-tabs"
            scrollButtons="auto"
          >
            {tabs.map((tab: any) => (
              <Tab key={tab.label} label={tab.label} value={tab.label} />
            ))}
          </TabList>
        </Box>

        {tabs.map((tab: any) => (
          <TabPanel key={tab.label} sx={tabPanelStyle} value={tab.label}>
            {tab.component}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

const tabsStyle = {
  "&.MuiTabs-root": {
    height: 38,
    minHeight: 38,

    ".MuiTab-root": {
      fontSize: "0.75rem",
      padding: 0,
      margin: "0 30px",
      fontWeight: 700,
      textTransform: "none",

      "&:first-of-type": {
        ml: 0,
      },
      "&.Mui-selected": {
        color: "grey.800",
      },
    },
  },
};

const tabPanelStyle = { px: 0 };

export default BasicTabs;
