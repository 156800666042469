import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface ArrowIconProps extends SvgIconProps {
  fill?: string;
}

const ArrowIcon: React.FC<ArrowIconProps> = ({
  sx,

  ...props
}) => {
  return (
    <SvgIcon
      viewBox="0 0 10 6"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.00008 5.47533L9.00674 1.46866L8.06474 0.52533L5.00008 3.592L1.93608 0.52533L0.993408 1.468L5.00008 5.47533Z" />
      </svg>
    </SvgIcon>
  );
};

export default ArrowIcon;
