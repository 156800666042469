import SvgIcon, { type SvgIconProps } from "@mui/material/SvgIcon";

const PhoneIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.82326 1.48003C4.11313 0.884885 3.05507 0.970432 2.51626 1.72421C2.37235 1.92554 2.20181 2.16568 1.90725 2.53414C0.893962 3.80166 0.893965 5.63699 1.91285 6.90001C2.66501 7.8324 3.45364 8.77322 4.33523 9.65481C5.21682 10.5364 6.15765 11.325 7.09004 12.0772C8.35306 13.0961 10.1884 13.0961 11.4559 12.0828C11.849 11.7685 12.0943 11.5973 12.3025 11.4486C13.0306 10.9286 13.1326 9.90866 12.5641 9.2178C12.1148 8.67188 11.6145 8.15104 11.1319 7.64264C10.7475 7.23766 10.1054 7.2112 9.66908 7.55966C9.55173 7.65339 9.40601 7.77692 9.11057 8.05389C7.6607 7.19236 6.78199 6.30292 5.93616 4.87948C6.2165 4.58045 6.34014 4.43431 6.43468 4.31582C6.78253 3.87983 6.75671 3.23945 6.34932 2.85849C5.85281 2.39418 5.3475 1.91937 4.82326 1.48003Z"
          fill="#EEF2FF"
        />
        <path
          d="M4.82326 1.48003C4.11313 0.884885 3.05507 0.970432 2.51626 1.72421C2.37235 1.92554 2.20181 2.16568 1.90725 2.53414C0.893962 3.80166 0.893965 5.63699 1.91285 6.90001C2.66501 7.8324 3.45364 8.77322 4.33523 9.65481C5.21682 10.5364 6.15765 11.325 7.09004 12.0772C8.35306 13.0961 10.1884 13.0961 11.4559 12.0828C11.849 11.7685 12.0943 11.5973 12.3025 11.4486C13.0306 10.9286 13.1326 9.90866 12.5641 9.2178C12.1148 8.67188 11.6145 8.15104 11.1319 7.64264C10.7475 7.23766 10.1054 7.2112 9.66908 7.55966C9.55173 7.65339 9.40601 7.77692 9.11057 8.05389C7.6607 7.19236 6.78199 6.30292 5.93616 4.87948C6.2165 4.58045 6.34014 4.43431 6.43468 4.31582C6.78253 3.87983 6.75671 3.23945 6.34932 2.85849C5.85281 2.39418 5.3475 1.91937 4.82326 1.48003Z"
          stroke="#818CF8"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default PhoneIcon;
