import { Grid } from "@mui/material";
import { RegisterToOfferWithoutDialogStyle } from "Components/Steppers/RegisterToOfferStepper";
import { TimerProgressLine } from "Components/Timers";
import dayjs from "dayjs";
import { RegisterToOfferStepper } from "features/listing";
import { ListingStatus } from "features/listing/types";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";

const PublishedListingStatus: React.FC = (): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const auctionStartDate = parseInt(listing?.auction_start_date) || 0;
  const auctionEndDate = parseInt(listing?.auction_end_date) || 0;
  const auctionEndDelay = parseInt(listing?.auction_end_delay) || 0;
  const actualEndTime = auctionEndDate + auctionEndDelay;
  const isLive = listing?.status === ListingStatus.LIVE;

  return (
    <>
      <Grid item xs={12} mb={2}>
        <TimerProgressLine
          auctionStartDate={
            isLive ? auctionStartDate : Math.floor(Date.now() / 1000)
          }
          actualEndTime={isLive ? actualEndTime : auctionStartDate}
          expiryTimestamp={dayjs
            .unix(isLive ? actualEndTime : auctionStartDate)
            .toDate()}
          hideProgress={isLive ? false : true}
        />
      </Grid>

      <RegisterToOfferStepper
        isNewRegister={true}
        dialogContentStyle={RegisterToOfferWithoutDialogStyle}
      />
    </>
  );
};

export default PublishedListingStatus;
