import * as yup from "yup";
import { priceSchema } from "./reausableSchemas";

export const SubmitClosedOfferSchema = yup.object().shape({
  offer_amount: priceSchema
    .test(
      "moreThanMortgage",
      "must be greater than the mortgage amount",
      (value, { parent }) => {
        if (value <= parent.mortgage_amount) {
          return false;
        }
        return true;
      }
    )
    .test(
      "moreThanContract",
      "must be greater than the contract amount",
      (value, { parent }) => {
        if (value <= parent.on_contract) {
          return false;
        }
        return true;
      }
    ),
  mortgage_amount: priceSchema.test(
    "lessThanPurchasePrice",
    "must not exceed the purchase price",
    (value, { parent }) => value <= parent.offer_amount
  ),
  on_contract: priceSchema.test(
    "lessThanPurchasePrice",
    "must not exceed the purchase price",
    (value, { parent }) => value <= parent.offer_amount
  ),
});
