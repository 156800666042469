import { Theme } from "@mui/material/styles";

export const lightPrimary = (theme: Theme) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
  transitionProperty: "all",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  "&:focus": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  "&:active": {
    backgroundColor: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
  },
  "&:disabled": {
    backgroundColor: theme.palette.grey[600],
    borderColor: theme.palette.grey[600],
    color: theme.palette.grey[500],
  },
});
