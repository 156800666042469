import { Grid, Typography } from "@mui/material";
import { HomeBanner } from "Components/HomeComponents";
import { Button } from "Components/StyledComponents";
import { setCurrentListing } from "store/features/listingSlice";
import { useAppDispatch } from "store/hooks";
import { useCurrentListingContext } from "store/hooks/CurrentListingContext";
const NoListingYet: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { setDrawerState } = useCurrentListingContext();
  const addListingDrawer = () => {
    dispatch(setCurrentListing(undefined));
    setDrawerState((prev) => ({
      isOpen: !prev.isOpen,
      mode: "add",
    }));
  };
  return (
    <Grid
      container
      flexDirection={"column"}
      sx={{
        textAlign: "center",
      }}
    >
      <Grid item>
        <Typography color={"primary.dark"} variant="h4Bold">
          You have no listings yet.
        </Typography>
        <Typography mt={1} mb={2} color={"grey.700"} variant="bigRegularText">
          All your recently viewed listings and listings that were
          <br /> shared with you will show here.
        </Typography>
        <Button onClick={addListingDrawer}>Add a Listing</Button>
      </Grid>
      <Grid item mt={5}>
        <HomeBanner />
      </Grid>
    </Grid>
  );
};

export default NoListingYet;
