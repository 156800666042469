import { Box, ClickAwayListener, Popper } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DateTimePicker from "Components/DatesAndTime/DateTimePicker";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import ControlledDateTimeField from "./ControlledDateTimeField";

interface IDateTimePicker {
  label?: string;
  infoText?: string;
  name: string;
  disabled?: boolean;
}

const DateTimePickerField: React.FC<IDateTimePicker> = ({
  infoText,
  name,
  label,
}): JSX.Element => {
  const { control } = useFormContext();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ControlledDateTimeField
          label={label}
          onClick={handleClick}
          name={name}
          infoText={infoText}
        />
      </LocalizationProvider>
      <Popper
        sx={{
          zIndex: 99999,
        }}
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-start"}
        disablePortal
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box sx={datePopperStyle}>
            <DateTimePicker control={control} name={name} />
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
export default DateTimePickerField;

const datePopperStyle = {
  borderRadius: 1,
  zIndex: 9999,
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
  px: 3,
  py: 1,
  bgcolor: "common.white",
};
