import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface ShareIconProps extends SvgIconProps {
  stroke?: string;
  fill?: string;
}

const ShareIcon: React.FC<ShareIconProps> = ({
  sx,
  stroke = "#6366F1",
  fill = "#EEF2FF",
  ...props
}) => {
  return (
    <SvgIcon
      viewBox="0 0 14 12"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.18151 10.2603C10.1858 9.53573 11.6246 8.30128 12.7789 6.7462C13.2192 6.15311 13.2192 5.34688 12.7789 4.75379C11.6246 3.1987 10.1858 1.96426 9.18151 1.23968C8.86633 1.01228 8.44737 1.24444 8.44737 1.63309V3.49999L5.94737 3.49999C3.94737 3.49999 0.14737 5.29999 0.947371 10.5C1.44737 9.66666 2.44737 7.99999 5.94737 7.99999H8.44737V9.8669C8.44737 10.2555 8.86633 10.4877 9.18151 10.2603Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default ShareIcon;
