import Typography from "@mui/material/Typography";
import { Button } from "../../StyledComponents";
import { useState } from "react";
import { Box, Grid } from "@mui/material";
import PaymentBox from "./PaymentBox";

const steps = ["Select master blaster campaign settings", "Create an ad group"];

interface IProps {
  setPlanSelect: React.Dispatch<React.SetStateAction<number | null>>;
}

const PaymentStepper: React.FC<IProps> = ({ setPlanSelect }): JSX.Element => {
  const [step, setStep] = useState<number>(1);

  return (
    <>
      <Box
        sx={{
          outline: "1px solid #E5E7EB",
          background:
            "linear-gradient(90deg, rgba(120,114,236,0.75) 0%, rgba(99,102,241,1) 100%);",
          borderRadius: 2,
        }}
      >
        <Grid container px={2} display="flex" alignItems="center">
          <Grid item>
            <img src={"/static/images/missile.png"} width={40} height={40} />
          </Grid>
          <Grid ml={2} item sx={{ flex: 1 }}>
            <Typography variant="bigBoldText" color={"common.white"}>
              {"Starter"}
            </Typography>
            <Typography
              component={"span"}
              variant="smallRegularText"
              color={"common.white"}
              sx={{ verticalAlign: "text-top", mr: 0.2 }}
            >
              $
            </Typography>
            <Typography
              component={"span"}
              variant="bigBoldText"
              color={"common.white"}
            >
              45
            </Typography>
            <Typography
              component={"span"}
              variant="smallRegularText"
              color={"common.white"}
            >
              {" "}
              / month
            </Typography>
          </Grid>
          <Grid justifySelf={"end"} item>
            <Button
              onClick={() => setPlanSelect(null)}
              color={"white"}
              variant="outlined"
              sx={{
                my: 3,
                border: "1px solid white",
              }}
              fullWidth
            >
              Select Plan
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <PaymentBox />
      </Grid>
    </>
  );
};

export default PaymentStepper;
