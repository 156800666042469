import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, DialogTitle, Grid, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { useGetDenialReasonsQuery } from "features/listing/api/agentApi";
import { IListingDetails } from "features/listing/types";
import {
  formatFullName,
  formatPhone,
} from "features/listing/utils/listingsUtils";
import {
  IAgentOfferListing,
  IOfferData,
  useSetOfferDeclineMutation,
} from "features/offers/api";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDialogContext } from "store/hooks/DialogsContext";
import {
  ControlledDeclineDropdown,
  ControlledTextField,
} from "../FormComponents";
import ControlledCheckbox from "../FormComponents/ControlledCheckbox";
import { Divider } from "../StyledComponents";
import { myRequestsDeclineSchema } from "../Validation";
import DialogContainer from "./DialogContainer";

interface IMyRequestsDeclineForm {
  denial_reason_name: string;
  denial_notes: string;
  blocked: boolean;
}

interface IProps {
  request: IOfferData;
  listing: IAgentOfferListing | IListingDetails;
}

const DeclineDialog: React.FC<IProps> = ({ request, listing }): JSX.Element => {
  const [setOfferDecline] = useSetOfferDeclineMutation();
  const { data, isLoading } = useGetDenialReasonsQuery();
  const fullName = formatFullName(request?.firstName, request?.lastName);
  const { handleSubmit, control } = useForm<IMyRequestsDeclineForm>({
    mode: "onChange",
    resolver: yupResolver(myRequestsDeclineSchema),
  });
  const handleDeclineForm: SubmitHandler<IMyRequestsDeclineForm> = (
    data: IMyRequestsDeclineForm
  ) => {
    setOfferDecline({
      listingId: listing?.id,
      offerorId: request?.id,
      body: data,
    });
  };
  const { closeDialog } = useDialogContext();

  return (
    <ConditionalWrapper isLoading={isLoading}>
      <DialogContainer hideExit sx={declineDialogContainerStyle}>
        <Box onSubmit={handleSubmit(handleDeclineForm)} component="form">
          <DialogTitle
            sx={{ pb: 2 }}
            color="error.light"
            variant="h5Bold"
            align="center"
          >
            Decline Request
          </DialogTitle>
          <DialogContent sx={{ pb: 2, px: { xs: 1, sm: 2 } }}>
            <Grid container pb={2} justifyContent={"center"}>
              <Grid item textAlign={"center"} pb={2}>
                <Typography
                  component={"span"}
                  color="grey.800"
                  variant="extraSmallBoldText"
                >
                  {listing?.full_address}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent={"center"}
                textAlign={"center"}
                gap={2}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <Typography variant="extraSmallBoldText" color="grey.800">
                  {fullName}
                </Typography>
                <Typography
                  sx={userEmailStyle}
                  variant="extraSmallRegularText"
                  color="grey.700"
                >
                  {request?.userEmail}
                </Typography>
                <Typography variant="extraSmallRegularText" color="grey.700">
                  {formatPhone(request?.userPhoneNumber)}
                </Typography>
              </Grid>
            </Grid>
            <Divider
              sx={{ my: 1, borderColor: "grey.600" }}
              orientation={"horizontal"}
            />
            <Grid
              container
              flexDirection={"column"}
              alignItems={"center"}
              px={{ sm: 4 }}
            >
              <ControlledDeclineDropdown
                control={control}
                items={data?.denial_reasons || []}
                name={"denial_reason_name"}
              />
              <ControlledTextField
                sx={{ my: 1 }}
                multiline
                minRows={4}
                fullWidth
                control={control}
                name={"denial_notes"}
                placeholder="Additional Notes:"
              />
              <Grid container flexDirection={"column"}>
                <ControlledCheckbox
                  name="blocked"
                  control={control}
                  label={
                    <Typography
                      color="common.black"
                      component={"span"}
                      variant={"smallRegularText"}
                    >
                      Block this user from Registering on this Listing again
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", pt: 0, px: 4 }}>
            <LoadingButton
              sx={actionButtonsStyle}
              variant="tertiary"
              onClick={() => closeDialog()}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              type="submit"
              sx={actionButtonsStyle}
              variant="errorLight"
            >
              Decline
            </LoadingButton>
          </DialogActions>
        </Box>
      </DialogContainer>
    </ConditionalWrapper>
  );
};

export default DeclineDialog;

const actionButtonsStyle = {
  width: 192,
  mx: { xs: 0, sm: 2 },
};

const userEmailStyle = {
  px: { xs: 0, sm: 2 },
  borderRight: "1px solid",
  borderLeft: "1px solid",
  borderColor: "grey.600",
  borderWidth: { xs: 0, sm: 1 },
};

const declineDialogContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 800,

    "@media (max-width: 601px)": {
      width: "90%",
      maxHeight: "90%",
    },
  },
};
