import { IAdditionalPhoto } from "features/listing/types";
import { ReactImageGalleryItem } from "react-image-gallery";
import { ExtendedReactImageGalleryItem } from "store/hooks/CurrentListingContext";

export const convertToSwiperItems = (
  mainPhoto: string,
  additionalPhotos: IAdditionalPhoto[]
): ExtendedReactImageGalleryItem[] => {
  if (!mainPhoto) return [];
  const convertedMainPhoto = {
    id: 1,
    original: mainPhoto,
    thumbnail: mainPhoto,
    originalAlt: "main photo",
    thumbnailAlt: "main photo",
    originalHeight: 414,
    originalWidth: 690,
    thumbnailHeight: 80,
    thumbnailWidth: 80,
    description: "Main Photo",
    isNew: false,
  };

  const convertedAdditionalPhotos =
    additionalPhotos?.map((photo, idx) => {
      const id = idx + 2;
      return {
        id,
        original: photo.photo_url,
        thumbnail: photo.photo_url,
        description: photo.description,
        originalAlt: photo.photo_name,
        thumbnailAlt: photo.photo_name,
        originalHeight: 414,
        originalWidth: 690,
        thumbnailHeight: 80,
        thumbnailWidth: 80,
        isNew: false,
      };
    }) ?? [];

  return [convertedMainPhoto, ...convertedAdditionalPhotos];
};
