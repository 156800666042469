import { Grid, Typography } from "@mui/material";
import {
  formatFullName,
  formatPhone,
} from "features/listing/utils/listingsUtils";
import { IOptions } from "store/hooks/ListingDetailsStepperContext";
import { containerCardStyle } from "./RequestOfferOrDetails";
import {
  IOfferData,
  TListingOffer,
  formatOfferData,
} from "features/offers/api";
import { IListingOfferorRequests } from "../api";

interface IProps {
  options: IOptions;
  offer?: TListingOffer | IListingOfferorRequests;
}

const OfferorAttorneyCard: React.FC<IProps> = ({
  options,
  offer,
}): JSX.Element => {
  const offerData: IOfferData | null = offer ? formatOfferData(offer) : null;
  const attorneyName = offerData
    ? formatFullName(offerData?.attorneyFirstName, offerData?.attorneyLastName)
    : formatFullName(
        options.offerorRequestData?.attorney_first_name,
        options.offerorRequestData?.attorney_last_name
      );
  const attorneyNumber = offerData
    ? formatPhone(offerData?.attorneyNumber)
    : formatPhone(options.offerorRequestData?.attorney_number);
  const attorneyEmail = offerData
    ? offerData?.attorneyEmail
    : options.offerorRequestData?.attorney_email;
  return (
    <Grid item xs={12} py={1} sx={containerCardStyle}>
      <Typography variant="extraSmallBoldText" color="grey.800">
        Attorney Information:
      </Typography>
      <Grid container mt={1}>
        <Typography
          flexBasis={"80px"}
          variant="extraSmallRegularText"
          color="grey.500"
        >
          Name:
        </Typography>
        <Typography sx={attorneyValueStyle}>{attorneyName}</Typography>
      </Grid>
      <Grid container>
        <Typography
          flexBasis={"80px"}
          variant="extraSmallRegularText"
          color="grey.500"
        >
          Phone:
        </Typography>

        <Typography sx={attorneyValueStyle}>{attorneyNumber}</Typography>
      </Grid>
      <Grid container>
        <Typography
          flexBasis={"80px"}
          variant="extraSmallRegularText"
          color="grey.500"
        >
          Email:
        </Typography>

        <Typography sx={attorneyValueStyle}>{attorneyEmail}</Typography>
      </Grid>
    </Grid>
  );
};

export default OfferorAttorneyCard;

const attorneyValueStyle = {
  fontSize: "0.6875rem",
  fontWeight: 500,
  color: "grey.800",
  flex: 1,
};
