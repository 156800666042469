import { Grid } from "@mui/material";
import { AutoComplete } from "Components/FormComponents";
import FilterDropdown from "Components/StyledComponents/FilterDropdown";
import { TListingOffer } from "features/offers/api";
import { useEffect, useState } from "react";

interface IProps {
  offers: TListingOffer[] | null;
  setFilteredList: React.Dispatch<React.SetStateAction<TListingOffer[] | null>>;
}
const SearchPanel: React.FC<IProps> = ({
  offers,
  setFilteredList,
}): JSX.Element => {
  const [userFilter, setUserFilter] = useState("All");
  const [sortValue, setSortValue] = useState("highToLow");

  const handleSort = (sortValue: string) => {
    setSortValue(sortValue);
  };
  useEffect(() => {
    const filteredAndSortedList = offers?.filter((item: TListingOffer) => {
      return userFilter === "All" || item.user.first_name === userFilter;
    });
    if (sortValue === "highToLow") {
      filteredAndSortedList?.sort(
        (a: TListingOffer, b: TListingOffer) => b.offer_amount - a.offer_amount
      );
    } else if (sortValue === "lowToHigh") {
      filteredAndSortedList?.sort(
        (a: TListingOffer, b: TListingOffer) => a.offer_amount - b.offer_amount
      );
    } else if (sortValue === "user") {
      filteredAndSortedList?.sort((a: TListingOffer, b: TListingOffer) =>
        a.user.first_name.localeCompare(b.user.first_name)
      );
    } else if (sortValue === "recent") {
      filteredAndSortedList?.sort(
        (a: TListingOffer, b: TListingOffer) => b.id - a.id
      );
    }

    setFilteredList(filteredAndSortedList ?? []);
  }, [userFilter, sortValue, offers !== null]);

  const userOptions = Array.from(
    new Set(["All", ...(offers?.map(({ user }) => user.first_name) ?? [])])
  );

  return (
    <Grid container spacing={2} justifyContent={"space-between"}>
      <Grid item xs={7} md={5}>
        <AutoComplete
          label="Search user..."
          list={userOptions}
          onChange={(value) => setUserFilter(value)}
          value={userFilter}
        />
      </Grid>

      <Grid item xs={7} md={5}>
        <FilterDropdown
          options={sortOptions}
          label="Sort by:"
          onChange={(value) => handleSort(value)}
          value={sortValue}
        />
      </Grid>
    </Grid>
  );
};

export default SearchPanel;

const sortOptions = [
  { label: "High to Low", value: "highToLow" },
  { label: "Low to High", value: "lowToHigh" },
  { label: "User", value: "user" },
  { label: "Recent", value: "recent" },
];
