import { LoadingButton } from "@mui/lab";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { ViewMorePhotos } from "Components/Collapses";
import ShowMoreText from "Components/Collapses/ShowMoreText";
import TextIcon from "Components/Fields/TextIcon";
import { useGetAgentListingDetailsQuery } from "features/listing/api/agentApi";
import { Link, useParams } from "react-router-dom";
import { ContractIcon, HomeIcon } from "styles/icons";
import { reportBtnStyle, rowsKeysStyle } from "..";
import { handleOpenPDF } from "features/helpers";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import WaiveRequiredList from "Components/Contingencies/WaiveRequiredList";
import { formatToMMM } from "Components/DatesAndTime/helpers";
import TextInfo from "Components/Fields/TextInfo";
import { SaleType } from "features/listing/types";
import { Portal } from "features/users/types";
import { selectAuth } from "store/features/authSlice";
import { listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import {
  CalendarPrimaryIcon,
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  ListTabIcon,
  SaleTypeIcon,
} from "styles/icons";
import { formatUSD } from "utils/helpers";

const AgentListingDetailsTab: React.FC = (): JSX.Element => {
  const itemId = useParams().itemId || "";
  const { listing: agentListing } = useAppSelector(listingState);
  const { portal } = useAppSelector(selectAuth);
  const { data: listingData, isLoading } =
    useGetAgentListingDetailsQuery(itemId);
  const listing = agentListing || listingData;
  const formatStartDate = formatToMMM(listing?.auction_start_date || "");
  const formatEndDate = formatToMMM(listing?.auction_end_date || "");
  const isReserved = listing?.reserved;
  const isAutoWinner = listing?.automatic_winner;
  const isOpenListing = listing?.sale_type === SaleType.OPEN;

  return (
    <ConditionalWrapper isLoading={isLoading}>
      <Box sx={{ my: 2 }}>
        <TextIcon
          text="Listing Details"
          variant="bigBoldText"
          fontColor="primary"
          icon={<HomeIcon stroke="#6366F1" />}
        />
      </Box>
      <Grid container rowGap={2}>
        <Grid container>
          <Typography sx={rowsKeysStyle}>Address:</Typography>
          <Typography variant="smallBoldText" color="grey.800">
            {`${listingData?.address_line_1} ${listingData?.address_line_2}`}
            <br />
            {`${listingData?.city}, ${listingData?.state} ${listingData?.zip}`}
          </Typography>
        </Grid>
        <Grid container>
          <Typography sx={rowsKeysStyle}>Description:</Typography>
          <ShowMoreText text={listingData?.description} />
        </Grid>
        <Grid container>
          <Grid item sx={rowsKeysStyle}>
            <Typography variant="extraSmallRegularText" color="grey.500">
              Listing Photos:
            </Typography>
          </Grid>
          <Grid item container gap={1} flex={1}>
            <ViewMorePhotos photosList={listingData?.additional_photos} />
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3, borderColor: "grey.600" }} />
      <Grid container rowGap={2}>
          <Grid container>
            <Typography sx={rowsKeysStyle}>Sale Type:</Typography>
            <Grid item display="flex" gap={1}>
              <SaleTypeIcon sx={{ fontSize: "1.25rem" }} />
              <TextInfo
                text={isOpenListing ? "Open" : "Closed"}
                variant="extraSmallBoldText"
                fontColor={"primary.main"}
                infoText={
                  isOpenListing
                    ? "Offers are visible to other users"
                    : "Other user's offers will not be visible"
                }
              />
            </Grid>
          </Grid>
          
          <Grid container>
            <Typography sx={rowsKeysStyle}>Property Type:</Typography>
            <Typography
              variant="extraSmallRegularText"
              color="primary.dark"
            >
              {listing?.property_type}
            </Typography>
          </Grid>
          <Grid container>
            <Typography sx={rowsKeysStyle}>Starting Price:</Typography>
            <Typography variant="h6Bold" color="primary.dark">
              {formatUSD(listing?.price)}
            </Typography>
          </Grid>

          <Grid container>
            <Typography sx={rowsKeysStyle}>Offer Increment:</Typography>
            <TextInfo
              text={formatUSD(listing?.offer_increment)}
              variant="extraSmallBoldText"
              infoText="Minimum increment amount for counteroffers."
            />
          </Grid>

          <Grid container>
            <Typography sx={rowsKeysStyle}>Auction Start Date:</Typography>
            <TextIcon
              text={formatStartDate}
              fontColor="grey.800"
              variant="extraSmallRegularText"
              icon={<CalendarPrimaryIcon />}
            />
          </Grid>
          <Grid container>
            <Typography sx={rowsKeysStyle}>Auction End Date:</Typography>
            <TextIcon
              text={formatEndDate}
              fontColor="grey.800"
              variant="extraSmallRegularText"
              icon={<CalendarPrimaryIcon />}
            />
          </Grid>
          {isReserved && (
            <Grid container>
              <Typography sx={rowsKeysStyle}>Reserved Sale:</Typography>
              <Typography variant="extraSmallRegularText" color={"grey.800"}>
                {formatUSD(listing?.reserved_amount)}
              </Typography>
            </Grid>
          )}
          {!isOpenListing && (
            <Grid container>
              <Typography sx={rowsKeysStyle}>Automatic Winner:</Typography>
              <Grid item lineHeight={0}>
                {isAutoWinner ? (
                  <CheckboxCheckedIcon />
                ) : (
                  <CheckboxUncheckedIcon />
                )}
              </Grid>
            </Grid>
          )}
          {listing?.contingencies && listing?.contingencies?.length > 0 && (
            <Grid container>
              <Typography sx={rowsKeysStyle}>
                Required Waived Contingencies:
              </Typography>
              <WaiveRequiredList contingencies={listing?.contingencies} />
            </Grid>
          )}
        </Grid>
        <Grid container>
            <Typography sx={rowsKeysStyle}>Security Hold:</Typography>
            <TextInfo
              text={formatUSD(listing?.security_hold)}
              variant="extraSmallBoldText"
              infoText="Penalty if the winning offeror fails to sign contract"
            />
          </Grid>
        <Divider sx={{ my: 2 }} />
      <Grid container mb={1} pl={{ rowGap: 8, sm: 15, md: 20, lg: 26.75 }}>
        <Grid item>
          <Link to="#" onClick={() => handleOpenPDF(listingData?.title_report)}>
            <LoadingButton
              size="medium"
              variant="secondary"
              sx={{ ...reportBtnStyle, mr: 2 }}
              startIcon={
                <ContractIcon stroke={"#6366f1"} sx={{ width: "1rem" }} />
              }
            >
              Title Report
            </LoadingButton>
          </Link>
          <Link
            to="#"
            onClick={() => handleOpenPDF(listingData?.inspection_report)}
          >
            <LoadingButton
              sx={reportBtnStyle}
              variant="secondary"
              startIcon={
                <ContractIcon stroke={"#6366f1"} sx={{ width: "1rem" }} />
              }
            >
              Inspection Report
            </LoadingButton>
          </Link>
        </Grid>
      </Grid>
    </ConditionalWrapper>
  );
};

export default AgentListingDetailsTab;

export const moreLinkStyle = {
  fontSize: "0.75rem",
  color: "info.main",
  textDecoration: "underline",
  cursor: "pointer",
};
