import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const SearchIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 13 13"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9062 11.4922L9.07031 8.65625C9 8.60938 8.92969 8.5625 8.85938 8.5625H8.55469C9.28125 7.71875 9.75 6.59375 9.75 5.375C9.75 2.70312 7.54688 0.5 4.875 0.5C2.17969 0.5 0 2.70312 0 5.375C0 8.07031 2.17969 10.25 4.875 10.25C6.09375 10.25 7.19531 9.80469 8.0625 9.07812V9.38281C8.0625 9.45312 8.08594 9.52344 8.13281 9.59375L10.9688 12.4297C11.0859 12.5469 11.2734 12.5469 11.3672 12.4297L11.9062 11.8906C12.0234 11.7969 12.0234 11.6094 11.9062 11.4922ZM4.875 9.125C2.78906 9.125 1.125 7.46094 1.125 5.375C1.125 3.3125 2.78906 1.625 4.875 1.625C6.9375 1.625 8.625 3.3125 8.625 5.375C8.625 7.46094 6.9375 9.125 4.875 9.125Z"
          fill="#6B7280"
        />
      </svg>
    </SvgIcon>
  );
};

export default SearchIcon;
