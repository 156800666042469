import { DialogName, useDialogContext } from "store/hooks/DialogsContext";
import {
  AcceptDialog,
  DeclineDialog,
  DeleteDialog,
  PublishStepperDialog,
  UpdatePublishDateDialog,
  RegisterToOfferStepperDialog,
  ChangePasswordDialog,
  ChangeEmailDialog,
  EditPaymentDialog,
  DeletePaymentDialog,
  OfferorDetailsDialog,
  CVVDialog,
  SaleEndedDialog,
  ConfirmDialog,
  AddMembershipDialog,
  UpdateStatusDialog,
  ConfirmVoidSaleDialog,
  ConfirmVoidContractDialog,
  ImagesDialog,
  ShareDirectDialog,
  EmailVerificationDialog,
  UpdatePaymentDialog,
  AgentOfferorDetailsDialog,
  SubmitOfferDialog,
} from ".";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BlockUnverifyDialog from "../../store/features/BlockUnverifyDialog";
import AddNewPaymentDialog from "Components/Dialogs/AddNewPaymentDialog";
import AcceptOfferDialog from "./AcceptOfferDialog";
import CVVRetryDialog from "./CVVRetryDialog";

const DialogLookup: { [key in DialogName]: React.FC<any> } = {
  Accept: AcceptDialog,
  Decline: DeclineDialog,
  PublishStepper: PublishStepperDialog,
  RegisterToOfferStepper: RegisterToOfferStepperDialog,
  CVV: CVVDialog,
  Delete: DeleteDialog,
  SaleEnded: SaleEndedDialog,
  UpdatePublishDate: UpdatePublishDateDialog,
  ChangeEmail: ChangeEmailDialog,
  ChangePassword: ChangePasswordDialog,
  EditPayment: EditPaymentDialog,
  DeletePayment: DeletePaymentDialog,
  ShareDirect: ShareDirectDialog,
  Confirm: ConfirmDialog,
  AddMembership: AddMembershipDialog,
  OfferorDetails: OfferorDetailsDialog,
  AgentOfferorDetails: AgentOfferorDetailsDialog,
  UpdateStatus: UpdateStatusDialog,
  ConfirmVoidSale: ConfirmVoidSaleDialog,
  ConfirmVoidContract: ConfirmVoidContractDialog,
  Images: ImagesDialog,
  EmailVerification: EmailVerificationDialog,
  BlockUnverify: BlockUnverifyDialog,
  AddNewPayment: AddNewPaymentDialog,
  UpdatePayment: UpdatePaymentDialog,
  SubmitOffer: SubmitOfferDialog,
  AcceptOffer: AcceptOfferDialog,
  CVVRetry: CVVRetryDialog,
};
const DialogManager: React.FC = (): JSX.Element | null => {
  const { dialog, closeDialog } = useDialogContext();
  const location = useLocation();

  useEffect(() => {
    closeDialog();
  }, [location]);

  if (!dialog || dialog.name === null) return null;

  const Dialog = DialogLookup[dialog.name];
  return <Dialog {...dialog.props} />;
};

export default DialogManager;
