import { IContingencySettings } from "features/offers/api/apiTypes";
import { ephrownApi } from "store/services/ephrownApi";
import { IContingencyResponse } from "./responseTypes";

const contingencyApi = ephrownApi.injectEndpoints({
  endpoints: (build) => ({
    getSettingsContingencies: build.query<IContingencySettings[], boolean>({
      query: (getArchived) => ({
        url: `/api/private/agent/contingencies?archived=${getArchived}`,
        method: "GET",
      }),
      providesTags: ["contingencies"],
      transformResponse: (response: IContingencyResponse) =>
        response.contingencies,
    }),
    addContingency: build.mutation<any, string>({
      query: (name) => ({
        url: "/api/private/agent/contingency/add",
        method: "POST",
        body: { name },
      }),
      invalidatesTags: ["contingencies"],
    }),
    editContingency: build.mutation<number, { id: string; name: string }>({
      query: ({ id, name }) => ({
        url: `/api/private/agent/contingency/${id}/edit`,
        method: "PUT",
        body: { name },
      }),
      invalidatesTags: ["contingencies"],
    }),
    deleteContingency: build.mutation<any, string>({
      query: (contingencyId) => ({
        url: `/api/private/agent/contingency/${contingencyId}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["contingencies"],
    }),
    archiveContingency: build.mutation<any, string>({
      query: (contingencyId) => ({
        url: `/api/private/agent/contingency/${contingencyId}/archive`,
        method: "PUT",
      }),
      invalidatesTags: ["contingencies"],
    }),
    unArchiveContingency: build.mutation<number, string>({
      query: (contingencyId) => ({
        url: `/api/private/agent/contingency/${contingencyId}/unarchive`,
        method: "PUT",
      }),
      invalidatesTags: ["contingencies"],
    }),
  }),
});

export const {
  useGetSettingsContingenciesQuery,
  useAddContingencyMutation,
  useDeleteContingencyMutation,
  useArchiveContingencyMutation,
  useUnArchiveContingencyMutation,
  useEditContingencyMutation,
} = contingencyApi;
