declare module "@mui/material/styles" {
  interface TypographyVariants {
    h4Bold: React.CSSProperties;
    h5Bold: React.CSSProperties;
    h6Bold: React.CSSProperties;
    bigRegularText: React.CSSProperties;
    bigBoldText: React.CSSProperties;
    regularText: React.CSSProperties;
    boldText: React.CSSProperties;
    smallRegularText: React.CSSProperties;
    smallBoldText: React.CSSProperties;
    extraSmallRegularText: React.CSSProperties;
    extraSmallBoldText: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h4Bold: React.CSSProperties;
    h5Bold: React.CSSProperties;
    h6Bold: React.CSSProperties;
    bigRegularText: React.CSSProperties;
    bigBoldText: React.CSSProperties;
    regularText: React.CSSProperties;
    boldText: React.CSSProperties;
    smallRegularText: React.CSSProperties;
    smallBoldText: React.CSSProperties;
    extraSmallRegularText: React.CSSProperties;
    extraSmallBoldText: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h4Bold: true;
    h5Bold: true;
    h6Bold: true;
    bigRegularText: true;
    bigBoldText: true;
    regularText: true;
    boldText: true;
    smallRegularText: true;
    smallBoldText: true;
    extraSmallRegularText: true;
    extraSmallBoldText: true;
  }
}

export type ExtendedTypographyVariant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "caption"
  | "button"
  | "overline"
  | "h4Bold"
  | "h5Bold"
  | "h6Bold";

export const typography = {
  fontFamily: ["poppins"].join(","),
  h1: {
    fontSize: "3.5rem",
    lineHeight: "4.375rem",
    fontWeight: 500,
  },
  h2: {
    fontSize: "3rem",
    lineHeight: "3.75rem",
    fontWeight: 500,
  },
  h3: {
    fontSize: "2.5rem",
    lineHeight: "3.125rem",
    fontWeight: 700,
  },
  h4: {
    fontSize: "2rem",
    lineHeight: "2.5rem",
    fontWeight: 500,
  },
  h4Bold: {
    fontFamily: "poppins",
    fontSize: "2rem",
    lineHeight: "2.5rem",
    fontWeight: 700,
  },
  h5: {
    fontSize: "1.5rem",
    lineHeight: "1.875rem",
    fontWeight: 500,
  },
  h5Bold: {
    fontFamily: "poppins",
    fontSize: "1.5rem",
    lineHeight: "1.875rem",
    fontWeight: 700,
  },
  h6: {
    fontSize: "1.25rem",
    lineHeight: "1.563rem",
    fontWeight: 500,
  },
  h6Bold: {
    fontFamily: "poppins",
    fontSize: "1.25rem",
    lineHeight: "1.563rem",
    fontWeight: 700,
  },
  bigRegularText: {
    fontFamily: "poppins",
    fontSize: "1.125rem",
    lineHeight: "1.563rem",
    fontWeight: 400,
  },
  bigBoldText: {
    fontFamily: "poppins",
    fontSize: "1.125rem",
    lineHeight: "1.563rem",
    fontWeight: 700,
  },
  regularText: {
    fontFamily: "poppins",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    fontWeight: 400,
  },
  boldText: {
    fontFamily: "poppins",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    fontWeight: 700,
  },
  smallRegularText: {
    fontFamily: "poppins",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    fontWeight: 400,
  },
  smallBoldText: {
    fontFamily: "poppins",
    fontSize: "0.875rem",
    lineHeight: "1rem",
    fontWeight: 700,
  },
  extraSmallRegularText: {
    fontFamily: "poppins",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    fontWeight: 400,
  },
  extraSmallBoldText: {
    fontFamily: "poppins",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    fontWeight: 700,
  },
  body1: {
    fontSize: "1rem",
    lineHeight: "1rem",
    fontWeight: 400,
  },
};

export const MuiTypography = {
  defaultProps: {
    variantMapping: {
      h1Bold: "h1",
      h4Bold: "h4",
      h5Bold: "h5",
      h6Bold: "h6",
      bigRegularText: "p",
      bigBoldText: "p",
      regularText: "p",
      boldText: "p",
      smallRegularText: "p",
      smallBoldText: "p",
      extraSmallRegularText: "p",
      extraSmallBoldText: "p",
    },
  },
};
