import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const RegisterToOfferIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 31 32"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="31"
        height="32"
        viewBox="0 0 31 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.6688 21.306C26.4675 20.9002 27.1834 20.43 27.7917 19.8941C28.8862 18.9299 29.582 17.6057 29.7475 16.1723C29.913 14.7388 29.5366 13.2956 28.6896 12.116C27.8426 10.9364 26.5838 10.1022 25.1516 9.77135C25.0527 9.74241 24.9642 9.68633 24.8968 9.60973C24.8293 9.53313 24.7856 9.43921 24.7708 9.33905C24.5937 7.43671 23.7568 5.65138 22.3996 4.28077C21.0424 2.91017 19.247 2.03714 17.3127 1.80728C15.3784 1.57742 13.4222 2.00462 11.7703 3.01763C10.1184 4.03065 8.87064 5.56825 8.23513 7.37403C8.19308 7.45823 8.12733 7.52888 8.04564 7.57762C7.96396 7.62637 7.86975 7.65118 7.77414 7.64913C6.8529 7.70977 5.95287 7.94772 5.12543 8.34937C4.29798 8.75103 3.55934 9.30853 2.95166 9.99005C1.72441 11.3664 1.10512 13.1644 1.23004 14.9885C1.35496 16.8125 2.21386 18.5132 3.61778 19.7164C4.21297 20.2265 4.91364 20.6835 5.69604 21.0861"
          stroke="#CAD0D9"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.44629 7.65503C10.1645 7.65503 12.5955 9.44668 13.3623 11.0835"
          stroke="#CAD0D9"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5 30.2534L15.5 17.9619"
          stroke="#818CF8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.1915 22.0334C18.7659 20.2122 17.795 19.3647 16.3479 18.2563C15.8359 17.8641 15.1642 17.8641 14.6522 18.2563C13.2051 19.3647 12.2341 20.2122 10.8086 22.0334"
          stroke="#818CF8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default RegisterToOfferIcon;
