import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const HomeIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.75 8.47015L6.24259 2.09875C6.64151 1.636 7.35849 1.636 7.75741 2.09875L13.25 8.47015"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.67303 6.2446L6.24114 2.0876C6.6402 1.62268 7.35971 1.62268 7.75876 2.0876L11.3269 6.2446L11.5307 7.69855C11.7143 9.00874 11.7053 10.3387 11.504 11.6463C11.4021 12.308 10.8327 12.7964 10.1632 12.7964H3.83667C3.16719 12.7964 2.59782 12.308 2.49592 11.6463C2.29457 10.3387 2.28557 9.00874 2.46922 7.69855L2.67303 6.2446Z"
          fill="#EEF2FF"
        />
        <path
          d="M2.67303 6.2446L6.24114 2.0876C6.6402 1.62268 7.35971 1.62268 7.75876 2.0876L11.3269 6.2446L11.5307 7.69855C11.7143 9.00874 11.7053 10.3387 11.504 11.6463C11.4021 12.308 10.8327 12.7964 10.1632 12.7964H3.83667C3.16719 12.7964 2.59782 12.308 2.49592 11.6463C2.29457 10.3387 2.28557 9.00874 2.46922 7.69855L2.67303 6.2446Z"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M7.00001 8.08893C7.921 8.08893 8.66762 8.83554 8.66762 9.75654V12.7964H5.3324V9.75654C5.3324 8.83554 6.07901 8.08893 7.00001 8.08893Z"
          fill="white"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default HomeIcon;
