import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import { useListingDetailsStepperContext } from "store/hooks/ListingDetailsStepperContext";

const AfterSubmitStep: React.FC = (): JSX.Element => {
  const {
    resetStepsToZero,
    submitError: { registerError },
  } = useListingDetailsStepperContext();
  const title = registerError
    ? "Verification Not Submitted"
    : "Verification Submitted!";

  const subtitle =
    registerError ??
    "Woohoo 🎉! Your verification was successfully submitted. You are ready to continue on to the next step and start biding!";

  const image = {
    alt: registerError
      ? "holding a phone with confetti-like celebration"
      : "black failed text inside red circle",
    url: registerError
      ? "/static/images/verificationFailed.svg"
      : "/static/images/verificationSubmitted.svg",
  };
  return (
    <Grid item>
      <Grid item textAlign={"center"} my={4}>
        <Typography color="grey.800" variant="bigBoldText">
          {title}
        </Typography>
        <Typography color="grey.500" variant="extraSmallRegularText">
          {subtitle}
        </Typography>
      </Grid>
      <Grid container flexDirection={"column"} gap={1}>
        <Box
          component="img"
          alt={image.alt}
          src={image.url}
          width={"100%"}
          height={187}
        />

        {registerError && (
          <LoadingButton
            onClick={() => resetStepsToZero()}
            sx={{ alignSelf: "center", mt: 4 }}
            variant="success"
          >
            Try Again
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  );
};

export default AfterSubmitStep;
