import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, color }: any) => ({
    height: 26,
    borderRadius: theme.shape.borderRadius * 8,
    backgroundColor:
      color === "success"
        ? "#DAF9EA"
        : color === "error"
        ? "#FDE5EC"
        : theme.palette.secondary.main,
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor:
        color === "success"
          ? theme.palette.success.main
          : color === "error"
          ? theme.palette.error.light
          : theme.palette.primary.main,
    },

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 5,

    "&::before": {
      content: "''",
      position: "relative",

      zIndex: 1,
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: `url("/static/icons/TimerIcon.svg")`,
      backgroundSize: "cover",
    },

    "&:after": {
      content: "attr(data-content)",
      position: "relative",
      justifySelf: "center",
      alignSelf: "center",
      color: "black",
      zIndex: 1,
      fontSize: "0.75rem",
      fontWeight: 400,
      fontFamily: "Poppins",
    },
  })
);
