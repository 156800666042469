import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { formatToMMYYYY } from "Components/DatesAndTime/helpers";
import { HelperText } from "Components/StyledComponents";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { useGetPaymentMethodsQuery } from "features/payments/api/paymentApi";
import { checkCardExpiredDate } from "../helpers";
import { useDialogContext } from "store/hooks/DialogsContext";
import { DeleteIcon, EditPencilIcon } from "styles/icons";

const PaymentMethodList: React.FC = (): JSX.Element => {
  const { data: paymentMethods, isLoading: isGetLoading } =
    useGetPaymentMethodsQuery();
  const { openDialog } = useDialogContext();

  return (
    <ConditionalWrapper isLoading={isGetLoading}>
      <Grid container gap={1.25} flexDirection={"row"}>
        {paymentMethods?.map((paymentMethod) => {
          const formattedExpDate = formatToMMYYYY(paymentMethod.exp);
          const isexpired = checkCardExpiredDate(formattedExpDate);

          return (
            <Grid container key={paymentMethod.id}>
              <Grid container item xs={10} md={11} sx={itemContainerStyle}>
                <Grid item xs={5}>
                  <Typography
                    color="grey.500"
                    variant={"smallBoldText"}
                    fontWeight={600}
                  >
                    Credit Card ending in {paymentMethod.last_four_digits}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color="grey.500"
                    variant={"smallBoldText"}
                    fontWeight={600}
                  >
                    Name on Card: {paymentMethod.name_on_card}
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  xs={3}
                  textAlign={"right"}
                  flexDirection={"column"}
                  alignItems={"end"}
                >
                  <Typography
                    ml={{ xs: 1, md: 2 }}
                    minWidth={300}
                    component={"span"}
                    color="grey.500"
                    variant={"smallBoldText"}
                    fontWeight={500}
                  >
                    Exp. Date {formatToMMYYYY(paymentMethod.exp)}
                  </Typography>
                  {isexpired && (
                    <Typography
                      variant={"smallBoldText"}
                      color="error.main"
                      fontWeight={600}
                    >
                      expired
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item alignSelf={"center"}>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() =>
                      openDialog("EditPayment", {
                        editedPaymentItem: paymentMethod,
                      })
                    }
                  >
                    <EditPencilIcon fontSize="small" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete">
                  <IconButton
                    onClick={() =>
                      openDialog("DeletePayment", {
                        deletedPaymentItem: paymentMethod,
                      })
                    }
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </ConditionalWrapper>
  );
};

export default PaymentMethodList;

const itemContainerStyle = {
  justifyContent: "start",
  alignItems: "center",
  flexWrap: "nowrap",
  overflowWrap: "breakWord",
  py: 2.25,
  px: 3.25,
  gap: { xs: 3, md: 0 },
  borderRadius: 1,
  border: "1px solid",
  borderColor: "grey.30",
  overflowX: "auto",
  "& > *": {
    minWidth: "max-content",
  },
};

const actionBoxStyles = {
  position: "absolute",
  paddingLeft: "10px",
  right: 0,
  transform: `translateX(100%)`,
};
