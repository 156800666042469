import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { IContingency } from "features/offers/api";
import { CheckIcon } from "styles/icons";

interface IProps {
  contingencies: IContingency[] | undefined;
}

const WaiveRequiredList: React.FC<IProps> = ({ contingencies }) => {
  return (
    <List disablePadding>
      {contingencies?.map(({ contingency_name, waive_required }) => {
        return (
          <ListItem disableGutters disablePadding key={contingency_name}>
            <ListItemText
              sx={listTextStyle}
              disableTypography
              primary={
                <>
                  {waive_required === true ? (
                    <CheckIcon sx={{ mr: 1 }} />
                  ) : (
                    <CheckIcon fill="#CAD0D9" sx={{ mr: 1 }} />
                  )}
                  <Typography component="span" sx={listItemStyle}>
                    {contingency_name}
                  </Typography>
                </>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default WaiveRequiredList;

const listTextStyle = {
  color: "grey.800",
  display: "flex",
  alignItems: "center",
};

const listItemStyle = {
  fontWeight: 400,
  fontSize: "0.75rem",
};
