import { Box, Grid, Typography } from "@mui/material";
import {
  formatFullName,
  formatPhone,
} from "features/listing/utils/listingsUtils";
import { useFormContext } from "react-hook-form";
import { ShowFullAddress } from "utils/helpersComponents";

interface IAttorney {
  attorney_first_name: string;
  attorney_last_name: string;
  attorney_email: string;
  attorney_number: string;
}

interface IPurchaser {
  purchaser_first_name: string;
  purchaser_last_name: string;
  name: string;
  purchaser_address_line_1: string;
  purchaser_address_line_2: string;
  purchaser_city: string;
  purchaser_state: string;
  purchaser_zip: string;
  comments: string;
}

type TCustomerType = "attorney" | "purchaser";
interface IProps {
  type: TCustomerType;
}

const InformationCard: React.FC<IProps> = ({ type }): JSX.Element => {
  const { watch } = useFormContext();
  const attorney: IAttorney = attorneyFields.reduce(
    (acc, fieldName) => ({
      ...acc,
      [fieldName]: watch(fieldName),
    }),
    {} as IAttorney
  );
  const purchaser: IPurchaser = purchaserFields.reduce(
    (acc, fieldName) => ({
      ...acc,
      [fieldName]: watch(fieldName),
    }),
    {} as IPurchaser
  );
  const isAttorney = type === "attorney";

  return (
    <Grid container minHeight={116} flexDirection="column">
      <Typography mb={2} variant="boldText" color="primary.900">
        {isAttorney ? "Attorney Information:" : "Purchaser Information:"}
      </Typography>
      <Grid
        item
        justifyContent="center"
        minHeight={isAttorney ? 80 : 0}
        container
        flexDirection="column"
        rowGap={1}
      >
        <Grid item>
          <Box display="flex" justifyContent="space-between">
            <Typography color="grey.500" variant="extraSmallRegularText">
              Name:
            </Typography>
            <Typography color="grey.700" variant="extraSmallBoldText">
              {isAttorney
                ? formatFullName(
                    attorney.attorney_first_name,
                    attorney.attorney_last_name
                  )
                : formatFullName(
                    purchaser.purchaser_first_name,
                    purchaser.purchaser_last_name
                  )}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" justifyContent="space-between">
            <Typography
              sx={{ minWidth: 60 }}
              color="grey.500"
              variant="extraSmallRegularText"
            >
              {isAttorney ? "Email:" : "Address:"}
            </Typography>
            <Typography
              textAlign="end"
              color="grey.700"
              variant="extraSmallBoldText"
            >
              {isAttorney && attorney.attorney_email}
            </Typography>
            {!isAttorney && (
              <ShowFullAddress
                textAlign={"right"}
                color="grey.700"
                variant="extraSmallBoldText"
                address_line_1={purchaser.purchaser_address_line_1}
                address_line_2={purchaser.purchaser_address_line_2}
                city={purchaser.purchaser_city}
                state={purchaser.purchaser_state}
                zip={purchaser.purchaser_zip}
              />
            )}
          </Box>
        </Grid>
        <Grid maxWidth={"100%"}>
          <Box display="flex" justifyContent="space-between">
            <Typography
              color="grey.500"
              sx={{ minWidth: 80 }}
              variant="extraSmallRegularText"
            >
              {isAttorney ? "Number:" : "Comments:"}
            </Typography>
            <Typography
              sx={!isAttorney ? ellipsisStyle : {}}
              color="grey.700"
              variant="extraSmallBoldText"
            >
              {isAttorney
                ? formatPhone(attorney.attorney_number)
                : purchaser.comments}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InformationCard;

const ellipsisStyle = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  maxWidth: "100%",
};

export const attorneyFields = [
  "attorney_first_name",
  "attorney_last_name",
  "attorney_email",
  "attorney_number",
];

export const purchaserFields = [
  "purchaser_first_name",
  "purchaser_last_name",
  "purchaser_address_line_1",
  "purchaser_address_line_2",
  "purchaser_city",
  "purchaser_state",
  "purchaser_zip",
  "comments",
];
