import * as yup from "yup";

const cardHolderSchema = yup
  .string()
  .required("Enter cardholder name")
  .matches(/^[a-zA-Z\s]+$/, "Only English letters are allowed")
  .min(7, "Minimum 7 characters")
  .max(50, "Maximum 50 characters");

export { cardHolderSchema };
