import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ProfileIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 42 42"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 0C9.42025 0 0 9.42025 0 21C0 32.5798 9.42025 42 21 42C32.5798 42 42 32.5798 42 21C42 9.42025 32.5798 0 21 0ZM32.7215 33.957C31.5595 30.5042 28.3465 28 24.5 28H17.5C13.6535 28 10.444 30.506 9.282 33.9588C5.74175 30.7545 3.5 26.1397 3.5 21C3.5 11.3505 11.3505 3.5 21 3.5C30.6495 3.5 38.5 11.3505 38.5 21C38.5 26.138 36.26 30.7527 32.7215 33.957Z"
          fill="#818CF8"
        />
        <path
          d="M21 8.75C17.1342 8.75 14 11.8842 14 15.75V17.5C14 21.3658 17.1342 24.5 21 24.5C24.8658 24.5 28 21.3658 28 17.5V15.75C28 11.8842 24.8658 8.75 21 8.75Z"
          fill="#818CF8"
        />
      </svg>
    </SvgIcon>
  );
};

export default ProfileIcon;
