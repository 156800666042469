import { Grid, Typography } from "@mui/material";
import { RegisterToOfferWithoutDialogStyle } from "Components/Steppers/RegisterToOfferStepper";
import { RegisterToOfferStepper } from "features/listing";
import { ClosedOfferSubmitForm } from "features/listing/components/detailListing/user";
import { IUserOfferorRequest, OfferorStatus } from "features/offerors/api";
import { RequestOfferorDetails } from "features/offerors/components";
import { useGetMyOfferQuery } from "features/offers/api";
import { useParams } from "react-router-dom";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";

interface IProps {
  lastFourDigits: string | undefined;
  offerorRequestData: IUserOfferorRequest | undefined;
}

const ClosedLive: React.FC<IProps> = ({
  offerorRequestData,
  lastFourDigits,
}): JSX.Element => {
  const { portal, verified } = useAppSelector(selectAuth);
  const itemId: string = useParams().itemId!;
  const isOfferorVerified =
    offerorRequestData?.status !== OfferorStatus.NOT_VERIFIED &&
    offerorRequestData !== undefined;
  const isPaymentFailed =
    offerorRequestData?.status === OfferorStatus.PAYMENT_FAILED;

  const { data: myOfferData } = useGetMyOfferQuery(itemId, { skip: !verified });
  return (
    <Grid container minHeight={400}>
      {isOfferorVerified && !isPaymentFailed && (
        <ClosedOfferSubmitForm
          myOfferData={myOfferData}
          lastFourDigits={lastFourDigits}
          sx={{ mx: 3, justifyContent: "space-between" }}
        />
      )}
      {(!isOfferorVerified || !portal) && (
        <RegisterToOfferStepper
          isNewRegister={true}
          dialogContentStyle={RegisterToOfferWithoutDialogStyle}
        />
      )}
      {isPaymentFailed && <RequestOfferorDetails />}
    </Grid>
  );
};

export default ClosedLive;
