import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import { formatUSD } from "utils/helpers";

interface IProps {
  text: string;
  price: number;
}

const MainStatusButton: React.FC<IProps> = ({ text, price }): JSX.Element => {
  return (
    <LoadingButton fullWidth variant="success" sx={buttonContainerStyle}>
      <Typography variant="smallRegularText">{text}</Typography>
      <Typography variant="h5Bold" color={"white.main"}>
        {price && formatUSD(price)}
      </Typography>
    </LoadingButton>
  );
};

export default MainStatusButton;

const buttonContainerStyle = {
  pointerEvents: "none",
  flexDirection: { xs: "column", sm: "row" },
  gap: { xs: 0.5, sm: 2.5 },
  py: 3,
  color: "white.main",
};
