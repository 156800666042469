import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface HomeTabIconProps extends SvgIconProps {
  stroke?: string;
}

const HomeTabIcon: React.FC<HomeTabIconProps> = ({ sx, stroke, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.25 8.47015L6.74259 2.09875C7.14151 1.636 7.85849 1.636 8.25741 2.09875L13.75 8.47015"
          stroke={stroke || "white"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.17303 6.2446L6.74114 2.0876C7.1402 1.62268 7.85971 1.62268 8.25876 2.0876L11.8269 6.2446L12.0307 7.69855C12.2143 9.00874 12.2053 10.3387 12.004 11.6463V11.6463C11.9021 12.308 11.3327 12.7964 10.6632 12.7964H4.33667C3.66719 12.7964 3.09782 12.308 2.99592 11.6463V11.6463C2.79457 10.3387 2.78557 9.00874 2.96922 7.69855L3.17303 6.2446Z"
          stroke={stroke || "white"}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M7.50001 8.08893C8.421 8.08893 9.16762 8.83554 9.16762 9.75654V12.7964H5.8324V9.75654C5.8324 8.83554 6.57901 8.08893 7.50001 8.08893Z"
          stroke={stroke || "white"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default HomeTabIcon;
