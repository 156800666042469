import { Grid, Typography } from "@mui/material";
import { formatToMMM } from "Components/DatesAndTime/helpers";
import TextIcon from "Components/Fields/TextIcon";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { formatUSD } from "utils/helpers";
import { CalendarPrimaryIcon } from "../../../styles/icons";

interface IProps {
  publishDate: string | number | undefined;
  narrowStyle?: boolean;
}

const PublishListingCard: React.FC<IProps> = ({
  publishDate,
  narrowStyle = false,
}): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const currentUnixTime: string = Math.floor(Date.now() / 1000).toString();
  const dateOnCard: string =
    publishDate === "0" || publishDate === undefined || publishDate === 0
      ? formatToMMM(currentUnixTime)
      : formatToMMM(publishDate.toString());
  return (
    <Grid container p={narrowStyle ? 1 : 0}>
      <Grid item xs={7} margin={"16px auto"}>
        {narrowStyle && (
          <Typography color="grey.700" variant="smallBoldText">
            Confirm Publishing
          </Typography>
        )}
      </Grid>
      <Grid
        container
        item
        xs={narrowStyle ? 7 : 12}
        sx={{ ...cardContainerStyle, ...(narrowStyle && narrowCardStyle) }}
      >
        <Grid item container xs={narrowStyle ? 12 : 7} sx={imgContainerStyle}>
          <img
            style={imageStyle}
            src={listing?.main_photo}
            width="65"
            height="65"
          />
          <Grid item>
            <Typography
              variant="extraSmallBoldText"
              color={"grey.800"}
              fontWeight={500}
              mb={0.6}
            >
              {listing?.full_address}
            </Typography>
            <Typography
              fontSize={"0.5rem"}
              color="grey.500"
              sx={{ lineHeight: "0.5rem" }}
            >
              Current Price
            </Typography>
            <Typography color="primary.dark" variant="smallBoldText">
              {formatUSD(listing?.price)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography
            color="grey.900"
            fontWeight={600}
            fontSize={"0.625rem"}
            sx={{ mb: 1 }}
          >
            Publishing Date:
          </Typography>
          <TextIcon
            text={dateOnCard || null}
            icon={<CalendarPrimaryIcon />}
            sx={textIconStyle}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PublishListingCard;

const cardContainerStyle = {
  justifyContent: "space-between",
  alignItems: "center",
  bgcolor: "secondary.light",
  padding: "16px 24px",

  borderRadius: 1,
};

const imgContainerStyle = {
  alignItems: "center",
  flexWrap: "nowrap",
  display: "flex",
  wordBreak: "break-word",
  borderRadius: 2,
};

const imageStyle = {
  border: "3px solid #EEF2FF",
  outline: "3px solid rgba(238, 242, 255, 0.5)",
  borderRadius: 6,
  marginRight: "20px",
};

const textIconStyle = {
  marginLeft: 0.5,
  color: "primary.main",
  fontWeight: "700",
};

const narrowCardStyle = {
  margin: "0 auto",
  bgcolor: "grey.50",
  gap: 1,
};
