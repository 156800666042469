import { Grid, Typography } from "@mui/material";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { TimerProgressLine } from "Components/Timers";
import dayjs from "dayjs";
import { ClosedOfferSubmitForm } from "features/listing/components/detailListing/user";
import { useGetMyOfferQuery } from "features/offers/api/offersApi";
import { useParams } from "react-router-dom";
import { selectAuth } from "store/features/authSlice";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";

const ClosedPendingWinner: React.FC = (): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const itemId: string = useParams().itemId!;

  const { verified } = useAppSelector(selectAuth);
  const { data: myOfferData, isLoading: myOfferLoading } = useGetMyOfferQuery(
    itemId,
    {
      skip: !verified,
    }
  );
  //timer fields
  const auctionEnded = parseInt(listing?.auction_ended) || 0;
  const auctionEndedPlusHour = auctionEnded + 3600;
  return (
    <ConditionalWrapper isLoading={myOfferLoading}>
      {myOfferData && (
        <Grid container>
          <Grid container flexDirection={"column"} gap={1} mb={1}>
            <Typography color="primary" variant="h6Bold">
              Waiting for the agent to select a winner
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TimerProgressLine
              auctionStartDate={auctionEnded}
              actualEndTime={auctionEndedPlusHour}
              expiryTimestamp={dayjs.unix(auctionEndedPlusHour).toDate()}
            />
          </Grid>
        </Grid>
      )}
      <Grid container>
        {!myOfferData && (
          <Grid container mt={3} justifyContent={"center"}>
            <Typography color="grey.500" variant="h5Bold">
              You Have Not Offered On This Listing
            </Typography>
          </Grid>
        )}
        {myOfferData && (
          <>
            <Typography color="primary.main" variant="boldText" sx={{ my: 3 }}>
              My Submitted Offers
            </Typography>
            <ClosedOfferSubmitForm
              myOfferData={myOfferData}
              myOfferLoading={myOfferLoading}
              readOnly
            />
          </>
        )}
      </Grid>
    </ConditionalWrapper>
  );
};

export default ClosedPendingWinner;
