import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const UploadButtonIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.40405 17.8242V19.4544C1.40405 20.3191 1.74755 21.1483 2.35898 21.7598C2.97041 22.3712 3.79968 22.7147 4.66437 22.7147H19.3358C20.2005 22.7147 21.0298 22.3712 21.6412 21.7598C22.2526 21.1483 22.5961 20.3191 22.5961 19.4544V17.8242"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 10.1142C10.5171 10.1142 8.20231 9.84047 6.82239 9.65773C6.21661 9.57751 5.83334 8.97132 6.04802 8.39919C7.06652 5.68478 8.77699 3.11543 11.5491 1.5295C11.8284 1.36973 12.1717 1.36972 12.451 1.52947C15.2232 3.11522 16.9336 5.68466 17.952 8.39903C18.1667 8.97114 17.7835 9.57731 17.1777 9.65756C15.7978 9.84036 13.4829 10.1142 12 10.1142Z"
          fill="#EEF2FF"
        />
        <path
          d="M12 10.1142C10.5171 10.1142 8.20231 9.84047 6.82239 9.65773C6.21661 9.57751 5.83334 8.97132 6.04802 8.39919C7.06652 5.68478 8.77699 3.11543 11.5491 1.5295C11.8284 1.36973 12.1717 1.36972 12.451 1.52947C15.2232 3.11522 16.9336 5.68466 17.952 8.39903C18.1667 8.97114 17.7835 9.57731 17.1777 9.65756C15.7978 9.84036 13.4829 10.1142 12 10.1142Z"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 10.1152L12 17.8242"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default UploadButtonIcon;
