import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const isLocalEnvironment = process.env.NODE_ENV === "development";
export const apiUrl = isLocalEnvironment ? "http://localhost:3000" : "";

export const ephrownApi = createApi({
  reducerPath: "listingSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    credentials: "include",
  }),
  tagTypes: [
    "userListings",
    "agentListings",
    "payments",
    "offers",
    "contingencies",
    "offerors",
  ],
  endpoints: () => ({}),
});
