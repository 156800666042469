import { Box, Typography } from "@mui/material";
import { IListing, ListingStatus } from "features/listing/types";
import React, { useState } from "react";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import StatusOptions from "../StatusOptions";
import { Portal } from "features/users/types";
import { TimerField } from "Components/Timers";

interface IProps {
  timeToStartDate: Date;
  timeToEndDate: Date;
  listing: IListing;
  auctionEnded: string;
}

const NinthColumn: React.FC<IProps> = ({
  listing,
  timeToStartDate,
  timeToEndDate,
  auctionEnded,
}) => {
  const { portal } = useAppSelector(selectAuth);
  const isUserPortal = portal === Portal.USER || portal === null;
  const statusInfo = getStatusInfo(
    listing?.status,
    timeToStartDate,
    timeToEndDate,
    auctionEnded
  );

  const expiryTimestamp = statusInfo.expiryTimestamp;
  return (
    <>
      {isUserPortal ? (
        <Box sx={{ ml: 2 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            {statusInfo.title}
          </Typography>
          {statusInfo.title === "Auction ended" ? (
            <Typography variant="smallRegularText" color="grey.800">
              {auctionEnded}
            </Typography>
          ) : (
            <TimerField expiryTimestamp={expiryTimestamp} />
          )}
        </Box>
      ) : (
        <StatusOptions listing={listing} />
      )}
    </>
  );
};

export default NinthColumn;

const getStatusInfo = (
  status: ListingStatus,
  timeToStartDate: Date,
  timeToEndDate: Date,
  auctionEnded: string
) => {
  switch (status) {
    case ListingStatus.PUBLISHED:
      return {
        title: "Time to start date:",
        expiryTimestamp: timeToStartDate,
      };
    case ListingStatus.PENDING_WINNER:
    case ListingStatus.PENDING_CONTRACT:
    case ListingStatus.NO_OFFERS:
    case ListingStatus.NO_WINNER:
    case ListingStatus.IN_CONTRACT:
    case ListingStatus.FAILED_SALE:
    case ListingStatus.FAILED_CONTRACT:
    case ListingStatus.SOLD:
      return {
        title: "Auction ended",
        expiryTimestamp: timeToEndDate,
        auctionEnded: auctionEnded,
      };
    default:
      return {
        title: "Time to Auction end:",
        expiryTimestamp: timeToEndDate,
      };
  }
};
