import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
export interface IProps {
  children: React.ReactNode;
  className?: string;
  color?: string;
  title?: string;
  expanded: boolean;
  titleStyle?: object;
  iconStyle?: object;
  sx?: object;
  type?: string;
  setExpanded: (expand: boolean) => void;
}

const StyledCollapse: React.FC<IProps> = ({
  children,
  className,
  color,
  title,
  iconStyle,
  expanded,
  type = "arrow",
  titleStyle,
  sx,
  setExpanded,
}): JSX.Element => {
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const commonProps = {
    expand: expanded,
    onClick: handleExpandClick,
    "aria-expanded": expanded,
  };
  return (
    <>
      <Grid className={className} container alignItems="center" sx={sx}>
        <Typography
          variant="boldText"
          color={color || "grey.500"}
          sx={titleStyle}
          onClick={handleExpandClick}
          style={{ cursor: "pointer" }}
        >
          {title}
        </Typography>
        {type === "arrow" && (
          <ExpandMoreArrow sx={iconStyle} {...commonProps}>
            <ExpandMoreIcon sx={{ color: color ? color : "grey.500" }} />
          </ExpandMoreArrow>
        )}
        {type === "circle" && (
          <ExpandMoreCircle
            disableFocusRipple
            disableRipple
            sx={{ ...iconStyle, width: 16, height: 16, mr: 1.5 }}
            {...commonProps}
          />
        )}
      </Grid>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={{ width: "100%" }}
      >
        {children}
      </Collapse>
    </>
  );
};

export default StyledCollapse;

const ExpandMoreArrow = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(-90deg)" : "rotate(deg)",

  color: theme.palette.primary.main,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandMoreCircle = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  color: theme.palette.primary.main,
  boxSizing: "border-box",
  padding: 0,
  border: !expand ? "1px solid" : "5.5px solid",
  borderColor: !expand ? theme.palette.grey[400] : theme.palette.primary.main,
  transition: theme.transitions.create("background-color", {
    duration: theme.transitions.duration.shortest,
  }),
}));
