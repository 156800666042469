import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import { useTimer } from "react-timer-hook";
import TimerFieldIcon from "styles/icons/TimerFieldIcon";

interface ITimerField {
  expiryTimestamp: Date;
  hideTimer?: boolean;
}

const TimerField: React.FC<ITimerField> = ({
  expiryTimestamp,
  hideTimer = false,
}): JSX.Element => {
  const isExpired = expiryTimestamp < new Date();
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp:
      expiryTimestamp > new Date() ? expiryTimestamp : new Date(),
  });
  let timerString = "";
  if (!isExpired) {
    timerString = getTimerString(seconds, minutes, hours, days, 2);
  }
  return (
    <LoadingButton
      variant="secondary"
      sx={{ ...timerStyle, display: hideTimer ? "none" : "inline-flex" }}
      startIcon={
        <TimerFieldIcon
          stroke={!isExpired ? "" : "#CAD0D9"}
          sx={{ width: 16, height: 16 }}
        />
      }
    >
      <Typography
        variant="extraSmallRegularText"
        component={"span"}
        color={!isExpired ? "" : "grey.400"}
      >
        {!isExpired ? `${timerString.trim()}` : "--"}
      </Typography>
    </LoadingButton>
  );
};

export default TimerField;

const timerStyle = {
  justifyContent: "start",
  pointerEvents: "none",
  minWidth: 128,
  border: "1px solid transparent",
  ".MuiButton-startIcon": {
    mr: 0,
    ml: 0,
  },
};

export const getTimerString = (
  seconds: number,
  minutes: number,
  hours: number,
  days: number,
  units: 2 | 3 | 4
): string => {
  if (days > 0) {
    if (hours > 0) {
      return `${days}d ${hours}h ${
        units === 3 || units === 4
          ? `${minutes}m ${units === 4 ? `${seconds}s` : ""}`
          : ""
      }`;
    } else {
      return `${days}d 0h ${units === 4 ? `${minutes}m ${seconds}s` : ""}`;
    }
  } else if (hours > 0) {
    return `${hours}h ${minutes}m ${
      units === 3 || units === 4 ? `${seconds}s` : ""
    }`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
};
