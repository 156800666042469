import { Box, Grid, Typography } from "@mui/material";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import FilterDropdown from "Components/StyledComponents/FilterDropdown";
import { BidOfferBox } from "features/listing";
import {
  ClosedBidOfferBox,
  OpenBidOfferBox,
} from "features/listing/components/detailListing/agent/offerBox";
import { SaleType } from "features/listing/types";
import {
  IListingOfferorRequests,
  useGetListingOfferorRequestsQuery,
} from "features/offerors/api";
import { useEffect, useState } from "react";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";

interface IProps {
  redirectToPublish: boolean;
}
const PublishedListingStatus: React.FC<IProps> = ({
  redirectToPublish,
}): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const isOpenListing = listing?.sale_type === SaleType.OPEN;
  const [filterValue, setFilterValue] = useState(
    !isOpenListing ? "all" : "pending"
  );
  const { data: listingOffers, isLoading } = useGetListingOfferorRequestsQuery(
    listing.id
  );
  const [filteredOffers, setFilteredOffers] = useState(listingOffers);

  useEffect(() => {
    const filteredList = listingOffers?.filter(
      (item: IListingOfferorRequests) =>
        filterValue === "all" ||
        item.status.toLowerCase() === filterValue.toLowerCase()
    );
    setFilterValue(filterValue);

    setFilteredOffers(filteredList ?? []);
  }, [filterValue, listingOffers]);
  return (
    <ConditionalWrapper isLoading={isLoading}>
      <Grid
        container
        mb={2}
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item sx={backToBidsContainerStyle} gap={isOpenListing ? 0 : 1}>
          <Typography variant="h6Bold" color="primary">
            Requests
          </Typography>
        </Grid>
        {isOpenListing && (
          <Grid item>
            <FilterDropdown
              onChange={setFilterValue}
              value={filterValue}
              options={filterOptions}
            />
          </Grid>
        )}
      </Grid>
      <Box
        sx={{ height: redirectToPublish ? 750 : 500, overflow: "auto" }}
        mr={-2}
        pr={1.5}
      >
        {filteredOffers?.map((offer: any, idx: number) => {
          return isOpenListing ? (
            <OpenBidOfferBox
              key={offer.id}
              offer={offer}
              isOpenListing={true}
              index={idx}
              redirectToPublish={redirectToPublish}
            />
          ) : (
            <ClosedBidOfferBox key={offer.id} offer={offer} />
          );
        })}
      </Box>
    </ConditionalWrapper>
  );
};

export default PublishedListingStatus;

const backToBidsContainerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const filterOptions = [
  { label: "All", value: "all" },
  { label: "Accepted", value: "approved" },
  { label: "Pending", value: "pending" },
  { label: "Denied", value: "denied" },
];
