import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { ReactImageGalleryItem } from "react-image-gallery";
import { listingState } from "store/features/listingSlice";
import { useAppSelector } from ".";

export interface ExtendedReactImageGalleryItem extends ReactImageGalleryItem {
  isNew: boolean;
}

interface ICurrentListingContextProps {
  images: ExtendedReactImageGalleryItem[];
  setImages: Dispatch<SetStateAction<ExtendedReactImageGalleryItem[]>>;
  imgIndex: number;
  setImgIndex: Dispatch<SetStateAction<number>>;
  children?: React.ReactNode;
  drawerState: { isOpen: boolean; mode: string | null }; // Include the new drawerState property
  setDrawerState: Dispatch<
    SetStateAction<{ isOpen: boolean; mode: string | null }>
  >;
}

const CurrentListingContext = createContext<
  ICurrentListingContextProps | undefined
>(undefined);

export const useCurrentListingContext = (): ICurrentListingContextProps => {
  const context = useContext(CurrentListingContext);
  if (!context) {
    throw new Error(
      "useCurrentListingContext must be used within his Provider"
    );
  }
  return context;
};

export const CurrentListingProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }): JSX.Element => {
  const { listing } = useAppSelector(listingState);
  const [images, setImages] = useState<ExtendedReactImageGalleryItem[]>([]);
  const [imgIndex, setImgIndex] = useState<number>(0);
  const [drawerState, setDrawerState] = useState<{
    isOpen: boolean;
    mode: string | null;
  }>({
    isOpen: false,
    mode: null,
  });
  useEffect(() => {
    setImgIndex(0);
  }, [listing]);
  return (
    <CurrentListingContext.Provider
      value={{
        imgIndex,
        setImgIndex,
        images,
        setImages,
        drawerState,
        setDrawerState,
      }}
    >
      {children}
    </CurrentListingContext.Provider>
  );
};
