import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import ContingenciesCollapse from "Components/Collapses/ContingenciesCollapse";
import { formatToDDMMYYYYHHMMA } from "Components/DatesAndTime/helpers";
import TextIcon from "Components/Fields/TextIcon";
import { Divider, StatusButton } from "Components/StyledComponents";
import { ListingStatus } from "features/listing/types";
import {
  formatFullName,
  formatPhone,
} from "features/listing/utils/listingsUtils";
import StatusOfferButton from "features/myRequests/components/StatusOfferButton";
import { IListingOfferorRequests } from "features/offerors/api";
import {
  IOfferData,
  TListingOffer,
  formatOfferData,
} from "features/offers/api";
import BidOfferMenu from "features/offers/components/BidOfferMenu";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import {
  BidderIcon,
  ContractIcon,
  EnvelopeIcon,
  PersonIcon,
  PhoneIcon,
  PinIcon,
} from "styles/icons";
import { formatUSD } from "utils/helpers";

export interface ICollapseState {
  isOfferExpand: boolean[];
  isCollapsed: boolean;
}

interface IProps {
  index: number;
  isHighest?: boolean;
  isOpenListing: boolean;
  offer: TListingOffer | IListingOfferorRequests;
  collapseState?: ICollapseState;
  setCollapseState?: (value: ICollapseState) => void;
  redirectToPublish?: boolean;
}

const OpenBidOfferBox: React.FC<IProps> = ({
  index,
  isHighest,
  offer,
  isOpenListing,
  collapseState,
  setCollapseState,
  redirectToPublish = false,
}): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const isLiveBid = "offeror_request" in offer;
  const offerData: IOfferData = formatOfferData(offer);
  const userFullName = formatFullName(offerData.firstName, offerData.lastName);
  const userPhoneNumber = formatPhone(offerData.userPhoneNumber);
  const userEmail = offerData.userEmail;
  const attorneyFullName = formatFullName(
    offerData.attorneyFirstName,
    offerData.attorneyLastName
  );
  const attorneyEmail = offerData.attorneyEmail;
  const attorneyPhoneNumber = formatPhone(offerData.attorneyNumber);

  const isExpanded =
    listing.status === ListingStatus.PUBLISHED ||
    redirectToPublish ||
    collapseState?.isOfferExpand[index];
  return (
    <Grid container sx={containerStyle}>
      {/* Start Left Side */}

      <Grid item xs={12} sm={7} md={12} lg={7}>
        <Grid container>
          {(listing.status === ListingStatus.PENDING_CONTRACT ||
            listing.status === ListingStatus.PENDING_WINNER) && (
            <Grid container flexWrap={"nowrap"} gap={2} alignItems="center">
              <PinIcon />
              <LoadingButton
                onClick={() => alert("Accept this offer clicked")}
                sx={acceptOfferButton}
                fullWidth
                variant="successSecondary"
              >
                Accept this offer
              </LoadingButton>
            </Grid>
          )}
          <Grid container flexWrap={"nowrap"} my={2} gap={2}>
            <Typography sx={{ mt: 1.25 }} variant="h6Bold" color="primary.main">
              {index + 1}
            </Typography>
            <BidderIcon sx={{ fontSize: 44 }} />
            <Box sx={userAttorneyDetailsStyle}>
              <Typography variant="smallBoldText" color="grey.800">
                {userFullName}
              </Typography>
              <Typography variant="extraSmallRegularText" color="grey.700">
                {userEmail}
              </Typography>
              <Typography variant="extraSmallRegularText" color="grey.700">
                {userPhoneNumber}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            maxHeight: isExpanded ? 300 : 0,
            overflow: "hidden",
            transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          }}
        >
          <Divider
            sx={{ mb: 2, borderColor: "grey.600" }}
            orientation={"horizontal"}
          />
          <Box sx={userAttorneyDetailsStyle}>
            <Typography
              variant="extraSmallRegularText"
              color="grey.500"
              mb={0.5}
            >
              Buyers Attorney Information:
            </Typography>
            <TextIcon
              sx={{ mb: 0.5 }}
              text={attorneyFullName}
              icon={<PersonIcon sx={{ color: "primary.light" }} />}
              fontColor={"grey.700"}
              variant="smallBoldText"
            />
            <TextIcon
              sx={{ mb: 0.5 }}
              text={attorneyPhoneNumber}
              icon={<PhoneIcon />}
              fontColor={"grey.700"}
              variant="extraSmallRegularText"
            />
            <TextIcon
              sx={{ mb: 0.5 , textTransform: "lowercase" }}
              text={attorneyEmail}
              icon={<EnvelopeIcon stroke="#818CF8" />}
              fontColor={"grey.700"}
              variant="extraSmallRegularText"
            />
          </Box>
          <Divider
            sx={{ my: 2, borderColor: "grey.600" }}
            orientation={"horizontal"}
          />
          <LoadingButton
            variant="secondary"
            size="small"
            sx={documetButtonStyle}
            startIcon={<ContractIcon stroke={"#6366F1"} sx={{ width: 14 }} />}
          >
            Proof of funds
          </LoadingButton>
        </Box>
      </Grid>
      {/* End Left Side */}

      <Grid
        item
        sx={{
          display: { xs: "block", sm: "flex", md: "block", lg: "flex" },
          alignItems: "center",
          justifyContent: "center",
        }}
        xs={12}
        sm={1}
        md={12}
        lg={1}
      >
        <Divider
          sx={{
            mb: 2,
            borderColor: "grey.600",
            display: { xs: "block", md: "none", lg: "block" },
          }}
          orientation={"vertical"}
        />
      </Grid>
      {/* Right Side */}
      <Grid item xs={12} sm={4} md={12} lg={4}>
        {isLiveBid ? (
          <>
            <Grid
              item
              container
              justifyContent={isHighest ? "space-between" : "end"}
              mt={1}
            >
              {isHighest && (
                <StatusButton
                  sx={{
                    mb: 0.5,
                    width: 64,
                    display: "flex",
                    justifySelf: "end",
                  }}
                  status="Highest"
                  variant="success"
                />
              )}
              <BidOfferMenu sx={{ mt: -1 }} />
            </Grid>
            <Typography variant="extraSmallRegularText" color="grey.400">
              Offer Amount
            </Typography>
            <ContingenciesCollapse
              isOpenListing={true}
              idx={index}
              title={formatUSD(offerData.offerAmount)}
              contingencies={offerData.contingencies}
              collapseState={collapseState}
              setCollapseState={setCollapseState}
            />
          </>
        ) : (
          <Grid container sx={{ pr: 1 }}>
            <Grid
              item
              gap={1}
              container
              alignItems={"center"}
              justifyContent={"end"}
            >
              <Typography
                component={"span"}
                variant="extraSmallRegularText"
                color="grey.400"
              >
                {formatToDDMMYYYYHHMMA(offer.date_created)}
              </Typography>
            </Grid>
            <StatusOfferButton sx={{ mt: 1 }} request={offerData} />
          </Grid>
        )}
      </Grid>
      {/* End Right Side */}
    </Grid>
  );
};

export default OpenBidOfferBox;

const containerStyle = {
  p: { xs: 1, sm: 2 },
  background: "white",
  border: "1px solid",
  borderColor: "grey.600",
  borderRadius: 1,
  mb: 1,
  ":last-of-type": {
    mb: 0,
  },
};

const userAttorneyDetailsStyle = {
  wordBreak: "break-word",
  display: "flex",
  flexDirection: "column",
  gap: 1,
};

const acceptOfferButton = {
  height: "32px",
  padding: "8px 16px",
  mt: "auto",
};

const documetButtonStyle = {
  gap: 0,
  borderColor: "transparent.main",
  borderRadius: 0.5,
  minWidth: 128,
  height: 30,
  fontWeight: 400,
};
