import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { MySubmittedOffers } from "features/listing/components/detailListing/user";
import { IHighestOffer } from "features/listing/types";
import { useState } from "react";

const TabPanel = ({ value, index, children }: any) => {
  return (
    <div hidden={value !== index}>
      {value === index && <Box sx={{ p: 0, pt: 1 }}>{children}</Box>}
    </div>
  );
};

interface IProps {
  isOpenListing: boolean;
  isApproved: boolean;
  highestOffer: IHighestOffer | undefined;
  allOffersData: any;
  myOffersData: any;
  userId: number | null | undefined;
}

const OffersTabsPanel: React.FC<IProps> = ({
  isOpenListing,
  highestOffer,
  allOffersData,
  myOffersData,
  userId,
  isApproved,
}): JSX.Element => {
  const [value, setValue] = useState("all_offers");

  const handleChange = (e: any, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", maxHeight: 400 }}>
      <Tabs
        TabIndicatorProps={{ sx: { height: "1px" } }}
        sx={{ p: 0, minHeight: 0 }}
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="offers-tabs"
      >
        <Tab
          sx={{
            ...tabTitleStyle,
            pointerEvents: isApproved ? "auto" : "none",
          }}
          value="all_offers"
          label="All Offers"
        />
        {isApproved && (
          <Tab
            sx={{
              ...tabTitleStyle,
              ml: 2,
            }}
            value="my_offers"
            label="My Offers"
          />
        )}
      </Tabs>

      <TabPanel value={value} index="all_offers">
        {allOffersData?.length > 0 ? (
          allOffersData.map((offer: any, idx: string) => {
            const isMyOffer = userId === offer.user_id;
            const isHighestOffer = offer.offer_amount === highestOffer?.amount;
            return (
              <MySubmittedOffers
                offer={offer}
                key={idx}
                isOpenListing={isOpenListing}
                isMyOfferType={false}
                showByUsername={true}
                isMyOffer={isMyOffer}
                isHighestOffer={isHighestOffer}
              />
            );
          })
        ) : (
          <Typography color="text.secondary" variant="extraSmallRegularText">
            No offers available
          </Typography>
        )}
      </TabPanel>
      {isApproved && (
        <TabPanel value={value} index="my_offers">
          {allOffersData?.length > 0 ? (
            myOffersData?.map((offer: any, idx: string) => (
              <MySubmittedOffers
                offer={offer}
                key={idx}
                isOpenListing={isOpenListing}
                isMyOfferType={true}
                showByUsername={true}
              />
            ))
          ) : (
            <Typography color="text.secondary" variant="extraSmallRegularText">
              You have not submitted an offer on this listing
            </Typography>
          )}
        </TabPanel>
      )}
    </Box>
  );
};

export default OffersTabsPanel;

const tabTitleStyle = {
  fontSize: "0.875rem",
  fontWeight: 700,
  minHeight: 24,
  minWidth: 0,
  p: 0,
  textTransform: "none",
};
