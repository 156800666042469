import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const PinIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.7861 1.76761C6.4931 3.06061 6.30454 5.67353 6.16986 6.61634L2.12925 7.42446L8.59422 13.8894L9.40234 9.84883C10.3452 9.71414 12.9581 9.52558 14.2511 8.23259C15.5441 6.93959 15.0592 5.80822 14.2511 5.0001L11.0186 1.76761C10.2105 0.959489 9.07909 0.474616 7.7861 1.76761Z"
          fill="#EEF2FF"
        />
        <path
          d="M7.7861 1.76761C6.4931 3.06061 6.30454 5.67353 6.16986 6.61634L2.12925 7.42446L8.59422 13.8894L9.40234 9.84883C10.3452 9.71414 12.9581 9.52558 14.2511 8.23259C15.5441 6.93959 15.0592 5.80822 14.2511 5.0001L11.0186 1.76761C10.2105 0.959489 9.07909 0.474616 7.7861 1.76761Z"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.36133 10.6572L0.916657 15.1019"
          stroke="#6366F1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default PinIcon;
