import { Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { useDialogContext } from "store/hooks/DialogsContext";
import { DialogContainer } from ".";
import DialogActions from "./DialogActions";
import { relativeSmallSquareStyle } from "./DialogContainer";

interface IProps {
  title: string;
  onConfirm: () => void;
}

const ConfirmDialog: React.FC<IProps> = ({ title, onConfirm }): JSX.Element => {
  return (
    <DialogContainer sx={relativeSmallSquareStyle} hideExit>
      <DialogContent sx={{ py: 0, pb: 3, px: { xs: 1, sm: 2 } }}>
        <Typography color="common.black" lineHeight={1.5} textAlign={"center"}>
          {title}
        </Typography>
      </DialogContent>
      <DialogActions onSubmit={onConfirm} submitText="Confirm" />
    </DialogContainer>
  );
};

export default ConfirmDialog;
