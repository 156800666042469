import { Grid } from "@mui/material";
import DateTimePicker from "Components/DatesAndTime/DateTimePicker";
import dayjs from "dayjs";
import { Control } from "react-hook-form";

interface IProps {
  name: string;
  maxDate: string;
  control: Control<any>;
}

const DateTimePickerStep: React.FC<IProps> = ({ control }): JSX.Element => {
  return (
    <Grid item pb={2}>
      <DateTimePicker
        name={"publish_date"}
        maxDate={String(dayjs().add(1, "week").unix())}
        control={control}
      />
    </Grid>
  );
};

export default DateTimePickerStep;
