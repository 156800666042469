import { Grid, Typography } from "@mui/material";
import GoogleMap from "Components/Google/GoogleMap";
import RequestOfferorDetails from "features/offerors/components/RequestOfferOrDetails";
import { TimerProgressLine } from "Components/Timers";
import dayjs from "dayjs";
import { mapContainerStyle } from "features/listing/components/detailListing/ListingDetailsWrapper";
import type { IListingDetails } from "features/listing/types";
import { useForm } from "react-hook-form";
import { AbsoluteDivider } from "styles/components";

interface IProps {
  listing: IListingDetails | undefined;
}

const VerifiedUserBidding: React.FC<IProps> = ({ listing }): JSX.Element => {
  const { control } = useForm();
  //timer fields
  const auctionStartDate = listing ? parseInt(listing?.auction_start_date) : 0;
  const publishDate = listing ? parseInt(listing.publish_date) : 0;
  return (
    <Grid container>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={1}
      >
        <Grid item>
          <Typography color="success.main" variant="h6Bold">
            Time to live:
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} pb={6}>
        <TimerProgressLine
          linearColor="success"
          auctionStartDate={publishDate}
          actualEndTime={auctionStartDate}
          expiryTimestamp={dayjs.unix(auctionStartDate).toDate()}
        />
        <AbsoluteDivider />
      </Grid>
      <RequestOfferorDetails />
    </Grid>
  );
};

export default VerifiedUserBidding;

const verifiedBiddingMapStyle = {
  ...mapContainerStyle,
  borderRadius: 1.25,
  height: {
    xs: 250,
    md: 400,
  },
};
