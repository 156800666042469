import { AppBar, Container, Grid, Toolbar, Typography } from "@mui/material";
import { Link } from "Components/StyledComponents";
import { OfferorStatus } from "features/offerors/api";
import { Portal } from "features/users/types";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const NavbarMobileTemp: React.FC = (): JSX.Element => {
  const { username, userTypes, agentRequestStatus, portal, verified } =
    useAppSelector(selectAuth);
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const location = useLocation();
  const isLoginPage = location?.pathname === "/login";
  const isAdmin = userTypes?.includes("admin");
  const isAgent = userTypes?.includes("agent") && !isAdmin;
  const isGuest = portal === null;
  const isPendingRequest = agentRequestStatus === OfferorStatus.PENDING;
  const isUserPortal = portal === Portal.USER;
  const isAgentPortal = portal === Portal.AGENT;
  const isAdminPortal = portal === Portal.ADMIN;
  const isBorder = isUserPortal && !verified;

  return (
    <>
      <AppBar
        position="relative"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        elevation={0}
      >
        <Container maxWidth="xl" sx={{ py: 1 }}>
          <Toolbar disableGutters>
            <Grid
              container
              item
              md={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h5Bold"
                noWrap
                sx={{
                  flexGrow: { xs: 0, sm: 0 },
                  letterSpacing: ".2rem",
                }}
              >
                <Link to="/">Ephrown</Link>
              </Typography>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default NavbarMobileTemp;

export const iconNavbarStyle = {
  p: 1,
  mx: 1,
  width: 48,
  height: 48,
  border: "1.5px solid rgba(238, 242, 255, 0.5)",
  borderRadius: 1,
  "& .MuiTouchRipple-root .MuiTouchRipple-child": {
    borderRadius: 1,
  },
  "&:hover": {
    backgroundColor: "primary.white",
  },
};

export const messageIconStyle = {
  backgroundColor: "rgba(0, 0, 0, 0.05)",
  border: "none",
  "& .MuiTouchRipple-root .MuiTouchRipple-child": {
    borderRadius: 1,
  },
};
