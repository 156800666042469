import { LoadingButton } from "@mui/lab";
import { ArrowBackIos } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { SharesTable } from "Components/StyledComponents";

interface IProps {
  setIsSharedDisplay: (value: boolean) => void;
  listingId: string | undefined;
}

const SharedView: React.FC<IProps> = ({
  setIsSharedDisplay,
  listingId,
}): JSX.Element => {
  return (
    <Grid container>
      <Grid
        item
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Typography variant={"h6Bold"} color="primary">
          Shares:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SharesTable listingId={listingId} />
      </Grid>
    </Grid>
  );
};

export default SharedView;
