import { Grid, Typography } from "@mui/material";
import { formatToMMDDYY } from "Components/DatesAndTime/helpers";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { useGetPaymentRecordsQuery } from "features/payments/api/paymentApi";
import StatusPaymentButton from "features/payments/components/StatusPaymentButton";
import { formatUSD } from "utils/helpers";

const PaymentHistoryList: React.FC = (): JSX.Element => {
  const { data: paymentRecordsData, isLoading } = useGetPaymentRecordsQuery();

  return (
    <ConditionalWrapper isLoading={isLoading}>
      <Grid container gap={1.25}>
        {paymentRecordsData?.map((payment) => (
          <Grid key={payment.id} container sx={itemContainerStyle}>
            <Grid item xs={6}>
              <Typography
                color="grey.500"
                variant={"extraSmallRegularText"}
                fontWeight={600}
              >
                Subscription charge:{" "}
                <Typography
                  component={"span"}
                  color="grey.400"
                  variant={"extraSmallRegularText"}
                  fontWeight={500}
                >
                  {payment.description}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                color="grey.700"
                variant={"extraSmallRegularText"}
                fontSize={"0.6875rem"}
                fontWeight={600}
              >
                ****{payment.payment_method.last_four_digits}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <StatusPaymentButton status={payment.status} />
            </Grid>
            <Grid item xs={2} textAlign={"right"}>
              <Typography color="primary" variant="smallBoldText">
                {formatUSD(payment.amount)}
                <Typography
                  ml={{ xs: 1, md: 2 }}
                  minWidth={300}
                  component={"span"}
                  color="grey.500"
                  variant="extraSmallRegularText"
                  fontWeight={500}
                >
                  {formatToMMDDYY(payment.date_created)}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </ConditionalWrapper>
  );
};

export default PaymentHistoryList;

const itemContainerStyle = {
  justifyContent: "start",
  alignItems: "center",
  flexWrap: "nowrap",
  overflowWrap: "breakWord",
  py: 2.25,
  px: 3.25,
  gap: { xs: 3, md: 0 },
  borderRadius: 1,
  border: "1px solid",
  borderColor: "grey.30",
  overflowX: "auto",
  "& > *": {
    minWidth: "max-content",
  },
};
