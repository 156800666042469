import { Box, Grid, ToggleButtonGroup, Typography } from "@mui/material";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";

import { useGetCountListingsQuery } from "features/listing/api/agentApi";
import { ListingToggle } from "styles/components";

interface IProps {
  handleFilter: any;
  filter: string;
}

const MyOverview: React.FC<IProps> = ({
  handleFilter,
  filter,
}): JSX.Element => {
  const { data: statusData, isLoading } = useGetCountListingsQuery();
  return (
    <ConditionalWrapper isLoading={isLoading}>
      <Box
        sx={{
          mb: 2,
          px: { xs: 2, md: 4 },
          pt: { xs: 2, md: 2 },
          backgroundColor: "secondary.light",
          borderRadius: 1.5,
        }}
      >
        <Grid
          mb={1}
          container
          flexDirection={"column"}
          sx={{ overflowX: "auto" }}
        >
          <Grid item>
            <Typography mb={1} variant="boldText" color="primary">
              My Overview
            </Typography>
          </Grid>
          <Grid
            item
            display="flex"
            justifyContent="space-between"
            alignItems={"baseline"}
          >
            {statusData?.map(({ title, count, color }) => (
              <ToggleButtonGroup
                key={title}
                sx={{ width: "11%", minWidth: "100px", alignSelf: "stretch" }}
                value={filter}
                exclusive
                onChange={handleFilter}
              >
                <ListingToggle
                  value={title}
                  sx={{
                    justifyContent: "stretch",
                    color: color,
                    "&:hover": {
                      bgcolor: "common.white",
                    },
                    "&.Mui-selected": {
                      bgcolor: "common.white",
                      color: color,
                    },
                    "&:hover::before": {
                      backgroundColor: color,
                    },
                    "&.Mui-selected::before": {
                      backgroundColor: color,
                    },
                  }}
                >
                  <Typography variant="h4Bold">{count}</Typography>
                  <Typography textTransform={"none"} variant="smallRegularText">
                    {title}
                  </Typography>
                </ListingToggle>
              </ToggleButtonGroup>
            ))}
          </Grid>
        </Grid>
      </Box>
    </ConditionalWrapper>
  );
};

export default MyOverview;
