import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { Dayjs } from "dayjs";
//types
import { Dispatch, SetStateAction } from "react";

type TIsPublishNow = {
  date: Dayjs;
  isPublishNow: boolean;
};
interface IProps {
  setPublishNow: Dispatch<SetStateAction<TIsPublishNow>>;
  handleNext: (arg?: number) => void;
}

const ScheduleToPublishStep: React.FC<IProps> = ({
  setPublishNow,
  handleNext,
}): JSX.Element => {
  const handlePublishNow = () => {
    setPublishNow((prev) => ({ ...prev, isPublishNow: true }));
    handleNext(2);
  };

  return (
    <Grid item container xs={5} sx={buttonsContainerStyle} pb={2}>
      <LoadingButton size="large" variant="primary" onClick={handlePublishNow}>
        Publish Now
      </LoadingButton>
      <LoadingButton
        size="large"
        variant="tertiary"
        onClick={() => handleNext()}
      >
        Schedule to Publish
      </LoadingButton>
    </Grid>
  );
};

export default ScheduleToPublishStep;

const buttonsContainerStyle = {
  flexDirection: "column",
  margin: "0 auto",
  gap: 1.5,
  my: 4,
};
