import { CvvSchema } from "Components/Validation/reausableSchemas";
import * as yup from "yup";

export const EditPaymentMethodSchema = yup.object().shape({
  expDate: yup
    .string()
    .required("required")
    .matches(/^(0[1-9]|1[0-2])\d{2}$/, "enter valid exp"),
  cvv: CvvSchema,
});
