import { Box, InputAdornment, TextFieldProps, Typography } from "@mui/material";
import { getRightDateType } from "Components/DatesAndTime/helpers";
import dayjs from "dayjs";
import { useController, useFormContext } from "react-hook-form";
import { ArrowIcon, CalendarPrimaryIcon, InfoIcon } from "styles/icons";
import { StyledDateTimeField } from "../../styles/components";
import { HelperText } from "../StyledComponents";

export type ExtendDateFieldProps = {
  name: string;
  label?: string;
  labelColor?: string;
  infoText?: string;
  onClick: any;
} & TextFieldProps;

const ControlledDateTimeField: React.FC<ExtendDateFieldProps> = ({
  name,
  label,
  labelColor,
  infoText,
  onClick,
  ...rest
}) => {
  const { control } = useFormContext();
  const {
    field: { value },
  } = useController({ name, control });
  const inputProps = {
    startAdornment: (
      <InputAdornment position="start">
        {<CalendarPrimaryIcon />}
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position="end">{<ArrowIcon />}</InputAdornment>
    ),
    ...rest.InputProps,
  };
  return (
    <Box width={"100%"} mb={2}>
      <Typography
        fontWeight={600}
        color={labelColor || "grey.800"}
        variant="extraSmallBoldText"
        mb={0.5}
      >
        {label}
      </Typography>
      <StyledDateTimeField
        value={value ? getRightDateType(value) : dayjs()}
        onClick={onClick}
        InputProps={inputProps}
        sx={{ mt: 0.5, ...rest.sx }}
      />
      {infoText && (
        <HelperText
          icon={
            <InfoIcon
              sx={{ fontSize: "0.625rem", mr: 0.5, color: "info.main" }}
            />
          }
          text={infoText}
        />
      )}
    </Box>
  );
};

export default ControlledDateTimeField;
