import { Dialog, Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { BlockUnverifyDialog } from "Components/Dialogs";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store/hooks";
import { closePopup, popupState } from "./popupSlice";

const ApiErrorsPopup: React.FC = (): JSX.Element | null => {
  const { isOpen, message, type } = useAppSelector(popupState);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closePopup());
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  if (message && type === "dialog") {
    return (
      <Dialog
        PaperProps={{ sx: { py: 4, maxWidth: 440 } }}
        open={isOpen}
        onClose={() => handleClose()}
      >
        <BlockUnverifyDialog />
      </Dialog>
    );
  } else if (message && type === "snackbar")
    return (
      <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    );

  return null;
};

export default ApiErrorsPopup;
