import { LoadingButton } from "@mui/lab";
import { useCurrentListingContext } from "store/hooks/CurrentListingContext";
import {
  CheckIcon,
  ContractIcon,
  EditPencilIcon,
  PersonIcon,
} from "styles/icons";

import { setCurrentListing } from "store/features/listingSlice";
import { useAppDispatch } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { IListing, IListingDetails, ListingStatus } from "../types";
import { ShareMenu } from "./detailListing/agent";

interface IProps {
  listing: IListing | IListingDetails | undefined;
  panelWidth?: boolean;
}

const StatusOptions: React.FC<IProps> = ({ listing, panelWidth = false }) => {
  const dispatch = useAppDispatch();
  const { setDrawerState } = useCurrentListingContext();
  const { openDialog } = useDialogContext();

  const handlePublishClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setCurrentListing(listing));
    openDialog("PublishStepper", { listing });
  };

  const handleConfirmSale = (e: React.MouseEvent<HTMLButtonElement>) => {
    openDialog("ConfirmVoidSale", {
      listing,
      type: "confirm",
    });
  };

  const handleConfirmContract = (e: React.MouseEvent<HTMLButtonElement>) => {
    openDialog("ConfirmVoidContract", {
      listing,
      type: "confirm",
    });
  };

  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setCurrentListing(listing));
    setDrawerState({ isOpen: true, mode: "edit" });
  };

  switch (listing?.status) {
    case ListingStatus.DRAFT:
      return (
        <>
          <LoadingButton
            variant="tertiaryPrimary"
            sx={{ width: 84, minWidth: panelWidth ? 120 : "none" }}
            startIcon={<EditPencilIcon />}
            onClick={handleEditClick}
          >
            Edit
          </LoadingButton>
          <LoadingButton
            variant="primary"
            sx={{ width: 84, minWidth: panelWidth ? 120 : "none" }}
            onClick={handlePublishClick}
          >
            Publish
          </LoadingButton>
        </>
      );

    case ListingStatus.LIVE:
    case ListingStatus.PUBLISHED:
      return <ShareMenu panelWidth={panelWidth} listing={listing} />;
    case ListingStatus.IN_CONTRACT:
      return (
        <LoadingButton
          variant={"primary"}
          sx={{
            minWidth: panelWidth ? "none" : 178,
            maxWidth: 150,
            gap: panelWidth ? 0 : 1,
          }}
          startIcon={<CheckIcon fill="white" />}
          onClick={handleConfirmSale}
        >
          Confirm Sale
        </LoadingButton>
      );

    case ListingStatus.PENDING_WINNER:
      return (
        <LoadingButton
          variant="tertiaryPrimary"
          sx={{
            minWidth: panelWidth ? "none" : 178,
            maxWidth: 150,
            gap: panelWidth ? 0 : 1,
          }}
          startIcon={<PersonIcon fill="white" />}
        >
          Select Winner
        </LoadingButton>
      );
    case ListingStatus.PENDING_CONTRACT:
      return (
        <LoadingButton
          variant="tertiaryPrimary"
          sx={{
            minWidth: panelWidth ? "none" : 178,
            maxWidth: 170,
            gap: panelWidth ? 0 : 1,
          }}
          startIcon={<ContractIcon stroke={"#6366f1"} />}
          onClick={handleConfirmContract}
        >
          Confirm Contract
        </LoadingButton>
      );
    default:
      return null;
  }
};

export default StatusOptions;
