import { Grid, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

interface IProps {
  HasBottomBorder: boolean;
}

const LoginPageStrip: React.FC<IProps> = ({ HasBottomBorder }): JSX.Element => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  });

  return (
    <Grid container sx={containerStyle(mounted, HasBottomBorder)}>
      <Grid
        container
        sx={{ mx: "auto", px: { xs: 1, md: 5 } }}
        gap={3}
        maxWidth="xl"
      >
        <Typography
          color="primary.dark"
          sx={{
            textTransform: "none",
            typography: { xs: "extraSmallBoldText", sm: "boldText" },
          }}
        >
          Real Estate Agents: Revolutionize your sales and auctions with our
          minimal flat fee. Exclusive offer — contact us now at{" "}
          <Link
            href="mailto:contact@ephrown.com"
            sx={{
              ...emailLinkStyle,
            }}
          >
            contact@ephrown.com
          </Link>{" "}
          to learn more
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoginPageStrip;

const containerStyle = (mounted: boolean, HasBottomBorder: boolean) => ({
  bgcolor: "#D4D1FF",
  py: 1.75,
  borderBottom: HasBottomBorder ? "1px solid #818cf8" : "none",
  transform: `translateY(${mounted ? 0 : "-100%"})`,
  transition: "transform 0.3s",
  justifyContent: { xs: "center", md: "start" },
});

const emailLinkStyle = {
  cursor: "pointer",
  color: "#6366F1",
  textDecoration: "underline",
};
