import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface NotificationIconProps extends SvgIconProps {
  stroke?: string;
}

const NotificationIcon: React.FC<NotificationIconProps> = ({
  sx,
  stroke,
  ...props
}) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.14006 0.860352C2.26552 1.73489 1.65228 2.82188 1.35059 4.00001M12.8123 0.860352C13.6869 1.73489 14.3001 2.82188 14.6018 4.00001"
          stroke={stroke || "white"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.18382 2.88059C5.93063 2.13378 6.94351 1.71423 7.99965 1.71423C9.0558 1.71423 10.0687 2.13378 10.8155 2.88059C11.5623 3.6274 11.9818 4.64028 11.9818 5.69643C11.9818 6.66776 12.165 7.4175 12.4294 7.99235C12.6525 8.47726 13.2092 8.65627 13.6667 8.93125C14.5099 9.4381 14.3834 10.6796 13.7484 11.1491C13.7484 11.1491 12.7656 11.9999 7.99965 11.9999C3.23373 11.9999 2.25096 11.1491 2.25096 11.1491C1.6159 10.6796 1.4894 9.4381 2.3326 8.93125C2.79006 8.65627 3.34684 8.47726 3.56989 7.99235C3.8343 7.4175 4.01746 6.66776 4.01746 5.69643C4.01746 4.64028 4.43701 3.6274 5.18382 2.88059Z"
          stroke={stroke || "white"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.58887 14.2377C6.87783 14.7765 7.44642 15.1429 8.10059 15.1429C8.75476 15.1429 9.32334 14.7765 9.61231 14.2377"
          stroke={stroke || "white"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default NotificationIcon;
