import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const BiddersIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.1092 12.0148C18.3804 12.0148 20.2215 10.1736 20.2215 7.9024C20.2215 5.6312 18.3804 3.79004 16.1092 3.79004C13.838 3.79004 11.9968 5.6312 11.9968 7.9024C11.9968 10.1736 13.838 12.0148 16.1092 12.0148Z"
          fill="#EEF2FF"
        />
        <path
          d="M16.1091 12.0145C18.3803 12.0145 20.2215 10.1734 20.2215 7.90216C20.2215 6.14526 19.1197 4.64569 17.5695 4.05664"
          stroke="#6366F1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.7263 17.7917C23.7379 19.1315 22.3796 21.7155 19.9627 21.7155H10.8866C8.46968 21.7155 7.11138 19.1315 9.12294 17.7917C10.9273 16.59 13.0942 15.8896 15.4246 15.8896C17.7551 15.8896 19.922 16.59 21.7263 17.7917Z"
          fill="#EEF2FF"
        />
        <path
          d="M9.18944 11.1997C11.6514 11.1997 13.6472 9.20388 13.6472 6.74193C13.6472 4.27998 11.6514 2.28418 9.18944 2.28418C6.72749 2.28418 4.73169 4.27998 4.73169 6.74193C4.73169 9.20388 6.72749 11.1997 9.18944 11.1997Z"
          fill="#EEF2FF"
          stroke="#6366F1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0175 17.4622C18.1981 18.9145 16.7257 21.7155 14.1058 21.7155H4.26738C1.6475 21.7155 0.175112 18.9145 2.35562 17.4622C4.31149 16.1596 6.66038 15.4004 9.18659 15.4004C11.7128 15.4004 14.0617 16.1596 16.0175 17.4622Z"
          fill="#EEF2FF"
          stroke="#6366F1"
          strokeWidth="2"
        />
        <path
          d="M13.7268 21.7153H19.9626C22.3795 21.7153 23.7378 19.1313 21.7263 17.7916C21.1708 17.4216 20.581 17.0992 19.9626 16.8301"
          stroke="#6366F1"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default BiddersIcon;
