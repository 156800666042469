const textRegex = /^[a-zA-Z\s-]+$/;
const digitsRegex = /^\d+$/;
const usernameRegex =/^[a-zA-Z0-9\- ']+$/;

const creditCardExpDateRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
const digitsOrSlashRegex = /^[\d\/]+$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/g;

export {
  creditCardExpDateRegex,
  digitsOrSlashRegex,
  digitsRegex,
  emailRegex,
  textRegex,
  usernameRegex
};
