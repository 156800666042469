import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface PersonIconProps extends SvgIconProps {
  stroke?: string;
  fill?: string;
}

const PersonIcon: React.FC<PersonIconProps> = ({
  sx,
  stroke = "#818CF8",
  fill = "#EEF2FF",
  ...props
}) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 7C8.72589 7 10.125 5.60089 10.125 3.875C10.125 2.14911 8.72589 0.75 7 0.75C5.27411 0.75 3.875 2.14911 3.875 3.875C3.875 5.60089 5.27411 7 7 7Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5481 10.5935C13.888 11.4884 13.0477 13.2498 11.4364 13.2498H2.56348C0.952254 13.2498 0.111912 11.4884 1.4518 10.5935C3.03945 9.53321 4.94751 8.91504 6.99995 8.91504C9.05239 8.91504 10.9604 9.53321 12.5481 10.5935Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1.5"
        />
      </svg>
    </SvgIcon>
  );
};

export default PersonIcon;
