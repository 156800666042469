import { Grid, Typography } from "@mui/material";
import { formatFullName } from "features/listing/utils/listingsUtils";
import { IOptions } from "store/hooks/ListingDetailsStepperContext";
import { ShowFullAddress } from "utils/helpersComponents";
import { containerCardStyle } from "./RequestOfferOrDetails";
import {
  IOfferData,
  TListingOffer,
  formatOfferData,
} from "features/offers/api";
import { IListingOfferorRequests } from "../api";

interface IProps {
  options: IOptions;
  offer?: TListingOffer | IListingOfferorRequests;
}

const OfferorPurchaserCard: React.FC<IProps> = ({
  options,
  offer,
}): JSX.Element => {
  const offerData: IOfferData | null = offer ? formatOfferData(offer) : null;
  const purchaserName = offerData
    ? formatFullName(offerData?.firstName, offerData?.lastName)
    : formatFullName(
        options.offerorRequestData?.purchaser_first_name,
        options.offerorRequestData?.purchaser_last_name
      );

  return (
    <Grid item xs={12} py={1} sx={containerCardStyle}>
      <Typography variant="extraSmallBoldText" color="grey.800">
        Purchaser Information:
      </Typography>
      <Grid container mt={1}>
        <Typography
          flexBasis={"80px"}
          variant="extraSmallRegularText"
          color="grey.500"
        >
          Name:
        </Typography>
        <Typography sx={attorneyValueStyle}>{purchaserName}</Typography>
      </Grid>
      <Grid container>
        <Typography
          flexBasis={"80px"}
          variant="extraSmallRegularText"
          color="grey.500"
        >
          Address:
        </Typography>
        <ShowFullAddress
          address_line_1={options.offerorRequestData?.purchaser_address_line_1}
          address_line_2={options.offerorRequestData?.purchaser_address_line_2}
          city={options.offerorRequestData?.purchaser_city}
          state={options.offerorRequestData?.purchaser_state}
          zip={options.offerorRequestData?.purchaser_zip}
          sx={attorneyValueStyle}
          variant="extraSmallRegularText"
        />
      </Grid>
    </Grid>
  );
};

export default OfferorPurchaserCard;

const attorneyValueStyle = {
  fontSize: "0.6875rem",
  fontWeight: 500,
  color: "grey.800",
  flex: 1,
};
