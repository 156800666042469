import { Typography } from "@mui/material";

type THelperText = {
  text: string | undefined;
  icon?: React.ReactNode;
  sx?: object;
};

const HelperText: React.FC<THelperText> = ({ text, icon, sx }): JSX.Element => {
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      {text != null && icon}
      <Typography
        fontWeight={300}
        color="grey.500"
        sx={{ fontSize: "0.625rem", ...sx, m: 0 }}
        component={"span"}
      >
        {text}
      </Typography>
    </span>
  );
};

export default HelperText;
