import { Grid } from "@mui/material";
import { AmountField } from "Components/Fields";
import { transparentContainerStyle } from "Components/Fields/AmountField";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

interface IProps {
  readOnly?: boolean;
}
const BalanceAtClosingForm: React.FC<IProps> = ({
  readOnly = false,
}): JSX.Element => {
  const { setValue, watch } = useFormContext();

  const purchasePrice = watch("offer_amount");
  const onContract = watch("on_contract");
  const mortgageAmount = watch("mortgage_amount");

  useEffect(() => {
    const balanceAtClosing = purchasePrice - (onContract ?? 0);
    setValue("balance_at_closing", balanceAtClosing);
  }, [purchasePrice, onContract, mortgageAmount]);
  return (
    <Grid container gap={2}>
      <AmountField
        readOnly={readOnly}
        label={"Purchase Price"}
        name={"offer_amount"}
      />
      <AmountField
        readOnly={readOnly}
        label={"Mortgage Amount"}
        name={"mortgage_amount"}
      />
      <AmountField
        readOnly={readOnly}
        label={"On Contract"}
        name={"on_contract"}
      />
      <AmountField
        readOnly={readOnly}
        sx={transparentContainerStyle}
        label={"Balance at Closing"}
        hasInfoText
        disabled
        name={"balance_at_closing"}
      />
    </Grid>
  );
};

export default BalanceAtClosingForm;
