import { ArrowIcon } from "styles/icons";
import { useMediaQuery } from "@mui/material";

interface IProps {
  onClick: any;
  disabled: boolean;
}

const PreviousButton: React.FC<IProps> = ({ onClick, disabled }: any) => {
  const handlePrevClick = () => {
    onClick && onClick();
  };
  const isLgOrLess = useMediaQuery("(max-width:1200px)");

  return (
    <>
      <button
        onClick={handlePrevClick}
        className={`arrows next-button${disabled ? " disabled" : ""}`}
        style={disabled ? { opacity: 0.5 } : {}}
        disabled={disabled}
      >
        <ArrowIcon fill={"#6366F1"} sx={{ transform: "rotate(90deg)" }} />
      </button>

      <button
        onClick={handlePrevClick}
        className={`thumbArrows arrows next-button${
          disabled ? " disabled" : ""
        }`}
        style={disabled ? { opacity: 0.5 } : {}}
        disabled={disabled}
      >
        <ArrowIcon
          fill="#6366F1"
          sx={{ transform: isLgOrLess ? "rotate(90deg)" : "rotate(180deg)" }}
        />
      </button>
    </>
  );
};

const NextButton: React.FC<IProps> = ({ onClick, disabled }: any) => {
  const handleNextClick = () => {
    onClick && onClick();
  };
  const isLgOrLess = useMediaQuery("(max-width:1200px)");
  return (
    <>
      <button
        onClick={handleNextClick}
        className={`arrows prev-button${disabled ? " disabled" : ""}`}
        style={disabled ? { opacity: 0.5 } : {}}
        disabled={disabled}
      >
        <ArrowIcon fill={"#6366F1"} sx={{ transform: "rotate(-90deg)" }} />
      </button>

      <button
        onClick={handleNextClick}
        className={`thumbArrows arrows prev-button${
          disabled ? " disabled" : ""
        }`}
        style={disabled ? { opacity: 0.5 } : {}}
        disabled={disabled}
      >
        <ArrowIcon
          fill="#6366F1"
          sx={{ transform: isLgOrLess ? "rotate(-90deg)" : "rotate(0deg)" }}
        />
      </button>
    </>
  );
};

export { PreviousButton, NextButton };
