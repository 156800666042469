import RegisterToOfferStepper, {
  RegisterToOfferDialogStyle,
} from "Components/Steppers/RegisterToOfferStepper";
import { DialogContainer } from ".";
import { registerToOfferContainerStyle } from "./DialogContainer";
import { exitBtnStyle } from "./ListingOptionsDialogs/UpdatePublishDateDialog";
import { DialogContent } from "@mui/material";

interface IProps {
  isNewRegister: boolean;
}

const RegisterToOfferStepperDialog: React.FC<IProps> = ({
  isNewRegister,
}): JSX.Element => {
  return (
    <DialogContainer
      sx={registerToOfferContainerStyle}
      exitBtnStyle={exitBtnStyle}
    >
      <DialogContent sx={{ p: 0 }}>
        <RegisterToOfferStepper
          isNewRegister={isNewRegister}
          dialogContentStyle={RegisterToOfferDialogStyle}
        />
      </DialogContent>
    </DialogContainer>
  );
};

export default RegisterToOfferStepperDialog;
