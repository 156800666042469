import {
  emailSchema,
  passwordSchema,
  phoneSchema,
  usernameSchema,
} from "Components/Validation/reausableSchemas";
import * as yup from "yup";

export const updateUserInfoSchema = yup.object().shape({
  email: emailSchema,
  username: usernameSchema,
  phone: phoneSchema,
  password: passwordSchema
    .required("Password is required"),
});
