import { Button, ButtonProps, Typography } from "@mui/material";
import { useTimer } from "react-timer-hook";
import { TimeIcon } from "styles/icons";
import TimerFieldIcon from "styles/icons/TimerFieldIcon";

type ExtendedButtonProps = {
  startDate?: any;
  sx?: object;
} & ButtonProps;

const TimeButton: React.FC<ExtendedButtonProps> = ({
  startDate,
  sx,
  ...props
}) => {
  const targetDate = new Date(startDate * 1000);
  const { days, hours, minutes } = useTimer({
    expiryTimestamp: targetDate,
    onExpire: () => {},
  });

  return (
    <Button
      sx={{
        ...timerStyles,
        ...sx,
      }}
      startIcon={<TimerFieldIcon sx={{ width: 14 }} />}
      {...props}
    >
      <Typography variant="extraSmallRegularText" component={"span"}>
        {days}d
      </Typography>
      <Typography ml={0.5} variant="extraSmallRegularText" component={"span"}>
        {hours}h
      </Typography>
      <Typography ml={0.5} variant="extraSmallRegularText" component={"span"}>
        {minutes}m
      </Typography>
    </Button>
  );
};

export default TimeButton;

const timerStyles: object = {
  boxShadow: "none",
  pointerEvents: "none",
  textTransform: "none",
  bgcolor: "secondary.main",
  color: "primary.main",
  minWidth: 128,
  height: 26,
  borderRadius: 4,
  padding: "4px 16px",
  fontSize: "0.75rem",
};
