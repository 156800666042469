import { Grid, Typography } from "@mui/material";
import WinnersName from "Components/Fields/WinnersName";
import { TimerField, TimerTextField } from "Components/Timers";
import { auctionColumnStyle } from "Components/Timers/TimerTextField";
import "css/verificationProgress.css";
import { IUserOfferorRequest } from "features/offers/api";
import { ListingStatus } from "features/listing/types";
import { Portal } from "features/users/types";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import { OfferorStatus } from "features/offerors/api";

interface IProps {
  winnerName: string | null;
  timeToStartDate: Date;
  timeToEndDate: Date;
  timeToPublishDate: Date;
  winnerDueDate: Date;
  status: ListingStatus;
  verification: IUserOfferorRequest | undefined;
}

const SeventhColumn: React.FC<IProps> = ({
  status,
  winnerName,
  timeToStartDate,
  timeToEndDate,
  timeToPublishDate,
  verification,
  winnerDueDate,
}) => {
  const { portal } = useAppSelector(selectAuth);
  const isUserPortal = portal === Portal.USER || portal === null;
  return isUserPortal
    ? userVerificationColumn(verification)
    : agentColumn(
        winnerName,
        status,
        timeToStartDate,
        timeToEndDate,
        timeToPublishDate,
        winnerDueDate
      );
};

const userVerificationColumn: any = (verification: IUserOfferorRequest) => {
  const { text, className, color } = getVerificationDetails(verification);

  return (
    <Grid
      xs={2}
      item
      sx={{
        minWidth: 260,
      }}
    >
      <Grid container gap={2} alignItems="center" flexWrap={"nowrap"}>
        <Grid item className={className}>
          {statusIcons[verification?.status] || ""}
        </Grid>
        <Grid item>
          <Typography
            variant="extraSmallRegularText"
            mb={0}
            fontSize={"0.625rem"}
            color="grey.800"
          >
            Verification:
          </Typography>
          <Typography variant="extraSmallBoldText" mb={0.5} color={color}>
            {verification?.status === OfferorStatus.DENIED && "Denied: "}
            <Typography
              variant="extraSmallBoldText"
              component={"span"}
              fontSize={
                verification?.status === OfferorStatus.PENDING_PAYMENT
                  ? "0.6rem"
                  : "0.75rem"
              }
              fontWeight={
                verification?.status === OfferorStatus.DENIED ? 400 : 700
              }
            >
              {text}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const agentColumn = (
  winnerName: string | null,
  status: ListingStatus,
  timeToStartDate: Date,
  timeToEndDate: Date,
  timeToPublishDate: Date,
  winnerDueDate: Date
) => {
  switch (status) {
    case ListingStatus.DRAFT:
    case "published":
      return (
        <Grid xs={2} item sx={{ minWidth: 180 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            Time to start date:
          </Typography>
          <TimerField expiryTimestamp={timeToStartDate} />
        </Grid>
      );

    case ListingStatus.PUBLISH_SCHEDULED:
      return (
        <Grid xs={2} item sx={{ minWidth: 180 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            Time to Publish:
          </Typography>
          <TimerField expiryTimestamp={timeToPublishDate} />
        </Grid>
      );

    case ListingStatus.LIVE:
      return (
        <Grid xs={2} item sx={{ minWidth: 180 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            Time to Auction end:
          </Typography>
          <TimerTextField
            units={2}
            withIcon={true}
            sx={auctionColumnStyle}
            expiryTimestamp={timeToEndDate}
          />
        </Grid>
      );

    case ListingStatus.PENDING_WINNER:
      return (
        <Grid xs={2} item sx={{ minWidth: 180 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            Winner Due Date:
          </Typography>
          <TimerTextField
            units={2}
            withIcon={true}
            sx={auctionColumnStyle}
            expiryTimestamp={winnerDueDate}
          />
        </Grid>
      );

    case ListingStatus.PENDING_CONTRACT:
    case ListingStatus.IN_CONTRACT:
    case ListingStatus.SOLD:
    case ListingStatus.FAILED_CONTRACT:
    case ListingStatus.FAILED_SALE:
    case ListingStatus.NO_WINNER:
    case ListingStatus.NO_OFFERS:
      return (
        <Grid xs={2} item sx={{ minWidth: 180 }}>
          <Typography variant="extraSmallRegularText" mb={0.5} color="grey.500">
            Winning Offeror
          </Typography>
          <WinnersName name={winnerName} />
        </Grid>
      );

    default:
      return null;
  }
};

const getVerificationDetails = (verification: IUserOfferorRequest) => {
  switch (verification?.status) {
    case OfferorStatus.PENDING:
      return {
        text: "Verification Pending",
        className: "pendingVerification",
        color: "primary.main",
      };
    case OfferorStatus.APPROVED:
      return {
        text: "Verified Complete",
        className: "verifiedApproved",
        color: "primary.main",
      };
    case OfferorStatus.DENIED:
      return {
        text: verification.denial_reason_name,
        className: "verifiedDenied",
        color: "primary.main",
      };
    case OfferorStatus.PAYMENT_FAILED:
      return {
        text: "Payment Failed",
        className: "verifiedDenied",
        color: "primary.main",
      };
    case OfferorStatus.PENDING_PAYMENT:
      return {
        text: (
          <>
            Pending Payment: The security hold <br /> will be placed 4 days
            before the <br /> auction start date
          </>
        ),
        className: "pendingVerification",
        color: "primary.main",
      };
    default:
      return {
        text: "Not Verified",
        className: "notVerified",
        color: "grey.500",
      };
  }
};

const statusIcons: Record<string, string> = {
  pending: "\u2713",
  pending_payment: "\u2713",
  approved: "\u2713",
  denied: "\u2715",
  payment_failed: "\u2715",
};

export default SeventhColumn;
