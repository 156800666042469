import { Box, IconButton, ListItemIcon, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListingDrawer } from "Components/Drawers";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { useCurrentListingContext } from "store/hooks/CurrentListingContext";
import { ArchiveIcon, DotsIcon, EditPencilIcon } from "styles/icons";

interface IProps {
  sx?: object;
}
const BidOfferMenu: React.FC<IProps> = ({ sx }): JSX.Element => {
  const { setDrawerState } = useCurrentListingContext();
  const handlePopupClick = (popupState: any) => {
    setDrawerState({ isOpen: true, mode: null });
    popupState.close();
  };
  return (
    <Box sx={sx}>
      <PopupState variant="popover" popupId="offer-menu">
        {(popupState) => (
          <>
            <IconButton {...bindTrigger(popupState)}>
              <DotsIcon />
            </IconButton>
            <Menu
              MenuListProps={{ disablePadding: true }}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              {...bindMenu(popupState)}
            >
              <MenuItem
                onClick={() => handlePopupClick(popupState)}
                style={{ minWidth: "140px" }}
              >
                <ListItemIcon style={{ minWidth: "26px" }}>
                  <EditPencilIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant={"extraSmallBoldText"}>option 1</Typography>
              </MenuItem>

              <MenuItem>
                <ListItemIcon style={{ minWidth: "26px" }}>
                  <ArchiveIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant={"extraSmallBoldText"}>option 2</Typography>
              </MenuItem>
            </Menu>
          </>
        )}
      </PopupState>
      <ListingDrawer />
    </Box>
  );
};

export default BidOfferMenu;
