import { DialogTitle, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import numberToWords from "number-to-words";
import { useDialogContext } from "store/hooks/DialogsContext";
import { convertToWords, formatUSD } from "utils/helpers";
import { DialogContainer } from ".";
import DialogActions from "./DialogActions";

interface IProps {
  offerAmount: string;
  onConfirm: () => void;
}

const SubmitOfferDialog: React.FC<IProps> = ({
  offerAmount,
  onConfirm,
}): JSX.Element => {
  const offerUSD = formatUSD(parseInt(offerAmount));
  const offerInWords = numberToWords.toWords(offerAmount);
  const offerFinalized = convertToWords(offerInWords);
  return (
    <DialogContainer sx={submitOfferContainer} hideExit>
      <DialogTitle textAlign={"center"} color="primary" fontWeight={700}>
        Submit Offer
      </DialogTitle>
      <DialogContent sx={{ py: 0, px: { xs: 1, sm: 2 } }}>
        <Typography
          fontSize={16}
          fontWeight={700}
          color="grey.800"
          lineHeight={1.5}
          textAlign={"center"}
          mb={1}
        >
          {offerFinalized}
        </Typography>
      </DialogContent>
      <DialogActions onSubmit={onConfirm} submitText="Confirm" />
    </DialogContainer>
  );
};

export default SubmitOfferDialog;

export const submitOfferContainer = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 400,
    py: 2,
  },
  "@media (max-width: 601px)": {
    "& .MuiDialog-paper": {
      minWidth: "90%",
      py: 2,
    },
  },
};
