import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ArchiveIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 12 12"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.79324 9.58999C1.86008 10.036 2.2339 10.3668 2.68285 10.4099C3.6929 10.5069 4.74678 10.6273 5.83038 10.6273C6.92814 10.6273 7.98791 10.5509 9.01318 10.4333C9.44585 10.3837 9.79259 10.052 9.85627 9.62117C9.97966 8.78636 10.0611 7.92277 10.0611 7.03095C10.0611 6.13531 9.98717 5.25845 9.86015 4.42355C9.79584 4.00078 9.45444 3.67739 9.02973 3.62749C7.99609 3.50603 6.9351 3.43457 5.83038 3.43457C4.72383 3.43457 3.64386 3.50177 2.61627 3.62559C2.19868 3.67591 1.86303 3.99295 1.79829 4.40854C1.66775 5.24649 1.59961 6.13057 1.59961 7.03095C1.59961 7.91198 1.66951 8.76423 1.79324 9.58999Z"
          fill="#EEF2FF"
        />
        <path
          d="M1.79324 9.58999C1.86008 10.036 2.2339 10.3668 2.68285 10.4099C3.6929 10.5069 4.74678 10.6273 5.83038 10.6273C6.92814 10.6273 7.98791 10.5509 9.01318 10.4333C9.44585 10.3837 9.79259 10.052 9.85627 9.62117C9.97966 8.78636 10.0611 7.92277 10.0611 7.03095C10.0611 6.13531 9.98717 5.25845 9.86015 4.42355C9.79584 4.00078 9.45444 3.67739 9.02973 3.62749C7.99609 3.50603 6.9351 3.43457 5.83038 3.43457C4.72383 3.43457 3.64386 3.50177 2.61627 3.62559C2.19868 3.67591 1.86303 3.99295 1.79829 4.40854C1.66775 5.24649 1.59961 6.13057 1.59961 7.03095C1.59961 7.91198 1.66951 8.76423 1.79324 9.58999Z"
          stroke="#4F46E5"
          strokeWidth="1.5"
        />
        <path
          d="M4.7998 6.41602H7.1075"
          stroke="#4F46E5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.943406 3.32566C1.05768 3.69721 1.42916 3.91022 1.81783 3.9036C4.4255 3.85916 7.06768 3.90575 9.77472 3.91494C10.167 3.91627 10.5351 3.69069 10.651 3.31594C10.7431 3.01776 10.7998 2.71186 10.7998 2.39835C10.7998 2.06453 10.742 1.73641 10.6397 1.41968C10.5289 1.07669 10.1964 0.867819 9.83638 0.850419C8.53421 0.787486 7.19659 0.75 5.79981 0.75C4.39996 0.75 3.03612 0.785292 1.74565 0.849926C1.39505 0.867486 1.0695 1.06709 0.960175 1.40067C0.854526 1.72305 0.799805 2.05816 0.799805 2.39835C0.799805 2.71542 0.850737 3.02435 0.943406 3.32566Z"
          fill="#EEF2FF"
        />
        <path
          d="M0.943406 3.32566C1.05768 3.69721 1.42916 3.91022 1.81783 3.9036C4.4255 3.85916 7.06768 3.90575 9.77472 3.91494C10.167 3.91627 10.5351 3.69069 10.651 3.31594C10.7431 3.01776 10.7998 2.71186 10.7998 2.39835C10.7998 2.06453 10.742 1.73641 10.6397 1.41968C10.5289 1.07669 10.1964 0.867819 9.83638 0.850419C8.53421 0.787486 7.19659 0.75 5.79981 0.75C4.39996 0.75 3.03612 0.785292 1.74565 0.849926C1.39505 0.867486 1.0695 1.06709 0.960175 1.40067C0.854526 1.72305 0.799805 2.05816 0.799805 2.39835C0.799805 2.71542 0.850737 3.02435 0.943406 3.32566Z"
          stroke="#4F46E5"
          strokeWidth="1.5"
        />
      </svg>
    </SvgIcon>
  );
};

export default ArchiveIcon;
