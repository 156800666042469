import { LoadingButton } from "@mui/lab";
import { Grid, Typography, ButtonProps } from "@mui/material";
import { ReactNode } from "react";
import { InfoIcon } from "styles/icons";

interface IExtendedButtonInfoProps extends ButtonProps {
  children: ReactNode;
  sx?: object;
  infoText?: string;
  infoWrap?: boolean;
}

const LoadingButtonInfo: React.FC<IExtendedButtonInfoProps> = ({
  children,
  infoText,
  sx,
  infoWrap = false,
  ...props
}): JSX.Element => {
  return (
    <Grid
      container
      flexWrap={{ xs: "wrap", sm: infoWrap ? "wrap" : "nowrap" }}
      alignItems={"center"}
    >
      <LoadingButton sx={sx} {...props}>
        {children}
      </LoadingButton>
      {infoText && (
        <Grid
          item
          container
          alignItems={"center"}
          flexWrap={"nowrap"}
          mt={{ xs: 1, sm: 0 }}
        >
          <InfoIcon sx={{ mr: 0.5, ml: { xs: 0, sm: 1 } }} />

          <Typography
            component="span"
            variant="extraSmallRegularText"
            color="grey.500"
          >
            {infoText}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default LoadingButtonInfo;
