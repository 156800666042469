import { Grid } from "@mui/material";
import {
  IListing,
  SaleType,
  type IListingDetails,
} from "features/listing/types";
import ClosedPastListing from "./ClosedPastListing";
import OpenPastListing from "./OpenPastListing";

interface IProps {
  listing: IListingDetails | IListing | undefined;
  userId: number | null | undefined;
}

const PastListingStatus: React.FC<IProps> = ({
  listing,
  userId,
}): JSX.Element => {
  const isOpenListing = listing?.sale_type === SaleType.OPEN;
  return (
    <>
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        {isOpenListing && <OpenPastListing listing={listing} userId={userId} />}
        {!isOpenListing && <ClosedPastListing />}
      </Grid>
    </>
  );
};

export default PastListingStatus;
