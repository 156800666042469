import { Box, Stack } from "@mui/material";
import PlanBox from "./PlanBox";

// data
import { IMembershipType } from "features/subscriptions/api/apiTypes";
import type { Views } from "./AddMembership";

interface IProps {
  nextStep: (view: Views, plan: IMembershipType) => void;
  membershipTypes: IMembershipType[] | undefined;
}

const SelectPlan: React.FC<IProps> = ({
  nextStep,
  membershipTypes,
}): JSX.Element => {
  return (
    <Box m={{ xs: 1, sm: 3, md: 5 }} sx={{ position: "relative" }}>
      <Stack direction="row" justifyContent="center" gap={"40px"}>
        {membershipTypes?.map((membership, index) => (
          <PlanBox
            key={membership.name}
            membership={membership}
            nextStep={nextStep}
            features={features[index].featuresData}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default SelectPlan;

const features: any = [
  {
    key: 0,
    featuresData: [
      "Post 10 listings Per month.",
      "Accept user bids.",
      "View Agent ratings.",
      "Manage & Update Listings.",
    ],
  },
  {
    key: 1,
    featuresData: [
      "Unlimited listings Per month.",
      "Accept user bids.",
      "View Agent ratings.",
      "Manage & Update Listings.",
    ],
  },
];
