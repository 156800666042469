import { Grid, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
interface IProps {
  showShares: boolean;
  setShowShares: Dispatch<SetStateAction<boolean>>;
}

const TopPanelButtons: React.FC<IProps> = ({
  setShowShares,
  showShares,
}): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;

  return (
    <>
      <Grid p={2} item justifyContent={"center"} alignItems={"center"}>
        <Typography
          component="p"
          variant="h4"
          fontWeight={600}
          color={"primary"}
        >
          {listing?.view_count}
        </Typography>
        <Typography variant="extraSmallRegularText" color={"grey.500"}>
          Views
        </Typography>
      </Grid>
      <Grid p={2} item>
        <Typography
          component="p"
          variant="h4"
          fontWeight={600}
          color={"primary"}
        >
          {listing?.offer_increment}
        </Typography>
        <Typography variant="extraSmallRegularText" color={"grey.500"}>
          Offers
        </Typography>
      </Grid>
      <Grid
        p={2}
        item
        sx={showShares ? { ...shareStyleClicked } : { cursor: "pointer" }}
        onClick={() => setShowShares((prev) => !prev)}
      >
        <Typography
          component="p"
          variant="h4"
          fontWeight={600}
          color={"primary"}
        >
          {listing?.shared_listings?.length || 0}
        </Typography>
        <Typography variant="extraSmallRegularText" color={"grey.500"}>
          Shares
        </Typography>
      </Grid>
    </>
  );
};

export default TopPanelButtons;

const shareStyleClicked = {
  cursor: "pointer",
  backgroundColor: "secondary.main",
  borderRadius: "5px",
};
