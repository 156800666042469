import { TListingOffer } from "features/offers/api";

const sortHighestOffers = (
  offersList: TListingOffer[] | undefined,
  highestOffer: number | undefined
) => {
  if (!Array.isArray(offersList)) return null;
  const highestList = offersList.filter(
    (offer) => offer.offer_amount === (highestOffer ?? 0)
  );
  const otherOffersList = offersList.filter(
    (offer) => offer.offer_amount !== (highestOffer ?? 0)
  );
  return [...highestList, ...otherOffersList];
};
export { sortHighestOffers };
