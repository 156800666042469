import { Box } from "@mui/material";

const AbsoluteDivider: React.FC = (): JSX.Element => {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        left: 0,
        mt: 3,
        height: "1px",
        bgcolor: "grey.600",
      }}
    />
  );
};

export default AbsoluteDivider;
