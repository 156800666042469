import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { HelperText } from "Components/StyledComponents";
import { IListingDetails } from "features/listing/types";
import { DenialsText } from "features/offerors/components";
import { RetryPaymentSchema } from "features/payments/schema";
import { useForm } from "react-hook-form";
import { useDialogContext } from "store/hooks/DialogsContext";
import { InfoIcon } from "styles/icons";

interface IProps {
  denialNotes: string | undefined;
  denialReason: string | undefined;
  dateSubmitted: string;
  listing: IListingDetails;
  isBlocked: boolean;
  isPaymemtFailed: boolean;
  lastFourDigits: string | undefined;
}

const OpenLiveDeniedOfferor: React.FC<IProps> = ({
  denialNotes,
  denialReason,
  listing,
  dateSubmitted,
  isBlocked,
  isPaymemtFailed,
  lastFourDigits,
}): JSX.Element => {
  const { openDialog } = useDialogContext();
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(RetryPaymentSchema),
  });
  const handleButtonClick = () => {
    if (!isPaymemtFailed) {
      openDialog("RegisterToOfferStepper");
    } else {
      openDialog("CVVRetry", {
        methods: methods,
        lastFourDigits: lastFourDigits,
      });
    }
  };

  return (
    <Grid container mt={1}>
      <Typography
        component={"span"}
        color="grey.700"
        variant="extraSmallRegularText"
      >
        Date Submitted:
        <Typography
          ml={1}
          component={"span"}
          variant="extraSmallBoldText"
          color="grey.700"
        >
          {dateSubmitted}
        </Typography>
      </Typography>
      <Grid container mt={1}>
        {"isDenialsExists" && (
          <DenialsText
            sx={denialsContainerStyle}
            denialNotes={denialNotes}
            denialReason={denialReason}
          />
        )}
      </Grid>

      <Grid item xs={12} mt={2}>
        <LoadingButton
          fullWidth
          variant="tertiary"
          onClick={() => openDialog("UpdatePayment", { methods: methods })}
        >
          Update Payment Method
        </LoadingButton>
      </Grid>
      <Grid
        container
        justifyContent={"center"}
        mt={1}
        gap={2}
        flexDirection={"column"}
      >
        <Grid item xs={10}>
          <LoadingButton
            fullWidth
            variant="tertiary"
            onClick={() =>
              openDialog("OfferorDetails", {
                listing,
              })
            }
          >
            View
          </LoadingButton>
        </Grid>

        {isBlocked && (
          <HelperText
            sx={{ fontSize: "0.75rem" }}
            icon={<InfoIcon sx={{ fontSize: "0.75rem", mr: 0.5 }} />}
            text={"You have been blocked from resubmitting"}
          />
        )}
        {!isBlocked && (
          <Grid item xs={10}>
            <LoadingButton
              onClick={handleButtonClick}
              fullWidth
              variant="primary"
            >
              {!isPaymemtFailed ? "Resubmit" : "Retry"}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default OpenLiveDeniedOfferor;

const denialsContainerStyle = {
  gap: 1,
};
