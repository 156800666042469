import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useEmailVerificationMutation } from "features/auth/api/authApi";
import { AccountServices } from "features/listing/types";
import { useAppDispatch } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { closePopup } from "./popupSlice";

const BlockUnverifyDialog: React.FC = (): JSX.Element => {
  const { openDialog } = useDialogContext();
  const [emailVerification] = useEmailVerificationMutation();
  const dispatch = useAppDispatch();
  const handleVerifyClick = () => {
    dispatch(closePopup());
    openDialog("EmailVerification", {
      dialogType: AccountServices.EMAIL_VERIFY,
    });
    emailVerification();
  };

  return (
    <>
      <DialogContent sx={{ py: 0, pb: 2, px: { xs: 1, sm: 2 } }}>
        <Typography
          sx={{ mb: 0.5 }}
          variant="h5Bold"
          color="primary"
          textAlign={"center"}
        >
          Your email address is not verified
        </Typography>
        <Typography
          color="grey.300"
          textAlign={"center"}
          lineHeight={"1.25rem"}
        >
          Your email address must be verified to access this option
        </Typography>
      </DialogContent>
      <DialogActions sx={{ mx: "auto" }}>
        <LoadingButton
          variant="primary"
          onClick={handleVerifyClick}
          size="large"
          sx={{ minWidth: 150 }}
        >
          Verify Now
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default BlockUnverifyDialog;
