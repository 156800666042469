import { Box, DialogTitle, Grid, Typography } from "@mui/material";
import { ErrorResponse } from "@remix-run/router";
import { digitsRegex } from "Components/Validation/regex";
import { useRetryPaymentMutation } from "features/offerors/api/offerorsApi";
import { listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { ErrorIcon } from "styles/icons";
import { DialogContainer } from ".";
import { ControlledTextField } from "../FormComponents";
import DialogActions from "./DialogActions";

interface IProps {
  lastFourDigits: number;
  methods: any;
}

const CVVRetryDialog: React.FC<IProps> = ({
  lastFourDigits,
  methods,
}): JSX.Element => {
  const { listing } = useAppSelector(listingState);
  const { openDialog, closeDialog } = useDialogContext();
  const [retryPayment, { error: retryError, isLoading: retryLoading }] =
    useRetryPaymentMutation();

  const handleSubmitClick = async () => {
    const body = {
      cvv: methods?.watch("cvv"),
    };
    const isCvvValid = await methods?.trigger("cvv");
    try {
      if (isCvvValid) {
        await retryPayment({ listingId: listing?.id, body }).unwrap();
        closeDialog();
      }
    } catch (error) {
      console.error("Error adding payment method:", error);
    }
  };

  return (
    <DialogContainer hideExit sx={dialogContainerStyle}>
      <>
        <DialogTitle sx={titleStyle}>
          Please verify the CVV <br /> for CC ending in {lastFourDigits || "**"}
          {retryError && (
            <Grid
              container
              my={1}
              alignItems="center"
              justifyContent={"center"}
            >
              <Grid
                item
                display={"flex"}
                alignItems={"center"}
                gap={0.5}
                mr={0.5}
              >
                <ErrorIcon sx={{ fontSize: "1.25rem" }} />
                <Typography
                  variant="extraSmallBoldText"
                  component={"span"}
                  color="grey.900"
                >
                  There was an error:
                </Typography>
              </Grid>
              <Typography
                variant="extraSmallRegularText"
                component={"span"}
                color="grey.900"
              >
                {(retryError as ErrorResponse)?.data?.message}
              </Typography>

              <Typography
                onClick={() =>
                  openDialog("UpdatePayment", { methods: methods })
                }
                sx={{ cursor: "pointer", mt: 1, textDecoration: "underline" }}
                variant="extraSmallRegularText"
                color="primary.main"
              >
                Select New Payment Method
              </Typography>
            </Grid>
          )}
        </DialogTitle>
        <Box mx="auto">
          <ControlledTextField
            sx={cvvTextStyle}
            control={methods.control}
            name={"cvv"}
            placeholder="CVV"
            regex={digitsRegex}
            maxLength={4}
          />
        </Box>
        <DialogActions onSubmit={handleSubmitClick} isLoading={retryLoading} />
      </>
    </DialogContainer>
  );
};

export default CVVRetryDialog;

export const titleStyle = {
  textAlign: "center",
  color: "primary.main",
  fontWeight: 700,
  pt: 1,
};
export const dialogContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiDialog-paper": {
    borderRadius: 2,
    width: 380,
  },
  "@media (max-width: 601px)": {
    "& .MuiDialog-paper": {
      minWidth: "90%",
    },
  },
};

const cvvTextStyle = {
  input: {
    textAlign: "center !important",
    fontSize: "1.125rem !important",
    maxWidth: 100,
    fontWeight: 600,
    ":placeholder": {
      fontSize: "1.125rem !important",
    },
  },
};
