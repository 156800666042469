import { Theme } from "@mui/material/styles";

export const transparent = (theme: Theme) => ({
  color: theme.palette.common.black,
  backgroundColor: "transparent",
  borderColor: "transparent",
  transitionProperty: "all",
  "&:hover": {
    backgroundColor: theme.palette.grey[50],
    borderColor: theme.palette.grey[50],
  },
  "&:focus": {
    backgroundColor: theme.palette.grey[50],
  },
  "&:active": {
    backgroundColor: theme.palette.grey[50],
    borderColor: theme.palette.grey[50],
  },
});
