import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ErrorTextIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 11 9"
      {...props}
      sx={{
        fontSize: "0.625rem",
        ...sx,
      }}
    >
      <svg
        width="11"
        height="9"
        viewBox="0 0 11 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.875 3.70312L4.98438 5.45312C4.98438 5.5625 5.0625 5.625 5.17188 5.625H5.8125C5.92188 5.625 6 5.54688 6 5.45312L6.10938 3.70312C6.10938 3.59375 6.03125 3.5 5.92188 3.5H5.0625C4.95312 3.5 4.875 3.59375 4.875 3.70312ZM6.15625 6.5C6.15625 6.14062 5.85938 5.84375 5.5 5.84375C5.125 5.84375 4.84375 6.14062 4.84375 6.5C4.84375 6.875 5.125 7.15625 5.5 7.15625C5.85938 7.15625 6.15625 6.875 6.15625 6.5ZM6.14062 0.875C5.85938 0.390625 5.125 0.375 4.84375 0.875L1.09375 7.39062C0.8125 7.875 1.17188 8.5 1.75 8.5H9.23438C9.8125 8.5 10.1719 7.89062 9.89062 7.39062L6.14062 0.875ZM1.82812 7.625L5.40625 1.40625C5.45312 1.34375 5.53125 1.34375 5.57812 1.40625L9.15625 7.625C9.20312 7.6875 9.15625 7.75 9.07812 7.75H1.90625C1.82812 7.75 1.78125 7.6875 1.82812 7.625Z"
          fill="#EF4444"
        />
      </svg>
    </SvgIcon>
  );
};

export default ErrorTextIcon;
