import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography, type SxProps } from "@mui/material";
import { IMembershipType } from "features/subscriptions/api/apiTypes";
import { formatUSD } from "utils/helpers";
import { Views } from "./AddMembership";

interface IProps {
  plan: IMembershipType;
  setPlanSelect: React.Dispatch<React.SetStateAction<Views>>;
}

const PlanBoxSelected: React.FC<IProps> = ({
  plan,
  setPlanSelect,
}): JSX.Element => {
  return (
    <Stack
      sx={stackContainerStyle}
      direction="row"
      justifyContent="space-between"
    >
      <Box>
        <Typography variant="bigBoldText" component="span">
          {plan.name}
        </Typography>

        <Typography variant="smallRegularText" sx={{ fontSize: "16px" }}>
          <Typography variant="boldText" component="span">
            {formatUSD(plan.membership_fee)}{" "}
          </Typography>
          / month
        </Typography>
      </Box>

      <LoadingButton
        variant="transparent"
        sx={{ color: "common.white", border: "1px solid #fff" }}
        onClick={() => setPlanSelect("select-plan")}
      >
        Change Plan
      </LoadingButton>
    </Stack>
  );
};

export default PlanBoxSelected;

const stackContainerStyle: SxProps = {
  background:
    "linear-gradient(80.62deg, rgba(120, 114, 236, 0.744553) 6.98%, #6366F1 61.82%)",
  borderRadius: "16px",
  padding: "40px 30px",
  color: "common.white",
  alignItems: "center",
};
