import dayjs, { Dayjs } from "dayjs";

export const formatToMMM = (unixDate: string | Dayjs | null): string => {
  if (typeof unixDate === "object") {
    return dayjs(unixDate).format("MMM D, YYYY");
  }
  if (!unixDate) return "--";
  const stringToIntDate = parseInt(unixDate, 10);
  return dayjs.unix(stringToIntDate).format("MMM D, YYYY");
};

export const formatToMMDDYY = (unixDate: string): string => {
  const stringToIntDate = parseInt(unixDate, 10);
  return dayjs.unix(stringToIntDate).format("MM/DD/YY");
};

export const formatToHHMMA = (unixDate: string): string => {
  const stringToIntDate = parseInt(unixDate, 10);
  return dayjs.unix(stringToIntDate).format("hh:mm A");
};

export const formatToDDMMYYYYHHMMA = (unixDate: string): string => {
  const stringToIntDate = parseInt(unixDate, 10);
  return dayjs.unix(stringToIntDate).format("DD/MM/YYYY hh:mm A");
};

export const getRemainingTime = (startDate: string | number): Date => {
  const timestampString = startDate;
  const timestamp = Number(timestampString) * 1000;
  return new Date(timestamp);
};

export const convertToAmPm = (timeString: string) => {
  if (typeof timeString === "string") {
    const [hours, minutes] = timeString.split(":").map(Number);
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;
    return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${amPm}`;
  } else {
    return "";
  }
};

export const getTimeToEndDate = (
  unixTimestamp: number,
  delayInSeconds: number
): Date => {
  const timestampInMilliseconds = unixTimestamp * 1000;
  const endTimeInMilliseconds = timestampInMilliseconds + delayInSeconds * 1000;
  return new Date(endTimeInMilliseconds);
};

export const getDaysHoursDuration = (startDate: string, endDate: string) => {
  const startTime = dayjs.unix(Number(startDate));
  const endTime = dayjs.unix(Number(endDate));

  const duration = endTime.diff(startTime, "hour"); // Difference in hours
  const days = Math.max(Math.floor(duration / 24), 0); // Convert hours to days, ensure it's not negative
  const hours = Math.max(duration % 24, 0); // Remaining hours, ensure it's not negative
  if (days < 1 && hours < 1) return `--`;
  if (days < 1) return `${hours}h`;
  return `${days}d ${hours}h`;
};

export const hoursToDH = (
  hours: number,
  textType: "shortText" | "longText" = "shortText"
): string => {
  const days = Math.floor(hours / 24);
  const remainingHours = hours % 24;
  if (textType === "longText")
    return `${days} days and ${remainingHours} hours`;
  return `${days}d ${remainingHours}h`;
};

//Global to all status progress
export const calcProgressPercentageLeft = (
  startDate: number,
  endDate: number
): number => {
  const currentUnixTimestamp = new Date().getTime() / 1000;
  const totalMinutes = (endDate - startDate) / 60;
  const minutesPassed = (currentUnixTimestamp - startDate) / 60;
  if (totalMinutes <= 0) {
    return 0;
  }
  const percentageLeft = Math.max(
    0,
    Math.min(100, (100 * minutesPassed) / totalMinutes)
  );

  return percentageLeft;
};

export const getRightDateType = (value: any) => {
  return typeof value === "number"
    ? dayjs.unix(value)
    : typeof value === "object"
    ? dayjs(value)
    : dayjs(value || undefined);
};

export const formatToMMYYYY = (expDate: string) => {
  if (!expDate) return "";

  const month = expDate.slice(0, 2);
  const year = `20${expDate.slice(2, 4)}`;

  const formattedDate = dayjs(`${year}-${month}`).format("MM/YYYY");

  return formattedDate;
};
