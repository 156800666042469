import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import {
  useAddContingencyMutation,
  useGetSettingsContingenciesQuery,
} from "features/contingencies";
import {
  useAddAgentFileTypeMutation,
  useGetAgentFileTypesQuery,
} from "features/listing/api/agentApi";
import { ContingencyOrFileSchema } from "features/payments/schema/ContingencyOrFileSchema";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ArchiveIcon, NotificationIcon, PlusIcon } from "styles/icons";
import AddNewItemInput from "./AddNewItemInput";
import ContingencyOrFileTypeItem from "./ContingencyOrFileTypeItem";

interface IProps {
  isContingency: boolean;
}

const AgentSettingsTab: React.FC<IProps> = ({ isContingency }): JSX.Element => {
  const [isAddingNewItem, setIsAddingNewItem] = useState<boolean>(false);
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const { data: fileTypes, isLoading: fileTypesLoading } =
    useGetAgentFileTypesQuery(
      { archived: isArchived },
      { skip: isContingency }
    );
  const { data: contingencies, isLoading: contingenciesLoading } =
    useGetSettingsContingenciesQuery(isArchived, {
      skip: !isContingency,
    });

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(ContingencyOrFileSchema),
  });
  const [addContingency] = useAddContingencyMutation();
  const [addFileType] = useAddAgentFileTypeMutation();
  const list = contingencies || fileTypes || [];
  useEffect(() => {
    if (isContingency) {
      contingencies?.map((item) =>
        methods.setValue(String(item.id), item.name)
      );
    } else {
      fileTypes?.map((item) => methods.setValue(String(item.id), item.name));
    }
  }, [isContingency, contingencies, fileTypes]);

  const addNewContingency = async (value: string) => {
    let isValid = false;
    const name = isContingency ? "new_contingency" : "new_file";
    isValid = await methods.trigger(name);
    if (isValid) {
      if (isContingency) {
        addContingency(value);
      } else {
        addFileType(value);
      }
      methods.setValue(name, "");
      setIsAddingNewItem(false);
    }
  };

  return (
    <ConditionalWrapper isLoading={fileTypesLoading || contingenciesLoading}>
      <FormProvider {...methods}>
        <Grid container justifyContent={"space-between"} gap={1.5}>
          <Grid item xs={12} sm={9}>
            {list?.map((item) => (
              <ContingencyOrFileTypeItem
                key={item.id}
                item={item}
                isContingency={isContingency}
                isArchived={isArchived}
                control={methods.control}
              />
            ))}
            {isAddingNewItem && !isArchived ? (
              <AddNewItemInput
                control={methods.control}
                isContingency={isContingency}
                addNewContingency={addNewContingency}
                setIsAddingNewItem={setIsAddingNewItem}
              />
            ) : (
              !isArchived && (
                <LoadingButton
                  variant="tertiaryPrimary"
                  startIcon={<PlusIcon />}
                  onClick={() => setIsAddingNewItem((prev) => !prev)}
                >
                  {isContingency ? "Add Contingency" : "Add File Type"}
                </LoadingButton>
              )
            )}
          </Grid>
          <LoadingButton
            variant="tertiary"
            startIcon={
              isArchived ? (
                <NotificationIcon stroke="#6366F1" sx={{ width: 14 }} />
              ) : (
                <ArchiveIcon sx={{ width: 14 }} />
              )
            }
            onClick={() => setIsArchived((isArchived) => !isArchived)}
          >
            {isArchived ? "Active" : "Archived"}
          </LoadingButton>
        </Grid>
      </FormProvider>
    </ConditionalWrapper>
  );
};

export default AgentSettingsTab;
