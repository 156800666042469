import { IContingency } from "features/offers/api";
import { ExtendedTypographyVariant } from "styles/theme/typography";

export enum ListingStatus {
  DRAFT = "draft",
  LIVE = "live",
  PUBLISHED = "published",
  PUBLISH_SCHEDULED = "publish_scheduled",
  PENDING_WINNER = "pending_winner",
  PENDING_CONTRACT = "pending_contract",
  NO_OFFERS = "no_offers",
  NO_WINNER = "no_winner",
  FAILED_SALE = "failed_sale",
  FAILED_CONTRACT = "failed_contract",
  IN_CONTRACT = "in_contract",
  SOLD = "sold",
}

enum ListingStatusCategory {
  DRAFT = "Draft Listings",
  LIVE = "Live Listings",
  PUBLISH = "Publish",
  PENDING = "Pending",
  FAILED = "Failed",
  SOLD = "Sold",
  IN_CONTRACT = "In Contract",
}

export enum SaleType {
  CLOSED = "closed",
  OPEN = "open",
}

export enum AccountServices {
  RESET_PASSWORD = "reset_password",
  EMAIL_VERIFY = "email_verify",
  EMAIL_UPDATE = "email_update",
}

interface IListing {
  id: string;
  additional_photos: IAdditionalPhoto[];
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip: string;
  main_photo: string;
  user_id: number;
  status: ListingStatus;
  inspection_report: any;
  title_report: any;
  description: string;
  price: number;
  sale_type: SaleType;
  offer_increment: number;
  auction_start_date: string;
  auction_end_date: string;
  auction_end_delay: string;
  contract_due_date: string;
  auction_ended: string;
  created_at: string;
  archived: boolean;
  offer_count: number;
  highest_offer: number;
  winner_first_name: string;
  winner_last_name: string;
  selling_price: number;
  sale_contract: string;
  security_hold: number;
  publish_date: string;
  verification: string;
  statusText: string;
  statusCat: ListingStatusCategory;
  reserved: boolean;
  reserved_amount: number;
  full_address: string;
  isArchivable: boolean;
  is_favorite: boolean;
  is_shared: boolean;
  contract_signed: string;
  contingencies: IContingency[];
  attorney_email_address: string;
  attorney_first_name: string;
  attorney_last_name: string;
  attorney_number: string;
  property_type: string;
  automatic_winner: boolean;
  contingency_disclaimer: string;
}

interface IListingDetails {
  id: string;
  additional_photos: IAdditionalPhoto[];
  archived: boolean;
  attorney_email_address: string;
  attorney_first_name: string;
  attorney_last_name: string;
  attorney_number: string;
  auction_end_date: string;
  auction_start_date: string;
  auction_end_delay: string;
  auction_ended: string;
  publish_date: string;
  automatic_winner: boolean;
  description: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip: string;
  full_address: string;
  inspection_report: any;
  title_report: any;
  main_photo: any;
  offer_increment: number;
  price: number;
  property_type: string;
  contingencies: IContingency[];
  reserved: boolean;
  reserved_amount: number;
  sale_type: SaleType;
  security_hold: number;
  shared_listings: string;
  contract_due_date: string;
  status: ListingStatus;
  view_count: string;
  winner_selected: string;
  contingency_disclaimer: string;
  agentName: string;
  agentCompany: string;
  agentPhone: string;
  agentEmail: string;
}

interface IAgentInfo {
  company: string;
  email: string;
  legal_name: string;
  phone: string;
}

interface IAdditionalPhoto {
  photo_name: string;
  photo_url: string;
  description: string;
}

interface ITopPanel {
  text: string;
  value: string | number | React.ReactNode;
  color: string;
  variant?: ExtendedTypographyVariant;
}

interface Step {
  label: string;
}

interface IFileType {
  id: string;
  name: string;
}

interface IInvitedUser {
  email_address: string;
  first_name: string;
  last_name: string;
}

interface IHighestOffer {
  amount: number;
  user_id: string;
}
export {
  IAdditionalPhoto,
  IAgentInfo,
  IFileType,
  IHighestOffer,
  IInvitedUser,
  IListing,
  IListingDetails,
  ITopPanel,
  ListingStatusCategory,
  Step,
};
