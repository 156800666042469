import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { AccountServices } from "features/listing/types";

import React, { useEffect, useState } from "react";
import { useDialogContext } from "store/hooks/DialogsContext";

const EmailVerificationStrip: React.FC = (): JSX.Element => {
  const { openDialog } = useDialogContext();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  });

  return (
    <Grid container sx={containerStyle(mounted)}>
      <Grid
        container
        sx={{ mx: "auto", px: { xs: 1, md: 5 } }}
        gap={3}
        maxWidth="xl"
      >
        <Typography
          color="primary.dark"
          sx={{
            textTransform: "none",
            typography: { xs: "extraSmallBoldText", sm: "boldText" },
            alignSelf: "center",
          }}
        >
          Your email address is not verified
        </Typography>
        <LoadingButton
          variant="primary"
          onClick={() => {
            openDialog("EmailVerification", {
              dialogType: AccountServices.EMAIL_VERIFY,
            });
          }}
          size="small"
          sx={{ py: { xs: 1, sm: 2 } }}
        >
          Verify Now
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default EmailVerificationStrip;

const containerStyle = (mounted: boolean) => ({
  bgcolor: "#D4D1FF",
  py: 0.75,
  transform: `translateY(${mounted ? 0 : "-100%"})`,
  transition: "transform 0.3s",
  justifyContent: { xs: "center", md: "start" },
});
