import { ArrowBackIos } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { Theme, useMediaQuery } from "@mui/material";

interface IProps {
  variant?: any;
  sx?: object;
}

const BackButton: React.FC<IProps> = ({ variant = "primary", sx }) => {
  const navigate = useNavigate();

  return (
    <LoadingButton
      variant={variant}
      sx={{ color: "primary.main", padding: 0, fontSize: "0.75rem", ...sx }}
      startIcon={<ArrowBackIos />}
      onClick={() => navigate(-1)}
    >
      Back
    </LoadingButton>
  );
};

export default BackButton;
