import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { RequestStatusButton } from "Components/Buttons";
import { formatToMMDDYY } from "Components/DatesAndTime/helpers";
import LoadingButtonInfo from "Components/Fields/LoadingButtonInfo";
import { useRetryPaymentMutation } from "features/offerors/api/offerorsApi";
import { useForm } from "react-hook-form";
import { listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { useListingDetailsStepperContext } from "store/hooks/ListingDetailsStepperContext";
import { EditPencilIcon } from "styles/icons";
import OfferorAttorneyCard from "./OfferorAttorneyCard";
import OfferorPurchaserCard from "./OfferorPurchaserCard";
import { RetryPaymentSchema } from "features/payments/schema";

const RequestOfferorDetails: React.FC = (): JSX.Element => {
  const { openDialog, closeDialog } = useDialogContext();
  const { setStepToShow, options } = useListingDetailsStepperContext();
  const { listing } = useAppSelector(listingState);
  const [retryPayment] = useRetryPaymentMutation();
  const dateSubmitted = formatToMMDDYY(
    options.offerorRequestData?.date_created || ""
  );
  const isDenied = options.offerorRequestData?.status === "denied";
  const isPaymentFailed =
    options.offerorRequestData?.status === "payment_failed";
  const isPending =
    options.offerorRequestData?.status === "pending" ||
    options.offerorRequestData?.status === "pending_payment";
  const initialProofOfFounds = options.offerorRequestData?.proof_of_funds;
  const isProofOfFounds = options.offerorRequestData?.proof_of_funds !== "";
  const lastFourDigits =
    options?.offerorRequestData?.payment_method_last_four_digits;
  const denialReason = options.offerorRequestData?.denial_reason_name || "";
  const denialNotes = options.offerorRequestData?.denial_notes || "";
  const showDenialNotes = denialNotes !== "";
  const showDenialReason = denialReason !== "";
  const isBlocked = options.offerorRequestData?.blocked;

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(RetryPaymentSchema),
  });

  const handleEdit = () => {
    setStepToShow(0);
    openDialog("RegisterToOfferStepper");
  };

  const handleRetry = () => {
    openDialog("CVVRetry", {
      methods: methods,
      lastFourDigits: lastFourDigits,
    });
  };

  return (
    <Grid container>
      <Grid
        container
        flexDirection={{ xs: "column", sm: "row" }}
        gap={1}
        justifyContent={"start"}
        alignItems={"center"}
      >
        <Grid item flex={1} minWidth={160}>
          <Typography variant="h6Bold" color="primary">
            Register To Bid
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"center"}>
          <Typography mr={0.5} color="grey.700" variant="extraSmallRegularText">
            Date Submitted:
          </Typography>
          <Typography mr={2} variant="extraSmallBoldText" color="grey.700">
            {dateSubmitted}
          </Typography>
          <RequestStatusButton
            status={options.offerorRequestData?.status || null}
          />
        </Grid>
      </Grid>
      {isPaymentFailed && (
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-around"}
          mt={4}
          gap={2}
        >
          <Grid item xs={5}>
            <LoadingButton
              fullWidth
              variant="tertiary"
              onClick={() => openDialog("UpdatePayment", { methods: methods })}
            >
              Update Payment Method
            </LoadingButton>
          </Grid>
          <Grid item xs={5}>
            <LoadingButton fullWidth variant="primary" onClick={handleRetry}>
              Retry
            </LoadingButton>
          </Grid>
        </Grid>
      )}
      {isDenied && (
        <Grid sx={{ ...boxContainersStyle, py: 2 }} container gap={0.5}>
          {!!showDenialReason && (
            <Grid container>
              <Typography
                flexBasis={"120px"}
                variant="extraSmallBoldText"
                color="grey.700"
              >
                Denial Reason:
              </Typography>
              <Typography
                flex={1}
                variant="extraSmallRegularText"
                color="grey.700"
              >
                {denialReason}
              </Typography>
            </Grid>
          )}
          {!!showDenialNotes && (
            <Grid container mb={1}>
              <Typography
                flexBasis={"120px"}
                variant="extraSmallBoldText"
                color="grey.700"
              >
                Denial Note:
              </Typography>
              <Typography
                flex={1}
                component="span"
                variant="extraSmallRegularText"
                color="grey.700"
              >
                {denialNotes}
              </Typography>
            </Grid>
          )}
          <LoadingButtonInfo
            variant={!isBlocked ? "primary" : "greyLight"}
            onClick={() =>
              openDialog("RegisterToOfferStepper", { isNewRegister: false })
            }
            infoText={
              isBlocked
                ? "You have been blocked by the agent from resubmitting verification on this listing"
                : ""
            }
            sx={{ minWidth: 220, pointerEvents: isBlocked ? "none" : "auto" }}
          >
            Resubmit Verification
          </LoadingButtonInfo>
        </Grid>
      )}
      <Grid sx={boxContainersStyle} container gap={2.5}>
        {/*isProofOfFounds ? (
          <Grid item xs={12} py={1} sx={preApprovalContainerStyle}>
            <ControlledDropzone
              hasDownloadButton
              style={registerToBidDzStyle}
              control={methods.control}
              name={"proof_of_funds"}
              label={"Pre-Approval:"}
              initialName={initialProofOfFounds}
              hasDeleteButton={false}
            />
          </Grid>
        ) : (
          <Grid item xs={12} py={1} sx={preApprovalContainerStyle}>
            <Typography variant="extraSmallBoldText" color="grey.800">
              Pre-Approval:
            </Typography>
            <Typography sx={proofOfFoundsValueStyle}>
              Pre-Approval not found
            </Typography>
          </Grid>
        )*/}
        <Grid container>
          {isPending && (
            <Grid container justifyContent={"end"}>
              <EditPencilIcon />
              <Typography
                variant="extraSmallRegularText"
                color="#0000EE"
                onClick={handleEdit}
                sx={editVerificationStyle}
              >
                Edit Verification
              </Typography>
            </Grid>
          )}

          <OfferorPurchaserCard options={options} />
        </Grid>
        <OfferorAttorneyCard options={options} />
      </Grid>
    </Grid>
  );
};

export default RequestOfferorDetails;

const boxContainersStyle = {
  mt: { xs: 2, md: 3 },
  p: { xs: 1.5, md: 3 },
  boxShadow: "0px 0px 10px 0px rgba(99, 102, 241, 0.1)",
  bgcolor: "common.white",
  borderRadius: 1.75,
};

const proofOfFoundsValueStyle = {
  fontSize: "0.6875rem",
  fontWeight: 500,
  color: "grey.500",
  flex: 1,
  display: "flex",
  justifyContent: "center",
  mb: 2,
};

const preApprovalContainerStyle = {
  border: "1px solid #E5E7EB",
  backgroundColor: "grey.50",
  borderRadius: 1,
  px: { xs: 2, sm: 3 },
};
const editVerificationStyle = {
  cursor: "pointer",
  textDecoration: "underline",
  mr: 0.5,
  pb: 0.5,
};

export const containerCardStyle = {
  border: "1px solid #E5E7EB",
  backgroundColor: "grey.50",
  borderRadius: 1,
  px: { xs: 2, sm: 3 },
};
