import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid } from "@mui/material";
import { ControlledTextField } from "Components/FormComponents";
import FullAddressForm from "Components/FormComponents/FullAddressForm";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { digitsRegex, textRegex } from "Components/Validation/regex";
import {
  useUpdateUserEmailMutation,
  useUpdateUserPhoneMutation,
  useUpdateUsernameMutation,
} from "features/listing/api/userApi";
import { AccountServices } from "features/listing/types";
import { updateUserInfoSchema } from "features/settings/schema/updateUserInfoSchema";
import { useGetUserInfoQuery } from "features/users";
import { FormProvider, useForm } from "react-hook-form";
import { selectAuth, setUser } from "store/features/authSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { EditPencilIcon } from "styles/icons";

type TUpdateInfoFields =
  | "email"
  | "username"
  | "phone"
  | "password"
  | "first_name"
  | "last_name";

interface IUserUpdateInfoForm {
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  phone: string;
  password: string;
}
const AccountSettingsTab: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);
  const { data: userData, isLoading: userLoading } = useGetUserInfoQuery();
  const methods = useForm<IUserUpdateInfoForm>({
    defaultValues: {
      phone: userData?.user.phone || "",
      first_name: userData?.user.first_name || "",
      last_name: userData?.user.last_name || "",
      username: userData?.user.username || "",
      email: userData?.user.email || "",
      password: "********",
    },
    mode: "onChange",
    resolver: yupResolver(updateUserInfoSchema),
  });
  const { openDialog } = useDialogContext();
  const [updateUserEmail] = useUpdateUserEmailMutation();
  const [updateUserPhone] = useUpdateUserPhoneMutation();
  const [updateUsername] = useUpdateUsernameMutation();
  const handleUpdateField = async (field: TUpdateInfoFields) => {
    try {
      const isValid = await methods.trigger([field]);
      if (isValid) {
        const newValue = methods.watch(field);
        switch (field) {
          case "email":
            if (methods.formState.defaultValues?.email !== newValue) {
              updateUserEmail(newValue);
              openDialog("EmailVerification", {
                dialogType: AccountServices.EMAIL_UPDATE,
              });
            }
            break;
          case "username":
            updateUsername(newValue);
            break;
          case "phone":
            updateUserPhone(newValue);
            break;

          default:
            break;
        }
        dispatch(
          setUser({
            ...authState,
            portal: authState.portal,
            verified: true,
          })
        );
      }
    } catch (error) {
      console.error("Error in methods.trigger:", error);
    }
  };

  return (
    <ConditionalWrapper isLoading={userLoading}>
      <Grid container sx={wrapperTwoColumnsStyle}>
        <FormProvider {...methods}>
          <Grid item xs={12} sm={5.5}>
            <Grid
              container
              justifyContent={"space-between"}
              flexWrap={"nowrap"}
              gap={2}
            >
              <ControlledTextField
                fullWidth
                control={methods.control}
                name="first_name"
                label="First Name"
                regex={textRegex}
                sx={disableTextStyle}
              />
              <ControlledTextField
                fullWidth
                control={methods.control}
                name="last_name"
                label="Last Name"
                regex={textRegex}
                sx={disableTextStyle}
              />
            </Grid>

            <ControlledTextField
              onUpdateClick={handleUpdateField}
              fullWidth
              editableText
              control={methods.control}
              resetField={methods.resetField}
              name="email"
              inputMode="email"
              label="Email Address"
            />

            <ControlledTextField
              onUpdateClick={handleUpdateField}
              fullWidth
              editableText
              control={methods.control}
              resetField={methods.resetField}
              name="username"
              label="Username"
            />

            <ControlledTextField
              onUpdateClick={handleUpdateField}
              fullWidth
              editableText
              control={methods.control}
              resetField={methods.resetField}
              name="phone"
              inputMode="numeric"
              label="Phone Number"
              regex={digitsRegex}
              maxLength={10}
            />

            <ControlledTextField
              onUpdateClick={handleUpdateField}
              fullWidth
              onClick={() => openDialog("ChangePassword")}
              control={methods.control}
              name="password"
              label="Password"
              type="password"
              endAdornment={<EditPencilIcon />}
            />
          </Grid>

          <Divider
            sx={{ borderColor: "grey.600" }}
            orientation="vertical"
            variant="middle"
            flexItem
          />
          <Grid
            item
            xs={12}
            sm={5.5}
            sx={{
              pt: { xs: 3, sm: 0 },
              borderTop: { xs: "1px solid #E5E7EB", sm: "none" },
            }}
          >
            <FullAddressForm userData={userData} />
          </Grid>
        </FormProvider>
      </Grid>
    </ConditionalWrapper>
  );
};

export default AccountSettingsTab;

const wrapperTwoColumnsStyle = {
  maxWidth: 800,
  gap: { xs: 0, sm: 2 },
  justifyContent: "space-between",
};

export const deniedContainersStyle = {
  mt: { xs: 2, md: 3 },
  p: { xs: 1.5, md: 3 },
  boxShadow: "0px 0px 10px 0px rgba(99, 102, 241, 0.1)",
  bgcolor: "common.white",
  borderRadius: 1.75,
};

export const disableTextStyle = {
  pointerEvents: "none",
};
