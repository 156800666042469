import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const MarkerIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 30 34"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="30"
        height="34"
        viewBox="0 0 30 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="30" height="30" rx="4" fill="#6366F1" />
        <path
          d="M8.75 15.6948L14.2426 9.32342C14.6415 8.86067 15.3585 8.86067 15.7574 9.32342L21.25 15.6948"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6732 13.4859L14.2413 9.32887C14.6403 8.86395 15.3598 8.86395 15.7589 9.32887L19.327 13.4859L19.5874 15.7498C19.7337 17.0213 19.7091 18.3067 19.5143 19.5717V19.5717C19.4065 20.2713 18.8045 20.7877 18.0967 20.7877H11.9035C11.1956 20.7877 10.5936 20.2713 10.4859 19.5717V19.5717C10.2911 18.3067 10.2665 17.0213 10.4127 15.7498L10.6732 13.4859Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 29.5L14.576 32.8216C14.7718 33.1349 15.2282 33.1349 15.424 32.8216L17.5 29.5H12.5Z"
          fill="#6366F1"
        />
        <path
          d="M12.5 29.5L14.576 32.8216C14.7718 33.1349 15.2282 33.1349 15.424 32.8216L17.5 29.5M12.5 29.5H10.5M12.5 29.5H17.5M17.5 29.5H19.5"
          stroke="#6366F1"
        />
      </svg>
    </SvgIcon>
  );
};

export default MarkerIcon;
