import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface ErrorIconProps extends SvgIconProps {
  fill?: string;
}

const ErrorIcon: React.FC<ErrorIconProps> = ({
  sx,
  fill = "#EF4444",
  ...props
}) => {
  return (
    <SvgIcon
      viewBox="0 0 8 9"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="8"
        height="9"
        viewBox="0 0 8 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 0.625C1.85938 0.625 0.125 2.375 0.125 4.5C0.125 6.64062 1.85938 8.375 4 8.375C6.125 8.375 7.875 6.64062 7.875 4.5C7.875 2.375 6.125 0.625 4 0.625ZM4 7.625C2.26562 7.625 0.875 6.23438 0.875 4.5C0.875 2.78125 2.26562 1.375 4 1.375C5.71875 1.375 7.125 2.78125 7.125 4.5C7.125 6.23438 5.71875 7.625 4 7.625ZM4.65625 6C4.65625 5.64062 4.35938 5.34375 4 5.34375C3.625 5.34375 3.34375 5.64062 3.34375 6C3.34375 6.375 3.625 6.65625 4 6.65625C4.35938 6.65625 4.65625 6.375 4.65625 6ZM3.375 2.70312L3.48438 4.82812C3.48438 4.92188 3.57812 5 3.67188 5H4.3125C4.40625 5 4.5 4.92188 4.5 4.82812L4.60938 2.70312C4.60938 2.59375 4.53125 2.5 4.42188 2.5H3.5625C3.45312 2.5 3.375 2.59375 3.375 2.70312Z"
          fill={fill}
        />
      </svg>
    </SvgIcon>
  );
};

export default ErrorIcon;
