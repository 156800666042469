import {
  IconButton,
  ListItemIcon,
  Menu as MUIMenu,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useAddListingToArchiveMutation } from "features/listing/api/agentApi";
import {
  IListing,
  IListingDetails,
  ListingStatus,
} from "features/listing/types";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { setCurrentListing } from "store/features/listingSlice";
import { useAppDispatch } from "store/hooks";
import { useCurrentListingContext } from "store/hooks/CurrentListingContext";
import { useDialogContext } from "store/hooks/DialogsContext";
import {
  ArchiveIcon,
  CalendarPrimaryIcon,
  DeleteIcon,
  DotsIcon,
  EditPencilIcon,
  PersonIcon,
} from "styles/icons";
import ContractTabIcon from "styles/icons/ContractTabIcon";
interface IProps {
  icon?: React.ReactNode;
  listing: IListing | IListingDetails | undefined;
  sx?: object;
}

const ListingOptionsMenu: React.FC<IProps> = ({
  icon,
  listing,
  sx,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { setDrawerState } = useCurrentListingContext();
  const { openDialog } = useDialogContext();
  const [addListingToArchive, { isLoading }] = useAddListingToArchiveMutation();
  const handleEdit = (popupState: any): any => {
    setDrawerState({ isOpen: true, mode: "edit" });
    dispatch(setCurrentListing(listing));
    popupState.close();
  };

  const handleArchive = (popupState: any) => {
    addListingToArchive({ id: listing?.id });
    popupState.close();
  };

  const getMenuByStatus = (
    status: ListingStatus | undefined,
    popupState: any
  ) => {
    const options: { text: string; icon: JSX.Element; onClick: () => void }[] =
      [];
    const editOption = {
      text: "Edit",
      icon: <EditPencilIcon />,
      onClick: () => handleEdit(popupState),
    };
    const deleteOption = {
      text: "Delete",
      icon: <DeleteIcon />,
      onClick: () =>
        openDialog("Delete", {
          listing,
        }),
    };
    const archiveOption = {
      text: "Archive",
      icon: <ArchiveIcon />,
      onClick: () => handleArchive(popupState),
    };
    const publishDateOption = {
      text: "Publish Date",
      icon: <CalendarPrimaryIcon />,
      onClick: () =>
        openDialog("UpdatePublishDate", {
          listing,
          publishDate: listing?.publish_date,
        }),
    };
    const selectWinnerOption = {
      text: "Select Winner",
      icon: <PersonIcon />,
      onClick: () => {},
    };
    const updateContractOption = {
      text: "Update Selling Contract",
      icon: <ContractTabIcon stroke={"#6366F1"} />,
      onClick: () => {},
    };
    const voidContractOption = {
      text: "Void Contract",
      icon: <ContractTabIcon stroke={"#6366F1"} />,
      onClick: () =>
        openDialog("ConfirmVoidContract", {
          listing,
          type: "void",
        }),
    };
    const voidSaleOption = {
      text: "Void Sale",
      icon: <ContractTabIcon stroke={"#6366F1"} />,
      onClick: () =>
        openDialog("ConfirmVoidSale", {
          listing,
          type: "void",
        }),
    };
    const updateStatusOption = {
      text: "Update Status",
      icon: <EditPencilIcon />,
      onClick: () =>
        openDialog("UpdateStatus", {
          listing,
        }),
    };
    switch (status) {
      case ListingStatus.LIVE:
        options.push(editOption);
        break;
      case ListingStatus.DRAFT:
        options.push(deleteOption);
        break;
      case ListingStatus.PUBLISH_SCHEDULED:
        options.push(editOption, archiveOption, publishDateOption);
        break;
      case ListingStatus.PUBLISHED:
        options.push(editOption, archiveOption);
        break;
      case ListingStatus.PENDING_WINNER:
        break;
      case ListingStatus.PENDING_CONTRACT:
        options.push(updateContractOption, voidContractOption);
        break;
      case ListingStatus.IN_CONTRACT:
        options.push(voidSaleOption, updateContractOption);
        break;
      case ListingStatus.SOLD:
        options.push(archiveOption, updateContractOption);
        break;
      case ListingStatus.FAILED_CONTRACT:
      case ListingStatus.FAILED_SALE:
        options.push(archiveOption, updateStatusOption, updateContractOption);
        break;
      case ListingStatus.NO_OFFERS:
        options.push(archiveOption);
        break;
      case ListingStatus.NO_WINNER:
        options.push(archiveOption, selectWinnerOption);
        break;
      default:
        return options;
    }
    return options;
  };

  return (
    <>
      <PopupState variant="popover" popupId="listing-options-menu">
        {(popupState) => (
          <>
            <IconButton
              sx={{
                ...sx,
              }}
              {...bindTrigger(popupState)}
            >
              {icon ? icon : <DotsIcon vertical />}
            </IconButton>
            <MUIMenu
              MenuListProps={{ disablePadding: true }}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              {...bindMenu(popupState)}
            >
              {getMenuByStatus(listing?.status, popupState).map(
                (option, index) => (
                  <MenuItem
                    key={index}
                    onClick={option.onClick}
                    style={{ minWidth: "140px" }}
                  >
                    <ListItemIcon style={{ minWidth: "26px" }}>
                      {option.icon}
                    </ListItemIcon>
                    <Typography variant={"extraSmallBoldText"}>
                      {option.text}
                    </Typography>
                  </MenuItem>
                )
              )}
            </MUIMenu>
          </>
        )}
      </PopupState>
    </>
  );
};

export default ListingOptionsMenu;
