import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, FormControl, Grid, Input, Typography } from "@mui/material";
import { ControlledNumericFormat } from "Components/FormComponents";
import { HelperText } from "Components/StyledComponents";
import ErrorText from "Components/StyledComponents/ErrorText";
import { SubmitOpenOfferSchema } from "Components/Validation";
import { useSubmitOfferMutation } from "features/offers/api/offersApi";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { DollarIcon, InfoIcon } from "styles/icons";

export interface ISubmitOpenOfferForm {
  offer_amount: string | null | undefined;
}

interface IProps {
  userId: number | null | undefined;
  highestOffer?: any;
}

const OpenOfferSubmitForm: React.FC<IProps> = ({
  userId,
  highestOffer,
}): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const { dialog, closeDialog } = useDialogContext();
  const itemId: string = useParams().itemId!;
  const offerIncrement = listing?.offer_increment;
  const minOfferAmount = (
    highestOffer?.amount >= listing?.price ? Number(highestOffer?.amount) + (offerIncrement || 0) : listing?.price
  ).toString();

  const [submitOffer, { isLoading: submitOfferLoading }] =
    useSubmitOfferMutation();

  const methods = useForm<ISubmitOpenOfferForm>({
    mode: "onChange",
    resolver: yupResolver(SubmitOpenOfferSchema(Number(minOfferAmount), true)),
  });
  const handleOfferSubmit: SubmitHandler<ISubmitOpenOfferForm> = (data) => {
    const body = {
      offer_amount: Number(data.offer_amount),
    };
    submitOffer({ listingId: itemId, body });
    closeDialog();
  };
  useEffect(() => {
    methods.setValue("offer_amount", minOfferAmount);
  }, [highestOffer, offerIncrement]);
  const isUserHighestOffer = highestOffer?.user_id === userId;
  const { openDialog } = useDialogContext();
  const [isReadyToSubmit, setIsReadyToSubmit] = useState<boolean>(false);
  const handleButtonClick = async () => {
    if (Number(methods.watch("offer_amount")) >= Number(minOfferAmount)) {
      openDialog("SubmitOffer", {
        offerAmount: methods.watch("offer_amount"),
        onConfirm: () => methods.handleSubmit(handleOfferSubmit)(),
      });
    }
  };
  const error = methods.formState.errors.offer_amount;
  return (
    <FormProvider {...methods}>
      <FormControl sx={{ mt: 1, minWidth: "100%" }}>
        <Box sx={highestBidStripStyle(isUserHighestOffer)}>
          <Typography sx={highestBidTextStyle} variant="extraSmallRegularText">
            {isUserHighestOffer
              ? "This is currently the highest offer"
              : "You are not the Highest Offeror"}
          </Typography>
        </Box>

        <Grid
          container
          justifyContent={"center"}
          p={2}
          gap={2}
          sx={containerStyle}
        >
          <Grid item sx={inputPriceStyle} justifySelf={"end"}>
            <ControlledNumericFormat
              hideError
              labelColor="#4B5563"
              control={methods.control}
              customInput={Input}
              name="offer_amount"
              placeholder="000"
              basicInputStartAdornment={<DollarIcon sx={dollarStyle} />}
            />
          </Grid>
          <LoadingButton
            loading={submitOfferLoading}
            variant={isReadyToSubmit ? "success" : "primary"}
            onClick={handleButtonClick}
            disabled={!!error}
          >
            {isReadyToSubmit ? "Confirm" : "Submit"}
          </LoadingButton>
        </Grid>
        {error && (
          <ErrorText text={error && error.message} color={"error.main"} />
        )}
        <HelperText
          icon={
            <InfoIcon
              sx={{
                fontSize: "0.7rem",
                mr: 0.5,
                color: "info.main",
              }}
            />
          }
          text={`Current Highest Offer must be incremented by a minimum of $${offerIncrement}`}
          sx={{ fontSize: "0.7rem" }}
        />

        <Typography
          mt={2}
          mb={0.5}
          color="primary.main"
          variant="smallBoldText"
        >
          Contingency Disclaimer
        </Typography>
        <Typography variant="extraSmallRegularText">
          {listing?.contingency_disclaimer}
        </Typography>
      </FormControl>
    </FormProvider>
  );
};

export default OpenOfferSubmitForm;

export const containerStyle = {
  zIndex: 1,
  backgroundColor: "common.white",
  alignItems: "center",
  border: "1px solid",
  borderColor: "grey.400",
  borderRadius: 1.5,
};

export const inputPriceStyle = {
  flex: 1,
  flexBasis: 125,
  pt: 2,
  input: {
    fontSize: "1.25rem !important",
    fontWeight: 700,
    color: "primary.main",
  },
  "& input::placeholder": {
    fontSize: "1.25rem",
  },
  ".MuiInputBase-root.MuiInput-root:before": {
    borderBottom: "1px solid",
    borderColor: "grey.400",
    fontSize: "1.25rem !important",
  },
};
export const dollarStyle = {
  fontWeight: "700",
  color: "#6366F1",
  fontSize: "1.25rem",
};

const highestBidStripStyle = (isHighestIsMyOffer: boolean) => ({
  height: 20,
  borderRadius: "12px 12px 0 0",
  bgcolor: isHighestIsMyOffer ? "success.light" : "error.light",
  position: "relative",
  "&:after": {
    content: "''",
    height: 1,
    position: "absolute",
    top: "100%",
    bgcolor: isHighestIsMyOffer ? "success.light" : "error.light",
    width: "100%",
  },
});

const highestBidTextStyle = {
  fontSize: "0.625rem",
  color: "common.white",
  ml: 2,
  lineHeight: "20px",
};
