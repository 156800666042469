const offerorRequestFieldsToGet = {
  attorney_email: "attorney_email",
  attorney_first_name: "attorney_first_name",
  attorney_last_name: "attorney_last_name",
  attorney_number: "attorney_number",
  blocked: "blocked",
  comments: "comments",
  cvv: "cvv",
  proof_of_funds: "proof_of_funds",
  payment_method_last_four_digits: "payment_method_last_four_digits",
  payment_method_name_on_card: "payment_method_name_on_card",
  file_type: "file_type",
  purchaser_first_name: "purchaser_first_name",
  purchaser_last_name: "purchaser_last_name",
  purchaser_address_line_1: "purchaser_address_line_1",
  purchaser_address_line_2: "purchaser_address_line_2",
  purchaser_city: "purchaser_city",
  purchaser_state: "purchaser_state",
  purchaser_zip: "purchaser_zip",
};
const offerorRequestFieldsToSend = {
  attorney_first_name: "attorney_first_name",
  attorney_last_name: "attorney_last_name",
  attorney_number: "attorney_number",
  attorney_email: "attorney_email",
  file_type: "file_type",
  purchaser_first_name: "purchaser_first_name",
  purchaser_last_name: "purchaser_last_name",
  purchaser_address_line_1: "purchaser_address_line_1",
  purchaser_address_line_2: "purchaser_address_line_2",
  purchaser_city: "purchaser_city",
  purchaser_state: "purchaser_state",
  purchaser_zip: "purchaser_zip",
  comments: "comments",
};
const purchaserFields = [
  "purchaser_first_name",
  "purchaser_last_name",
  "purchaser_address_line_1",
  "purchaser_address_line_2",
  "purchaser_city",
  "purchaser_zip",
  "purchaser_state",
];

const uploadFilesFields = ["proof_of_funds", "file_type"];

const attorneyFields = [
  "attorney_first_name",
  "attorney_last_name",
  "attorney_email",
  "attorney_number",
];
const offerFields = ["offer_amount", "mortgage_amount", "on_contract"];

const newPaymentFields = [
  "card_holder_name",
  "payment_method",
  "card_number",
  "mm",
  "yy",
  "new_card_cvv",
  "name_on_card",
  "billing_address",
  "zip_code",
];

const submitFields = [
  "attorney_first_name",
  "attorney_last_name",
  "attorney_number",
  "attorney_email",
  "purchaser_first_name",
  "purchaser_last_name",
  "purchaser_address_line_1",
  "purchaser_address_line_2",
  "purchaser_city",
  "purchaser_state",
  "purchaser_zip",
  "comments",

  "payment_method_last_four_digits",
];

export {
  offerorRequestFieldsToSend,
  offerorRequestFieldsToGet,
  purchaserFields,
  uploadFilesFields,
  attorneyFields,
  submitFields,
  offerFields,
  newPaymentFields,
};
