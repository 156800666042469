import { ephrownApi } from "store/services/ephrownApi";
import { IPaymentMethod, IPaymentRecord } from "./apiTypes";
import {
  IPaymentMethodsResponse,
  IPaymentRecordsResponse,
} from "./responseTypes";

type EditPayment = {
  payment_method_id: any;
  expiration_date: any;
  cvv: any;
};

const paymentMethodApi = ephrownApi.injectEndpoints({
  endpoints: (build) => ({
    getPaymentMethods: build.query<IPaymentMethod[], void>({
      query: () => ({
        url: `/api/unverified/payment-methods`,
        method: "GET",
      }),
      providesTags: ["payments"],
      transformResponse: (response: IPaymentMethodsResponse) =>
        response?.payment_methods?.reverse(),
    }),

    getPaymentRecords: build.query<IPaymentRecord[], void>({
      query: () => ({
        url: `/api/unverified/payment-records`,
        method: "GET",
      }),
      providesTags: ["payments"],
      transformResponse: (response: IPaymentRecordsResponse) => {
        return response?.payment_records;
      },
    }),

    addPaymentMethod: build.mutation<any, any>({
      query: ({ body }) => ({
        url: `/api/unverified/add-payment-method`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["payments"],
    }),

    editPaymentMethod: build.mutation<number, EditPayment>({
      query: (body) => ({
        url: "/api/unverified/update-payment-method-expiration-date",
        body,
        method: "POST",
      }),
      invalidatesTags: ["payments"],
    }),
    updatePaymentMethod: build.mutation({
      query: ({ listingId, payment_method_id }) => ({
        url: `/api/private/user/offeror-request/${listingId}/update-payment-method`,
        body: {
          payment_method_id: payment_method_id,
        },
        method: "PUT",
      }),
    }),

    deletePaymentMethod: build.mutation<number, number>({
      query: (paymentId) => ({
        url: `/api/unverified/payment-method/${paymentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["payments"],
    }),
  }),
});

export const {
  useAddPaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useEditPaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useGetPaymentRecordsQuery,
  useUpdatePaymentMethodMutation,
} = paymentMethodApi;
