import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import {
  ControlledNumericFormat,
  ControlledTextField,
} from "Components/FormComponents";
import { expiryDateFormat } from "Components/FormComponents/ControlledTextField/ControlledNumericFormat";
import { digitsOrSlashRegex, digitsRegex } from "Components/Validation/regex";
import {
  IPaymentMethod,
  useEditPaymentMethodMutation,
} from "features/payments/api";
import { EditPaymentMethodSchema } from "features/payments/schema";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDialogContext } from "store/hooks/DialogsContext";
import DialogActions from "./DialogActions";
import DialogContainer, { EditContainerStyle } from "./DialogContainer";

interface IEditPaymentMethodForm {
  expDate: string;
  cvv: string;
}

interface IProps {
  editedPaymentItem: IPaymentMethod;
  onEdited: () => void;
}

const EditPaymentDialog: React.FC<IProps> = ({
  editedPaymentItem,
  onEdited,
}) => {
  const [editItem, { isLoading }] = useEditPaymentMethodMutation();
  const { closeDialog } = useDialogContext();
  const { handleSubmit, control } = useForm<IEditPaymentMethodForm>({
    mode: "onChange",
    resolver: yupResolver(EditPaymentMethodSchema),
  });
  const handleRegisterForm: SubmitHandler<IEditPaymentMethodForm> = async (
    data: IEditPaymentMethodForm
  ) => {
    const formattedExpirationDate = data.expDate?.replace("/", "");
    editItem({
      expiration_date: formattedExpirationDate,
      cvv: data.cvv,
      payment_method_id: editedPaymentItem.id,
    }).then(onEdited);
    closeDialog();
  };

  return (
    <DialogContainer sx={EditContainerStyle} hideExit>
      <Box onSubmit={handleSubmit(handleRegisterForm)} component="form">
        <DialogTitle textAlign={"center"} color="primary" fontWeight={700}>
          Update Payment Method ending in {editedPaymentItem.last_four_digits}
        </DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          <Grid
            container
            mb={1}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Typography
                sx={{ width: 80 }}
                variant="smallRegularText"
                fontWeight={600}
                color={"primary.main"}
              >
                Exp. Date:
              </Typography>
            </Grid>
            <Grid item sx={fieldStyle}>
              <ControlledNumericFormat
                control={control}
                name="expDate"
                placeholder="MM/YY"
                format={expiryDateFormat}
                maxLength={5}
                mb={0}
                regex={digitsOrSlashRegex}
              />
            </Grid>
          </Grid>
          <Grid
            container
            mb={1}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Typography
                sx={{ width: 80 }}
                variant="smallRegularText"
                fontWeight={600}
                color={"primary.main"}
              >
                CVV:
              </Typography>
            </Grid>
            <Grid item mb={0} sx={fieldStyle}>
              <ControlledTextField
                name={"cvv"}
                control={control}
                placeholder="000"
                regex={digitsRegex}
                mb={0}
                maxLength={4}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions onCancel={() => closeDialog()} isLoading={isLoading} />
      </Box>
    </DialogContainer>
  );
};

export default EditPaymentDialog;

const fieldStyle = {
  width: 95,
  ".MuiFormHelperText-root.Mui-error": {
    textWrap: "nowrap",
  },
};
