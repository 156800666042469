import { Box } from "@mui/material";
import DialogManager from "Components/Dialogs/DialogManager";
import PageNotFound from "PageNotFound";
import MyAccount from "Pages/AccountSettings/MyAccount";
import Settings from "Pages/AccountSettings/Settings";
import Home from "Pages/index";
import Listings from "Pages/listings";
import UserListingDetails from "Pages/listings/UserlistingDetails";
import MyListings from "Pages/myListings";
import AgentListingDetails from "Pages/myListings/agentListingDetails";
import MyRequests from "Pages/myRequests";
import Navbar from "layout/Navbar";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import ApiErrorsPopup from "store/features/ApiErrorsPopup";
import { CurrentListingProvider } from "store/hooks/CurrentListingContext";
import { useDialogContext } from "store/hooks/DialogsContext";
import ProtectedRoute from "utils/routes/ProtectedRoute";
import PublicRoute from "utils/routes/PublicRoute";
import RemoveTrailingSlash from "utils/routes/RemoveTrailingSlash";
import "./customStyles.css";
import { useAppSelector } from "store/hooks";
import { selectAuth } from "store/features/authSlice";
import { BrowserView, MobileView } from "react-device-detect";
import MobileTemp from "MobileTemp";
import NavbarMobileTemp from "NavbarMobileTemp";

const App = (): JSX.Element => {
  const { verified } = useAppSelector(selectAuth);
  const { closeDialog } = useDialogContext();
  const location = useLocation();

  useEffect(() => {
    closeDialog();
  }, [location]);

  return (
    <>
      <BrowserView>
        <CurrentListingProvider>
          <ScrollToTop />
          <Navbar />
          <ApiErrorsPopup />
          <Box
            style={{
              height: "calc(100vh - 80px)",
            }}
          >
            <RemoveTrailingSlash />
            <Routes>
              <Route path="/verify-email" element={<Home />} />
              <Route path="/update-email" element={<Home />} />
              <Route path="/reset-password" element={<Home />} />
              {/* PUBLIC ROUTES */}
              <Route
                element={
                  <PublicRoute
                    redirect={{
                      admin: verified ? "/myListings" : "/listings",
                      agent: verified ? "/myListings" : "/listings",
                      user: "/listings",
                    }}
                  />
                }
              >
                <Route path="/login" element={<Home />} />
                <Route path="/signup" element={<Home />} />
              </Route>

              <Route element={<PublicRoute />}>
                <Route path="/listings" element={<Listings />} />
                <Route
                  path="/listings/:itemId"
                  element={<UserListingDetails />}
                />
              </Route>

              {/* PROTECTED ROUTES */}
              <Route
                element={<ProtectedRoute allowedRoles={["admin", "agent"]} />}
              >
                <Route path="/myRequests" element={<MyRequests />} />
              </Route>

              <Route
                element={
                  <ProtectedRoute
                    allowedRoles={["admin", "user", "agent"]}
                    redirect="/listings"
                  />
                }
              >
                <Route path="/settings" element={<Settings />} />
                <Route path="/my-account" element={<MyAccount />} />
              </Route>
              <Route
                element={<ProtectedRoute allowedRoles={["admin", "agent"]} />}
              >
                <Route path="/myListings" element={<MyListings />} />
                <Route
                  path="/myListings/:itemId"
                  element={<AgentListingDetails />}
                />
              </Route>
              {/* HOME ROUTE */}
              <Route
                element={
                  <PublicRoute
                    redirect={{
                      admin: "/myListings",
                      agent: "/myListings",
                      user: "/listings",
                    }}
                  />
                }
              >
                <Route path="/" element={<Navigate to="/listings" replace />} />
              </Route>

              <Route path="*" element={<PageNotFound />} />
            </Routes>
            <DialogManager />
          </Box>
        </CurrentListingProvider>
      </BrowserView>
      <MobileView>
        <NavbarMobileTemp />
        <MobileTemp />
      </MobileView>
    </>
  );
};

export default App;

export function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
