import { LoadingButton } from "@mui/lab";
import { Box, Dialog } from "@mui/material";
import { ReactNode } from "react";
import { useDialogContext } from "store/hooks/DialogsContext";

interface IProps {
  children: ReactNode;
  sx?: object;
  exitBtnStyle?: object;
  hideExit?: boolean;
  preventBackdrop?: boolean;
}

const DialogContainer: React.FC<IProps> = ({
  children,
  sx,
  hideExit,
  exitBtnStyle,
  preventBackdrop = false,
}): JSX.Element => {
  const { dialog, closeDialog } = useDialogContext();

  return (
    <Dialog
      disableScrollLock
      sx={{ ...dialogContainerStyle, ...sx }}
      maxWidth={"lg"}
      open={dialog?.name !== null}
      onClose={preventBackdrop ? undefined : () => closeDialog()}
    >
      <Box m={{ xs: 1, sm: 2 }} sx={contentContainerStyle}>
        {!hideExit && (
          <LoadingButton
            onClick={() => closeDialog()}
            variant="tertiary"
            sx={{ ...exitButtonStyle, ...exitBtnStyle }}
          >
            ✕
          </LoadingButton>
        )}
        {children}
      </Box>
    </Dialog>
  );
};

export default DialogContainer;

export const contentContainerStyle = {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  flex: 1,
};

export const dialogContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 800,
    height: "80%",

    "@media (max-width: 601px)": {
      width: "90%",
      maxHeight: "90%",
    },
  },
};

export const AddPaymentContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 450,
    height: 500,

    "@media (max-width: 601px)": {
      width: "90%",
      maxHeight: "90%",
    },
  },
};

export const VerifyEmailContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 450,
    height: 300,

    "@media (max-width: 601px)": {
      width: "90%",
      maxHeight: "70%",
    },
  },
};

export const EditContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 400,

    "@media (max-width: 601px)": {
      width: "90%",
      maxHeight: "90%",
    },
  },
};

export const DeleteContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 400,
    "@media (max-width: 701px)": {
      width: "90%",
      maxHeight: "70%",
    },
  },
};

export const registerToOfferContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 800,
    minHeight: 350,
    "@media (max-width: 601px)": {
      width: "90%",
    },
  },
};

export const relativeSmallSquareStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 1,
    maxWidth: 300,
  },
  "@media (max-width: 601px)": {
    "& .MuiDialog-paper": {
      minWidth: "90%",
      py: 2,
    },
  },
};

export const relativeSmallCircleStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    maxWidth: 300,
  },
  "@media (max-width: 601px)": {
    "& .MuiDialog-paper": {
      minWidth: "90%",
      py: 2,
    },
  },
};

export const relativeContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 300,
    height: "fit-content",
    "@media (max-width: 601px)": {
      width: "90%",
      maxHeight: "90%",
    },
  },
};

export const declineContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 600,
    "@media (max-width: 601px)": {
      width: "90%",
      maxHeight: "90%",
    },
  },
};

export const exitButtonStyle = {
  position: "absolute",
  zIndex: 9999,
  right: 0,
  height: { xs: "23px", sm: "40px" },
  width: { xs: "23px", sm: "40px" },
  minWidth: 0,
  padding: 0,
  fontSize: { xs: "0.5rem", sm: "0.75rem" },
  color: "primary.main",
};
