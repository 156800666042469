import { Box, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ArrowIcon, SearchIcon } from "styles/icons";

interface IProps {
  sx?: object;
  list: string[];
  label?: string;
  onChange: (value: any) => void;
  value: string;
}

const AutoComplete: React.FC<IProps> = ({
  sx,
  list,
  label = null,
  value,
  onChange,
}): JSX.Element => {
  const handleChange = (e: any, newValue: string | null) => {
    onChange(newValue ?? "All");
  };
  return (
    <Box width={"100%"} mb={2}>
      {label && (
        <Typography
          fontWeight={600}
          color="grey.800"
          variant="extraSmallBoldText"
          mb={0.5}
        >
          {label}
        </Typography>
      )}
      <Autocomplete
        sx={{
          mb: 0.5,
          minWidth: 150,
          alignSelf: "end",
          ...sx,
        }}
        id="autocomplete-filter"
        disablePortal
        popupIcon={<ArrowIcon />}
        options={list.map((option) => option)}
        onChange={handleChange}
        value={value}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{
              fontSize: "0.8rem",
              "&.MuiAutocomplete-option[aria-selected='true']": {
                fontWeight: 800,
                color: "primary.main",
                backgroundColor: "common.white",
              },
              "&.MuiAutocomplete-option.Mui-focused": {
                backgroundColor: "common.white",
                color: "primary.main",
              },
              "&.MuiAutocomplete-option[aria-selected='true'].Mui-focused": {
                backgroundColor: "common.white",
              },
              "&. css-gz4cyd-MuiFormControl-root-MuiTextField-root .MuiInputBase-root.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                {
                  border: "2px solid red !important",
                },
            }}
            {...props}
          >
            {option}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
            }}
          />
        )}
      />
    </Box>
  );
};

export default AutoComplete;
