export const checkCookieExistence = (cookieName: string) => {
  return document.cookie.split(";").some((cookie) => {
    const [name, value] = cookie.split("=");
    return name.trim() === cookieName && value.trim() !== "";
  });
};

export const formatUSD = (number: number | undefined): string => {
  const formattedNumber = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number || 0);

  return formattedNumber;
};

export const addCommasToNumber = (number: number | undefined): string => {
  const formattedNumber = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number || 0);

  return formattedNumber;
};



 export const convertToWords = (num: string): string => {
  const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
  const result = capitalizeFirstLetter(num.replace(/,/g, '').trim());

  return result + ' dollars';
};
