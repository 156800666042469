import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const InfoIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 8 9"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="8"
        height="9"
        viewBox="0 0 8 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 0.625C1.85938 0.625 0.125 2.375 0.125 4.5C0.125 6.64062 1.85938 8.375 4 8.375C6.125 8.375 7.875 6.64062 7.875 4.5C7.875 2.375 6.125 0.625 4 0.625ZM4 7.625C2.26562 7.625 0.875 6.23438 0.875 4.5C0.875 2.78125 2.26562 1.375 4 1.375C5.71875 1.375 7.125 2.78125 7.125 4.5C7.125 6.23438 5.71875 7.625 4 7.625ZM4 2.34375C3.625 2.34375 3.34375 2.64062 3.34375 3C3.34375 3.375 3.625 3.65625 4 3.65625C4.35938 3.65625 4.65625 3.375 4.65625 3C4.65625 2.64062 4.35938 2.34375 4 2.34375ZM4.875 6.3125V5.9375C4.875 5.84375 4.78125 5.75 4.6875 5.75H4.5V4.1875C4.5 4.09375 4.40625 4 4.3125 4H3.3125C3.20312 4 3.125 4.09375 3.125 4.1875V4.5625C3.125 4.67188 3.20312 4.75 3.3125 4.75H3.5V5.75H3.3125C3.20312 5.75 3.125 5.84375 3.125 5.9375V6.3125C3.125 6.42188 3.20312 6.5 3.3125 6.5H4.6875C4.78125 6.5 4.875 6.42188 4.875 6.3125Z"
          fill="#3B82F6"
        />
      </svg>
    </SvgIcon>
  );
};

export default InfoIcon;
