import { Theme } from "@mui/material/styles";

export const info = (theme: Theme) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.info.main,
  borderColor: theme.palette.info.main,
  transitionProperty: "all",
  "&:hover": {
    backgroundColor: theme.palette.info.light,
    borderColor: theme.palette.info.light,
  },
  "&:focus": {
    backgroundColor: theme.palette.info.dark,
  },
  "&:active": {
    backgroundColor: theme.palette.info.dark,
    borderColor: theme.palette.info.dark,
  },
  "&:disabled": {
    backgroundColor: theme.palette.grey[600],
    borderColor: theme.palette.grey[600],
    color:theme.palette.grey[500],
    
  },
});
