import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface ArrowIconProps extends SvgIconProps {
  stroke?: string;
}
const EnvelopeIcon: React.FC<ArrowIconProps> = ({ sx, stroke, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.03167 10.5369C1.15058 11.4947 1.92435 12.2514 2.88428 12.3517C4.21258 12.4905 5.58924 12.6372 7 12.6372C8.41076 12.6372 9.78742 12.4905 11.1157 12.3517C12.0756 12.2514 12.8494 11.4947 12.9683 10.5369C13.1103 9.39337 13.25 8.21075 13.25 7.00022C13.25 5.78969 13.1103 4.60707 12.9683 3.46354C12.8494 2.50573 12.0756 1.74909 11.1157 1.64875C9.78742 1.5099 8.41076 1.36328 7 1.36328C5.58924 1.36328 4.21257 1.5099 2.88428 1.64875C1.92435 1.74909 1.15058 2.50573 1.03166 3.46354C0.88969 4.60707 0.75 5.78969 0.75 7.00022C0.75 8.21075 0.88969 9.39337 1.03167 10.5369Z"
          fill="#EEF2FF"
          stroke={stroke || "#818CF8"}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M1.05957 3.20508L5.76169 6.91254C6.48793 7.48516 7.51207 7.48516 8.23831 6.91254L12.9404 3.20508"
          stroke={stroke || "#818CF8"}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default EnvelopeIcon;
