import { DialogContent } from "@mui/material";
import { SelectOrAddPaymentMethod } from "features/payments";
import { DialogContainer } from ".";

interface IProps {
  methods: any;
}

const UpdatePaymentDialog: React.FC<IProps> = ({ methods }): JSX.Element => {
  return (
    <DialogContainer hideExit sx={dialogContainerStyle}>
      <DialogContent>
        <SelectOrAddPaymentMethod methods={methods} isUpdateOnly={true} />
      </DialogContent>
    </DialogContainer>
  );
};

export default UpdatePaymentDialog;

export const titleStyle = {
  textAlign: "center",
  color: "primary.main",
  fontWeight: 700,
  pt: 1,
};
export const dialogContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiDialog-paper": {
    borderRadius: 2,
    width: 600,
  },
  "@media (max-width: 601px)": {
    "& .MuiDialog-paper": {
      minWidth: "90%",
    },
  },
};
