import { Box, DialogTitle } from "@mui/material";
import {
  IPaymentMethod,
  useDeletePaymentMethodMutation,
} from "features/payments/api";
import { useDialogContext } from "store/hooks/DialogsContext";
import DialogActions from "./DialogActions";
import DialogContainer, { DeleteContainerStyle } from "./DialogContainer";

interface IProps {
  deletedPaymentItem: IPaymentMethod;
  onDelete: () => void;
}

const DeletePaymentDialog: React.FC<IProps> = ({
  deletedPaymentItem,
  onDelete,
}) => {
  const [removeItem, { isLoading }] = useDeletePaymentMethodMutation();
  const { closeDialog } = useDialogContext();

  const confirmDeleteItem = () => {
    removeItem(deletedPaymentItem.id).then(onDelete);
    closeDialog();
  };

  return (
    <DialogContainer sx={DeleteContainerStyle} hideExit>
      <DialogTitle textAlign={"center"} color="primary" fontWeight={700}>
        Delete Payment Method Ending In {deletedPaymentItem.last_four_digits}
      </DialogTitle>
      <DialogActions
        onSubmit={confirmDeleteItem}
        submitText="Confirm"
        isLoading={isLoading}
      />
    </DialogContainer>
  );
};

export default DeletePaymentDialog;
