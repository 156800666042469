import { authApi } from "features/auth/api/authApi";
import { IUserInfo, IAgentRequestOfUser } from "../types";

export interface IUserInfoResponse {
  status: string;
  user: IUserInfo;
  agent_request: IAgentRequestOfUser;
}

const userApi = authApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query<IUserInfoResponse, void>({
      query: () => ({
        url: "/api/unverified/user-info",
      }),
      providesTags: ["userListings", "agentListings"],
      transformResponse: (response: IUserInfoResponse) => response,
    }),
  }),
});

export const { useGetUserInfoQuery } = userApi;
