import { Grid, Typography } from "@mui/material";
import PaymentHistoryList from "features/payments/components/PaymentHistoryList";

const PaymentHistoryTab: React.FC = (): JSX.Element => {
  return (
    <Grid container>
      <Typography
        variant="smallBoldText"
        sx={{ mt: 2, mb: 1, color: "grey.700" }}
      >
        Payment History
      </Typography>
      <PaymentHistoryList />
    </Grid>
  );
};

export default PaymentHistoryTab;
