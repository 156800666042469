import { Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import {
  useConfirmSaleMutation,
  useVoidSaleMutation,
} from "features/listing/api/agentApi";
import type { IListing, IListingDetails } from "features/listing/types";
import { useDialogContext } from "store/hooks/DialogsContext";
import { DialogContainer } from "..";
import DialogActions from "../DialogActions";
import {
  declineContainerStyle,
  relativeSmallSquareStyle,
} from "../DialogContainer";

interface IProps {
  listing: IListing | IListingDetails | undefined;
  type: "confirm" | "void";
}

const ConfirmVoidSaleDialog: React.FC<IProps> = ({
  listing,
  type,
}): JSX.Element => {
  const [confirmSale, { isLoading: isConfirmLoading }] =
    useConfirmSaleMutation();
  const [voidSale, { isLoading: isVoidLoading }] = useVoidSaleMutation();
  const { closeDialog } = useDialogContext();
  const handleConfirmSale = () => {
    if (type === "confirm") {
      confirmSale(listing?.id);
    } else if (type === "void") {
      voidSale(listing?.id);
    }
    closeDialog();
  };
  const isLoading = isConfirmLoading || isVoidLoading;
  return (
    <DialogContainer sx={declineContainerStyle} hideExit>
      <DialogContent sx={{ py: 0, pb: 3, px: { xs: 1, sm: 2 } }}>
        <Typography color="common.black" lineHeight={1.5} textAlign={"center"}>
          {type === "confirm" ? "Confirm" : "Void"} Sale for listing
          <Typography lineHeight={1.5}>{listing?.address_line_1}</Typography>
        </Typography>
      </DialogContent>
      <DialogActions
        onSubmit={handleConfirmSale}
        submitText="Confirm"
        isLoading={isLoading}
      />
    </DialogContainer>
  );
};

export default ConfirmVoidSaleDialog;
