import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {
  RegisterStepName,
  stepperStyle,
} from "Components/Steppers/StepsProgress";
import { useListingDetailsStepperContext } from "store/hooks/ListingDetailsStepperContext";

export interface ISteps {
  name: RegisterStepName;
  label: string;
  number: number;
}

interface IProps {
  steps: ISteps[];
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProcessStepper: React.FC<IProps> = ({
  steps,
  setIsVisible,
}): JSX.Element => {
  const { completedStep, setStepToShow } = useListingDetailsStepperContext();

  const handleStepClick = (stepNumber: number) => () => {
    if (completedStep >= stepNumber) {
      setStepToShow(stepNumber);
      setIsVisible(true);
    }
  };

  return (
    <Stepper
      sx={{ ...stepperStyle, ...connectorStyle }}
      activeStep={completedStep}
      orientation="vertical"
    >
      {steps.map((step) => (
        <Step
          sx={{ cursor: completedStep >= step.number ? "pointer" : "auto" }}
          key={step.name}
          onClick={handleStepClick(step.number)}
        >
          <StepLabel>{step.label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default ProcessStepper;

const connectorStyle = {
  ".MuiStepConnector-line": {
    height: 25,
    borderWidth: 1,
    margin: "-12px 0 -12px -6px",
  },
};
