import { Grid, Typography } from "@mui/material";
import { ControlledTextField } from "Components/FormComponents";
import ErrorText from "Components/StyledComponents/ErrorText";
import { digitsRegex } from "Components/Validation/regex";
import { useFormContext } from "react-hook-form";

interface IProps {
  isStepper?: boolean;
}

const DatePayment: React.FC<IProps> = ({ isStepper = false }): JSX.Element => {
  const { control, formState } = useFormContext();

  return (
    <Grid container>
      <Typography
        sx={{ mb: 0.5 }}
        fontWeight={600}
        color={isStepper ? "grey.900" : "primary.main"}
        variant="extraSmallBoldText"
      >
        Expiry Date
      </Typography>
      <Grid xs={12} item display={"flex"} alignItems="end">
        <ControlledTextField
          fullWidth
          mb={0}
          name={"mm"}
          control={control}
          placeholder="MM"
          labelColor={"#111827"}
          regex={digitsRegex}
          maxLength={2}
          hideError
        />
        <Typography
          sx={{ px: 1, mb: 1.2 }}
          alignSelf={"end"}
          variant="boldText"
          color="grey.400"
        >
          /
        </Typography>
        <ControlledTextField
          fullWidth
          mb={0}
          name={"yy"}
          control={control}
          placeholder="YY"
          labelColor={"#111827"}
          regex={digitsRegex}
          maxLength={2}
          hideError
        />
      </Grid>
      <Grid mb={2} item xs={12}>
        {formState.errors && (
          <ErrorText
            text={
              (formState?.errors?.mm?.message ||
                formState?.errors?.yy?.message) as string
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DatePayment;
