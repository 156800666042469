import { LoadingButton } from "@mui/lab";
import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import LoginPageStrip from "Components/Strips/LoginPageStrip";
import { Link } from "Components/StyledComponents";
import EmailVerificationStrip from "features/auth/components/emailVerify/EmailVerificationStrip";
import { OfferorStatus } from "features/offerors/api";
import { Portal } from "features/users/types";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import { SettingsIcon } from "styles/icons";
import HamburgerMenu from "./HamburgerMenu";
import UserAvatar from "./UserAvatar";
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Navbar: React.FC = (): JSX.Element => {
  const { username, userTypes, agentRequestStatus, portal, verified } =
    useAppSelector(selectAuth);
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const location = useLocation();
  const isLoginPage = location?.pathname === "/login";
  const isAdmin = userTypes?.includes("admin");
  const isAgent = userTypes?.includes("agent") && !isAdmin;
  const isGuest = portal === null;
  const isPendingRequest = agentRequestStatus === OfferorStatus.PENDING;
  const isUserPortal = portal === Portal.USER;
  const isAgentPortal = portal === Portal.AGENT;
  const isAdminPortal = portal === Portal.ADMIN;
  const isBorder = isUserPortal && !verified;

  return (
    <>
      <AppBar
        position="relative"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        elevation={0}
      >
        <Container maxWidth="xl" sx={{ py: 1 }}>
          <Toolbar disableGutters>
            <Box>
              <HamburgerMenu
                isAgent={isAgent}
                isPendingRequest={isPendingRequest}
                isAdmin={isAdmin}
              />
            </Box>
            <Grid
              container
              item
              md={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h5Bold"
                noWrap
                sx={{
                  flexGrow: { xs: 0, sm: 0 },
                  letterSpacing: ".2rem",
                }}
              >
                <Link to="/">Ephrown</Link>
              </Typography>
            </Grid>
            <Grid
              container
              item
              md={9}
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {isUserPortal && (
                <Link to="/listings">
                  <LoadingButton variant="darkPrimary" sx={{ mx: 1 }}>
                    Listings
                  </LoadingButton>
                </Link>
              )}
              {isAgentPortal && (
                <>
                  <Link to="/myrequests">
                    <LoadingButton variant="darkPrimary" sx={{ mx: 1 }}>
                      My Requests
                    </LoadingButton>
                  </Link>
                  <Link to="/myListings">
                    <LoadingButton variant="darkPrimary" sx={{ mx: 1 }}>
                      My Listings
                    </LoadingButton>
                  </Link>
                  {/*<IconButton
                    onClick={() => alert("need to go to inviteUser")}
                    sx={{ ...iconNavbarStyle, ...messageIconStyle }}
                  >
                    <EnvelopeSendIcon />
                  </IconButton>*/}
                </>
              )}
              {isAdminPortal && (
                <>
                  <Link to="/myrequests">
                    <LoadingButton variant="darkPrimary" sx={{ mx: 1 }}>
                      My Requests
                    </LoadingButton>
                  </Link>
                  <Link to="/settings">
                    <IconButton
                      sx={{ ...iconNavbarStyle, ...messageIconStyle }}
                    >
                      <SettingsIcon stroke={"#fff"} fontSize="medium" />
                    </IconButton>
                  </Link>
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {/*username ? (
                  <IconButton sx={iconNavbarStyle}>
                    <NotificationIcon sx={{ color: "white.main" }} />
                  </IconButton>
                ) : */}
                {!username && (
                  <>
                    <LoadingButton
                      sx={{ mx: 1 }}
                      onClick={() => navigate("/listings")}
                      variant="darkPrimary"
                    >
                      Listings
                    </LoadingButton>
                    <LoadingButton
                      onClick={() => navigate("/login")}
                      variant="darkPrimary"
                    >
                      Log in
                    </LoadingButton>
                  </>
                )}
                {username && (
                  <UserAvatar
                    isPendingRequest={isPendingRequest}
                    isAgent={isAgent}
                    isAdmin={isAdmin}
                  />
                )}
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-navbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      {(isLoginPage || isUserPortal || isGuest) && !isAgent && !isAdmin && (
        <LoginPageStrip HasBottomBorder={isBorder} />
      )}
      {!verified && isUserPortal && <EmailVerificationStrip />}
    </>
  );
};

export default Navbar;

export const iconNavbarStyle = {
  p: 1,
  mx: 1,
  width: 48,
  height: 48,
  border: "1.5px solid rgba(238, 242, 255, 0.5)",
  borderRadius: 1,
  "& .MuiTouchRipple-root .MuiTouchRipple-child": {
    borderRadius: 1,
  },
  "&:hover": {
    backgroundColor: "primary.white",
  },
};

export const messageIconStyle = {
  backgroundColor: "rgba(0, 0, 0, 0.05)",
  border: "none",
  "& .MuiTouchRipple-root .MuiTouchRipple-child": {
    borderRadius: 1,
  },
};
