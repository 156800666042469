import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CalendarIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.09195 10.5251C1.25329 11.9645 2.42323 13.1073 3.86995 13.177C4.8699 13.2252 5.89139 13.2502 7.00003 13.2502C8.10867 13.2502 9.13015 13.2252 10.1301 13.177C11.5768 13.1073 12.7468 11.9645 12.9081 10.5251C13.0168 9.55578 13.1059 8.562 13.1059 7.54965C13.1059 6.53731 13.0168 5.54353 12.9081 4.57422C12.7468 3.13483 11.5768 1.99199 10.1301 1.92231C9.13015 1.87415 8.10867 1.84912 7.00003 1.84912C5.89139 1.84912 4.8699 1.87415 3.86995 1.92231C2.42323 1.99199 1.25329 3.13483 1.09195 4.57422C0.983304 5.54353 0.894165 6.53731 0.894165 7.54965C0.894165 8.562 0.983304 9.55578 1.09195 10.5251Z"
          fill="white"
        />
        <path
          d="M4.75342 0.75L4.75342 3.28918"
          stroke="#686F7A"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M9.23975 0.75L9.23975 3.28918"
          stroke="#686F7A"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M12.6682 6.60449L1.33177 6.60449L1.4909 2.56972L6.93316 2.17499L12.6682 2.56972L12.6682 6.60449Z"
          fill="white"
        />
        <path
          d="M1.09195 10.5251C1.25329 11.9645 2.42323 13.1073 3.86995 13.177C4.8699 13.2252 5.89139 13.2502 7.00003 13.2502C8.10867 13.2502 9.13015 13.2252 10.1301 13.177C11.5768 13.1073 12.7468 11.9645 12.9081 10.5251C13.0168 9.55578 13.1059 8.562 13.1059 7.54965C13.1059 6.53731 13.0168 5.54353 12.9081 4.57422C12.7468 3.13483 11.5768 1.99199 10.1301 1.92231C9.13016 1.87415 8.10867 1.84912 7.00003 1.84912C5.89139 1.84912 4.8699 1.87415 3.86995 1.92231C2.42323 1.99199 1.25329 3.13483 1.09195 4.57422C0.983304 5.54353 0.894165 6.53731 0.894165 7.54965C0.894165 8.562 0.983304 9.55578 1.09195 10.5251Z"
          stroke="#686F7A"
          strokeWidth="1.5"
        />
        <path d="M1.18628 6.22461H12.8738" stroke="#686F7A" strokeWidth="1.5" />
      </svg>
    </SvgIcon>
  );
};

export default CalendarIcon;
