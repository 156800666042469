import { CheckBoxOutlined, Delete } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { iconNavbarStyle } from "layout/Navbar";
import React, { useState } from "react";

interface IProps {
  handleCheckBox: any;
  initialCheck: any;
}
const HamburgerMenu: React.FC<IProps> = ({
  handleCheckBox,
  initialCheck,
}): JSX.Element => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  return (
    <>
      <IconButton
        sx={{
          display: { xs: "inline-flex", md: "none" },
        }}
        size="large"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        PaperProps={nestedListStyle}
        sx={{
          display: { xs: "inline-flex", md: "none" },
        }}
      >
        <MenuList>
          <MenuItem onClick={() => alert("menu item")}>
            <Typography textAlign="center">
              <>
                <Checkbox
                  size="medium"
                  icon={<CheckBoxOutlined />}
                  onChange={handleCheckBox}
                  checked={initialCheck}
                />
                <Typography
                  component={"span"}
                  color="white"
                  variant="smallRegularText"
                  fontWeight={300}
                >
                  This is set as main photo
                </Typography>
              </>
            </Typography>
          </MenuItem>
          <MenuItem>
            <Delete sx={{ color: "primary.main", mr: 1 }} fontSize="small" />

            <Typography
              component={"span"}
              color="white"
              variant="smallRegularText"
              fontWeight={300}
            >
              Delete
            </Typography>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default HamburgerMenu;

const nestedListStyle = {
  sx: {
    ".MuiList-root": {
      p: 0,
    },
  },
};
