import { Typography } from "@mui/material";

interface IProps {}

const PendingContractStatus: React.FC<IProps> = ({}): JSX.Element => {
  return (
    <Typography variant="regularText" color="grey.00" mb={1}>
      PENDING CONTRACT AGENT STATUS
    </Typography>
  );
};

export default PendingContractStatus;
