import { Box, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { PaymentMethodCard } from "styles/components";
import DisplayFileUpload from "./DisplayFileUpload";
import InformationCard from "./InformationCard";
import OfferDetailsCard from "./OfferDetailsCard";
import { useListingDetailsStepperContext } from "store/hooks/ListingDetailsStepperContext";
import { formatUSD } from "utils/helpers";

const SubmitStep: React.FC = (): JSX.Element => {
  const {
    listing: { security_hold },
  } = useAppSelector(listingState) as TListingDetails;
  const { options, selectedPaymentMethod } = useListingDetailsStepperContext();

  const { watch } = useFormContext();
  const haveSecurityHold = !!security_hold;
  const fileType =
    watch("file_type") === "preapproval" ? "Pre-Approval" : "Proof of Funds";
  const fileName = watch("proof_of_funds")?.name || watch("proof_of_funds");
  const fileSize = watch("proof_of_funds")?.size
    ? watch("proof_of_funds")?.size + "kb"
    : "";
  const { control } = useFormContext();
  return (
    <Grid item mb={4}>
      <Grid item textAlign={"center"} my={4}>
        <Typography color="grey.800" variant="bigBoldText">
          Review & Submit:
        </Typography>
        <Typography color="grey.800" variant="extraSmallRegularText">
          Review all the information to make sure they are entered correctly and
          then submit Verification
        </Typography>
      </Grid>
      {options.includeOfferStep && <OfferDetailsCard />}
      <Grid
        justifyContent={"space-between"}
        container
        sx={{ wordBreak: "break-word" }}
      >
        {/* {Left Side Container} */}
        <Grid
          position="relative"
          item
          container
          flexDirection={"column"}
          xs={12}
          sm={5.9}
        >
          <Grid item mb={2} sx={reviewContainersStyle}>
            <InformationCard type={"purchaser"} />
          </Grid>

          {haveSecurityHold && (
            <Grid item sx={{ ...reviewContainersStyle, pb: 1.5 }}>
              <Grid item container flexDirection="column">
                <Typography variant="boldText" color="primary.900" mb={2.25}>
                  Proof of Funds:
                </Typography>

                <Typography
                  sx={{ borderBottom: "1px solid #CAD0D9" }}
                  variant="extraSmallRegularText"
                  color="grey.700"
                >
                  {fileType}
                </Typography>
                <Grid mt={1.5} container flexDirection={"column"}>
                  <DisplayFileUpload size={fileSize} name={fileName} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* {Right Side Container} */}

        <Grid item container flexDirection={"column"} xs={12} sm={5.9}>
          <Grid item mb={2} sx={{ ...reviewContainersStyle }}>
            <InformationCard type={"attorney"} />
          </Grid>

          {haveSecurityHold && (
            <Grid
              item
              sx={{
                ...reviewContainersStyle,
                py: 0,
                pt: 1,
                pb: 0.5,
              }}
            >
              <Typography variant="boldText" color="primary.900">
                Payment:
              </Typography>
              <Grid item sx={paymentContainer}>
                <PaymentMethodCard
                  control={control}
                  readOnly
                  isSelected={true}
                  lastFourDigits={
                    selectedPaymentMethod?.last_four_digits ||
                    watch("payment_method_last_four_digits")
                  }
                  nameOnCard={
                    selectedPaymentMethod?.name_on_card ||
                    watch("payment_method_name_on_card")
                  }
                  isCvvCollapsed={options.includeOfferStep}
                />
              </Grid>
              <Grid item>
                <Box mt={0.5} display="flex" justifyContent="space-between">
                  <Typography color="grey.700" variant="extraSmallBoldText">
                    Total:
                  </Typography>
                  <Typography color="grey.700" variant="extraSmallBoldText">
                    {formatUSD(security_hold)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>

        {!haveSecurityHold && (
          <Grid
            sx={{ ...reviewContainersStyle, pb: 1 }}
            container
            justifyContent={"space-between"}
            gap={2}
            alignItems={"center"}
          >
            <Grid item flexBasis={250}>
              <Typography noWrap variant="boldText" color="primary.900">
                Proof Of Funds:
                <Typography
                  component={"span"}
                  ml={2}
                  noWrap
                  variant="smallRegularText"
                  color="grey.300"
                >
                  {fileType}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <DisplayFileUpload size={fileSize} name={fileName} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SubmitStep;

const reviewContainersStyle = {
  py: 1,
  px: { xs: 2, sm: 1, lg: 2 },
  borderRadius: 1.5,
  bgcolor: "secondary.light",
  maxWidth: "100%",
};

const paymentContainer = {
  mt: 0.5,
  flex: 1,
  maxWidth: "100%",
};
