import { DialogContent, Typography } from "@mui/material";
import { DialogContainer } from "Components/Dialogs";

const ChangeEmailDialog = () => {
  return (
    <DialogContainer exitBtnStyle={exitBtnStyle} sx={changeEmailContainerStyle}>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 3,
        }}
      >
        <Typography color="primary" fontWeight={700} sx={ensureTextStyle}>
          To ensure security, this change must be verified. An email was sent to
          the new email address with directions for verifying this change.
        </Typography>
      </DialogContent>
    </DialogContainer>
  );
};

export default ChangeEmailDialog;

export const exitBtnStyle = {
  color: "primary.light",
  backgroundColor: "rgba(238, 242, 255, 0.5)",
  border: "none",
  ":hover": {
    border: "none",
  },
};

const ensureTextStyle = {
  maxWidth: 300,
  textAlign: "center",
  lineHeight: "1.25rem",
};

const changeEmailContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    maxHeight: "fit-content",
    "@media (max-width: 601px)": {
      width: "90%",
    },
  },
};
