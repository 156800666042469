import { Grid, Typography } from "@mui/material";
import { IPaymentMethod } from "features/payments/api";
import { useFormContext } from "react-hook-form";
import { PaymentMethodCard } from "styles/components";

interface IProps {
  paymentMethod: IPaymentMethod | null;
}
const ConfirmPublishStep: React.FC<IProps> = ({
  paymentMethod,
}): JSX.Element => {
  const { control } = useFormContext();
  return (
    <Grid item pb={7}>
      <Grid container item xs={7} sx={cardContainerStyle}>
        <Grid item xs={12}>
          <PaymentMethodCard
            control={control}
            readOnly
            isSelected={true}
            lastFourDigits={paymentMethod?.last_four_digits ?? ""}
            nameOnCard={paymentMethod?.name_on_card ?? ""}
            isCvvCollapsed={false}
          />
        </Grid>
        <Grid item sx={typographyStyle}>
          <Typography variant="extraSmallRegularText">Subtotal Hold</Typography>
          <Typography variant="extraSmallRegularText">$5000.00</Typography>
        </Grid>
        <Grid item sx={typographyStyle}>
          <Typography variant="extraSmallRegularText">Taxes</Typography>
          <Typography variant="extraSmallRegularText">$0.00</Typography>
        </Grid>
        <Grid item sx={typographyStyle}>
          <Typography variant="extraSmallBoldText">Total</Typography>
          <Typography variant="extraSmallBoldText">$5000.00</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConfirmPublishStep;

const cardContainerStyle = {
  bgcolor: "grey.50",
  padding: "16px 24px",
  borderRadius: 1,
  flexDirection: "column",
  margin: "0 auto",
};

const typographyStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  marginTop: 1,
};
