import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CheckboxCheckedIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="16" height="16" rx="4" fill="#6366F1" />
        <path
          d="M10.7969 5.3125L6.6875 9.42188L5.1875 7.90625C5.10938 7.84375 4.98438 7.84375 4.92188 7.90625L4.46875 8.35938C4.40625 8.42188 4.40625 8.54688 4.46875 8.625L6.5625 10.7031C6.64062 10.7812 6.75 10.7812 6.82812 10.7031L11.5156 6.01562C11.5781 5.95312 11.5781 5.82812 11.5156 5.75L11.0625 5.3125C11 5.23438 10.875 5.23438 10.7969 5.3125Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default CheckboxCheckedIcon;
