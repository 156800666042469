import { LoadingButton } from "@mui/lab";
import { Divider, Grid } from "@mui/material";
import { BackButton } from "Components/Buttons";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import TabsPanel from "Components/TabsPanel";
import { listingDetailsTabs } from "Components/TabsPanel/tabsData";
import PageNotFound from "PageNotFound";
import { ListingDetailsWrapper, SharedView, TopPanel } from "features/listing";
import { useGetAgentListingDetailsQuery } from "features/listing/api/agentApi";
import AgentRightScreen from "features/listing/components/detailListing/agent/AgentRightScreen";
import ListingOptionsMenu from "features/listing/components/detailListing/agent/menus/ListingOptionsMenu";
import { IListingDetails } from "features/listing/types";
import { Portal } from "features/users/types";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { selectAuth, setPortal } from "store/features/authSlice";
import { setCurrentListing } from "store/features/listingSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { listingDetailsTabsStyle } from "styles/components/StyledTabs";
import { ComputerIcon } from "styles/icons";

const AgentListingDetails: React.FC = (): JSX.Element => {
  const itemId = useParams().itemId || "";
  const dispatch = useAppDispatch();
  const [redirectToPublish, setRedirectToPublish] = useState(false);
  const [isSharedDisplay, setIsSharedDisplay] = useState(false);
  const { portal } = useAppSelector(selectAuth);
  const { data: listingData, isLoading: listingLoading } =
    useGetAgentListingDetailsQuery(itemId, {
      skip: !itemId,
    });

  useEffect(() => {
    if (listingData) {
      dispatch(
        setCurrentListing({ ...listingData, id: itemId } as IListingDetails)
      );
    }
  }, [itemId, listingData]);

  useEffect(() => {
    if (portal !== Portal.AGENT && portal !== Portal.ADMIN)
      dispatch(setPortal({ portal: Portal.AGENT }));
  }, []);

  if (!listingData && !listingLoading) return <PageNotFound />;
  return (
    <ConditionalWrapper isLoading={listingLoading}>
      <ListingDetailsWrapper
        leftWrapper={
          <Grid container>
            <Grid pb={2} item container justifyContent={"space-between"}>
              <BackButton variant="secondary" sx={backButtonStyle} />
              <ListingOptionsMenu listing={listingData} />
            </Grid>
            <Grid item margin={"0 auto"} width={"100%"}>
              <TopPanel
                isSharedDisplay={isSharedDisplay}
                setIsSharedDisplay={setIsSharedDisplay}
              />
              <Divider sx={{ my: 2, borderColor: "grey.600" }} />
              {isSharedDisplay ? (
                <SharedView
                  setIsSharedDisplay={setIsSharedDisplay}
                  listingId={itemId}
                />
              ) : (
                <Grid container flexDirection={"column"} position="relative">
                  <TabsPanel
                    sx={listingDetailsTabsStyle}
                    tabsList={listingDetailsTabs(portal)}
                  />
                  <Link to={`/listings/${itemId}`}>
                    <LoadingButton
                      variant="tertiary"
                      sx={viewAsUserButton}
                      startIcon={<ComputerIcon sx={{ width: 16 }} />}
                    >
                      View as User
                    </LoadingButton>
                  </Link>
                </Grid>
              )}
            </Grid>
          </Grid>
        }
        rightWrapper={
          <>
            <AgentRightScreen
              setRedirectToPublish={setRedirectToPublish}
              redirectToPublish={redirectToPublish}
            />
          </>
        }
      />
    </ConditionalWrapper>
  );
};

export default AgentListingDetails;

const viewAsUserButton = {
  color: "primary.main",
  position: "absolute",
  mt: 1,
  top: 0,
  right: 0,
  span: { mr: 0 },
  "@media (max-width: 500px)": {
    position: "relative",
    maxHeight: 30,
  },
};

const backButtonStyle = {
  color: "grey.700",
  border: "1px solid transparent",
  padding: "4px 12px",
};
