import { CvvSchema, cardHolderSchema, monthSchema, yearSchema, zipCodeSchema } from "Components/Validation/reausableSchemas";
import * as yup from "yup";
import { object } from "yup";

export const PaymentMethodSchema = object().shape({
  card_number: yup
    .string()
    .required("Enter card number")
    .min(15,"Card Number must contain minimun 15 digits"),
  card_holder_name: cardHolderSchema,
  mm: monthSchema,
  yy: yearSchema,
  new_card_cvv: CvvSchema,
  billing_address: yup
    .string()
    .required("Enter billing address")
    .matches(/^[a-zA-Z0-9\- '.,]+$/, "Invalid characters in the address")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters"),
  zip_code: zipCodeSchema,
});
