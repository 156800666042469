import * as yup from "yup";

const priceSchema = yup
  .number()
  .min(2, "Must be greater than 1$")
  .max(999999999, "Must be less than 999999999$")
  .nullable()
  .transform((value) => (isNaN(value) ? null : value))
  .required("Must be greater than 1$");

export { priceSchema };
