import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CopyLinkIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      {...props}
      sx={{
        fontSize: "1rem",
        ...sx,
      }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.98041 12.5363L1.46359 11.0195C0.763963 10.3199 0.674858 9.21618 1.25319 8.41338C1.68541 7.81341 2.21361 7.28882 2.81653 6.86073L3.09784 6.66098C3.745 6.20148 4.62925 6.27592 5.19047 6.83715L7.1868 8.83347C7.73416 9.38083 7.80676 10.2432 7.35861 10.8744L7.13921 11.1834C6.71112 11.7863 6.18653 12.3145 5.58656 12.7467C4.78376 13.3251 3.68004 13.236 2.98041 12.5363Z"
          fill="#EEF2FF"
        />
        <path
          d="M2.98041 12.5363L1.46359 11.0195C0.763963 10.3199 0.674858 9.21618 1.25319 8.41338C1.68541 7.81341 2.21361 7.28882 2.81653 6.86073L3.09784 6.66098C3.745 6.20148 4.62925 6.27592 5.19047 6.83715L7.1868 8.83347C7.73416 9.38083 7.80676 10.2432 7.35861 10.8744L7.13921 11.1834C6.71112 11.7863 6.18653 12.3145 5.58656 12.7467C4.78376 13.3251 3.68004 13.236 2.98041 12.5363Z"
          stroke="#686F7A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5363 2.98041L11.0195 1.46359C10.3199 0.763963 9.21618 0.674858 8.41338 1.25319C7.81341 1.68541 7.28882 2.21361 6.86073 2.81653L6.66098 3.09784C6.20148 3.745 6.27592 4.62925 6.83714 5.19047L8.83347 7.1868C9.38083 7.73416 10.2432 7.80676 10.8744 7.35861L11.1834 7.13921C11.7863 6.71112 12.3145 6.18653 12.7467 5.58656C13.3251 4.78376 13.236 3.68004 12.5363 2.98041Z"
          fill="#EEF2FF"
        />
        <path
          d="M12.5363 2.98041L11.0195 1.46359C10.3199 0.763963 9.21618 0.674858 8.41338 1.25319C7.81341 1.68541 7.28882 2.21361 6.86073 2.81653L6.66098 3.09784C6.20148 3.745 6.27592 4.62925 6.83714 5.19047L8.83347 7.1868C9.38083 7.73416 10.2432 7.80676 10.8744 7.35861L11.1834 7.13921C11.7863 6.71112 12.3145 6.18653 12.7467 5.58656C13.3251 4.78376 13.236 3.68004 12.5363 2.98041Z"
          stroke="#686F7A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.86199 5.13831L5.14746 8.85284"
          stroke="#686F7A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default CopyLinkIcon;
