import { Divider } from "@mui/material";
import { digitsRegex } from "Components/Validation/regex";
import React, { useEffect, useRef } from "react";
import { Control } from "react-hook-form";
import { useListingDetailsStepperContext } from "store/hooks/ListingDetailsStepperContext";
import { Collapse } from "../../styles/components";
import { ControlledTextField } from "../FormComponents";

interface IProps {
  expanded: boolean;
  setExpanded: (expand: boolean) => void;
  sx: object;
  control: Control<any>;
  setValue: any;
}

const CvvCollapse: React.FC<IProps> = ({
  setExpanded,
  expanded,
  control,
  setValue,
  sx,
}) => {
  const { selectedPaymentMethod } = useListingDetailsStepperContext();
  const prevSelectedPaymentMethod = useRef(selectedPaymentMethod);
  useEffect(() => {
    if (prevSelectedPaymentMethod.current !== selectedPaymentMethod) {
      setValue("cvv", 0);
      prevSelectedPaymentMethod.current = selectedPaymentMethod;
    }
  }, [selectedPaymentMethod]);
  return (
    <Collapse
      type="circle"
      sx={sx}
      setExpanded={setExpanded}
      expanded={expanded}
      color="grey.500"
      titleStyle={titleStyle}
    >
      <Divider sx={{ border: "none", my: 0.5 }} orientation={"horizontal"} />
      <ControlledTextField
        mb={0}
        name={"cvv"}
        control={control}
        label={"CVV"}
        placeholder="000"
        labelColor="#111827"
        sx={{ width: 90 }}
        regex={digitsRegex}
        maxLength={4}
      />
    </Collapse>
  );
};

export default CvvCollapse;

const titleStyle = { fontWeight: 400, fontSize: "0.625rem" };
