import { Box, Grid, Typography } from "@mui/material";
import { TextIcon } from "Components/Fields";
import ConditionalWrapper from "Components/StyledComponents/ConditionalWrapper";
import { useGetUserListingDetailsQuery } from "features/listing/api/userApi";
import {
  formatFullName,
  formatPhone,
} from "features/listing/utils/listingsUtils";
import { Portal } from "features/users/types";
import { useParams } from "react-router-dom";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import { AttorneyCard } from "styles/components";
import { attorneyCardStyle } from "styles/components/AttorneyCard";
import { ProfileIcon } from "styles/icons";
import { rowsKeysStyle } from "..";

const AgentDetailsTab: React.FC = (): JSX.Element => {
  const itemId = useParams().itemId || "";
  const { portal } = useAppSelector(selectAuth);
  const { data: listingData, isLoading: listingLoading } =
    useGetUserListingDetailsQuery(itemId, {
      skip: portal !== null && portal !== Portal.USER,
    });
  const attorneyName = formatFullName(
    listingData?.attorney_first_name,
    listingData?.attorney_last_name
  );
  const isLoading = listingLoading;
  return (
    <ConditionalWrapper isLoading={isLoading}>
      <Grid>
        <Box sx={{ my: 2 }}>
          <TextIcon
            text="Agent Details"
            variant="bigBoldText"
            fontColor="primary"
            icon={<ProfileIcon sx={{ width: 28, height: 28 }} />}
          />
        </Box>
        <Grid container rowGap={2}>
          <Grid container>
            <Typography sx={rowsKeysStyle}>Agents Name:</Typography>
            <Typography
              fontWeight={700}
              variant="regularText"
              color={"grey.800"}
            >
              {listingData?.agentName}
            </Typography>
          </Grid>
          <Grid container>
            <Typography sx={rowsKeysStyle}>Company:</Typography>
            <Typography
              variant="regularText"
              fontWeight={700}
              color={"grey.800"}
            >
              {listingData?.agentCompany}
            </Typography>
          </Grid>
          <Grid container>
            <Typography sx={rowsKeysStyle}>Email:</Typography>
            <Typography variant="smallBoldText" color={"grey.300"}>
              {listingData?.agentEmail}
            </Typography>
          </Grid>
          <Grid container>
            <Typography sx={rowsKeysStyle}>Phone Number:</Typography>
            <Typography variant="smallBoldText" color={"grey.300"}>
              {formatPhone(listingData?.agentPhone)}
            </Typography>
          </Grid>
          <Grid container>
            <Typography sx={rowsKeysStyle}>Attorney:</Typography>
            <AttorneyCard
              email={listingData?.attorney_email_address}
              phone={listingData?.attorney_number}
              name={attorneyName}
              sx={{ ...attorneyCardStyle, maxWidth: 220 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </ConditionalWrapper>
  );
};

export default AgentDetailsTab;
