import { LoadingButton } from "@mui/lab";
import { Grid, Step, StepLabel, Stepper } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import { useListingDetailsStepperContext } from "store/hooks/ListingDetailsStepperContext";
import { QontoConnector } from "styles/components";
import { ArrowIcon } from "styles/icons";
import AfterSubmitStep from "./RegisterToOfferStepper/AfterSubmitStep";
import { OfferorStatus } from "features/offerors/api";

export enum RegisterStepName {
  PURCHASER = "purchaser",
  FILEUPLOAD = "fileUpload",
  ATTORNEY = "attorney",
  OFFERDETAILS = "offerDetails",
  PAYMENT = "payment",
  SUBMIT = "submit",
  AFTERSUBMIT = "afterSubmit",
}

export enum PublishStepName {
  PUBLISH = "publish",
  DATEANDTIME = "dateAndTime",
  PAYMENT = "payment",
  CONFIRM = "confirm",
}

export interface IStep {
  label: string;
  content: React.ReactNode;
  number: number;
  name: RegisterStepName | PublishStepName;
}

interface IProps {
  hideStepper?: boolean;
  steps: IStep[];
  activeStep: number;
  handleNext: (arg?: number) => void;
}

const StepsProgress: React.FC<IProps> = ({
  activeStep,
  handleNext,
  steps,
  hideStepper = false,
}) => {
  const { formState } = useFormContext();
  const { stepToShow, setStepToShow, submitError, options } =
    useListingDetailsStepperContext();
  const { portal, verified } = useAppSelector(selectAuth);
  const isGuest = portal === null;
  const isPending =
    options?.offerorRequestData?.status === OfferorStatus.PENDING;
  const handleShowStep = (stepNumber: number) => {
    const hasErrors = Object.keys(formState.errors).length > 0;
    if (!hasErrors && (isPending || stepNumber <= activeStep)) {
      setStepToShow(stepNumber);
    }
  };

  const currentStepName = steps[stepToShow]?.name;
  const disableStepsChange =
    (currentStepName === RegisterStepName.OFFERDETAILS &&
      submitError.offerError !== null) ||
    currentStepName === RegisterStepName.AFTERSUBMIT;

  return (
    <>
      <Grid container flexDirection={"column"} py={1} px={6}>
        {!hideStepper && (
          <Grid item>
            <Stepper
              sx={stepperStyle}
              connector={<QontoConnector />}
              activeStep={isPending ? steps.length - 1 : activeStep}
            >
              {steps?.slice(0, -1).map(({ label, number }: IStep) => (
                <Step
                  onClick={
                    !disableStepsChange
                      ? () => handleShowStep(number)
                      : undefined
                  }
                  key={label}
                  disabled={disableStepsChange}
                  sx={{ cursor: "pointer" }}
                >
                  <StepLabel
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        )}
      </Grid>
      {activeStep === steps?.length ? (
        <Grid container flexDirection={"column"}>
          <AfterSubmitStep />
        </Grid>
      ) : (
        <Grid item>{steps[stepToShow]?.content}</Grid>
      )}
      <Grid
        container
        mt={7}
        sx={{
          justifyContent:
            (stepToShow < steps?.length - 1 || submitError) &&
            currentStepName !== RegisterStepName.AFTERSUBMIT
              ? "space-between"
              : "end",
        }}
      >
        {(stepToShow < steps?.length - 1 || submitError) &&
          currentStepName !== RegisterStepName.AFTERSUBMIT && (
            <LoadingButton
              sx={{
                p: 0,
                minWidth: 40,
                border: "transparent",
              }}
              variant="secondary"
              disabled={stepToShow < 1 ? true : false || disableStepsChange}
              onClick={() => handleShowStep(stepToShow - 1)}
            >
              <ArrowIcon sx={{ fill: "#818CF8", transform: "rotate(90deg)" }} />
            </LoadingButton>
          )}
        {activeStep < steps?.length && (
          <LoadingButton
            sx={{ right: 0 }}
            variant="primary"
            id={"next"}
            onClick={() => handleNext()}
            disabled={isGuest || !verified}
          >
            {currentStepName === RegisterStepName.SUBMIT
              ? "Submit Verification"
              : currentStepName === RegisterStepName.AFTERSUBMIT
              ? "Done"
              : currentStepName === RegisterStepName.OFFERDETAILS &&
                submitError.offerError
              ? "Resubmit"
              : "Next"}
          </LoadingButton>
        )}
      </Grid>
    </>
  );
};

export default StepsProgress;

export const stepperStyle = {
  maxWidth: "100%",
  flexWrap: "wrap",
  mb: 4,
  ".MuiSvgIcon-root": {
    background: "transparent",
    borderRadius: 4,
    border: "1px solid",
    borderColor: "grey.400",
    width: "10px",
    fontWeight: 600,
    height: "10px",
    marginY: "-4px",
    fill: "transparent",
    "&.MuiStepIcon-root.Mui-active": {
      borderColor: "primary.main",
    },
  },
  ".MuiStepIcon-root.Mui-completed": {
    background: 'url("/static/icons/stepComplete.svg") no-repeat center',
    border: "none",
    width: "12px",
    height: "12px",
    color: "primary.main",
  },
  ".MuiStepLabel-label": {
    color: "grey.700",
    fontWeight: 600,

    "&.Mui-completed": {
      color: "primary.main",
    },
    "&.Mui-completed:hover": {
      color: "primary.light",
    },
    "&.Mui-active": {
      color: "primary.main",
    },
  },
};
