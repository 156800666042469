import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { TimerProgressLine } from "Components/Timers";
import dayjs from "dayjs";
import { SaleType } from "features/listing/types";
import { OfferorStatus } from "features/offerors/api";
import { IUserOfferorRequest } from "features/offers/api";
import { TListingDetails, listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { CompletedIcon } from "styles/icons";
import ClosedLive from "./ClosedLive";
import OpenLive from "./OpenLive";

interface IProps {
  offerorRequestData: IUserOfferorRequest | undefined;
  userId: number | null | undefined;
}

const LiveListingStatus: React.FC<IProps> = ({
  offerorRequestData,
  userId,
}): JSX.Element => {
  const { listing } = useAppSelector(listingState) as TListingDetails;
  const { openDialog } = useDialogContext();
  const isOpenListing = listing?.sale_type === SaleType.OPEN;
  const isApproved = offerorRequestData?.status === OfferorStatus.APPROVED;
  const lastFourDigits = offerorRequestData?.payment_method_last_four_digits;
  //timer fields
  const auctionStartDate = listing ? parseInt(listing.auction_start_date) : 0;
  const auctionEndDate = listing ? parseInt(listing.auction_end_date) : 0;
  const auctionEndDelay = listing ? parseInt(listing.auction_end_delay) : 0;
  const actualEndTime = auctionEndDate + auctionEndDelay;

  return (
    <>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={3}
        gap={2}
      >
        <Grid item>
          <Typography color="primary" variant="h6Bold">
            {isOpenListing ? "Submit an Offer" : "Offer Details"}
          </Typography>
        </Grid>
        <Grid item>
          {isApproved && (
            <LoadingButton
              startIcon={<CompletedIcon />}
              onClick={() =>
                openDialog("OfferorDetails", {
                  listing: listing,
                })
              }
              variant="secondary"
              sx={{ border: "none" }}
            >
              Verification Completed
            </LoadingButton>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} mb={2}>
        <TimerProgressLine
          isWithoutProgress={true}
          auctionStartDate={auctionStartDate}
          actualEndTime={actualEndTime}
          expiryTimestamp={dayjs.unix(actualEndTime).toDate()}
        />
      </Grid>

      {isOpenListing && (
        <OpenLive userId={userId} offerorRequestData={offerorRequestData} />
      )}
      {!isOpenListing && (
        <ClosedLive
          lastFourDigits={lastFourDigits}
          offerorRequestData={offerorRequestData}
        />
      )}
    </>
  );
};

export default LiveListingStatus;
