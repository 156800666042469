import { DialogContent, DialogTitle } from "@mui/material";
import PublishStepper from "Components/Steppers/PublishStepper";
import { IListing } from "features/listing/types";
import DialogContainer from "./DialogContainer";
import { exitBtnStyle } from "./ListingOptionsDialogs/UpdatePublishDateDialog";

interface IProps {
  listing: IListing;
}

const PublishStepperDialog: React.FC<IProps> = ({ listing }): JSX.Element => {
  return (
    <DialogContainer
      sx={publishStepperContainerStyle}
      exitBtnStyle={exitBtnStyle}
    >
      <DialogTitle
        sx={{ pt: 1, pb: 2 }}
        color="primary.dark"
        variant="h5Bold"
        align="center"
      >
        Publish Listing
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <PublishStepper listing={listing} publishDate={listing?.publish_date} />
      </DialogContent>
    </DialogContainer>
  );
};

export default PublishStepperDialog;

const publishStepperContainerStyle = {
  "& .MuiDialog-paper": {
    borderRadius: 3,
    width: 800,
    height: "fit-content",

    "@media (max-width: 601px)": {
      width: "90%",
      height: "90%",
    },
  },
};
