import { Box, Grid, Typography } from "@mui/material";
import { HelperText } from "../StyledComponents";
import { InfoIcon } from "styles/icons";

interface ITextInfo {
  text: string;
  sx?: object;
  variant?: any;
  infoText?: string;
  fontColor?: any;
  component?: React.ElementType;
}

const TextInfo: React.FC<ITextInfo> = ({
  text,
  fontColor,
  infoText,
  variant,
}): JSX.Element => {
  return (
    <Grid
      flex={1}
      container
      flexWrap={{ xs: "wrap", sm: "nowrap" }}
      alignItems={"center"}
    >
      <Typography
        variant={variant || "extraSmallBoldText"}
        color={fontColor || "transparent.contrastText"}
      >
        {text}
      </Typography>
      <Grid
        item
        container
        alignItems={"center"}
        flexWrap={"nowrap"}
        mt={{ xs: 1, sm: 0 }}
      >
        <InfoIcon
          sx={{ fontSize: "0.625rem", mr: 0.5, ml: { xs: 0, sm: 1 } }}
        />

        <Typography
          component="span"
          variant="extraSmallRegularText"
          color="grey.500"
        >
          {infoText}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TextInfo;
