import { Box } from "@mui/material";
import { ReactNode } from "react";
import LoadingSpinner from "./LoadingSpinner";

interface IProps {
  isLoading?: boolean;
  relative?: boolean;
  children: ReactNode;
}

const ConditionalWrapper: React.FC<IProps> = ({
  isLoading,
  children,
  relative,
}) => {
  return (
    <Box width={"100%"}>
      {isLoading ? <LoadingSpinner relative={relative} /> : children}
    </Box>
  );
};

export default ConditionalWrapper;
