import { Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { useDeleteListingMutation } from "features/listing/api/agentApi";
import type { IListing, IListingDetails } from "features/listing/types";
import { useDialogContext } from "store/hooks/DialogsContext";
import { DialogContainer } from "..";
import DialogActions from "../DialogActions";
import { relativeSmallSquareStyle } from "../DialogContainer";

interface IProps {
  listing: IListing | IListingDetails | undefined;
}

const DeleteDialog: React.FC<IProps> = ({ listing }): JSX.Element => {
  const [deleteListing, { isLoading }] = useDeleteListingMutation();
  const { closeDialog } = useDialogContext();
  const handleDelete = () => {
    deleteListing(listing?.id);
    closeDialog();
  };

  return (
    <>
      <DialogContainer sx={relativeSmallSquareStyle} hideExit>
        <DialogContent sx={{ py: 0, pb: 3, px: { xs: 1, sm: 2 } }}>
          <Typography
            color="common.black"
            lineHeight={1.5}
            textAlign={"center"}
          >
            Are you sure you want to delete this?
          </Typography>
        </DialogContent>
        <DialogActions
          onSubmit={handleDelete}
          submitText="Confirm"
          isLoading={isLoading}
        />
      </DialogContainer>
    </>
  );
};

export default DeleteDialog;
