import { Box, Grid, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

import { useState } from "react";
import { Control } from "react-hook-form";
import { CalendarIcon, TimeIcon } from "../../styles/icons";
import { TimePicker } from "../FormComponents";
import CalendarPicker from "../FormComponents/CalendarPicker";
import { convertToAmPm, formatToMMM } from "./helpers";

export interface SelectedDate {
  dateToShow: string;
  timeStamp: string | Dayjs;
}

interface IProps {
  maxDate?: string;
  publishDate?: string;
  name: string;
  control: Control<any>;
}

const DateTimePicker: React.FC<IProps> = ({
  name,
  maxDate = null,
  publishDate = "",
  control,
}): JSX.Element => {
  const currentSelectedDate = publishDate
    ? dayjs.unix(Number(publishDate))
    : dayjs();
  const [isCalendarSelected, setIsCalendarSelected] = useState(true);
  const [selectedDate, setSelectedDate] = useState<SelectedDate>({
    dateToShow: formatToMMM(currentSelectedDate),
    timeStamp: currentSelectedDate,
  });
  const minDateObject = dayjs(formatToMMM(currentSelectedDate));
  const maxDateObject = dayjs(formatToMMM(maxDate));
  const selectedDateTime = dayjs(selectedDate.dateToShow);
  const selectedDateHours = selectedDateTime.hour();
  const selectedDateMinutes = selectedDateTime.minute();
  const initialTime = `${selectedDateHours}:${selectedDateMinutes}`;
  const [hourSelected, setHourSelected] = useState(convertToAmPm(initialTime));
  return (
    <>
      <Grid py={2} sx={{ margin: "0 auto" }} container width={320}>
        <Grid item>
          <Typography mb={0.5} color="grey.700" variant="extraSmallBoldText">
            Select Date and Time:
          </Typography>
        </Grid>
        <Grid item container sx={dateTimeContainerStyle}>
          <Grid
            item
            container
            onClick={() => setIsCalendarSelected(true)}
            xs={6}
            sx={toggleDateContainerStyle(isCalendarSelected)}
            py={1}
            px={2}
          >
            <Grid item xs={1}>
              <CalendarIcon />
            </Grid>
            <Grid ml={1.5} xs={9} item container flexDirection={"column"}>
              <Grid item>
                <Typography
                  variant="extraSmallRegularText"
                  sx={dateAndTimeStyle}
                >
                  Date
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={dateAndTimeValuesStyle}>
                  {selectedDate.dateToShow}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            onClick={() => setIsCalendarSelected(false)}
            xs={6}
            sx={toggleTimeContainerStyle(isCalendarSelected)}
            py={1}
            px={2}
          >
            <Grid item xs={1}>
              <TimeIcon />
            </Grid>
            <Grid ml={1.5} xs={9} item container flexDirection={"column"}>
              <Grid item>
                <Typography
                  variant="extraSmallRegularText"
                  sx={dateAndTimeStyle}
                >
                  Time
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={dateAndTimeValuesStyle}>
                  {hourSelected}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isCalendarSelected ? (
        <Grid item my={2}>
          <CalendarPicker
            minDate={minDateObject}
            maxDate={maxDateObject}
            name={name}
            control={control}
            selectedDate={selectedDate.timeStamp}
            setSelectedDate={setSelectedDate}
          />
        </Grid>
      ) : (
        <Grid item my={2}>
          <Box sx={timeContainerStyle}>
            <Typography variant="extraSmallBoldText" sx={selectedHourStyle}>
              {hourSelected}
            </Typography>
            <TimePicker
              name={name}
              control={control}
              selectedDate={selectedDate.timeStamp}
              setHourSelected={setHourSelected}
              hourSelected={hourSelected}
            />
          </Box>
        </Grid>
      )}
    </>
  );
};

export default DateTimePicker;

const timeContainerStyle = {
  overflow: "hidden",
  border: "1px solid #818cf8",
  boxShadow: "0px 0px 10px 0px rgba(99, 102, 241, 0.1)",
  borderRadius: 1.5,
  width: 320,
  height: 295,
  maxHeight: 295,
  px: 0,
  margin: "0 auto",
};

const selectedHourStyle = {
  mx: 2,
  pt: 1.5,
  pb: 1,
  borderBottom: "1px solid #CAD0D9",
};

const dateTimeContainerStyle = {
  boxShadow: "0px 0px 10px 0px rgba(99, 102, 241, 0.1)",
  cursor: "pointer",
  borderRadius: 1,
};

const dateAndTimeStyle = {
  fontSize: "0.625rem",
  fontWeight: 300,
};
const dateAndTimeValuesStyle = {
  fontSize: "0.75rem",
  fontWeight: 500,
};

const toggleDateContainerStyle = (isCalendarSelected: boolean) => ({
  border: isCalendarSelected ? "1px solid #6366F1" : "1px solid #CAD0D9",
  borderTopLeftRadius: 8,
  borderBottomLeftRadius: 8,
});

const toggleTimeContainerStyle = (isCalendarSelected: boolean) => ({
  border: !isCalendarSelected ? "1px solid #6366F1" : "1px solid #CAD0D9",
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
});
