import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { TextIcon, TextInfo } from "Components/Fields";
import ControlledCheckbox from "Components/FormComponents/ControlledCheckbox";
import { Link } from "Components/StyledComponents";
import { Control } from "react-hook-form";
import { InfoIcon } from "styles/icons";

interface IProps {
  isLoading: boolean;
  control: Control<any>;
}
const SignupBottom: React.FC<IProps> = ({
  isLoading,
  control,
}): JSX.Element => {
  return (
    <>
      <ControlledCheckbox
        name="agree"
        control={control}
        label={
          <Typography
            color="grey.700"
            component={"span"}
            variant={"extraSmallRegularText"}
          >
            By creating an account you agree to the <br />
            <Typography
              sx={{ color: "primary.main" }}
              component={"span"}
              variant={"extraSmallRegularText"}
            >
              <Link underline to="/">
                terms of uses
              </Link>
            </Typography>{" "}
            and our{" "}
            <Typography
              sx={{ color: "primary.main" }}
              component={"span"}
              variant={"extraSmallRegularText"}
            >
              <Link underline to="/">
                privacy policy
              </Link>
            </Typography>
          </Typography>
        }
      />
      <Grid container className="isAriaExpanded" mt={0}>
        <Grid item xs={12}>
          <LoadingButton
            variant="primary"
            loading={isLoading}
            type="submit"
            fullWidth
            sx={{ my: 1.5 }}
          >
            Sign Up
          </LoadingButton>
        </Grid>
        <Grid item xs={12} mb={1}>
          <Typography variant="smallRegularText" color="text.secondary" mt={0}>
            Have an account?{" "}
            <Link underline color="primary" to="/login">
              Login
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <TextIcon
        fontColor={"primary.main"}
        iconPosition="start"
        variant={"extraSmallRegularText"}
        sx={{ fontWeight: 600, textTransform: "none" }}
        text={
          "Are you an agent?\n Empower your selling expeirnce -\n Sell with us, your rules.\n Get in touch with us contact@ephrown.com to\n learn more."
        }
        icon={<InfoIcon />}
      />
    </>
  );
};

export default SignupBottom;
