import { Grid, Typography } from "@mui/material";

interface IProps {
  denialReason: string | undefined;
  denialNotes: string | undefined;
  sx?: object;
}

const DenialsText: React.FC<IProps> = ({
  denialReason,
  denialNotes,
  sx,
}): JSX.Element => {
  return (
    <Grid container sx={sx}>
      {denialReason && (
        <Grid container>
          <Typography variant="smallBoldText" sx={denialItemStyle}>
            Denial Reason:
          </Typography>
          <Typography variant="extraSmallRegularText" color="grey.700">
            {denialReason}
          </Typography>
        </Grid>
      )}
      {denialNotes && (
        <Grid container>
          <Typography variant="smallBoldText" sx={denialItemStyle}>
            Denial Note:
          </Typography>
          <Typography variant="extraSmallRegularText" color="grey.700">
            {denialNotes}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default DenialsText;

const denialItemStyle = {
  flexBasis: 105,
  color: "grey.800",
  fontSize: "0.75rem",
  "+ *": {
    flex: { xs: "auto", md: 1 },
  },
};
