import { Grid, Typography } from "@mui/material";
import { useGetUserListingDetailsQuery } from "features/listing/api/userApi";
import {
  formatFullName,
  formatPhone,
} from "features/listing/utils/listingsUtils";
import { Portal } from "features/users/types";
import { useParams } from "react-router-dom";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import { AttorneyCard } from "styles/components";
import { attorneyCardStyle } from "styles/components/AttorneyCard";

interface IProps {}

const AgentDetails: React.FC<IProps> = ({}): JSX.Element => {
  const itemId = useParams().itemId || "";
  const { portal } = useAppSelector(selectAuth);
  const { data: listingData } = useGetUserListingDetailsQuery(itemId, {
    skip: portal !== null && portal !== Portal.USER,
  });
  const attorneyName = formatFullName(
    listingData?.attorney_first_name,
    listingData?.attorney_last_name
  );
  const agentName = listingData?.agentName;
  const agentCompany = listingData?.agentCompany;
  const agentEmail = listingData?.agentEmail;
  const agentPhone = listingData?.agentPhone;
  return (
    <Grid container rowGap={2} sx={containerStyle}>
      <Grid container>
        <Typography sx={rowsKeysStyle}>Agents Name:</Typography>
        <Typography
          fontWeight={700}
          variant="smallRegularText"
          color={"grey.800"}
        >
          {agentName}
        </Typography>
      </Grid>
      <Grid container>
        <Typography sx={rowsKeysStyle}>Company:</Typography>
        <Typography
          variant="smallRegularText"
          fontWeight={700}
          color={"grey.800"}
        >
          {agentCompany}
        </Typography>
      </Grid>
      <Grid container>
        <Typography sx={rowsKeysStyle}>Email:</Typography>
        <Typography variant="extraSmallBoldText" color={"grey.300"}>
          {agentEmail}
        </Typography>
      </Grid>
      <Grid container>
        <Typography sx={rowsKeysStyle}>Phone Number:</Typography>
        <Typography variant="extraSmallBoldText" color={"grey.300"}>
          {formatPhone(agentPhone)}
        </Typography>
      </Grid>
      <Grid container>
        <Typography sx={rowsKeysStyle}>Attorney:</Typography>
      </Grid>
      <Grid container>
        <AttorneyCard
          email={listingData?.attorney_email_address}
          phone={listingData?.attorney_number}
          name={attorneyName}
          sx={attorneyCardStyle}
        />
      </Grid>
    </Grid>
  );
};

export default AgentDetails;

const containerStyle = {
  bgcolor: "common.white",
  border: "1.5px solid",
  borderColor: "grey.600",
  p: 2,
  borderRadius: 1,
};

const rowsKeysStyle = {
  flexBasis: 100,
  mr: { xs: 0, lg: 0 },
  color: "grey.500",
  fontSize: "0.75rem",
};
