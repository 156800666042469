import * as yup from "yup";

const multiLineSchema = yup
  .string()
  .matches(/^[a-zA-Z0-9@$#%&*()_+=\{\}\[\]| ~,\^"'\.,\n\r\n\t]*$/, "Invalid characters in the input")
  .min(0, "Minimum 0 characters")
  .max(225, "Maximum 225 characters");

export { multiLineSchema };

