import { Box, DialogTitle, Grid, Typography } from "@mui/material";
import { ErrorResponse } from "@remix-run/router";
import { digitsRegex } from "Components/Validation/regex";
import { useSubmitOfferMutation } from "features/offers/api/offersApi";
import { SelectOrAddPaymentMethod } from "features/payments";
import { useState } from "react";
import { listingState } from "store/features/listingSlice";
import { useAppSelector } from "store/hooks";
import { useDialogContext } from "store/hooks/DialogsContext";
import { ErrorIcon } from "styles/icons";
import { DialogContainer } from ".";
import { ControlledTextField } from "../FormComponents";
import DialogActions from "./DialogActions";

enum Steps {
  CVV = "cvv",
  PAYMENT_METHODS = "paymentMethods",
}

interface IProps {
  lastFourDigits: number;
  methods: any;
}

const CVVDialog: React.FC<IProps> = ({
  lastFourDigits,
  methods,
}): JSX.Element => {
  const [step, setStep] = useState<Steps>(Steps.CVV);
  const { listing } = useAppSelector(listingState);
  const { closeDialog } = useDialogContext();
  const [
    submitOffer,
    { error: submitOfferError, isLoading: submitOfferLoading },
  ] = useSubmitOfferMutation();
  const offer_amount = methods?.watch("offer_amount");
  const handleSubmitClick = async () => {
    const body = {
      offer_amount: Number(offer_amount),
      cvv: methods?.watch("cvv"),
      ...(methods?.getValues().hasOwnProperty("on_contract") && {
        on_contract: Number(methods?.watch("on_contract")),
        mortgage_amount: Number(methods?.watch("mortgage_amount")),
        contingencies: methods
          ?.watch("contingencies")
          ?.filter((contingency: any) => contingency?.is_waived)
          ?.map((contingency: any) => contingency?.contingency_id),
      }),
    };
    const isCvvValid = await methods.trigger("cvv");
    try {
      if (isCvvValid) {
        await submitOffer({ listingId: listing?.id, body }).unwrap();
        closeDialog();
      }
    } catch (error) {
      console.error("Error adding payment method:", error);
    }
  };

  return (
    <DialogContainer hideExit sx={dialogContainerStyle}>
      {step === Steps.CVV && (
        <>
          <DialogTitle sx={titleStyle}>
            Please verify the CVV <br /> for CC ending in{" "}
            {lastFourDigits || "**"}
            {submitOfferError && (
              <Grid
                container
                my={1}
                alignItems="center"
                justifyContent={"center"}
              >
                <Grid
                  item
                  display={"flex"}
                  alignItems={"center"}
                  gap={0.5}
                  mr={0.5}
                >
                  <ErrorIcon sx={{ fontSize: "1.25rem" }} />
                  <Typography
                    variant="extraSmallBoldText"
                    component={"span"}
                    color="grey.900"
                  >
                    There was an error:
                  </Typography>
                </Grid>
                <Typography
                  variant="extraSmallRegularText"
                  component={"span"}
                  color="grey.900"
                >
                  {(submitOfferError as ErrorResponse)?.data?.message}
                </Typography>

                <Typography
                  onClick={() => setStep(Steps.PAYMENT_METHODS)}
                  sx={{ cursor: "pointer", mt: 1, textDecoration: "underline" }}
                  variant="extraSmallRegularText"
                  color="primary.main"
                >
                  Select New Payment Method
                </Typography>
              </Grid>
            )}
          </DialogTitle>
          <Box mx="auto">
            <ControlledTextField
              sx={cvvTextStyle}
              control={methods?.control}
              name={"cvv"}
              placeholder="CVV"
              regex={digitsRegex}
              maxLength={4}
            />
          </Box>
          <DialogActions
            onSubmit={handleSubmitClick}
            isLoading={submitOfferLoading}
          />
        </>
      )}
      {step === Steps.PAYMENT_METHODS && (
        <SelectOrAddPaymentMethod
          methods={methods}
          setStep={setStep}
          isCVVDialog={true}
        />
      )}
    </DialogContainer>
  );
};

export default CVVDialog;

export const titleStyle = {
  textAlign: "center",
  color: "primary.main",
  fontWeight: 700,
  pt: 1,
};
export const dialogContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiDialog-paper": {
    borderRadius: 2,
    width: 380,
  },
  "@media (max-width: 601px)": {
    "& .MuiDialog-paper": {
      minWidth: "90%",
    },
  },
};

const cvvTextStyle = {
  input: {
    textAlign: "center !important",
    fontSize: "1.125rem !important",
    maxWidth: 100,
    fontWeight: 600,
    ":placeholder": {
      fontSize: "1.125rem !important",
    },
  },
};
