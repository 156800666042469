import { Theme } from "@mui/material/styles";

export const successLight = (theme: Theme) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.success.light,
  borderColor: theme.palette.success.light,
  transitionProperty: "all",
  "&:hover": {
    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
  "&:focus": {
    backgroundColor: theme.palette.success.main,
  },
  "&:active": {
    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
  "&:disabled": {
    backgroundColor: theme.palette.grey[600],
    borderColor: theme.palette.grey[600],
    color:theme.palette.grey[500],
    
  },
});
