import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { ControlledFullAddress } from "Components/FormComponents";
import { useUpdateUserAddressMutation } from "features/listing/api/userApi";
import { updateAddressSchema } from "features/settings/schema/updateAddressSchema";
import { IUserInfoResponse } from "features/users/api/userApi";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

interface IProps {
  userData?: IUserInfoResponse | undefined;
}

interface IAddressUpdateInfoForm {
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip: string;
}

const FullAddressForm: React.FC<IProps> = ({ userData }) => {
  const methods = useForm<IAddressUpdateInfoForm>({
    defaultValues: {
      address_line_1: userData?.user.address_line_1 || "",
      address_line_2: userData?.user.address_line_2 || "",
      city: userData?.user.city || "",
      state: userData?.user.state || "",
      zip: userData?.user.zip || "",
    },
    mode: "onChange",
    resolver: yupResolver(updateAddressSchema),
  });

  const isEditableOption = true;
  const [isEditable, setIsEditable] = useState(false);
  const [updateUserAddress, { isLoading }] = useUpdateUserAddressMutation();
  const handleAddressUpdate = (data: IAddressUpdateInfoForm) => {
    const body = {
      address_line_1: data.address_line_1,
      address_line_2: data.address_line_2,
      city: data.city,
      state: data.state,
      zip: data.zip,
    };
    updateUserAddress(body);
    setIsEditable(!isEditable);
  };

  const handleCancelClick = () => {
    setIsEditable(!isEditable);
    methods.reset();
  };
  return (
    <FormProvider {...methods}>
      <Grid
        item
        component={"form"}
        onSubmit={methods.handleSubmit(handleAddressUpdate)}
      >
        <ControlledFullAddress
          isEditableOption
          isEditable={isEditable}
          setIsEditable={setIsEditable}
        />
        {isEditableOption && isEditable && (
          <Grid
            container
            justifyContent={"center"}
            gap={3}
            sx={{ flexWrap: "nowrap", mt: 1 }}
          >
            <LoadingButton
              onClick={handleCancelClick}
              fullWidth
              variant="tertiary"
            >
              Cancel
            </LoadingButton>

            <LoadingButton
              fullWidth
              variant="primary"
              type="submit"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
};

export default FullAddressForm;
