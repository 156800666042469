import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { selectAuth } from "store/features/authSlice";

interface IProps {
  redirect?: {
    user?: string;
    agent?: string;
    admin?: string;
  };
}

const PublicRoute: React.FC<IProps> = ({ redirect }): JSX.Element | null => {
  const location = useLocation();
  const navigate = useNavigate();
  const { portal } = useAppSelector(selectAuth);
  if (location.state && location.state.redirectToLive && portal) {
    navigate(location.state.redirectToLive, { replace: true, state: null });
    return null;
  }
  if (location.state && location.state.redirectToPublished && portal) {
    navigate(location.state.redirectToPublished, {
      replace: true,
      state: null,
    });
    return null;
  }
  if (portal) {
    // Check if the redirect property for the portal exists
    const portalRedirect = redirect?.[portal];

    // Check if portalRedirect is defined and not an empty string
    if (portalRedirect) {
      return (
        <Navigate to={portalRedirect} state={{ from: location }} replace />
      );
    }
  }

  // For guests or if no valid redirect URL is found, display the page component
  return <Outlet />;
};

export default PublicRoute;
