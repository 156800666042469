import { Grid, Typography } from "@mui/material";
import { IListing, ListingStatus } from "features/listing/types";
import { selectAuth } from "store/features/authSlice";
import { useAppSelector } from "store/hooks";
import { formatUSD } from "utils/helpers";
import { listingRowStyle } from "../AuctionListing";
import { Portal } from "features/users/types";

interface IProps {
  listing: IListing;
}

type IPrice = {
  title: string;
  priceType: number;
};

const PriceColumn: React.FC<IProps> = ({ listing }) => {
  const { portal } = useAppSelector(selectAuth);
  const isUserPortal = portal === Portal.USER || portal === null;
  const title: string = isUserPortal
    ? "Listed Price"
    : getTitleAndPriceForAgent(listing).title;
  const price = isUserPortal
    ? listing.price
    : getTitleAndPriceForAgent(listing).priceType;
  return (
    <Grid xs={2} item sx={{ ...listingRowStyle, minWidth: 140 }}>
      <Typography variant="extraSmallRegularText" color="grey.500">
        {title}
      </Typography>
      <Typography variant="h6Bold" color={"primary.dark"}>
        {formatUSD(price)}
      </Typography>
    </Grid>
  );
};

const getTitleAndPriceForAgent = (listing: IListing): IPrice => {
  switch (listing.status) {
    case ListingStatus.DRAFT:
      return {
        title: "Selling Price",
        priceType: listing.price,
      };
    case ListingStatus.PUBLISHED:
    case ListingStatus.PUBLISH_SCHEDULED:
    case ListingStatus.SOLD:
      return {
        title: "Selling Price",
        priceType: listing.price,
      };

    case ListingStatus.LIVE:
      return { title: "Selling Price", priceType: listing.price };

    case ListingStatus.PENDING_WINNER:
      return {
        title: "Highest Offer",
        priceType: listing.highest_offer,
      };

    case ListingStatus.IN_CONTRACT:
    case ListingStatus.PENDING_CONTRACT:
      return { title: "Winning Offer", priceType: listing.selling_price };

    case ListingStatus.NO_WINNER:
    case ListingStatus.NO_OFFERS:
    case ListingStatus.FAILED_SALE:
    case ListingStatus.FAILED_CONTRACT:
      return { title: "Listed Price", priceType: listing.price };

    default:
      return { title: "Price", priceType: listing.price };
  }
};

export default PriceColumn;
