import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { MySubmittedOffersCollapse } from "Components/Collapses";
import { formatToHHMMA, formatToMMDDYY } from "Components/DatesAndTime/helpers";
import { IMyOffer } from "features/offers/api";
import { IAllOffers } from "features/offers/api/apiTypes";
import { useState } from "react";
import { formatUSD } from "utils/helpers";

interface IProps {
  offer?: IMyOffer | IAllOffers;
  isOpenListing?: boolean;
  isMyOfferType?: boolean;
  isMyOffer?: boolean;
  isHighestOffer?: boolean;
  showByUsername?: boolean;
}
const MySubmittedOffers: React.FC<IProps> = ({
  offer,
  isOpenListing,
  isMyOfferType,
  isMyOffer,
  isHighestOffer,
  showByUsername = false,
}): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <Grid
      container
      sx={isMyOffer ? containerStyle(isMyOffer) : containerStyle(false)}
    >
      <Grid item>
        <Typography variant="boldText" color="primary.main" mb={1}>
          {formatUSD(Number(offer?.offer_amount))}
        </Typography>
        {!isMyOfferType && showByUsername && (
          <Typography
            variant={isMyOffer ? "smallBoldText" : "smallRegularText"}
            color={isMyOffer ? "grey.700" : "grey.500"}
          >
            {isMyOffer ? "By: You" : `By: ${(offer as IAllOffers).username}`}
          </Typography>
        )}
      </Grid>
      <Grid item display="flex" flexDirection="column" alignItems="end">
        <Typography
          variant="extraSmallBoldText"
          color="grey.700"
          fontWeight={600}
          sx={{ mr: 1 }}
        >
          {formatToMMDDYY(offer?.offer_date || "")}
        </Typography>

        <Typography
          variant="extraSmallRegularText"
          color="grey.500"
          sx={{ mb: 0.5, mr: 1 }}
        >
          {formatToHHMMA(offer?.offer_date || "")}
        </Typography>
        {isHighestOffer && (
          <LoadingButton
            variant="success"
            size="small"
            sx={{ ...highestButtonStyle, pointerEvents: "none" }}
          >
            Highest Bid
          </LoadingButton>
        )}
      </Grid>
      {!isOpenListing && (
        <Grid item xs={12} sx={expandContingenciesStyle(expanded)}>
          <MySubmittedOffersCollapse
            contingencies={offer?.contingencies || []}
            setExpanded={setExpanded}
            expanded={expanded}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default MySubmittedOffers;

export const containerStyle = (isMyOffer: boolean) => ({
  borderRadius: 1,
  p: 2,
  bgcolor: "common.white",
  border: "1px solid",
  borderColor: isMyOffer ? "primary.main" : "grey.400",
  alignItems: "center",
  mb: 1,
  justifyContent: "space-between",
  gap: 1,
});

const expandContingenciesStyle = (expanded: boolean) => ({
  borderTop: expanded ? "1px solid white" : "1px solid white",
  borderColor: expanded ? "grey.400" : "white",
  marginTop: expanded ? 1 : 0,
  transition: "0.3s ease",
});

const highestButtonStyle = {
  borderRadius: 3,
  fontSize: "0.5625rem",
  px: 1.5,
  gap: 1,
  height: 18,
  pointerEvents: "none",
};
