import * as yup from "yup";

const emailRegex = /^[^\s@<>]+@[^\s@<>]+\.[^\s@<>]+$/;


const emailSchema = yup
  .string()
  .required("email is required")
  .matches(emailRegex, "Email must be valid")
  .max(50, "Email cannot exceed 50 characters");

export { emailSchema };

