import type {
  IFileType,
  IInvitedUser,
  IListing,
  IListingDetails,
} from "features/listing/types";
import { IListingsOffers, TListingOffer } from "features/offers/api";
import { IListingOffersResponse } from "features/offers/api/responseTypes";
import { ephrownApi } from "store/services/ephrownApi";
import {
  getPropForStatus,
  transformListing,
  transformListingDetails,
} from "store/services/helpers";
import type { IStatusItem, ListingParam } from "./apiTypes";
import {
  IAvailablePublishDatesResponse,
  IFileTypeResponse,
  IInvitedUsersResponse,
  IListingDetailsResponse,
  IListingsResponse,
} from "./responseTypes";

const agentListingsApi = ephrownApi.injectEndpoints({
  endpoints: (builder) => ({
    getAgentListings: builder.query<IListing[], ListingParam>({
      query: (params: any) => ({
        url: "/api/private/agent/listings",
        params,
      }),
      providesTags: ["userListings", "agentListings", "offers"],

      transformResponse: (response: IListingsResponse) =>
        response.listings.map(transformListing),
    }),

    addNewListing: builder.mutation({
      query: (payload) => ({
        url: `/api/private/agent/new-listing-with-parameters`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
      transformResponse: (response: IListingDetailsResponse) =>
        response.listing,
    }),

    getAgentListingDetails: builder.query<IListingDetails, string>({
      query: (listingId) => ({
        url: `/api/private/agent/listing/${listingId}/details`,
      }),
      providesTags: ["userListings", "agentListings", "offers"],

      transformResponse: (response: IListingDetailsResponse) =>
        transformListingDetails(response),
    }),

    addListingToArchive: builder.mutation({
      query: ({ id }) => ({
        url: `/api/private/agent/listing/${id}/archive`,
        method: "POST",
        body: id,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),

    getAvailablePublishDates: builder.query<number, string>({
      query: (listingId) => ({
        url: `/api/private/agent/listing/${listingId}/available-publish-dates`,
      }),
      providesTags: ["userListings", "agentListings", "offers"],

      transformResponse: (response: IAvailablePublishDatesResponse) =>
        response.max_available_date,
    }),

    updatePublishDate: builder.mutation({
      query: ({ listing_id, publishDate }) => ({
        url: `/api/private/agent/listing/${listing_id}/update-publish-date`,
        method: "PUT",
        body: {
          publish_date: publishDate,
        },
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),

    schedulePublishDate: builder.mutation({
      query: (payload) => {
        const { listing_id, body } = payload;
        return {
          url: `/api/private/agent/listing/${listing_id}/publish`,
          method: "POST",
          body: body,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),

    editListing: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/private/agent/listing/${id}/edit`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),

    uploadPhotos: builder.mutation({
      query: (payload) => {
        const { listing_id, file_type, body } = payload;
        return {
          url: `/api/private/agent/listing/${listing_id}/upload/${file_type}`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),

    deleteListing: builder.mutation({
      query: (id) => ({
        url: `/api/private/agent/listing/${id}`,
        method: "DELETE",
        credentials: "include",
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
    updateListingStatus: builder.mutation({
      query: ({ status, listingId }) => ({
        url: `/api/private/agent/listing/${listingId}/set-status`,
        method: "POST",
        body: { status },
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
    confirmSale: builder.mutation({
      query: (listingId) => ({
        url: `/api/private/agent/listing/${listingId}/sale/confirm`,
        method: "POST",
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
    voidSale: builder.mutation({
      query: (listingId) => ({
        url: `/api/private/agent/listing/${listingId}/sale/void`,
        method: "POST",
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),

    confirmContract: builder.mutation({
      query: (listingId) => ({
        url: `/api/private/agent/listing/${listingId}/contract/confirm`,
        method: "POST",
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
    voidContract: builder.mutation({
      query: (listingId) => ({
        url: `/api/private/agent/listing/${listingId}/contract/fail`,
        method: "POST",
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),

    getCountListings: builder.query<IStatusItem[], void>({
      query: () => ({
        url: "/api/private/agent/listings/count",
      }),
      transformResponse: (response): IStatusItem[] => {
        const countData = response as object;
        const { message }: any = countData;
        const transformedData: IStatusItem[] = [];
        for (const key in message) {
          if (Object.prototype.hasOwnProperty.call(message, key)) {
            const title = getPropForStatus(key).title;
            const color = getPropForStatus(key).color;
            const item: IStatusItem = {
              title,
              count: message[key],
              color,
            };
            transformedData.push(item);
          }
        }

        return transformedData;
      },
      providesTags: ["userListings", "agentListings", "offers"],
    }),

    getDenialReasons: builder.query<any, void>({
      query: () => ({
        url: "/api/private/agent/settings",
      }),
      providesTags: ["userListings", "agentListings", "offers"],
    }),

    getContingencies: builder.query<any, { archived: boolean }>({
      query: ({ archived }) => ({
        url: `/api/private/agent/contingencies/?archived=${archived}`,
      }),
      providesTags: ["userListings", "agentListings", "offers"],
    }),

    addContingencies: builder.mutation({
      query: ({ listingId, body }) => ({
        url: `/api/private/agent/listing/${listingId}/add-contingencies`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),

    deleteContingencies: builder.mutation({
      query: ({ listingId, body }) => ({
        url: `/api/private/agent/listing/${listingId}/remove-contingencies`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),

    setWaiveRequired: builder.mutation({
      query: ({ listingId, body }) => ({
        url: `/api/private/agent/listing/${listingId}/set-contingencies-waived-required`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),

    getSharedListing: builder.query({
      query: (listingId) => ({
        url: `/api/private/agent/listing/${listingId}/shares`,
      }),
      providesTags: ["userListings", "agentListings", "offers"],
    }),
    getListingOffers: builder.query<IListingsOffers, string>({
      query: (listingId) => ({
        url: `/api/private/agent/listing/${listingId}/offers/all`,
      }),
      providesTags: ["userListings", "agentListings", "offers"],

      transformResponse: (response: IListingOffersResponse) => {
        const res = response?.offers;
        const highestAmount =
          res &&
          Math.max(
            ...response?.offers?.map(
              (offer: TListingOffer) => offer.offer_amount
            )
          );
        return { offers: response.offers, highest_offer: highestAmount };
      },
    }),
    getAgentFileTypes: builder.query<IFileType[], { archived: boolean }>({
      query: ({ archived }) => ({
        url: `/api/private/agent/file-types/?archived=${archived}`,
      }),
      providesTags: ["userListings", "agentListings", "offers"],

      transformResponse: (response: IFileTypeResponse) => response.file_types,
    }),
    archiveAgentFileType: builder.mutation({
      query: (fileTypeId) => ({
        url: `/api/private/agent/file-type/${fileTypeId}/archive`,
        method: "PUT",
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
    unArchiveAgentFileType: builder.mutation({
      query: (fileTypeId) => ({
        url: `/api/private/agent/file-type/${fileTypeId}/unarchive`,
        method: "PUT",
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
    addAgentFileType: builder.mutation({
      query: (name) => ({
        url: `/api/private/agent/file-type/add`,
        method: "POST",
        body: {
          name,
        },
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
    inviteNewUser: builder.mutation<any, any>({
      query: (body) => ({
        url: `/api/private/agent/invite-new-user`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userListings", "agentListings", "offers"],
    }),
    getInvitedUsers: builder.query<IInvitedUser[], void>({
      query: () => ({
        url: `/api/private/agent/invited-users`,
      }),
      providesTags: ["userListings", "agentListings", "offers"],
      transformResponse: (response: IInvitedUsersResponse) =>
        response.invited_users,
    }),
  }),
});

export const {
  useGetAgentListingsQuery,
  useAddNewListingMutation,
  useGetAgentListingDetailsQuery,
  useAddListingToArchiveMutation,
  useGetAvailablePublishDatesQuery,
  useUpdatePublishDateMutation,
  useEditListingMutation,
  useSchedulePublishDateMutation,
  useUploadPhotosMutation,
  useDeleteListingMutation,
  useUpdateListingStatusMutation,
  useGetCountListingsQuery,
  useGetDenialReasonsQuery,
  useGetContingenciesQuery,
  useGetInvitedUsersQuery,
  useAddContingenciesMutation,
  useDeleteContingenciesMutation,
  useSetWaiveRequiredMutation,
  useGetSharedListingQuery,
  useGetListingOffersQuery,

  useConfirmSaleMutation,
  useVoidSaleMutation,
  useConfirmContractMutation,
  useVoidContractMutation,
  useGetAgentFileTypesQuery,
  useArchiveAgentFileTypeMutation,
  useUnArchiveAgentFileTypeMutation,
  useAddAgentFileTypeMutation,
  useInviteNewUserMutation,
} = agentListingsApi;
