import { formatFullAddress } from "store/services/helpers";
import {
  IAgentOfferListing,
  IContingencyOffer,
  TListingOffer,
} from "./apiTypes";
import { IListingOfferorRequests } from "features/offerors/api";

export const transformAgentOfferListing = (
  listing: IAgentOfferListing
): IAgentOfferListing => {
  const fullAddress = formatFullAddress(listing);
  return {
    ...listing,
    full_address: fullAddress,
  };
};

export interface IOfferData {
  id: number;
  firstName: string;
  lastName: string;
  userEmail: string | undefined;
  userPhoneNumber: string | undefined;
  attorneyFirstName: string | undefined;
  attorneyLastName: string | undefined;
  attorneyNumber: string | undefined;
  attorneyEmail: string | undefined;
  proofOfFunds: string | undefined;
  dateCreated: string | number | undefined;
  denialReasonName: string | undefined;
  contingencies: IContingencyOffer[] | undefined;
  status: string;
  offerAmount: number | undefined;
  mortgageAmount: number | undefined;
  onContract: number | undefined;
}

export const formatOfferData = (
  offer: IListingOfferorRequests | TListingOffer
) => {
  const isOfferorRequest = "offeror_request" in offer;
  return {
    id: offer.id,
    firstName: isOfferorRequest ? offer.user.first_name : offer.user_first_name,
    lastName: isOfferorRequest ? offer.user.last_name : offer.user_last_name,
    userPhoneNumber: isOfferorRequest
      ? offer.user.phone
      : offer.user_phone_number,
    userEmail: isOfferorRequest ? offer.user.email : offer.user_email,
    attorneyFirstName: isOfferorRequest
      ? offer.offeror_request.attorney_first_name
      : offer.attorney_first_name,
    attorneyLastName: isOfferorRequest
      ? offer.offeror_request.attorney_last_name
      : offer.attorney_last_name,
    attorneyNumber: isOfferorRequest
      ? offer.offeror_request.attorney_number
      : offer.attorney_number,
    attorneyEmail: isOfferorRequest
      ? offer.offeror_request.attorney_email
      : offer.attorney_email,
    proofOfFunds: isOfferorRequest
      ? offer.offeror_request.proof_of_funds
      : offer.proof_of_funds,
    dateCreated: isOfferorRequest
      ? offer.offeror_request.date_created
      : offer.date_created,
    denialReasonName: isOfferorRequest
      ? offer.offeror_request.denial_reason_name
      : offer.denial_reason_name,
    status: isOfferorRequest ? offer.offeror_request.status : offer.status,
    contingencies: isOfferorRequest ? offer.contingencies : undefined,
    offerAmount: isOfferorRequest ? offer.offer_amount : undefined,
    mortgageAmount: isOfferorRequest ? offer.mortgage_amount : undefined,
    onContract: isOfferorRequest ? offer.on_contract : undefined,
  };
};
