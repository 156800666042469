import { createTheme } from "@mui/material/styles";
import { textFieldStyle } from "styles/components/StyledTextField";
import { variantStyles } from "./buttonsVariants";
import { palette } from "./pallete";
import { MuiTypography, typography } from "./typography";

let theme = createTheme({
  palette,
  typography,
  shape: {
    borderRadius: 8,
  },
});

theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // This is where you can override the default scrollbar styles
        "*::-webkit-scrollbar": {
          width: "12px",
          height: "12px",
        },
        "*::-webkit-scrollbar-thumb": {
          borderRadius: "6px",
          background: "#555",
        },
        "*::-webkit-scrollbar-thumb:hover": {
          background: "#777",
        },
        "*::-webkit-scrollbar-track": {
          background: "#ddd",
        },
        "*::-webkit-scrollbar-track:hover": {
          background: "#ccc",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grey[100],
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          marginRight: 4,
          fontSize: "1rem",
          width: 20,
          height: 20,

          ".MuiSvgIcon-root": {
            fontSize: 16,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grey[400],
          boxShadow: "0px 0px 10px 0px rgba(99, 102, 241, 0.1)",
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: (props: any) => ({
          ".MuiLoadingButton-loadingIndicator": {
            color: theme.palette.common.white,
          },
          textTransform: "none",
          maxWidth: "100%",
          whiteSpace: "nowrap",
          borderRadius: theme.shape.borderRadius,
          gap: theme.spacing(1),
          border: "1px solid",
          fontWeight: 600,
          "&:focus": {
            border: `1px solid ${theme.palette.common.white}`,
            boxShadow: `1px 0px 0px #4F90FA,
                          0px 1px 0px #4F90FA,
                          -1px 0px 0px #4F90FA,
                          0px -1px 0px #4F90FA`,
          },
          "&:hover": { boxShadow: "none", border: "1px solid" },
          "&:active": { boxShadow: "none", border: "1px solid" },
          minWidth:
            props?.size === "small" ? 65 : props?.size === "large" ? 103 : 80,
          height:
            props?.size === "small" ? 24 : props?.size === "large" ? 48 : 40,
          padding:
            props?.size === "small"
              ? "4px 12px"
              : props?.size === "large"
              ? "8px 16px"
              : "12px 24px",
          fontSize:
            props?.size === "small"
              ? "0.75rem"
              : props?.size === "large"
              ? "1rem"
              : "0.875rem",
        }),
      },
      variants: Object.keys(variantStyles).map((variant) => ({
        props: { variant },
        style: variantStyles[variant](theme),
      })),
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          margin: 4,
          borderRadius: 4,
          "&:hover .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
          "&:hover": {
            backgroundColor: "#f6f8ff",
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          MenuListProps: {
            disablePadding: true,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: textFieldStyle(theme),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: textFieldStyle(theme),
      },
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiTypography,
  },
});

export default theme;

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    darkPrimary: true;
    lightPrimary: true;
    error: true;
    errorLight: true;
    secondary: true;
    tertiary: true;
    tertiaryPrimary: true;
    success: true;
    successLight: true;
    successSecondary: true;
    transparent: true;
    warning: true;
    info: true;
    grey: true;
    greyLight: true;
    white: true;
    warningLight: true;
  }
}
